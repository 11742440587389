import {
	useContext,
	createContext,
	useEffect,
	useState,
	useRef,
	useCallback,
} from "react"
import { HeadlessService, IMessage } from "@novu/headless"
import useAppStore from "../zustand/new-store"

const NotificationContext = createContext<{
	unread: number
	notifications: IMessage[]
	markNotificationsAsRead: (messageIds: string | string[]) => void
	pageNum: number
	setPage: (num: number) => void
	fetchNotifications: () => void
	fetchUnread: () => void
	hasMore: boolean
	loading: boolean
	fetchNotifsError: boolean
}>({
	unread: 0,
	notifications: [],
	markNotificationsAsRead: messageIds => null,
	pageNum: 0,
	setPage: num => null,
	fetchNotifications: () => null,
	fetchUnread: () => null,
	hasMore: false,
	loading: false,
	fetchNotifsError: false,
})

const NotificationProvider = ({ children }: { children: React.ReactNode }) => {
	const [notifications, setNotifications] = useState<IMessage[]>([])
	const { cacheConfig } = useAppStore()
	const [hasMore, setHasMore] = useState(false)
	const [unread, setUnread] = useState(0)
	const [loading, setLoading] = useState(false)
	const headlessServiceRef = useRef<HeadlessService | null>(null)
	const [pageNum, setPageNum] = useState(0)
	const [fetchNotifsError, setFetchNotifsError] = useState(false)

	const fetchNotifications = useCallback(() => {
		const headlessService = headlessServiceRef.current
		if (headlessService) {
			headlessService.fetchNotifications({
				listener: ({ isError, isLoading, status }) => {
					if (isError) {
						setFetchNotifsError(true)
					}
					setLoading(isLoading)
				},
				onSuccess: response => {
					setFetchNotifsError(false)
					setHasMore(response.hasMore)
					setLoading(false)
					setNotifications(response.data)
				},
				onError(error) {
					setLoading(false)
				},
				page: pageNum,
			})
		}
	}, [pageNum, cacheConfig?.host])

	const fetchUnread = useCallback(() => {
		const headlessService = headlessServiceRef.current
		if (headlessService) {
			headlessService.fetchUnreadCount({
				listener: ({ isError, isLoading }) => {
					if (isError) {
						setFetchNotifsError(true)
					}
					setLoading(isLoading)
				},
				onSuccess: response => {
					setFetchNotifsError(false)
					setUnread(response.count)
					setLoading(false)
				},
				onError(error) {
					setLoading(false)
				},
			})
		}
	}, [cacheConfig?.host])

	useEffect(() => {
		const headlessService = new HeadlessService({
			applicationIdentifier: process.env.REACT_APP_NOVU_APPLICATION_ID || "",
			subscriberId: cacheConfig?.host,
		})

		headlessService.initializeSession({
			listener: res => {},
			onSuccess: session => {
				headlessServiceRef.current = headlessService

				fetchNotifications()
				fetchUnread()
			},
			onError: error => {
				console.log("headless error:", error)
				setFetchNotifsError(true)
			},
		})
	}, [fetchNotifications, cacheConfig?.host])

	const markNotificationsAsRead = (messageIds: string | string[]) => {
		if (!Array.isArray(messageIds)) {
			messageIds = [messageIds]
		}
		const headlessService = headlessServiceRef.current
		if (headlessService) {
			headlessService.markNotificationsAs({
				mark: {
					read: true,
					seen: true,
				},
				onSuccess(message) {
					const notifs = notifications.map(n => {
						if (n._id === messageIds[0]) {
							n.read = true
							n.seen = true
						}
						return n
					})
					setNotifications(notifs)
					fetchUnread()
				},
				messageId: messageIds,
				listener: result => {},
				onError: error => {
					console.error("Error marking notifications as read:", error)
				},
			})
		}
	}
	const setPage = (num: number) => {
		setLoading(true)
		setPageNum(num)
	}

	useEffect(() => {
		const headlessService = headlessServiceRef.current

		if (headlessService) {
			headlessService.listenNotificationReceive({
				listener(message) {
					fetchUnread()

					if (pageNum === 0) {
						const a = [...notifications]
						if (a.length > 1 && a[0]._id !== message._id) {
							a.unshift(message)
						}
						if (a.length > 10) {
							a.pop()
						}
						setNotifications(a)
						return
					} else {
						setPage(0)
					}
				},
			})
		}
	}, [
		cacheConfig?.host,
		headlessServiceRef.current,
		notifications,
		fetchUnread,
	])
	return (
		<NotificationContext.Provider
			value={{
				notifications,
				markNotificationsAsRead,
				pageNum,
				setPage,
				fetchNotifications,
				fetchUnread,
				hasMore,
				loading,
				unread,
				fetchNotifsError,
			}}
		>
			{children}
		</NotificationContext.Provider>
	)
}

const useNotification = () => useContext(NotificationContext)

export { useNotification, NotificationProvider }
