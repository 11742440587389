import { Email } from "../../../assets/icons"
import { isClerkAPIResponseError, useSignUp } from "@clerk/clerk-react"
import { Logo } from "../../../assets/icons"
import {
	Stack,
	Text,
	rem,
	createStyles,
	Box,
	MediaQuery,
	Flex,
	UnstyledButton,
} from "@mantine/core"
import { Link, useNavigate } from "react-router-dom"
import CustomPinInput from "../../../Components/PinInput/PinInput"
import { IconReload, IconX } from "@tabler/icons-react"
import { useState } from "react"
import { notifications } from "@mantine/notifications"
import { useDocumentTitle } from "@mantine/hooks"

const useStyles = createStyles(theme => ({
	logo: {
		marginBottom: rem(75),
		marginTop: rem(66),
	},
	serviceLink: {
		display: "inline",
		fontWeight: 400,
		fontSize: "inherit",
		linHeight: "inherit",
		letterSpacing: "inherit",
		fontVariantNumeric: "inherit",
		color: theme.colors["refactor"][0],
	},
	highlight: {
		fontSize: rem(16),
		lineHeight: rem(24),
		fontWeight: 500,
		letterSpacing: -0.2,
		color: theme.colors[theme.primaryColor][7],
	},
}))

interface IProps {
	email: string
	resend: () => void
	resendSuccess: boolean
	setPendingVerification: () => void
}
const OTP = ({
	email,
	resend,
	resendSuccess,
	setPendingVerification,
}: IProps) => {
	const { classes, cx } = useStyles()
	const { isLoaded, signUp, setActive } = useSignUp()
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()
	const [otp, setOTP] = useState("")
	const [otpError, setOTPError] = useState("")
	useDocumentTitle("OTP Verification")

	const verifyPin = async () => {
		setLoading(true)
		if (!isLoaded) {
			setLoading(false)
			return
		}
		try {
			const completeSignUp = await signUp.attemptEmailAddressVerification({
				code: otp,
			})
			if (completeSignUp.status !== "complete") {
				console.log(completeSignUp)
			}
			if (completeSignUp.status === "complete") {
				await setActive({ session: completeSignUp.createdSessionId })
				navigate("/")
			}
		} catch (error) {
			if (isClerkAPIResponseError(error)) {
				notifications.show({
					withCloseButton: true,
					color: "danger.0",
					title: "Error",
					icon: <IconX />,
					autoClose: 5000,
					message: error.errors[0].longMessage,
				})
				setOTPError(error.errors[0].longMessage || "Something went wrong")
			} else {
				notifications.show({
					withCloseButton: true,
					color: "danger.0",
					title: "Error",
					icon: <IconX />,
					autoClose: 5000,
					message: "Something went wrong",
				})
			}
			console.log(error)
		}

		setLoading(false)
	}

	return (
		<MediaQuery
			query='(min-width: 75em)'
			styles={{
				width: rem(360),
			}}
		>
			<Flex
				sx={{
					width: `max(${rem(360)}, 45vw)`,
					alignItems: "flex-start",
				}}
			>
				<Stack
					justify='center'
					sx={{
						gap: rem(0),
					}}
				>
					<Logo className={classes.logo} />
					<Stack sx={{ gap: rem(40) }}>
						<Email />
						<Stack sx={{ gap: rem(8) }}>
							<Text
								component='h1'
								sx={theme => ({
									fontSize: rem(36),
									fontWeight: 600,
									lineHeight: rem(40),
									letterSpacing: rem(-0.2),
									color: theme.colors[theme.primaryColor][7],
								})}
								mt={0}
								mb={0}
							>
								Check your inbox
							</Text>
							<Text
								component='h2'
								mt={0}
								mb={0}
								sx={theme => ({
									lineHeight: rem(24),
									fontSize: rem(16),
									letterSpacing: rem(-0.2),
									fontWeight: 400,
									color: theme.colors["refactor"][4],
								})}
							>
								Please enter the 6-digit code we sent to your email
								address{" "}
								<span className={classes.highlight}>{email}</span>.
							</Text>
						</Stack>

						<Box
							component='form'
							sx={{
								alignItems: "flex-start",
								justifyContent: "center",
								display: "flex",
								flexDirection: "column",
								gap: rem(16),
							}}
							w={"100%"}
						>
							<CustomPinInput
								name='pin'
								onComplete={() => {
									verifyPin()
								}}
								errorMessage={otpError}
								value={otp}
								onChange={e => {
									setOTP(e)
								}}
								disabled={loading}
							/>
							<UnstyledButton
								disabled={resendSuccess || loading}
								sx={theme => ({
									display: "flex",
									gap: rem(8),
									padding: `${rem(10)} ${rem(16)}`,
									border: ` 1px solid ${theme.colors["refactor"][7]}`,
									borderRadius: rem(6),
									width: "fit-content",
									alignItems: "center",
									cursor: "pointer",
								})}
								onClick={() => {
									resend()
								}}
							>
								<IconReload stroke='#737373' />
								<Text
									sx={theme => ({
										fontSize: rem(14),
										lineHeight: rem(16),
										fontVariantNumeric:
											"lining-nums  proportional-nums",
										color: theme.colors["refactor"][4],
										letterSpacing: -0.2,
									})}
								>
									{resendSuccess ? "Code resent" : "Resend code"}
								</Text>
							</UnstyledButton>
						</Box>

						<Box
							sx={theme => ({
								borderRadius: rem(8),
								padding: rem(16),
								background: theme.colors["refactor"][9],
							})}
						>
							<Text
								sx={theme => ({
									color: theme.colors["refactor"][0],
									fontVariantNumeric: "lining-nums  proportional-nums",
									fontSize: rem(14),
									lineHeight: rem(16),
									letterSpacing: -0.2,
									display: "inline-block",
									borderRadius: rem(6),
									border: ` 1px solid ${theme.colors["refactor"][7]}`,
									width: "fit-content",
								})}
							>
								Can’t see the email? Please check the spam folder. Wrong
								email?{" "}
								<Link
									to={""}
									className={classes.serviceLink}
									onClick={e => {
										e.preventDefault()
										setPendingVerification()
									}}
								>
									Please re-enter your email address
								</Link>
							</Text>
						</Box>
					</Stack>
				</Stack>
			</Flex>
		</MediaQuery>
	)
}

export default OTP
