import { analyticsAxiosConfig } from "../../../config/axios.config"

export async function purgeEndpoints(body: { token: string; paths: string[] }) {
	const response = await analyticsAxiosConfig.delete(`/cache/tag`, {
		headers: {
			Authorization: `Bearer ${body.token}`,
		},
		data: JSON.stringify(body.paths),
	})
	const { data } = response
	return data
}
