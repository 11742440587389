import { Group, Stack, rem } from "@mantine/core"
import { useEffect, useState } from "react"
import { Intervals } from "../../../types/dashboard-chart-types"
import HomeHeader from "./components/HomeHeader"
import InfoCardLayout from "./components/InfoCardLayout"
import LatencyChart from "./components/LatencyChart"
import EndpointTraffic from "./components/EndpointTraffic"
import MethodTraffic from "./components/MethodTraffic"
import GeoTraffic from "./components/GeoTraffic"
import { useDocumentTitle } from "@mantine/hooks"
import useTourStore from "../../../zustand/tour-store"

const Home = () => {
	const [interval, setInterval] = useState<Intervals>(Intervals["6hour"])
	useDocumentTitle("Overview")
	const { updateTourState, tourActive, completedTour } = useTourStore()

	useEffect(() => {
		if (tourActive && !completedTour) {
			updateTourState({ run: true, stepIndex: 0 })
		}
	}, [tourActive, completedTour])
	return (
		<Stack
			w='100%'
			sx={{
				gap: rem(32),
			}}
		>
			<HomeHeader
				interval={interval}
				onChange={e => {
					setInterval(e)
				}}
			/>

			<InfoCardLayout interval={interval} />
			<LatencyChart interval={interval} />
			<GeoTraffic interval={interval} />
			<Group
				noWrap
				sx={{
					gap: rem(32),
				}}
			>
				<EndpointTraffic interval={interval} />
				<MethodTraffic interval={interval} />
			</Group>
		</Stack>
	)
}

export default Home
