import { PinInput } from "react-hook-form-mantine"
import {
	Collapse,
	PinInput as MantinePinInput,
	createStyles,
	rem,
	Text,
	Stack,
} from "@mantine/core"
import { Control } from "react-hook-form"

interface IProps {
	name: string
	disabled?: boolean
	required?: true
	control?: Control<any, any>
	onComplete: () => void
	errorMessage?: string
	onChange?: (value: string) => void
	value?: string
}

const useStyles = createStyles(theme => ({
	root: {
		width: "100%",
	},
	wrapper: {
		aspectRatio: "1/1",
		flexGrow: 1,
	},
	input: {
		width: "100%",
		height: "100%",
		padding: `${rem(10)} ${rem(8)}`,
		borderRadius: rem(8),
		background: theme.colors["refactor"][8],
		border: `1px solid ${theme.colors["refactor"][2]}`,
		":focus": {
			background: theme.white,
			border: `1px solid ${theme.colors["refactor"][2]}`,
		},
		":focus-visible": {
			background: theme.white,
			border: `1px solid ${theme.colors["refactor"][2]}`,
		},
		fontSize: rem(36),
		fontWeight: 300,
		lineHeight: "100%",
		letterSpacing: -0.96,
		color: theme.colors[theme.primaryColor][7],
	},
}))
const CustomPinInput = ({
	name,
	disabled,
	required,
	control,
	onComplete,
	errorMessage,
	onChange,
	value,
}: IProps) => {
	const { classes, cx } = useStyles()

	if (control) {
		return (
			<Stack
				sx={{
					gap: rem(12),
				}}
			>
				<PinInput
					control={control}
					name={name}
					disabled={disabled}
					required={required}
					onComplete={() => {
						onComplete()
					}}
					inputMode='numeric'
					length={6}
					classNames={{
						root: classes.root,
						wrapper: classes.wrapper,
						input: classes.input,
					}}
					placeholder=''
					error={!!errorMessage}
					type={/^[0-9]+/}
				/>
				<Collapse in={!!errorMessage}>
					<Text
						sx={theme => ({
							color: theme.colors["danger"][0],
							lineHeight: rem(24),
							fontSize: rem(16),
							letterSpacing: -0.2,
						})}
					>
						{errorMessage}
					</Text>
				</Collapse>
			</Stack>
		)
	} else {
		return (
			<Stack
				sx={{
					gap: rem(12),
				}}
			>
				<MantinePinInput
					name={name}
					disabled={disabled}
					required={required}
					onComplete={() => {
						onComplete()
					}}
					classNames={{
						root: classes.root,
						wrapper: classes.wrapper,
						input: classes.input,
					}}
					inputMode='numeric'
					length={6}
					error={!!errorMessage}
					type={/^[0-9]+/}
					onChange={onChange}
					placeholder=''
					value={value}
				/>
				<Collapse in={!!errorMessage}>
					<Text
						sx={theme => ({
							color: theme.colors["danger"][0],
							lineHeight: rem(24),
							fontSize: rem(16),
							letterSpacing: -0.2,
						})}
					>
						{errorMessage}
					</Text>
				</Collapse>
			</Stack>
		)
	}
}

export default CustomPinInput
