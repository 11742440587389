import useAppStore from "../zustand/new-store"
import { useAuth } from "@clerk/clerk-react"
import { CacheConfig, ConfigSupabaseMetadata } from "../lib/parser"
import { useEffect, useState } from "react"
import { getEndpoints } from "../api/supabase/get-config"
const useCompletedGetStarted = () => {
	const { getToken, orgId } = useAuth()

	const [error, setError] = useState<any | null>(null)
	const {
		activeWorkspace,
		cacheConfig,
		setCacheConfig,
		organizationsMetadata,
		setGetStartedLoading,
	} = useAppStore()

	const cacheConfigLocal =
		cacheConfig &&
		activeWorkspace &&
		organizationsMetadata &&
		Boolean(
			organizationsMetadata.services.find(
				service => service.workspace_id === activeWorkspace?.workspace_id
			)
		) &&
		cacheConfig.workspace_id === activeWorkspace?.workspace_id
			? cacheConfig
			: null

	const [data, setData] = useState<
		(CacheConfig & ConfigSupabaseMetadata) | null
	>(cacheConfigLocal)

	async function fetchEndpoints() {
		setError(null)
		setGetStartedLoading(true)

		try {
			const result = await getEndpoints(
				orgId || "",
				activeWorkspace?.workspace_id || "",
				getToken
			)
			if (result.data) {
				setCacheConfig(result.data)
				setData(result.data)
				setError(null)
			} else {
				setData(null)
				setError(result.data || result.error)
			}
		} catch (error) {
			setError(error)
			setData(null)
		} finally {
			setGetStartedLoading(false)
		}
	}

	useEffect(() => {
		if (data && cacheConfigLocal) {
			setGetStartedLoading(false)
			setError(null)
			return
		}

		fetchEndpoints()
	}, [cacheConfig, activeWorkspace?.workspace_id])
	if (!data || !cacheConfig) {
		return { data: null, error: true }
	}

	return { data, error: false }
}

export default useCompletedGetStarted
