import {
	Box,
	Button,
	Center,
	Group,
	Stack,
	Text,
	ThemeIcon,
	rem,
} from "@mantine/core"
import CustomModal from "../../../../../Components/Modal/Modal"
import useAppStore from "../../../../../zustand/new-store"
import {
	IconAlertTriangle,
	IconSquareRoundedCheck,
	IconTrash,
} from "@tabler/icons-react"
import CustomTextInput from "../../../../../Components/TextInput/TextInput"
import { useState } from "react"
import useDeleteEndpoint from "../../api/useDeleteEndpoint"
import {
	useParams,
	useSearchParams,
	useNavigate,
	useLocation,
} from "react-router-dom"
import { useAuth } from "@clerk/clerk-react"
import { removePath } from "../../components/Table/utils"

const DeleteModal = () => {
	const {
		deleteModalOpen,
		setDeleteModalOpen,
		cacheConfigSrc,
		activeWorkspace,
		cacheConfig,
		setActiveEndpoint,
	} = useAppStore()
	const [searchParams] = useSearchParams()
	const endpoint = searchParams.get("p")
	const { method } = useParams()
	const { orgId, getToken } = useAuth()

	const {
		mutate,
		setSupabaseDeleteLoading,
		supabaseDeleteLoading,
		isLoading,
		setServerSideJSONErrors,
		serverSideJSONErrors,
		success,
	} = useDeleteEndpoint()
	const [value, setValue] = useState("")
	const navigate = useNavigate()
	const parseRowsForDeletion = async () => {
		const token = await getToken()
		if (!orgId || !activeWorkspace || !cacheConfig || !token) {
			return
		}
		setSupabaseDeleteLoading(true)

		// 	notifications.show({
		// 		id: "delete-path",
		// 		loading: true,
		// 		title: "Deleting",
		// 		message: "Deleting paths, please wait",
		// 		autoClose: false,
		// 		withCloseButton: false,
		// 	})
		// function notUndefined<TValue>(
		// 	value: TValue | undefined
		// ): value is TValue {
		// 	if (value === undefined) return false
		// 	return true
		// }
		// 	const selectedRows = selection
		// 		.map(id => {
		// 			return modifiedData.find(datum => {
		// 				return datum.id === id
		// 			})
		// 		})
		// 		.filter(notUndefined)
		const {
			workspace_id,
			source,
			user_id,
			org_id,
			uuid,
			created_at,
			updated_at,
			...dataWithoutSupabaseConfig
		} = structuredClone(cacheConfig)
		const parsedData = removePath(
			dataWithoutSupabaseConfig,
			method || "",
			endpoint || ""
		)

		mutate({ config: parsedData, token })
	}
	return (
		<CustomModal
			opened={deleteModalOpen}
			onClose={() => {
				if (isLoading || supabaseDeleteLoading) {
					return
				}
				setDeleteModalOpen(false)
				if (success) {
					// navigate("/endpoints", {
					// 	replace: true,
					// })
					setActiveEndpoint(null)
				}
			}}
		>
			{success ? (
				<Stack sx={{ gap: 0 }}>
					<Center>
						<ThemeIcon
							sx={theme => ({
								border: "none",
								color: theme.colors["green"][0],
							})}
							variant='outline'
							size={rem(40)}
						>
							<IconSquareRoundedCheck size={rem(40)} />
						</ThemeIcon>
					</Center>
					<Stack
						mt={rem(24)}
						sx={{
							gap: rem(16),
						}}
					>
						<Text
							ta='center'
							fz={rem(18)}
							fw={500}
							lh={rem(24)}
							c='#030124'
						>
							Endpoint deleted successfully!
						</Text>

						<Text
							ta='center'
							fz={rem(16)}
							fw={400}
							lh={rem(24)}
							sx={theme => ({
								color: theme.colors["refactor"][4],
							})}
						>
							Endpoint `
							<Box
								c='brand.7'
								fw={500}
								display='inline'
							>
								{endpoint}
							</Box>
							` has been successfully deleted.
						</Text>
					</Stack>

					<Button
						type='button'
						h={rem(48)}
						onClick={() => {
							setDeleteModalOpen(false)
							// navigate("/endpoints", {
							// 	replace: true,
							// })
							setActiveEndpoint(null)
						}}
						mt={rem(24)}
						fz={rem(16)}
						sx={{
							borderRadius: rem(8),
							fontWeight: 500,
							lineHeight: rem(16),
							letterSpacing: -0.2,
						}}
					>
						Close
					</Button>
				</Stack>
			) : serverSideJSONErrors.length > 0 ? (
				<Stack sx={{ gap: 0 }}>
					<Center>
						<ThemeIcon
							sx={theme => ({
								border: "none",
								color: theme.colors["danger"][0],
							})}
							variant='outline'
							size={rem(40)}
						>
							<IconAlertTriangle size={rem(40)} />
						</ThemeIcon>
					</Center>
					<Stack
						mt={rem(24)}
						sx={{
							gap: rem(16),
						}}
					>
						<Text
							ta='center'
							fz={rem(18)}
							fw={500}
							lh={rem(24)}
							c='#030124'
						>
							Oops! Something Went Wrong
						</Text>

						<Text
							ta='center'
							fz={rem(16)}
							fw={400}
							lh={rem(24)}
							sx={theme => ({
								color: theme.colors["refactor"][4],
							})}
						>
							Failed to delete endpoint `
							<Box
								c='brand.7'
								fw={500}
								display='inline'
							>
								{endpoint}
							</Box>
							`
						</Text>
					</Stack>
					<Stack
						mt={rem(24)}
						p={rem(20)}
						sx={theme => ({
							gap: rem(12),
							":empty": {
								display: "none",
							},
							borderRadius: rem(8),
							border: `1px solid ${theme.colors["danger"][0]}`,
						})}
					>
						{serverSideJSONErrors.map((error, idx) => (
							<Text
								key={idx}
								fz={rem(16)}
								lh={rem(24)}
								sx={theme => ({
									color: theme.colors["danger"][0],
								})}
							>
								{error}
							</Text>
						))}
					</Stack>
					<Button
						type='button'
						h={rem(48)}
						onClick={() => {
							setServerSideJSONErrors([])
						}}
						mt={rem(24)}
						fz={rem(16)}
						sx={{
							borderRadius: rem(8),
							fontWeight: 500,
							lineHeight: rem(16),
							letterSpacing: -0.2,
						}}
					>
						Try Again
					</Button>
				</Stack>
			) : (
				<Stack
					sx={{
						gap: rem(24),
					}}
				>
					<Box>
						<Group
							pb={rem(16)}
							sx={theme => ({
								gap: rem(12),
								borderBottom: `1px solid ${theme.colors["refactor"][2]}`,
							})}
						>
							<Center c='danger.0'>
								<IconTrash size={24} />
							</Center>
							<Text
								fz={rem(21)}
								fw={500}
								lh={rem(24)}
								c='brand.7'
							>
								Delete Endpoint?
							</Text>
						</Group>
					</Box>
					<Stack
						sx={{
							gap: rem(16),
						}}
					>
						<Text
							fw={400}
							lh={rem(24)}
							fz={rem(16)}
							c='brand.8'
						>
							Are you sure you want to delete this endpoint? This action
							cannot be undone, and all associated data and metrics will
							be permanently removed.
						</Text>
						<Text
							fw={400}
							lh={rem(24)}
							fz={rem(16)}
							c='brand.8'
						>
							Please type the endpoint path "{endpoint}" to confirm.
						</Text>
						<CustomTextInput
							name='endpoint-route'
							placeholder='Enter endpoint path'
							label='Endpoint'
							onChange={e => {
								setValue(e.target.value)
							}}
							value={value}
						/>
					</Stack>

					<Group noWrap>
						<Button
							w='fit-content'
							fz={rem(16)}
							h={rem(48)}
							bg='refactor.5'
							c='refactor.4'
							sx={theme => ({
								borderRadius: rem(8),
								fontWeight: 500,
								lineHeight: rem(16),
								letterSpacing: -0.2,
								":hover": {
									background: theme.colors["refactor"][5],
									color: theme.colors["refactor"][4],
								},
							})}
							onClick={() => {
								setDeleteModalOpen(false)
							}}
							disabled={isLoading || supabaseDeleteLoading}
						>
							Cancel
						</Button>
						<Button
							fullWidth
							fz={rem(16)}
							sx={{
								borderRadius: rem(8),
								fontWeight: 500,
								lineHeight: rem(16),
								letterSpacing: -0.2,
							}}
							h={rem(48)}
							disabled={
								value !== endpoint ||
								isLoading ||
								cacheConfigSrc === "Swagger" ||
								supabaseDeleteLoading
							}
							loading={isLoading || supabaseDeleteLoading}
							onClick={() => {
								parseRowsForDeletion()
							}}
						>
							Confirm Delete
						</Button>
					</Group>
				</Stack>
			)}
		</CustomModal>
	)
}

export default DeleteModal
