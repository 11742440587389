import React from "react"
import { ErrorBoundary as ER } from "react-error-boundary"
import useAppStore from "../../zustand/new-store"
import { Text, rem, Center, Stack } from "@mantine/core"
import { IconDeviceDesktopOff } from "@tabler/icons-react"

export function Fallback({
	resetErrorBoundary,
}: {
	resetErrorBoundary: (...args: any[]) => void
}) {
	return (
		<Center
			h='calc(100vh - 16px)'
			bg='white'
			pos='relative'
			top={0}
			left={0}
			sx={{
				zIndex: 1001,
			}}
		>
			<Stack
				justify='center'
				spacing={rem(8)}
				sx={{
					alignItems: "center",
				}}
			>
				<IconDeviceDesktopOff
					size={96}
					color='#DB1B24'
				/>
				<Text
					align='center'
					c='#0E0423'
					fz={rem(24)}
				>
					Something went wrong
				</Text>
			</Stack>
		</Center>
	)
}
const ErrorBoundary = ({ children }: { children: React.ReactNode }) => {
	const { resetState } = useAppStore()
	return (
		<ER
			FallbackComponent={Fallback}
			onReset={() => {
				resetState()
			}}
		>
			{children}
		</ER>
	)
}

export default ErrorBoundary
