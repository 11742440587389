import * as React from "react"
import * as ReactDOM from "react-dom/client"
import { App } from "./App"
import reportWebVitals from "./reportWebVitals"
import * as serviceWorker from "./serviceWorker"
import ErrorBoundary from "./Components/ErrorBoundary/ErrorBoundary"
import { PostHogProvider } from 'posthog-js/react'

const options = {
	api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
}

const container = document.getElementById("root")
if (!container) throw new Error("Failed to find the root element")
const root = ReactDOM.createRoot(container)


root.render(
	<React.StrictMode>
		<PostHogProvider
			apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
			options={options}
		>
			<ErrorBoundary>
				<App />
			</ErrorBoundary>
		</PostHogProvider>
	</React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
