import { Route, Routes, Navigate, Outlet } from "react-router-dom"
import { SignedIn, SignedOut } from "@clerk/clerk-react"
import { useMemo } from "react"
import SignUp from "../pages/auth/SignUp/SignUp"
import AuthLayout from "../pages/auth/Components/AuthLayout"
import LogIn from "../pages/auth/SignIn/LogIn"
import ForgotPassword from "../pages/auth/ForgotPassword/ForgotPassword"
import Home from "../pages/dashboard/home/home"
import SSOCallbacks from "../pages/auth/SSOCallbacks/SSOCallbacks"
import Onboard from "../pages/auth/Onboard/Onboard"
import OnboardGuard from "../pages/Guards/OnboardGuard"
import ConfigGuard from "../pages/Guards/ConfigGuard"
import GetStarted from "../pages/auth/GetStarted/GetStarted"
import AppShell from "../Components/AppShell/AppShell"
import Endpoints from "../pages/dashboard/Endpoints/EndpointSummary/Endpoints"
import Settings from "../pages/dashboard/Settings/Settings"
import SettingsLayout from "../pages/dashboard/Settings/Components/Layout"
import EndpointsLayout from "../pages/dashboard/Endpoints/components/Layout"
import DashboardLayout from "../pages/dashboard/Components/DashboardLayout"
import Domains from "../pages/dashboard/Settings/Domains/Domains"
import Security from "../pages/dashboard/Settings/Security/Security"
import Notifications from "../pages/dashboard/Settings/Notifications/Notifications"
import Teams from "../pages/dashboard/Settings/Teams/Teams"
import Integrations from "../pages/dashboard/Settings/Integration/Integrations"
import Billing from "../pages/dashboard/Settings/Billing/Billing"
import EndpointMetrics from "../pages/dashboard/Endpoints/EndpointDetails/Metrics"
import EndpointLogs from "../pages/dashboard/Endpoints/EndpointDetails/Logs"
import EndpointSettings from "../pages/dashboard/Endpoints/EndpointDetails/Settings"
import EndpointDetailsLayout from "../pages/dashboard/Endpoints/EndpointDetails/components/EndpointDetailsLayout"
import SlackConfirmation from "../pages/dashboard/confirmation/SlackConfirmation"
import Payments from "../pages/dashboard/Payments/Payments"
import PaymentConfirmation from "../pages/dashboard/confirmation/payment-success"
import SubscriptionGuard from "../pages/Guards/SubscriptionGuard"
import PaymentsAuth from "../pages/dashboard/Payments/PaymentsAuth"
import MultiStepTourWrapper from "../Components/Tour/Tour"
import ErrorBoundary from "../Components/ErrorBoundary/ErrorBoundary"
import HintModal from "../Components/Tour/Modal"
import useAppStore from "../zustand/new-store"
import { Step } from "react-joyride"
import useEndpointHintStore from "../zustand/endpoints-hint-tour-store"
export function Router() {
	const { cacheConfigSrc, cacheConfig } = useAppStore()
	const { steps, run, updateHintState, isModalOpen, completedHints } =
		useEndpointHintStore()
	const endpointStepsList = useMemo(() => {
		let a: Step[] =
			cacheConfigSrc === "Swagger" || cacheConfigSrc === "Postman"
				? [
						{
							content: "Clear selected endpoint cache.",
							placement: "bottom",
							target: "#purge",
						},
						{
							content:
								"Synchronize endpoints configuration with the schema document. This is necessary if the schema document has changed since onboarding.",
							placement: "bottom",
							target: "#refresh",
						},
				  ]
				: [
						{
							content: "Clear selected endpoint cache.",
							placement: "bottom",
							target: "#purge",
						},
						{
							content: "Add new endpoints",
							placement: "bottom",
							target: "#add-endpoint",
						},
				  ]

		if (
			cacheConfig?.cache?.paths &&
			Object.keys(cacheConfig?.cache?.paths).length > 0
		) {
			a = [
				...a,
				{
					content:
						"Administer Resilis management of the selected endpoint. You can also configure and view metrics for the specific endpoint.",
					placement: "bottom",
					target: "#more-0",
				},
			]
		}

		return a
	}, [cacheConfig?.cache?.paths, cacheConfig])
	return (
		<Routes>
			<Route path='/'>
				<Route
					path='/sso-callback'
					element={
						<ErrorBoundary>
							<SSOCallbacks />
						</ErrorBoundary>
					}
				/>

				<Route
					path='/'
					element={
						<>
							<SignedOut>
								<AuthLayout />
							</SignedOut>
							<SignedIn>
								<Navigate to='/onboard' />
							</SignedIn>
						</>
					}
				>
					<Route
						path='/sign-up'
						element={
							<ErrorBoundary>
								<SignUp />
							</ErrorBoundary>
						}
					/>
					<Route
						path='/login'
						element={
							<ErrorBoundary>
								<LogIn />
							</ErrorBoundary>
						}
					/>
					<Route
						path='/forgot-password'
						element={
							<ErrorBoundary>
								<ForgotPassword />
							</ErrorBoundary>
						}
					/>
				</Route>

				<Route
					path='/'
					element={
						<>
							<SignedOut>
								<Navigate to='/login' />
							</SignedOut>
							<SignedIn>
								<ErrorBoundary>
									<OnboardGuard>
										<Outlet />
									</OnboardGuard>
								</ErrorBoundary>
							</SignedIn>
						</>
					}
				>
					<Route
						path='/onboard'
						element={
							<>
								<AuthLayout />
							</>
						}
					>
						<Route
							index
							element={
								<ErrorBoundary>
									<Onboard />
								</ErrorBoundary>
							}
						/>
					</Route>

					<Route
						path='/'
						element={<AppShell />}
					>
						<Route
							path='/paid'
							element={
								<ErrorBoundary>
									<PaymentConfirmation />
								</ErrorBoundary>
							}
						/>

						<Route
							path='/'
							element={
								<ErrorBoundary>
									{/* <SubscriptionGuard> */}
									<Outlet />
									{/* </SubscriptionGuard> */}
								</ErrorBoundary>
							}
						>
							<Route
								path='/pricing-auth'
								element={<PaymentsAuth />}
							/>
							<Route
								path='/'
								element={
									<ConfigGuard>
										<Outlet />
									</ConfigGuard>
								}
							>
								<Route
									path='/get-started'
									element={<GetStarted />}
								/>
								<Route
									path='/pricing'
									element={<Payments />}
								/>

								<Route
									element={
										<MultiStepTourWrapper>
											<DashboardLayout />
										</MultiStepTourWrapper>
									}
								>
									<Route
										index
										element={<Home />}
									/>
									<Route path='/endpoints'>
										<Route
											index
											element={
												<HintModal
													stepsList={endpointStepsList}
													isModalOpen={isModalOpen}
													run={run}
													steps={steps}
													completedHints={completedHints}
													updateHintState={updateHintState}
												>
													<EndpointsLayout>
														<Endpoints />
													</EndpointsLayout>
												</HintModal>
											}
										/>

										<Route
											path='m/:method'
											element={<EndpointDetailsLayout />}
										>
											<Route
												path='metrics'
												element={<EndpointMetrics />}
											/>
											<Route
												path='logs'
												element={<EndpointLogs />}
											/>
											<Route
												path='endpoint-settings'
												element={<EndpointSettings />}
											/>
										</Route>
									</Route>
									<Route path='/confirmation'>
										<Route
											path='slack'
											element={<SlackConfirmation />}
										/>
									</Route>
									<Route
										path='/settings'
										element={<SettingsLayout />}
									>
										<Route
											index
											element={<Settings />}
										/>
										<Route
											path='teams'
											element={<Teams />}
										/>
										<Route
											path='domain'
											element={<Domains />}
										/>
										<Route
											path='notifications'
											element={<Notifications />}
										/>
										<Route
											path='billing'
											element={<Billing />}
										/>
										<Route
											path='security'
											element={<Security />}
										/>
										<Route
											path='integration'
											element={<Integrations />}
										/>
									</Route>
								</Route>
							</Route>
						</Route>
					</Route>
				</Route>
			</Route>
		</Routes>
	)
}
