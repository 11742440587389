import { supabaseClient } from "../../config/supabase.config"
import { SUPABASE_ENDPOINTS } from "../../constants/supabase-tables"

export const getEndpoints = async (
	orgId: string,
	workspaceId: string,
	getToken: (
		options?: { template: string } | undefined
	) => Promise<string | null>
) => {
	const supabaseAccessToken = (await getToken({
		template: "supabase",
	})) as string
	const supabase = supabaseClient(supabaseAccessToken)
	return await supabase
		.from(SUPABASE_ENDPOINTS)
		.select()
		.match({
			org_id: orgId,
			workspace_id: workspaceId,
		})
		.single()
}
