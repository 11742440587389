import { rem, Box } from "@mantine/core"

interface IProps {
	checked: boolean
	onChange: () => void
	disabled?: boolean
}
const CustomSwitch = ({ checked, onChange, disabled }: IProps) => {
	return (
		<>
			<Box
				w={rem(40)}
				h={rem(24)}
				p={rem(2)}
				pos='relative'
				sx={theme => ({
					background: checked
						? theme.colors[theme.primaryColor][2]
						: theme.colors["refactor"][5],
					transition: "background 0.1s ease",
					cursor: disabled ? "not-allowed" : "pointer",
					borderRadius: rem(16),
				})}
				onClick={() => {
					if (disabled) {
						return
					}
					onChange()
				}}
				role='switch'
				opacity={disabled ? 0.7 : 1}
			>
				<Box
					h={rem(20)}
					w={rem(20)}
					sx={theme => ({
						background: theme.white,
						borderRadius: "50%",
						transition: "transform 0.1s ease",
						position: "relative",
						transform: checked ? `translateX(${rem(16)})` : "initial",
					})}
				></Box>
			</Box>
		</>
	)
}

export default CustomSwitch
