import { useAuth, useOrganization } from "@clerk/clerk-react"
import { Button, Group, Stack, Text, rem } from "@mantine/core"
import { useDocumentTitle } from "@mantine/hooks"
import { useDisclosure } from "@mantine/hooks"
import TeamsTable from "./TeamsTable"
import useAppState from "../../../../zustand/new-store"
import TeamsDrawer from "./TeamsDrawer"
const Teams = () => {
	useDocumentTitle("Teams")
	const { organization, membershipList, membership } = useOrganization({
		invitationList: {},
		membershipList: {},
	})
	const [opened, { open, close }] = useDisclosure(false)
	const { getToken } = useAuth()
	const { teamsDrawerOpen, setTeamsDrawerOpen } = useAppState()

	return (
		<Stack
			w='100%'
			p={rem(32)}
			sx={theme => ({
				borderRadius: rem(8),
				border: `1px solid ${theme.colors["refactor"][2]}`,
				gap: rem(30),
			})}
		>
			<TeamsDrawer />
			<Group
				sx={{ justifyContent: "space-between", alignItems: "center" }}
				noWrap
			>
				<Stack
					sx={{
						gap: rem(8),
						flexGrow: 0,
					}}
				>
					<Text
						m={0}
						component='h3'
						fw={500}
						lh={rem(20)}
						fz={rem(16)}
						sx={theme => ({
							color: theme.colors[theme.primaryColor][7],
						})}
					>
						Manage Members
					</Text>
					<Text
						m={0}
						component='h4'
						fw={400}
						lh={rem(20)}
						fz={rem(14)}
						sx={theme => ({
							color: theme.colors["refactor"][4],
						})}
					>
						Invite, review, and manage team members to collaborate on your
						Resilis workspace
					</Text>
				</Stack>
				<Button
					h={rem(40)}
					sx={{
						borderRadius: rem(8),
						fontWeight: 500,
						lineHeight: rem(16),
						letterSpacing: -0.2,
					}}
					onClick={() => {
						setTeamsDrawerOpen(true)
					}}
				>
					+ Add new members
				</Button>
			</Group>
			<TeamsTable />
		</Stack>
	)
}

export default Teams
