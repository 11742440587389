import { getPaymentPlans } from "../../../../api/server/billing/get-plans"
import { useQuery } from "@tanstack/react-query"
import { Pricing } from "../../../../types/pricing.types"
const useGetPlans = () => {
	return useQuery<Pricing[]>({
		queryFn: () => {
			return getPaymentPlans()
		},
		queryKey: ["payment plans"],
		refetchOnWindowFocus: false,
	})
}

export default useGetPlans
