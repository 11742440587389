import { useMutation, useQueryClient } from "@tanstack/react-query"
import { axiosErrorParser } from "../../../../lib/parse-axios-error"
import { AxiosError } from "axios"
import { IconCheck, IconX } from "@tabler/icons-react"
import { notifications } from "@mantine/notifications"
import useAppStore from "../../../../zustand/new-store"
import { updateEndpoint } from "../../../../api/server/onboard/update-endpoint"
import { updateEndpoints } from "../../../../api/supabase/update-endpoints"
import { CacheConfig } from "../../../../lib/parser"
import { useAuth } from "@clerk/clerk-react"
import { useState } from "react"

async function update(
	orgId: string,
	workspaceId: string,
	endpoints: Partial<CacheConfig>,
	getToken: (
		options?: { template: string } | undefined
	) => Promise<string | null>
) {
	try {
		const result = await updateEndpoints(getToken, {
			org_id: orgId,
			workspace_id: workspaceId,
			...endpoints,
		})
		if (result.error || !result.data) {
			return { data: null, error: result.error || result.data }
		}
		return { data: result.data, error: null }
	} catch (error) {
		return { data: null, error }
	}
}
const useDeleteEndpoint = () => {
	const { cacheConfig, setCacheConfig, activeWorkspace } = useAppStore()
	const [supabaseDeleteLoading, setSupabaseDeleteLoading] = useState(false)
	const [success, setSuccess] = useState(false)
	const [serverSideJSONErrors, setServerSideJSONErrors] = useState<string[]>(
		[]
	)
	const { getToken, orgId } = useAuth()
	const queryClient = useQueryClient()

	const mutation = useMutation({
		mutationFn: updateEndpoint,
		onError(error: AxiosError) {
			const errorMessage = axiosErrorParser(error)
			if (typeof errorMessage === "string") {
				setServerSideJSONErrors([errorMessage])
			} else {
				if (errorMessage instanceof Array) {
					setServerSideJSONErrors(errorMessage)
				} else {
					setServerSideJSONErrors([error.message])
				}
			}
			setSuccess(false)
			setSupabaseDeleteLoading(false)
		},
		async onSuccess(data, variables) {
			const { config } = variables
			setSupabaseDeleteLoading(true)
			setServerSideJSONErrors([])
			const response = await update(
				orgId || "",
				activeWorkspace?.workspace_id || "",
				config,
				getToken
			)

			if (response.error || !response.data) {
				console.log("SupabaseError:", response.error)
				setSupabaseDeleteLoading(false)
				setSuccess(false)
				return
			}

			setCacheConfig(response.data)
			setSuccess(true)
			queryClient.invalidateQueries({ queryKey: [cacheConfig?.host] })
			setSupabaseDeleteLoading(false)
		},
	})

	return {
		supabaseDeleteLoading,
		setSupabaseDeleteLoading,
		setServerSideJSONErrors,
		serverSideJSONErrors,
		setSuccess,
		success,
		...mutation,
	}
}

export default useDeleteEndpoint
