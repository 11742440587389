import { Alert, createStyles, rem } from "@mantine/core"
import { IconInfoCircle } from "@tabler/icons-react"
export const useStyles = createStyles(theme => ({
	alertRoot: {
		borderRadius: rem(8),
	},
	alertGrey: {
		border: `1px solid ${theme.colors["refactor"][5]}`,
	},
	alertDanger: {
		background: theme.colors["danger"][2],
	},
	alertTitle: {
		color: theme.colors["refactor"][0],
	},
	alertDangerTitle: {
		color: theme.colors["danger"][0],
	},
	alertIcon: {
		color: theme.colors["refactor"][0],
	},
	alertDangerIcon: {
		color: theme.colors["danger"][0],
	},
}))
const DangerAlert = (props: { children: React.ReactNode; title: string }) => {
	const { classes, cx } = useStyles()

	return (
		<Alert
			variant='light'
			title={props.title}
			icon={<IconInfoCircle />}
			classNames={{
				root: cx(classes.alertRoot, classes.alertDanger),
				icon: classes.alertDangerIcon,
				title: classes.alertDangerTitle,
			}}
			// mt={!!formState.errors.type?.message ? rem(24) : 0}
		>
			{props.children}
		</Alert>
	)
}

export default DangerAlert
