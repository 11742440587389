import { CacheConfig } from "../../lib/parser"
import { supabaseClient } from "../../config/supabase.config"
import { SUPABASE_ENDPOINTS } from "../../constants/supabase-tables"

export const uploadCustomConfigToSupabase = async (
	getToken: (
		options?: { template: string } | undefined
	) => Promise<string | null>,
	values: Partial<CacheConfig> & {
		user_id: string
		org_id: string
		source: string
		workspace_id: string
		source_url: string | null
	}
) => {
	const supabaseAccessToken = (await getToken({
		template: "supabase",
	})) as string
	const supabase = supabaseClient(supabaseAccessToken)
	delete values?.orgId
	return await supabase
		.from(SUPABASE_ENDPOINTS)
		.insert({ ...values })
		.select()
		.single()
}
