import { analyticsAxiosConfig } from "../../../config/axios.config"
export async function inviteTeamMembers(body: {
	email: string
	token: string
	role: string
}) {
	const response = await analyticsAxiosConfig.post(
		`/organisation/invite`,
		{
			email: body.email,
			role: body.role,
		},
		{
			headers: {
				Authorization: `Bearer ${body.token}`,
			},
		}
	)
	const { data } = response
	return data
}
