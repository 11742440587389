import { create } from "zustand"
import { createSelectors } from "./create-selector"
import { TourState } from "../types/tour.types"
import { parseLocalStorage } from "../lib/localstorage-parser"
import { COMPLETED_HEADER_HINTS } from "../constants/localstorage"

export type HintTourState = Pick<TourState, "steps" | "run"> & {
	isModalOpen: boolean
	completedHints: boolean
}

export interface HintActions {
	updateHintState: (hint: Partial<HintTourState>) => void
}

const useHeaderHintStoreBase = create<HintTourState & HintActions>()(set => ({
	run: false,
	steps: [],
	isModalOpen: false,
	completedHints: parseLocalStorage(COMPLETED_HEADER_HINTS) || false,
	updateHintState(hint) {
		if (hint.completedHints !== undefined) {
			localStorage.setItem(
				COMPLETED_HEADER_HINTS,
				JSON.stringify(hint.completedHints)
			)
		}
		return set(state => ({
			...hint,
		}))
	},
}))
const useHeaderHintStore = createSelectors(useHeaderHintStoreBase)

export default useHeaderHintStore
