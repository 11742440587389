import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import useAppStore from "../../../../zustand/new-store"
import { updateNotificationPreferences } from "../../../../api/server/notifications/update-notifs"
import { AxiosError } from "axios"
import { axiosErrorParser } from "../../../../lib/parse-axios-error"
import { notifications } from "@mantine/notifications"
import { IconCheck, IconX } from "@tabler/icons-react"
const useUpdateNotifPreferences = () => {
	const { activeWorkspace } = useAppStore()

	const queryClient = useQueryClient()

	const mutation = useMutation({
		mutationFn: updateNotificationPreferences,

		async onMutate(variables) {
			await queryClient.cancelQueries({
				queryKey: [activeWorkspace?.workspace_id + "notifications"],
			})

			const previousData = queryClient.getQueryData([
				activeWorkspace?.workspace_id + "notifications",
			])
			const initialData = {
				data: [
					{
						template: {},
						preference: {
							enabled: false,
							channels: {},
						},
					},
				],
			}
			queryClient.setQueryData(
				[activeWorkspace?.workspace_id + "notifications"],
				(old: any) => {
					return {
						data: [
							{
								template: {
									...(old?.data?.[0]?.template || {}),
								},
								preference: {
									enabled:
										old?.data?.[0]?.preference?.enabled || false,
									overrides: old?.data?.[0]?.preference?.overrides,

									channels: {
										...old?.data?.[0]?.preference?.channels,
										[variables.type]: variables.enabled,
									},
								},
							},
						],
					}
				}
			)

			return { previousData: previousData ? previousData : initialData }
		},
		onError(error: AxiosError, variables, ctx) {
			const errorMessage = axiosErrorParser(error)

			if (typeof errorMessage === "string") {
				notifications.show({
					withCloseButton: true,
					color: "danger.0",
					title: "Something went wrong",
					icon: <IconX />,
					autoClose: 5000,
					message: errorMessage,
				})
			} else {
				if (errorMessage instanceof Array) {
					notifications.show({
						withCloseButton: true,
						color: "danger.0",
						title: "Something went wrong",
						icon: <IconX />,
						autoClose: 5000,
						message: errorMessage[0],
					})
				} else {
					notifications.show({
						withCloseButton: true,
						color: "danger.0",
						title: "Something went wrong",
						icon: <IconX />,
						autoClose: 5000,
						message: errorMessage.error,
					})
				}
			}

			queryClient.setQueryData(
				[activeWorkspace?.workspace_id + "notifications"],
				ctx?.previousData
			)
		},
		async onSuccess() {
			notifications.show({
				withCloseButton: true,
				color: "green",
				title: "Success",
				icon: <IconCheck />,
				autoClose: 5000,
				message: "Alerts config uploaded successfully",
			})
		},

		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: [activeWorkspace?.workspace_id + "notifications"],
			})
		},
	})
	return { ...mutation }
}

export default useUpdateNotifPreferences
