const usePasswordStrength = (password: string | undefined) => {
	if (!password) {
		return 0
	}
	const hasAtLeastOneUpperCaseLetter = /[A-Z]/.test(password)
	const hasAtLeastOneLowerCaseLetter = /[a-z]/.test(password)
	const hasAtLeastOneNumber = /[0-9]/.test(password)
	const hasASpecialCharacter = /[$&+,:;=?@#|'<>.^*()%!-]/.test(password)
	const meetsMinLength = password.length >= 8
	return (
		([
			hasASpecialCharacter,
			meetsMinLength,
			hasAtLeastOneUpperCaseLetter,
			hasAtLeastOneLowerCaseLetter,
			hasAtLeastOneNumber,
		].filter(Boolean).length /
			5) *
		100
	)
}

export default usePasswordStrength
