import { supabaseClient } from "../../config/supabase.config"
import { INTEGRATIONS } from "../../constants/supabase-tables"
import utc from "dayjs/plugin/utc"
import dayjs from "dayjs"
import { IIntegrations } from "../../types/supabase.types"
dayjs.extend(utc)

export const createWorkspaceIntegrations = async (
	getToken: (
		options?: { template: string } | undefined
	) => Promise<string | null>,
	values: Partial<IIntegrations>
) => {
	const { hostname, slack, user_id } = values

	const supabaseAccessToken = (await getToken({
		template: "supabase",
	})) as string
	const supabase = supabaseClient(supabaseAccessToken)

	return await supabase
		.from(INTEGRATIONS)
		.insert({ slack, hostname, user_id })
		.select()
		.single()
}
