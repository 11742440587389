import { Box, Stack, rem, Text, Group, Button, Center } from "@mantine/core"
import { IconEdit, IconTrash } from "@tabler/icons-react"
import useAppStore from "../../../../zustand/new-store"
import EndpointDrawer from "../components/Drawer/EndpointDrawer"
import DeleteModal from "./components/DeleteEndpointModal"
const EndpointSettings = () => {
	const {
		setEndpointDrawerOpen,
		setDeleteModalOpen,
		cacheConfig,
		setEndpointDrawerType,
	} = useAppStore()

	return (
		<Stack
			sx={{
				gap: rem(32),
			}}
			w='100%'
		>
			<Stack
				w='100%'
				p={rem(32)}
				sx={theme => ({
					borderRadius: rem(8),
					border: `1px solid ${theme.colors["refactor"][2]}`,
					gap: rem(30),
				})}
			>
				<Box pb={rem(16)}>
					<Text
						c='brand.7'
						fz={rem(16)}
						fw={500}
						lh={rem(24)}
					>
						Edit Endpoint
					</Text>
				</Box>
				<Group
					noWrap
					sx={{
						justifyContent: "space-between",
					}}
					align='flex-start'
				>
					<Text maw={rem(520)}>
						Modify the route of this endpoint to tailor it to your
						specific needs. Ensure any changes align with your API
						structure.
					</Text>

					<Button
						onClick={() => {
							if (cacheConfig?.source === "Swagger") return
							setEndpointDrawerOpen(true)
						}}
						h={rem(48)}
						sx={{
							borderRadius: rem(8),
							":disabled": {
								cursor: "pointer",
							},
						}}
						disabled={cacheConfig?.source === "Swagger"}
					>
						<Group
							sx={{
								gap: rem(8),
							}}
							align='center'
						>
							<Center c='#fff'>
								<IconEdit size={16} />
							</Center>

							<Text
								c='#fff'
								fw={500}
								fz={rem(16)}
								lts={-0.2}
								lh={rem(16)}
							>
								Edit Route
							</Text>
						</Group>
					</Button>
				</Group>
			</Stack>
			<Stack
				w='100%'
				p={rem(32)}
				sx={theme => ({
					borderRadius: rem(8),
					border: `1px solid ${theme.colors["refactor"][2]}`,
					gap: rem(30),
				})}
			>
				<Box pb={rem(16)}>
					<Text
						c='brand.7'
						fz={rem(16)}
						fw={500}
						lh={rem(24)}
					>
						Delete Route
					</Text>
				</Box>
				<Group
					noWrap
					sx={{
						justifyContent: "space-between",
					}}
					align='flex-start'
				>
					<Text maw={rem(520)}>
						Removing this endpoint will permanently erase its
						configuration and data. Proceed with caution.
					</Text>

					<Button
						bg='danger.0'
						h={rem(48)}
						sx={{
							borderRadius: rem(8),
						}}
						onClick={() => {
							if (cacheConfig?.source === "Swagger") return
							setDeleteModalOpen(true)
						}}
						disabled={cacheConfig?.source === "Swagger"}
					>
						<Group
							sx={{
								gap: rem(8),
							}}
							align='center'
						>
							<Center c='#fff'>
								<IconTrash size={16} />
							</Center>

							<Text
								c='#fff'
								fw={500}
								fz={rem(16)}
								lts={-0.2}
								lh={rem(16)}
							>
								Delete
							</Text>
						</Group>
					</Button>
				</Group>
			</Stack>
			<DeleteModal />
			<EndpointDrawer />
		</Stack>
	)
}

export default EndpointSettings
