import {
	HoverCard,
	Text,
	rem,
	Box,
	createStyles,
	Stack,
	List,
} from "@mantine/core"

export interface Body {
	text: string | React.ReactNode
	icon?: JSX.Element
	excludeFromList?: boolean
}
interface IProps {
	target: JSX.Element
	width?: number
	position?:
		| "bottom"
		| "left"
		| "right"
		| "top"
		| "bottom-end"
		| "bottom-start"
		| "left-end"
		| "left-start"
		| "right-end"
		| "right-start"
		| "top-end"
		| "top-start"

	title?: string
	body: Body[]
	iconSize?: number
	spacing?: number
	isList?: true
}
const useStyles = createStyles(theme => ({
	dropdown: {
		border: "none",
		background: theme.white,
		boxShadow: "0px 10px 40px 0px rgba(0, 0, 0, 0.10)",
		borderRadius: rem(8),
		padding: rem(16),
	},
	arrow: {
		border: "none",
		background: theme.white,
	},
}))
const CustomHoverCard = ({
	target,
	width = 350,
	position = "right",
	title,
	body = [],
	spacing = 16,
	isList,
}: IProps) => {
	const { classes } = useStyles()
	return (
		<HoverCard
			width={width}
			withArrow
			position={position}
			arrowSize={20}
			classNames={{
				...classes,
			}}
			zIndex={700}
			withinPortal
		>
			<HoverCard.Target>{target}</HoverCard.Target>
			<HoverCard.Dropdown>
				{title ? (
					<Box
						pb={rem(14)}
						sx={theme => ({
							borderBottom:
								body.length > 0
									? `1px solid ${theme.colors["refactor"][2]}`
									: "none",
						})}
					>
						<Text
							lts={-0.2}
							fw={500}
							lh={rem(20)}
							fz={rem(14)}
							c='brand.7'
							tt='capitalize'
						>
							{title}
						</Text>
					</Box>
				) : null}

				{isList ? (
					<>
						<Stack
							mt={title ? rem(14) : 0}
							sx={{
								gap: rem(spacing),
								"&:empty": {
									display: "none",
								},
							}}
						>
							{body.map((detail, index) => {
								if (!detail.excludeFromList) return null
								return (
									<Box
										key={index}
										sx={{
											display: "flex",
											alignItems: "center",
											gap: "10px",
										}}
									>
										{detail.icon ? (
											<Box mr={rem(8)}>{detail.icon}</Box>
										) : null}
										<Text
											fz={rem(12)}
											fw={500}
										>
											{detail.text}
										</Text>
									</Box>
								)
							})}
						</Stack>
						<List
							mt={title ? rem(14) : 0}
							sx={{
								"&:empty": {
									display: "none",
								},
							}}
						>
							{body.map((detail, index) => {
								if (detail.excludeFromList) return null
								return (
									<List.Item
										key={index}
										sx={{
											"&:not(:first-child)": {
												paddingTop: rem(spacing),
											},
										}}
									>
										<Box
											sx={{
												display: "flex",
												alignItems: "center",
												gap: "10px",
											}}
										>
											{detail.icon ? (
												<Box mr={rem(8)}>{detail.icon}</Box>
											) : null}
											<Text
												fw={500}
												fz={rem(12)}
											>
												{detail.text}
											</Text>
										</Box>
									</List.Item>
								)
							})}
						</List>
					</>
				) : (
					<Stack
						mt={title ? rem(14) : 0}
						sx={{
							gap: rem(spacing),
							"&:empty": {
								display: "none",
							},
						}}
					>
						{body.map((detail, index) => (
							<Box
								key={index}
								sx={{
									display: "flex",
									alignItems: "center",
									gap: "10px",
								}}
							>
								{detail.icon ? (
									<Box mr={rem(8)}>{detail.icon}</Box>
								) : null}
								<Text
									fw={500}
									fz={rem(12)}
								>
									{detail.text}
								</Text>
							</Box>
						))}
					</Stack>
				)}
			</HoverCard.Dropdown>
		</HoverCard>
	)
}

export default CustomHoverCard
