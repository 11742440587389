import { axiosConfig } from "../../../config/axios.config"
import { Authorization, Protocols } from "../../../lib/parser"

export const updatePostmanSwagger = async ({
	token,
	authorization,
	host,
	protocol: p,

	url,
}: {
	token: string
	authorization: Authorization | null
	host: string
	url: string
	protocol: Protocols
}) => {
	const response = await axiosConfig.patch(
		`/service/${host}`,
		{
			authorization,
			protocol: p,
			url,
		},
		{
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		}
	)
	const { data } = response

	return data
}
