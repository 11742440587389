import { Box, Center, Flex, Stack, Text, rem } from "@mantine/core"
import { IconNotebook } from "@tabler/icons-react"

const EndpointLogs = () => {
	return (
		<Stack
			w='100%'
			p={rem(32)}
			sx={theme => ({
				borderRadius: rem(8),
				border: `1px solid ${theme.colors["refactor"][2]}`,
				gap: rem(30),
			})}
			h={rem(320)}
		>
			<Box pb={rem(16)}>
				<Text
					c='brand.7'
					fz={rem(16)}
					fw={500}
					lh={rem(24)}
				>
					Logs
				</Text>
			</Box>
			<Flex
				direction='column'
				gap={rem(16)}
				align='center'
				justify='center'
				h='100'
			>
				<Center c='#A3A3A3'>
					<IconNotebook size={rem(40)} />
				</Center>
				<Stack
					sx={{
						gap: rem(8),
					}}
					w={rem(370)}
				>
					<Text
						ta='center'
						lh={rem(24)}
						fz={rem(18)}
						fw={500}
						c='#030124'
					>
						Coming soon!
					</Text>

					<Text
						ta='center'
						fw={400}
						lh={rem(24)}
						fz={rem(16)}
						c='refactor.4'
					>
						We’re working on bringing you detailed logs for this endpoint.
						Stay tuned for updates.
					</Text>
				</Stack>
			</Flex>
		</Stack>
	)
}

export default EndpointLogs
