import { useQueryClient, useIsFetching } from "@tanstack/react-query"
import useAppStore from "../../../zustand/new-store"
import { SubscriptionData } from "../../../hooks/useGetSubscription"
import { useStyles } from "./styles"
import {
	SegmentedControl,
	Stack,
	Text,
	rem,
	Box,
} from "@mantine/core"
import PricingTable from "./components/PricingTable"
import { useState } from "react"
import { useDocumentTitle } from "@mantine/hooks"
import { Periods } from "../../../types/pricing.types"
const PaymentsAuth = () => {
	useDocumentTitle("Pricing")
	const [period, setPeriod] = useState<Periods>("monthly")
	const { classes } = useStyles()
	const queryClient = useQueryClient()
	const { organizationsMetadata } = useAppStore()
	const subscriptionLoading = useIsFetching({
		queryKey: [
			"subscription",
			organizationsMetadata?.billing?.plan?.stripe_session_id,
			organizationsMetadata?.org_id,
		],
	})

	const subscriptionData = queryClient.getQueryData<SubscriptionData>([
		"subscription",
		organizationsMetadata?.billing?.plan?.stripe_session_id,
		organizationsMetadata?.org_id,
	])
	return (
		<Stack
			w='100%'
			sx={{
				gap: rem(44),
			}}
		>
			<Stack
				sx={{
					gap: rem(8),
				}}
			>
		
				<Text
					fz={rem(21)}
					fw={500}
					lh={rem(24)}
					component='h1'
					sx={theme => ({
						color: theme.colors[theme.primaryColor][7],
					})}
					m={0}
				>
					Billing
				</Text>

				<Text
					fw={400}
					component='h2'
					lh={rem(20)}
					fz={rem(16)}
					sx={theme => ({
						color: theme.colors["refactor"][4],
					})}
					m={0}
				>
					Easily review and update your plan, billing information, and
					payment history.
				</Text>
			</Stack>
			<Stack
				sx={{
					gap: rem(24),
				}}
			>
				<Box>
					<SegmentedControl
						value={period}
						classNames={{
							indicator: classes.indicator,
							root: classes.root,
							control: classes.control,
							label: classes.label,
						}}
						onChange={e => {
							setPeriod(e as Periods)
						}}
						data={[
							{ label: "Pay Monthly", value: "monthly" },
							{ label: "Pay Yearly", value: "yearly" },
						]}
					/>
				</Box>
				<PricingTable
					subscriptionData={subscriptionData}
					subscriptionLoading={subscriptionLoading > 0}
					period={period}
				/>
			</Stack>
		</Stack>
	)
}

export default PaymentsAuth
