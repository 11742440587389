import { useEffect } from "react"
import ReactJoyride, { CallBackProps, STATUS, Step } from "react-joyride"
import { useMantineTheme } from "@mantine/core"
import Tooltip from "./Components/Tooltip"
import { HintActions } from "../../zustand/header-hint-tour-store"

const HintModal = ({
	children,
	stepsList,
	isModalOpen,
	completedHints,
	steps,
	run,
	updateHintState,
}: {
	children: React.ReactNode
	stepsList: Step[]
	isModalOpen: boolean
	run: boolean
	steps: Step[]
	completedHints: boolean
	updateHintState: HintActions["updateHintState"]
}) => {
	const theme = useMantineTheme()

	useEffect(() => {
		if (!completedHints) {
			updateHintState({
				run: true,
				steps: stepsList,
			})
		}
	}, [completedHints])

	useEffect(() => {
		if (!run && isModalOpen && !completedHints) {
			updateHintState({ run: true })
		}
	}, [run, isModalOpen, completedHints])
	const handleJoyrideCallback = (data: CallBackProps) => {
		const { status, action } = data

		if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
			updateHintState({ run: false })
		}

		if (data.index === steps.length - 1 && action === "close") {
			updateHintState({
				completedHints: true,
			})
		}
	}

	return (
		<>
			<ReactJoyride
				callback={handleJoyrideCallback}
				continuous
				run={run}
				steps={steps}
				tooltipComponent={Tooltip}
				styles={{
					options: {
						arrowColor: theme.white,
						backgroundColor: theme.white,
						primaryColor: theme.colors[theme.primaryColor][2],
						textColor: theme.colors[theme.primaryColor][7],
						zIndex: 400,
						spotlightShadow: "0px 10px 40px 0px rgba(0, 0, 0, 0.10)",
					},
				}}
				disableScrolling
			/>
			{children}
		</>
	)
}

export default HintModal
