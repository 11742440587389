import { analyticsAxiosConfig } from "../../../config/axios.config"

export async function checkout(body: {
	org_id: string
	token: string
	lookup_key: string
}) {
	const { org_id, token, lookup_key } = body
	const response = await analyticsAxiosConfig.post(
		`/payments/checkout`,
		{ lookup_key, org_id },
		{
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		}
	)
	const { data } = response
	return data
}
