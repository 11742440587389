import ReactJoyride, { CallBackProps } from "react-joyride"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useMantineTheme } from "@mantine/core"
import Tooltip from "./Components/Tooltip"
import useTourStore from "../../zustand/tour-store"

enum PageMap {
	homePage = "/",
	endpointsPage = "/endpoints",
	settingsPage = "/settings",
}

export default function MultiStepTourWrapper({
	children,
}: {
	children: React.ReactNode
}) {
	const { updateTourState, run, stepIndex, steps, completedTour } =
		useTourStore()

	const navigate = useNavigate()

	const theme = useMantineTheme()

	useEffect(() => {
		if (!completedTour) {
			updateTourState({
				tourActive: true,
				steps: [
					{
						target: "#home",
						title: "Dashboard",
						content:
							"View upstream server performance, Resilis cache performance, egress savings, and geographic and API call distribution",
						disableBeacon: true,
					},
					{
						target: `#${PageMap.endpointsPage.slice(1)}`,
						title: "Endpoints",
						content:
							"This page is an API management dashboard that allows a user to configure caching, monitor API endpoints'	performance, and handle errors. It also provides tools to refresh or purge cached data.",
					},
					{
						target: `#${PageMap.settingsPage.slice(1)}`,
						title: "Settings",
						content:
							"Manage account and organizational settings, including updates to personal information, service preferences,and company details.",
					},
				],
			})
		}
	}, [completedTour])

	const handleCallback = (data: CallBackProps) => {
		const { action, index, lifecycle, type, status } = data
		if (
			type === "step:after" &&
			(index === 0 || (action === "prev" && index === 2))
		) {
			updateTourState({ run: false })
			navigate(PageMap.endpointsPage)
		} else if (type === "step:after" && index === 1) {
			if (action === "next") {
				updateTourState({ run: false })
				navigate(PageMap.settingsPage)
			} else {
				navigate(PageMap.homePage)
				updateTourState({ run: true, stepIndex: 0 })
			}
		} else if (action === "reset" || lifecycle === "complete") {
			updateTourState({
				run: false,
				stepIndex: 0,
				tourActive: false,
			})
		}

		if (data.index === steps.length - 1 && action === "close") {
			updateTourState({
				completedTour: true,
			})
		}
	}

	return (
		<>
			<ReactJoyride
				callback={handleCallback}
				continuous
				run={run}
				stepIndex={stepIndex}
				steps={steps}
				tooltipComponent={Tooltip}
				styles={{
					options: {
						arrowColor: theme.white,
						backgroundColor: theme.white,
						primaryColor: theme.colors[theme.primaryColor][2],
						textColor: theme.colors[theme.primaryColor][7],
						zIndex: 400,
						spotlightShadow: "0px 10px 40px 0px rgba(0, 0, 0, 0.10)",
					},
				}}
			/>
			{children}
		</>
	)
}
