import { PasswordInput } from "react-hook-form-mantine"
import {
	PasswordInput as MantinePasswordInput,
	Text,
	Collapse,
	rem,
	createStyles,
	Stack,
	useMantineTheme,
} from "@mantine/core"
import { Control } from "react-hook-form"
import PasswordStrength from "./PasswordStrength"
import { useState } from "react"
import { IconEye, IconEyeOff } from "@tabler/icons-react"

interface IProps {
	name: string
	disabled?: boolean
	label?: string
	placeholder?: string
	required?: true
	control?: Control<any, any>
	value?: string
	onChange?: React.ChangeEventHandler<HTMLInputElement>
	className?: string
	errorMessage?: string
	hideStrengthBar?: boolean
}
const useStyles = createStyles(theme => ({
	root: {
		height: "fit-content",
		width: "100%",
		position: "relative",
	},
	wrapper: {
		height: rem(56),
	},
	input: {
		borderRadius: rem(8),
		border: `1px solid ${theme.colors["refactor"][5]}`,
		height: "100%",
		paddingRight: 0,
		paddingLeft: rem(16),
		":focus-within": {
			border: `1px solid ${theme.colors["refactor"][5]}`,
			borderColor: theme.colors["refactor"][5],
		},
		"&[data-invalid]": {
			border: `1px solid ${theme.colors["danger"][0]}`,
		},
	},
	error: {
		letterSpacing: -0.2,
		fontSize: rem(14),
		color: theme.colors["danger"][0],
		bottom: rem(16),
	},
	innerInput: {
		height: "100%",
		width: "100%",
		paddingRight: rem(56),
		paddingLeft: rem(16),
		lineHeight: rem(20),
		fontSize: rem(16),
		letterSpacing: -0.2,
		transition: "0.1s padding ease",
		color: theme.colors[theme.primaryColor][7],
		"::placeholder": {
			color: theme.colors["refactor"][0],
			letterSpacing: -0.2,
			fontSize: rem(15),
			lineHeight: rem(20),
		},
		"&[data-invalid]": {
			color: theme.colors["danger"][0],
			caretColor: theme.colors["danger"][0],
			"::placeholder": {
				color: theme.colors["danger"][0],
				caretColor: theme.colors["danger"][0],
				letterSpacing: -0.2,
				fontSize: rem(15),
				lineHeight: rem(20),
			},
		},
	},
	innerInputFocus: {
		paddingTop: rem(32),
		paddingBottom: rem(9),
	},

	label: {
		pointerEvents: "none",
		position: "absolute",
		fontSize: rem(14),
		paddingLeft: rem(16.9),
		paddingTop: rem(9),
		zIndex: 1,
		display: "none",
	},
	labelFocus: {
		display: "inline-block",
	},
	visibilityToggle: {
		width: "fit-content",
		color: theme.colors[theme.primaryColor][7],
		fontSize: rem(14),
		paddingRight: rem(12),
		background: "transparent",

		":hover": {
			background: "transparent",
		},
	},
	rightSection: {
		width: "fit-content",
		right: rem(1),
	},
}))
export default function CustomPasswordInput({
	name,
	disabled,
	label,
	placeholder,
	required,
	control,
	value,
	onChange,
	className,
	errorMessage,
	hideStrengthBar,
}: IProps) {
	const [showStrength, setShowStrength] = useState(false)
	const [focused, setFocused] = useState(false)
	const { classes, cx } = useStyles()
	const theme = useMantineTheme()
	if (control) {
		return (
			<Stack sx={{ gap: rem(10), width: "100%" }}>
				<PasswordInput
					name={name}
					disabled={disabled}
					label={label}
					placeholder={focused ? "" : placeholder}
					control={control}
					required={required}
					onFocus={() => {
						setShowStrength(true)
						setFocused(true)
					}}
					visibilityToggleIcon={({ reveal, size }) =>
						reveal ? (
							<IconEye
								color={theme.colors.refactor[0]}
								size={size}
							/>
						) : (
							<IconEyeOff
								color={theme.colors.refactor[0]}
								size={size}
							/>
						)
					}
					className={className}
					classNames={{
						input: classes.input,
						wrapper: classes.wrapper,
						root: classes.root,
						label: cx(classes.label, {
							[classes.labelFocus]: focused || !!value,
						}),
						visibilityToggle: classes.visibilityToggle,
						rightSection: classes.rightSection,
						innerInput: cx(classes.innerInput, {
							[classes.innerInputFocus]: focused || !!value,
						}),
						error: classes.error,
					}}
					error={errorMessage}
					onBlur={() => setFocused(false)}
				/>
				<Collapse
					in={showStrength && Boolean(value) && !hideStrengthBar}
					sx={{ width: "100%" }}
				>
					<PasswordStrength password={value} />
				</Collapse>
			</Stack>
		)
	} else {
		return (
			<Stack sx={{ gap: rem(10) }}>
				<MantinePasswordInput
					value={value}
					onChange={onChange}
					name={name}
					disabled={disabled}
					label={label}
					placeholder={focused ? "" : placeholder}
					required={required}
					onFocus={() => {
						setShowStrength(true)
						setFocused(true)
					}}
					error={errorMessage}
					className={className}
					visibilityToggleIcon={({ reveal, size }) =>
						reveal ? (
							<IconEye
								color={theme.colors.refactor[0]}
								size={size}
							/>
						) : (
							<IconEyeOff
								color={theme.colors.refactor[0]}
								size={size}
							/>
						)
					}
					classNames={{
						input: classes.input,
						wrapper: classes.wrapper,
						root: classes.root,
						label: cx(classes.label, {
							[classes.labelFocus]: focused || !!value,
						}),
						visibilityToggle: classes.visibilityToggle,
						rightSection: classes.rightSection,
						innerInput: cx(classes.innerInput, {
							[classes.innerInputFocus]: focused || !!value,
						}),
						error: classes.error,
					}}
					onBlur={() => setFocused(false)}
				/>

				<Collapse in={showStrength && Boolean(value) && !hideStrengthBar}>
					<PasswordStrength password={value} />
				</Collapse>
			</Stack>
		)
	}
}
