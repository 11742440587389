import { rem, Stack, Text, Box, Group } from "@mantine/core"
import { Button } from "@mantine/core"
import useAppStore from "../../../../zustand/new-store"
import { useLayoutEffect } from "react"
import { notifications } from "@mantine/notifications"
import { useAuth } from "@clerk/clerk-react"
import useRefreshEndpoint from "../api/useRefreshEndpoint"
import usePurgeEndpoints from "../api/usePurgeEndpoints"
const EndpointsLayout = ({ children }: { children: React.ReactNode }) => {
	const {
		cacheConfigSrc,
		cacheConfig,
		activeWorkspace,
		setCacheConfigSrc,
		setEndpointDrawerOpen,
		setEndpointDrawerType,
		endpointTableSelection,
	} = useAppStore()
	const { getToken } = useAuth()
	useLayoutEffect(() => {
		if (cacheConfigSrc === "local" && cacheConfig) {
			setCacheConfigSrc(cacheConfig?.source)
		}
	}, [activeWorkspace?.workspace_id])

	const {
		mutate: refresh,
		supabaseLoading,
		isLoading,
		setSupabaseLoading,
	} = useRefreshEndpoint()

	const { mutate: purge, isLoading: isPurgeLoading } = usePurgeEndpoints()

	return (
		<>
			<Stack
				w='100%'
				sx={{
					gap: rem(32),
				}}
			>
				<Group
					align='center'
					sx={theme => ({
						justifyContent: "space-between",
						borderBottom: `1px solid ${theme.colors["refactor"][2]}`,
					})}
				>
					<Stack
						pb={rem(24)}
						sx={theme => ({
							gap: rem(8),
						})}
					>
						<Text
							fz={rem(21)}
							fw={500}
							lh={rem(24)}
							component='h1'
							sx={theme => ({
								color: theme.colors[theme.primaryColor][7],
							})}
							m={0}
						>
							API Management.
						</Text>

						<Text
							fw={400}
							component='h2'
							lh={rem(20)}
							fz={rem(16)}
							sx={theme => ({
								color: theme.colors["refactor"][4],
							})}
							m={0}
						>
							Manage {cacheConfig?.protocol == "REST" ? "endpoint" : "method"} configurations and monitor performance.
						</Text>
					</Stack>

					<Group
						sx={{
							gap: rem(16),
						}}
					>
						<Button
							h={rem(40)}
							sx={{
								borderRadius: rem(8),
								fontWeight: 500,
								lineHeight: rem(16),
								letterSpacing: -0.2,
							}}
							id='purge'
							disabled={
								isPurgeLoading ||
								supabaseLoading ||
								isLoading ||
								endpointTableSelection.length === 0
							}
							loading={isPurgeLoading || supabaseLoading || isLoading}
							onClick={async () => {
								const token = await getToken()
								if (!token) {
									return
								}

								if (!cacheConfig?.host) {
									return
								}
								notifications.show({
									id: "refresh-path",
									loading: true,
									title: "Purging",
									message: "Purging paths, please wait",
									autoClose: false,
									withCloseButton: false,
								})
								const paths = endpointTableSelection.map(item =>
									item.slice(item.indexOf("/"))
								)
								purge({
									token,
									paths,
								})
							}}
						>
							Purge
						</Button>
						<Button
							h={rem(40)}
							sx={{
								borderRadius: rem(8),
								fontWeight: 500,
								lineHeight: rem(16),
								letterSpacing: -0.2,
							}}
							id={
								cacheConfigSrc === "Swagger" ||
									cacheConfigSrc === "Postman"
									? "refresh"
									: "add-endpoint"
							}
							disabled={isPurgeLoading || supabaseLoading || isLoading}
							loading={isPurgeLoading || supabaseLoading || isLoading}
							onClick={async () => {
								const token = await getToken()
								if (!token) {
									return
								}

								if (
									cacheConfigSrc === "Swagger" ||
									cacheConfigSrc === "Postman"
								) {
									if (!cacheConfig?.source_url) {
										return
									}
									notifications.show({
										id: "refresh-path",
										loading: true,
										title: "Refreshing",
										message: "Refreshing paths, please wait",
										autoClose: false,
										withCloseButton: false,
									})
									setSupabaseLoading(true)

									refresh({
										url: cacheConfig?.source_url,
										host: cacheConfig.host,
										authorization: cacheConfig.authorization ?? null,
										basePath: cacheConfig.basePath,
										protocol: cacheConfig.protocol ?? "REST",
										token,
									})
								} else {
									setEndpointDrawerType("new")
									setEndpointDrawerOpen(true)
								}
							}}
						>
							{cacheConfigSrc === "Swagger" ||
								cacheConfigSrc === "Postman"
								? "Refresh"
								: `+ Add ${cacheConfig?.protocol == "JSON-RPC" ? "Method" : "Endpoint"}`}
						</Button>
					</Group>
				</Group>

				<Box
					w='100%'
					maw='100%'
				>
					{children}
				</Box>
			</Stack>
		</>
	)
}

export default EndpointsLayout
