import { useSignIn } from "@clerk/clerk-react"

const useOauth = () => {
	const { signIn, isLoaded } = useSignIn()
	if (!signIn) {
		return {
			signInWithGoogle: null,
			signInWithGithub: null,
			signInWithGitlab: null,
			isOauthLoaded: isLoaded,
		}
	}
	const signInWithGoogle = () => {
		return signIn.authenticateWithRedirect({
			strategy: "oauth_google",
			redirectUrl: "/sso-callback",
			redirectUrlComplete: "/",
		})
	}

	const signInWithGithub = () => {
		return signIn.authenticateWithRedirect({
			strategy: "oauth_github",
			redirectUrl: "/sso-callback",
			redirectUrlComplete: "/",
		})
	}
	const signInWithGitlab = () => {
		return signIn.authenticateWithRedirect({
			strategy: "oauth_gitlab",
			redirectUrl: "/sso-callback",
			redirectUrlComplete: "/",
		})
	}
	return {
		signInWithGoogle,
		signInWithGithub,
		signInWithGitlab,
		isOauthLoaded: isLoaded,
	}
}

export default useOauth
