import { MediaQuery, rem, Flex } from "@mantine/core"
import { useEffect } from "react"
import { Outlet } from "react-router-dom"
import { getOrganizationMetadata } from "../../../api/supabase/get-org-metadata"
import { useAuth } from "@clerk/clerk-react"
import useAppStore from "../../../zustand/new-store"
const DashboardLayout = () => {
	const { getToken, orgId } = useAuth()
	const { setOrganizationMetadata } = useAppStore()

	async function fetchData() {
		try {
			const data = await getOrganizationMetadata(getToken, orgId || "")
			if (data.data) {
				setOrganizationMetadata(data.data)
			} else {
				console.log(data.error)
			}
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		let ignore = false
		if (!ignore) {
			fetchData()
		}
		return () => {
			ignore = true
		}
	}, [])
	return (
		<MediaQuery
			query='(max-width: 68.5em)'
			styles={{
				width: "100%",
			}}
		>
			<Flex
				sx={{
					width: "100%",
				}}
			>
				<Outlet />
			</Flex>
		</MediaQuery>
	)
}

export default DashboardLayout
