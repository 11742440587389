import { purgeEndpoints } from "../../../../api/server/analytics/purge-endpoints"
import { useMutation } from "@tanstack/react-query"
import { axiosErrorParser } from "../../../../lib/parse-axios-error"
import { notifications } from "@mantine/notifications"
import { IconCheck, IconX } from "@tabler/icons-react"
import { AxiosError } from "axios"
import { useState } from "react"

const usePurgeEndpoints = () => {
	const [supabaseLoading, setSupabaseLoading] = useState(false)

	const mutation = useMutation({
		mutationFn: purgeEndpoints,
		onError(error: AxiosError) {
			const errorMessage = axiosErrorParser(error)
			if (typeof errorMessage === "string") {
				notifications.update({
					withCloseButton: true,
					color: "danger.0",
					title: "Something went wrong",
					icon: <IconX />,
					autoClose: 5000,
					message: errorMessage,
					id: "refresh-path",
				})
			} else {
				if (errorMessage instanceof Array) {
					for (const item of errorMessage) {
						notifications.update({
							withCloseButton: true,
							color: "danger.0",
							title: "Something went wrong",
							icon: <IconX />,
							autoClose: 5000,
							message: item,
							id: "refresh-path",
						})
					}
				} else {
					notifications.update({
						withCloseButton: true,
						color: "danger.0",
						title: "Something went wrong",
						icon: <IconX />,
						autoClose: 5000,
						message: errorMessage.error,
						id: "refresh-path",
					})
				}
			}

			setSupabaseLoading(false)
		},

		async onSuccess(data, variables, context) {
			notifications.update({
				id: "refresh-path",
				withCloseButton: true,
				color: "green.0",
				title: "Success",
				icon: <IconCheck />,
				autoClose: 5000,
				message: "Endpoints purged successfully",
			})
			setSupabaseLoading(false)
		},
	})
	return { supabaseLoading, setSupabaseLoading, ...mutation }
}

export default usePurgeEndpoints
