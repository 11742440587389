import {
	Button,
	Group,
	Stack,
	Text,
	rem,
	TextInput,
	createStyles,
} from "@mantine/core"
import { useDocumentTitle } from "@mantine/hooks"
import useAppStore from "../../../zustand/new-store"
import CustomTextInput from "../../../Components/TextInput/TextInput"
import { useUser, useOrganization, useAuth } from "@clerk/clerk-react"
import { IconLock } from "@tabler/icons-react"
import * as z from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm, SubmitHandler } from "react-hook-form"
import { urlPattern } from "../../../constants/regex"
import { useEffect, useState } from "react"
import { UpdateOrg } from "../../../types/supabase.types"
import { updateOrganizationMetadata } from "../../../api/supabase/update-org-metadata"
import useTourStore from "../../../zustand/tour-store"

const useStyles = createStyles(theme => ({
	root: {
		height: rem(56),
		width: "100%",
	},
	wrapper: {
		height: "100%",
	},

	input: {
		borderRadius: rem(8),
		border: "none",
		paddingTop: rem(32),
		paddingBottom: rem(9),
		paddingLeft: rem(16),
		paddingRight: rem(16),
		height: "100%",
		lineHeight: rem(20),
		fontSize: rem(16),
		letterSpacing: -0.2,
		color: theme.colors[theme.primaryColor][7],

		"&[data-disabled]": {
			color: theme.colors[theme.primaryColor][7],
			opacity: 1,
			cursor: "not-allowed",
			background: "#F5F4F4",
		},
	},

	label: {
		pointerEvents: "none",
		position: "absolute",
		fontSize: rem(14),
		paddingLeft: rem(16.9),
		paddingTop: rem(9),
		zIndex: 1,
		display: "inline-block",
		color: theme.colors["refactor"][0],
	},
	rightSection: {
		color: theme.colors["refactor"][0],
		width: "fit-content",
		right: rem(16),
	},
	margin: {
		marginTop: rem(16),
	},
}))

const organizationSchema = z.object({
	workspaceName: z
		.string({
			required_error: "service name is required",
		})
		.min(1, { message: "service name is required" }),
	companyName: z
		.string({
			required_error: "company name is required",
		})
		.min(1, { message: "company name is required" }),
	companyWebsite: z
		.string({
			required_error: "company website is required",
			invalid_type_error: "company website must be a valid url",
		})
		.regex(urlPattern, { message: "company website must be a valid url" })
		.min(1, { message: "company website is required" }),
})

type organizationFormType = z.infer<typeof organizationSchema>

const Settings = () => {
	useDocumentTitle("Profile")
	const { updateTourState, tourActive, completedTour } = useTourStore()
	const {
		organizationsMetadata,
		setOrganizationMetadata,
		activeWorkspace,
		setActiveWorkSpace,
	} = useAppStore()
	const { getToken } = useAuth()
	const { user } = useUser()
	const { classes } = useStyles()
	const [loading, setLoading] = useState<boolean>(false)
	const { organization } = useOrganization()

	useEffect(() => {
		if (tourActive && !completedTour) {
			updateTourState({ run: true, stepIndex: 2 })
		}
	}, [tourActive, completedTour])
	const { control, handleSubmit, watch, formState, reset } =
		useForm<organizationFormType>({
			resolver: zodResolver(organizationSchema),
			defaultValues: {
				workspaceName: activeWorkspace?.workspace_name || "",
				companyName: organizationsMetadata?.name || "",
				companyWebsite: organizationsMetadata?.website || "",
			},
		})

	const submit: SubmitHandler<organizationFormType> = async values => {
		setLoading(true)

		if (!organization) {
			setLoading(false)
			return
		}
		const serviceArr = organizationsMetadata?.services.map(service => {
			if (service.workspace_id === activeWorkspace?.workspace_id) {
				service.workspace_name = values.workspaceName
			}
			return service
		})
		const newOrganization: UpdateOrg = {
			services: serviceArr,
			org_id: organization.id,
			name: values.companyName,
			website: values.companyWebsite,
		}
		try {
			if (organization.name !== values.companyName) {
				await organization.update({ name: values.companyName })
			}
			const { data, error } = await updateOrganizationMetadata(
				getToken,
				newOrganization
			)

			if (data) {
				setOrganizationMetadata(data)
				setActiveWorkSpace({
					workspace_id: activeWorkspace?.workspace_id || "",
					workspace_name: values.workspaceName,
				})
			}

			if (error && !data) {
				console.log(error)
				console.log(JSON.stringify(error, null, 2))
			}
		} catch (error) {
			console.log(error)
			console.log(JSON.stringify(error, null, 2))
		}
		reset()
		setLoading(false)
	}
	return (
		<Stack
			miw='100%'
			p={rem(32)}
			sx={theme => ({
				borderRadius: rem(8),
				border: `1px solid ${theme.colors["refactor"][2]}`,
				gap: rem(30),
			})}
		>
			<Group
				pb={rem(24)}
				sx={theme => ({
					gap: rem(32),
					borderBottom: `1px solid ${theme.colors["refactor"][2]}`,
				})}
			>
				<Stack
					sx={{
						gap: rem(8),
						flexGrow: 0,
					}}
					maw={rem(300)}
				>
					<Text
						m={0}
						component='h3'
						fw={500}
						lh={rem(20)}
						fz={rem(16)}
						sx={theme => ({
							color: theme.colors[theme.primaryColor][7],
						})}
					>
						Personal Information
					</Text>
					<Text
						m={0}
						component='h4'
						fw={400}
						lh={rem(20)}
						fz={rem(14)}
						sx={theme => ({
							color: theme.colors["refactor"][4],
						})}
					>
						Update your personal information and ensure your profile
						reflects your preferences
					</Text>
				</Stack>
				<Stack
					sx={{
						flexGrow: 1,
					}}
				>
					<TextInput
						label='Email Address'
						value={user?.primaryEmailAddress?.emailAddress}
						disabled
						rightSection={<IconLock size={rem(20)} />}
						classNames={{
							input: classes.input,
							wrapper: classes.wrapper,
							root: classes.root,
							label: classes.label,
							rightSection: classes.rightSection,
						}}
					/>
				</Stack>
			</Group>

			<Group
				sx={theme => ({
					gap: rem(32),
					alignItems: "flex-start",
					"> *": {
						flexGrow: 1,
					},
				})}
			>
				<Stack
					sx={{
						gap: rem(8),
						flexGrow: 0,
					}}
					maw={rem(300)}
				>
					<Text
						m={0}
						component='h3'
						fw={500}
						lh={rem(20)}
						fz={rem(16)}
						sx={theme => ({
							color: theme.colors[theme.primaryColor][7],
						})}
					>
						Organization Details
					</Text>
					<Text
						m={0}
						component='h4'
						fw={400}
						lh={rem(20)}
						fz={rem(14)}
						sx={theme => ({
							color: theme.colors["refactor"][4],
						})}
					>
						Manage your organization's information and settings.
					</Text>
				</Stack>
				<form onSubmit={handleSubmit(submit)}>
					<Stack
						sx={{
							flexGrow: 1,
							gap: rem(24),
						}}
					>
						<CustomTextInput
							name='workspaceName'
							label='Service Name'
							placeholder='Service Name'
							control={control}
							disabled={loading}
							errorMessage={formState.errors.workspaceName?.message}
							value={watch("workspaceName")}
						/>
						<CustomTextInput
							name='companyName'
							label='Company Name'
							placeholder='Company Name'
							control={control}
							value={watch("companyName")}
							disabled={loading}
							errorMessage={formState.errors.companyName?.message}
							className={
								formState.errors.workspaceName?.message
									? classes.margin
									: undefined
							}
						/>
						<CustomTextInput
							name='companyWebsite'
							label='Company Website'
							placeholder='Company Website'
							control={control}
							value={watch("companyWebsite")}
							disabled={loading}
							errorMessage={formState.errors.companyWebsite?.message}
							className={
								formState.errors.companyName?.message
									? classes.margin
									: undefined
							}
						/>
						<Button
							w='fit-content'
							h={rem(48)}
							sx={{
								borderRadius: rem(8),
							}}
							mt={
								formState.errors.companyWebsite?.message
									? rem(16)
									: undefined
							}
							disabled={
								(watch("companyWebsite") ===
									organizationsMetadata?.website &&
									watch("companyName") ===
										organizationsMetadata.name &&
									watch("workspaceName") ===
										activeWorkspace?.workspace_name) ||
								loading
							}
							type='submit'
							loading={loading}
						>
							Save Changes
						</Button>
					</Stack>
				</form>
			</Group>
		</Stack>
	)
}

export default Settings
