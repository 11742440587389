import { memo, useMemo, useState, useEffect } from "react"
import {
	isClerkAPIResponseError,
	useAuth,
	useOrganization,
	useUser,
} from "@clerk/clerk-react"
import { notifications } from "@mantine/notifications"
import {
	createStyles,
	Table,
	UnstyledButton,
	Group,
	Text,
	Center,
	TextInput,
	rem,
	Stack,
	SimpleGrid,
	Flex,
	Select,
	Menu,
	ActionIcon,
	Pagination,
	Box,
	ScrollArea,
} from "@mantine/core"
import {
	IconCheck,
	IconChevronDown,
	IconChevronUp,
	IconSearch,
	IconSelector,
	IconSend,
	IconTrash,
	IconX,
} from "@tabler/icons-react"
import { keys } from "@mantine/utils"
import { usePaginationData } from "../../../../hooks/use-pagination"
import { RowData, Status, ThProps } from "../../../../types/teams-table.types"
import { IconMore } from "../../../../assets/icons"
import useAppStore from "../../../../zustand/new-store"
import useInviteMembers from "./api/useInviteMembers"
import { TEAMS_HITS_PER_PAGE } from "../../../../constants/hits_per_page"
const useStyles = createStyles(theme => ({
	th: {
		padding: "0 !important",
		borderBottom: `1px solid ${theme.colors["refactor"][2]}!important`,
	},

	table: {
		borderBottomWidth: "1px",
		overflow: "visible",
		borderBottomColor: theme.colors["refactor"][2],
	},
	control: {
		width: `${rem(72)}!important`,
		maxWidth: `${rem(72)}!important`,
		whiteSpace: "nowrap",
		paddingRight: rem(24),
		paddingLeft: rem(24),
		borderBottom: `1px solid ${theme.colors["refactor"][2]}!important`,
	},

	tr: {
		borderBottom: `1px solid ${theme.colors["refactor"][2]}!important`,
	},

	rowSelected: {
		backgroundColor:
			theme.colorScheme === "dark"
				? theme.fn.rgba(theme.colors[theme.primaryColor][7], 0.2)
				: theme.colors[theme.primaryColor][0],
	},

	icon: {
		width: rem(20),
		height: rem(20),
		borderRadius: rem(20),
	},

	root: {
		height: rem(40),
		width: "100%",
	},
	wrapper: {
		height: "100%",
	},
	input: {
		borderRadius: rem(8),
		border: `1px solid ${theme.colors["refactor"][5]}`,
		height: "100%",
		lineHeight: rem(20),
		letterSpacing: -0.2,
		color: theme.colors[theme.primaryColor][7],

		"::placeholder": {
			color: theme.colors["refactor"][0],
			letterSpacing: -0.2,
			lineHeight: rem(20),
		},
		":focus": {
			border: `1px solid ${theme.colors["refactor"][5]}`,
		},
	},
	selectRoot: {
		">div:first-of-type": {
			height: "100%",
		},
	},

	menuItem: {
		"&[data-hovered]": {
			background: theme.white,
		},
		background: theme.white,
		transition: "none",
	},
}))
function Th({ children, reversed, sorted, onSort }: ThProps) {
	const { classes } = useStyles()
	const Icon = sorted
		? reversed
			? IconChevronUp
			: IconChevronDown
		: IconSelector
	return (
		<th className={classes.th}>
			<UnstyledButton
				onClick={onSort}
				pt={0}
				pl={rem(24)}
				pr={rem(24)}
				pb={rem(16)}
				w='100%'
			>
				<Group
					position='apart'
					noWrap
				>
					<Text
						fw={500}
						fz={rem(12)}
						lts={0.8}
						lh={rem(18)}
						transform='uppercase'
						sx={theme => ({
							color: theme.colors["refactor"][0],
							whiteSpace: "nowrap",
						})}
					>
						{children}
					</Text>
					<Center
						className={classes.icon}
						sx={theme => ({
							color: theme.colors["refactor"][0],
						})}
					>
						<Icon size={rem(16)} />
					</Center>
				</Group>
			</UnstyledButton>
		</th>
	)
}

function filterData(data: RowData[], search: string) {
	const query = search.toLowerCase().trim()

	return data.filter(item =>
		keys(data[0]).some(key => {
			const currVal = item[key]
			if (typeof currVal !== "string") {
				return false
			}
			return currVal.toLowerCase().includes(query)
		})
	)
}

function sortData(
	data: RowData[],
	payload: {
		sortBy: keyof Omit<RowData, "deleteFn" | "updateFn"> | null
		reversed: boolean
		search: string
	}
) {
	const { sortBy } = payload
	if (!sortBy) {
		return filterData(data, payload.search)
	}

	return filterData(
		[...data].sort((a, b) => {
			if (payload.reversed) {
				return b[sortBy].localeCompare(a[sortBy])
			}
			return a[sortBy].localeCompare(b[sortBy])
		}),
		payload.search
	)
}

const TeamsTable = () => {
	const { organizationsMetadata } = useAppStore()
	const { organization, membership, invitations, memberships } =
		useOrganization({
			invitations: {
				infinite: true,
			},
			memberships: {
				infinite: true,
			},
		})

	const { getToken } = useAuth()
	const { mutate, isLoading: isResendLoading } = useInviteMembers({
		successFn: () => {
			notifications.show({
				withCloseButton: true,
				color: "green.0",
				title: "Success",
				icon: <IconCheck />,
				autoClose: 5000,
				message: "Invitation resent",
			})
		},
		errorFn: () => {
			notifications.show({
				withCloseButton: true,
				color: "danger.0",
				title: "Something went wrong",
				icon: <IconX />,
				autoClose: 5000,
				message: "Could not invite user",
			})
		},
	})
	const { user } = useUser()

	const isAdmin = membership?.role === "admin"
	const { classes, cx } = useStyles()
	const [isLoading, setIsLoading] = useState(false)
	const [sortBy, setSortBy] = useState<
		keyof Omit<RowData, "deleteFn" | "updateFn"> | null
	>(null)
	const [reverseSortDirection, setReverseSortDirection] = useState(false)

	const modifiedData = useMemo(() => {
		const modifiedInvitation: RowData[] =
			invitations && invitations.data
				? invitations.data?.map((invited, idx) => {
						return {
							id: invited.id,
							email: invited.emailAddress,
							role: invited.role,
							created: new Intl.DateTimeFormat("en-US", {
								dateStyle: "medium",
							}).format(invited.createdAt),
							status: Status.Invited,
							deleteFn: async () => {
								if (isAdmin) {
									setIsLoading(true)
									invited
										.revoke()
										.then(() => {
											invitations.setData(
												// @ts-ignore
												invitations.data.filter(
													inv => inv.id !== invited.id
												)
											)

											notifications.show({
												withCloseButton: true,
												color: "green.0",
												title: "Success",
												icon: <IconCheck />,
												autoClose: 5000,
												message: "User invite revoked successfully",
											})
										})
										.catch(error => {
											if (isClerkAPIResponseError(error)) {
												notifications.show({
													withCloseButton: true,
													color: "danger.0",
													title: "Something went wrong",
													icon: <IconX />,
													autoClose: 5000,
													message: error.errors[0].message,
												})
											} else {
												notifications.show({
													withCloseButton: true,
													color: "danger.0",
													title: "Something went wrong",
													icon: <IconX />,
													autoClose: 5000,
													message: "Could not revoke invite",
												})
											}
										})
										.finally(() => {
											setIsLoading(false)
										})
								} else {
									notifications.show({
										withCloseButton: true,
										color: "danger.0",
										title: "Something went wrong",
										icon: <IconX />,
										autoClose: 5000,
										message: "Unauthorized",
									})
								}
							},
							updateFn: async () => {
								const token = await getToken()
								if (isLoading || !token) {
									return
								}
								setIsLoading(true)

								try {
									if (isAdmin) {
										invited.revoke().then(() => {
											mutate({
												token,
												email: invited.emailAddress,
												role: invited.role,
											})
										})
									} else {
										notifications.show({
											withCloseButton: true,
											color: "danger.0",
											title: "Something went wrong",
											icon: <IconX />,
											autoClose: 5000,
											message: "Unauthorized",
										})
									}
								} catch (error) {
									if (isClerkAPIResponseError(error)) {
										notifications.show({
											withCloseButton: true,
											color: "danger.0",
											title: "Something went wrong",
											icon: <IconX />,
											autoClose: 5000,
											message: error.errors[0].message,
										})
									} else {
										notifications.show({
											withCloseButton: true,
											color: "danger.0",
											title: "Something went wrong",
											icon: <IconX />,
											autoClose: 5000,
											message: "Could not revoke invite",
										})
									}
								} finally {
									setIsLoading(false)
								}
							},
						}
				  })
				: []

		const modifiedMembership: RowData[] =
			memberships && memberships.data
				? memberships.data.map(member => {
						return {
							id: member.id,
							email: member.publicUserData.identifier,
							role: member.role,
							created: new Intl.DateTimeFormat("en-US", {
								dateStyle: "medium",
							}).format(member.createdAt),
							status: Status.Joined,
							deleteFn: async () => {
								const { publicUserData } = member
								if (publicUserData.userId === user?.id) {
									notifications.show({
										withCloseButton: true,
										color: "danger.0",
										title: "Something went wrong",
										icon: <IconX />,
										autoClose: 5000,
										message: "You cannot perform this action",
									})
									return
								}

								setIsLoading(true)

								try {
									if (isAdmin) {
										member.destroy().then(() => {
											memberships.data.filter(
												inv => inv.id !== member.id
											)
											notifications.show({
												withCloseButton: true,
												color: "green.0",
												title: "Success",
												icon: <IconCheck />,
												autoClose: 5000,
												message: "User deleted successfully",
											})

											memberships.revalidate()
										})
									} else {
										notifications.show({
											withCloseButton: true,
											color: "danger.0",
											title: "Something went wrong",
											icon: <IconX />,
											autoClose: 5000,
											message: "Unauthorized",
										})
									}
								} catch (error) {
									if (isClerkAPIResponseError(error)) {
										notifications.show({
											withCloseButton: true,
											color: "danger.0",
											title: "Something went wrong",
											icon: <IconX />,
											autoClose: 5000,
											message: error.errors[0].message,
										})
									} else {
										notifications.show({
											withCloseButton: true,
											color: "danger.0",
											title: "Something went wrong",
											icon: <IconX />,
											autoClose: 5000,
											message: "Unauthorized",
										})
									}
								} finally {
									setIsLoading(false)
								}
							},
							updateFn: async (
								newRole: "admin" | "basic_member" | undefined
							) => {
								if (!newRole) {
									return
								}
								const { publicUserData } = member
								if (publicUserData.userId === user?.id) {
									notifications.show({
										withCloseButton: true,
										color: "danger.0",
										title: "Something went wrong",
										icon: <IconX />,
										autoClose: 5000,
										message: "You cannot perform this action",
									})

									return
								}
								setIsLoading(true)

								try {
									if (isAdmin) {
										await member.update({
											role: newRole,
										})
										memberships.revalidate()

										notifications.show({
											withCloseButton: true,
											color: "green.0",
											title: "Success",
											icon: <IconCheck />,
											autoClose: 5000,
											message: "User updated successfully",
										})
									} else {
										notifications.show({
											withCloseButton: true,
											color: "danger.0",
											title: "Something went wrong",
											icon: <IconX />,
											autoClose: 5000,
											message: "Unauthorized",
										})
									}
								} catch (error) {
									if (isClerkAPIResponseError(error)) {
										notifications.show({
											withCloseButton: true,
											color: "danger.0",
											title: "Something went wrong",
											icon: <IconX />,
											autoClose: 5000,
											message: error.errors[0].message,
										})
									} else {
										notifications.show({
											withCloseButton: true,
											color: "danger.0",
											title: "Something went wrong",
											icon: <IconX />,
											autoClose: 5000,
											message: "Unauthorized",
										})
									}
								} finally {
									setIsLoading(false)
								}
							},
						}
				  })
				: []

		return [...modifiedInvitation, ...modifiedMembership]
	}, [
		organization?.id,
		membership?.organization?.membersCount,
		membership?.organization.pendingInvitationsCount,
		memberships?.data,
		invitations?.data,
	])

	const [activePage, setActivePage] = useState(1)

	const { totalPages, currentPageData, setUnprocessedPageData } =
		usePaginationData<RowData>(modifiedData, activePage, TEAMS_HITS_PER_PAGE)

	const [sortedData, setSortedData] = useState<RowData[]>(currentPageData)

	useEffect(() => {
		setSortedData(currentPageData)
	}, [currentPageData])

	const [search, setSearch] = useState("")

	const setSorting = (field: keyof Omit<RowData, "deleteFn" | "updateFn">) => {
		const reversed = field === sortBy ? !reverseSortDirection : false
		setReverseSortDirection(reversed)
		setSortBy(field)
		setActivePage(1)
		setUnprocessedPageData(
			sortData(modifiedData, { sortBy: field, reversed, search })
		)
	}

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.currentTarget
		setSearch(value)
		setActivePage(1)
		const sorted = sortData(modifiedData, {
			sortBy,
			reversed: reverseSortDirection,
			search: value,
		})

		setUnprocessedPageData(sorted)
	}
	const organizationOwner = useMemo(() => {
		return organizationsMetadata?.users.find(
			user => user.user_id === organizationsMetadata.user_id
		)
	}, [organizationsMetadata?.user_id, organizationsMetadata?.users.length])
	const rows = useMemo(() => {
		return sortedData.map((row, i) => {
			let { id, email, status, role, created, deleteFn, updateFn } = row

			return (
				<tr key={id}>
					<td className={classes.tr}>
						<Text
							fw={400}
							fz={rem(14)}
							lh={rem(18)}
							sx={theme => ({
								color: theme.colors[theme.primaryColor][7],
								width: "100%",
								overflow: "hidden",
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
							})}
						>
							{email}
						</Text>
					</td>
					<td className={classes.tr}>
						{isAdmin ? (
							<Select
								size='xs'
								placeholder='Role'
								data={[
									{ value: "admin", label: "Admin" },
									{ value: "basic_member", label: "Member" },
								]}
								onChange={e => {
									console.log("Changing member role")
									if (e) {
										updateFn(e as "admin" | "basic_member")
									}
								}}
								value={role}
								disabled={
									status === Status.Invited ||
									isLoading ||
									isResendLoading
								}
								classNames={{
									input: classes.input,
									wrapper: classes.wrapper,
									root: cx(classes.root, classes.selectRoot),
								}}
								withinPortal
							/>
						) : (
							<Text
								fw={400}
								fz={rem(14)}
								lh={rem(18)}
								sx={theme => ({
									color: theme.colors[theme.primaryColor][7],
								})}
							>
								{role === "admin" ? "Administrator" : "Member"}
							</Text>
						)}
					</td>
					<td className={classes.tr}>
						<Text
							fw={400}
							fz={rem(14)}
							lh={rem(18)}
							sx={theme => ({
								color: theme.colors[theme.primaryColor][7],
							})}
						>
							{created}
						</Text>
					</td>
					<td className={classes.tr}>
						{status === Status.Invited ? (
							<Center
								sx={{
									padding: `${rem(4)} ${rem(10)}`,
									borderRadius: rem(4),
									background: "rgba(242, 140, 48, 0.10)",
								}}
								w='fit-content'
							>
								<Text
									fz={rem(14)}
									fw={400}
									lh={rem(16)}
									sx={theme => ({
										color: theme.colors["yellow"][0],
										fontFamily: "Outfit, sans-serif",
									})}
								>
									pending
								</Text>
							</Center>
						) : (
							<Center
								sx={{
									padding: `${rem(4)} ${rem(10)}`,
									borderRadius: rem(4),
									background: "rgba(21, 163, 54, 0.10)",
								}}
								w='fit-content'
							>
								<Text
									fz={rem(14)}
									fw={400}
									lh={rem(16)}
									sx={theme => ({
										color: theme.colors["green"][2],
										fontFamily: "Outfit, sans-serif",
									})}
								>
									active
								</Text>
							</Center>
						)}
					</td>

					{isAdmin ? (
						<td className={classes.control}>
							<Menu
								shadow='md'
								width={200}
								withinPortal
								classNames={{ item: classes.menuItem }}
							>
								<Menu.Target>
									<ActionIcon
										sx={{
											height: "fit-content",
											minHeight: "fit-content",
											width: "fit-content",
											minWidth: "fit-content",
											":hover": {
												background: "transparent",
											},
										}}
									>
										<IconMore />
									</ActionIcon>
								</Menu.Target>

								<Menu.Dropdown
									sx={theme => ({
										boxShadow:
											"0px 10px 40px 0px rgba(0, 0, 0, 0.10)",
										borderRadius: rem(6),
										border: "none",
										background: theme.white,
									})}
								>
									{status === Status.Invited ? (
										<>
											<Menu.Item
												icon={<IconSend size={14} />}
												disabled={
													organizationOwner?.email === email ||
													isLoading ||
													isResendLoading
												}
												onClick={async () => {
													const token = await getToken()
													if (isLoading || !token) {
														return
													}
													console.log("Resending invite")
													updateFn()
												}}
												closeMenuOnClick={false}
											>
												Resend Invite
											</Menu.Item>
											<Menu.Item
												color='danger.0'
												icon={<IconTrash size={14} />}
												disabled={
													organizationOwner?.email === email ||
													isLoading ||
													isResendLoading
												}
												onClick={() => {
													if (isLoading) {
														return
													}
													console.log("Revoking invite")
													deleteFn()
												}}
												closeMenuOnClick={false}
											>
												Revoke Invite
											</Menu.Item>
										</>
									) : (
										<Menu.Item
											color='danger.0'
											icon={<IconTrash size={14} />}
											disabled={
												organizationOwner?.email === email ||
												isLoading
											}
											onClick={() => {
												if (isLoading) {
													return
												}
												console.log("Deleting membership")
												deleteFn()
											}}
											closeMenuOnClick={false}
										>
											Remove User
										</Menu.Item>
									)}
								</Menu.Dropdown>
							</Menu>
						</td>
					) : null}
				</tr>
			)
		})
	}, [sortedData])
	return (
		<Stack sx={{ gap: rem(24) }}>
			<SimpleGrid cols={2}>
				<Flex gap={rem(16)}>
					<TextInput
						placeholder='Search by any field'
						size='xs'
						icon={
							<IconSearch
								size={rem(16)}
								stroke={1.5}
							/>
						}
						value={search}
						w={"30vw"}
						onChange={handleSearchChange}
						classNames={{
							input: classes.input,
							wrapper: classes.wrapper,
							root: classes.root,
						}}
						miw={rem(220)}
					/>

					<Select
						size='xs'
						placeholder='Role'
						data={[
							{ value: "all", label: "ALL" },
							{ value: "admin", label: "ADMIN" },
							{ value: "basic_member", label: "MEMBER" },
						]}
						defaultValue='all'
						h={rem(40)}
						classNames={{
							input: classes.input,
							wrapper: classes.wrapper,
							root: cx(classes.root, classes.selectRoot),
						}}
						miw={rem(220)}
						onChange={e => {
							if (e) {
								if (e === "all") {
									setActivePage(1)
									setUnprocessedPageData(modifiedData)
								} else {
									setActivePage(1)
									setUnprocessedPageData(oldModifiedData =>
										modifiedData.filter(datum => datum.role === e)
									)
								}
							}
						}}
						withinPortal
					/>
				</Flex>
			</SimpleGrid>
			<Box
				p={0}
				maw='100%'
				w='100%'
				sx={{
					overflowX: "auto",
				}}
			>
				<ScrollArea>
					<Table
						horizontalSpacing={rem(24)}
						verticalSpacing={rem(16)}
						w='100%'
						className={classes.table}
					>
						<thead>
							<tr>
								<Th
									sorted={sortBy === "email"}
									reversed={reverseSortDirection}
									onSort={() => setSorting("email")}
								>
									User
								</Th>
								<Th
									sorted={sortBy === "role"}
									reversed={reverseSortDirection}
									onSort={() => setSorting("role")}
								>
									Role
								</Th>
								<Th
									sorted={sortBy === "created"}
									reversed={reverseSortDirection}
									onSort={() => setSorting("created")}
								>
									Date Invited
								</Th>

								<Th
									sorted={sortBy === "status"}
									reversed={reverseSortDirection}
									onSort={() => setSorting("status")}
								>
									Status
								</Th>
								{isAdmin ? <th className={classes.control}></th> : null}
							</tr>
						</thead>
						<tbody>
							{rows.length <= 0 ? (
								<tr>
									<td colSpan={5}>
										<Text
											weight={500}
											align='center'
										>
											Nothing found
										</Text>
									</td>
								</tr>
							) : (
								<>{rows}</>
							)}
						</tbody>
					</Table>
				</ScrollArea>
			</Box>
			<Flex justify='flex-end'>
				<Pagination
					disabled={totalPages === 1}
					total={totalPages}
					size='xs'
					value={activePage}
					onChange={e => {
						setActivePage(e)
					}}
					onFirstPage={() => {
						setActivePage(1)
					}}
					onLastPage={() => {
						if (activePage === totalPages) {
							return
						}
						setActivePage(totalPages)
					}}
					onPreviousPage={() => {
						if (activePage === 1) {
							return
						}
						setActivePage(curr => curr - 1)
					}}
					onNextPage={() => {
						if (activePage === totalPages) {
							return
						}
						setActivePage(curr => curr + 1)
					}}
				/>
			</Flex>
		</Stack>
	)
}

export default memo(TeamsTable)
