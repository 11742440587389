import { Loader, Text, rem, Center } from "@mantine/core"

const Loading = () => {
	return (
		<Center
			sx={theme => ({
				minHeight: `calc(100vh - ${rem(192)})`,
				gap: rem(48),
				backgroundColor: theme.white,
				position: "relative",
			})}
		>
			<Text
				color='black'
				size='xl'
				align='center'
			>
				Please wait...
			</Text>
			<Loader
				size={96}
				color='#3E3CFF'
			/>
		</Center>
	)
}

export default Loading
