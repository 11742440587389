import { Modal, ScrollArea, rem, createStyles } from "@mantine/core"

const useStyles = createStyles(theme => ({
	modalContent: {
		borderRadius: rem(16),
		background: theme.white,
		boxShadow: "none",
		padding: rem(40),
		width: `min(${rem(480)}, 100%)`,
		scrollbarWidth: "none",
		msOverflowStyle: "none",
		"::-webkit-scrollbar": {
			display: "none",
		},
	},
	modalBody: {
		padding: 0,
	},
	overlay: {
		background: "rgba(3, 2, 23, 0.20)",
	},
}))

const CustomModal = ({
	opened,
	onClose,
	children,
}: {
	opened: boolean
	onClose: () => void
	children: React.ReactNode
}) => {
	const { classes } = useStyles()

	return (
		<Modal
			opened={opened}
			onClose={() => {
				onClose()
			}}
			withCloseButton={false}
			withinPortal
			centered
			classNames={{
				content: classes.modalContent,
				body: classes.modalBody,
			}}
		>
			{children}
		</Modal>
	)
}

export default CustomModal
