import { set, useFormContext } from "react-hook-form"
import { serviceAuthSchemaType } from "../../../lib/parser"
import { Box, Button, Checkbox, Group, Stack, Text, rem } from "@mantine/core"
import CustomSwitch from "../../../Components/CustomSwitch/CustomSwitch"
import AuthForm from "./AuthForm"
import { useState } from "react"

const AuthMethod = (props: {
	next: () => void
	openAuth: boolean
	toggleAuth: () => void
}) => {
	const authMethods = useFormContext<serviceAuthSchemaType>()
	const [skipable, setSkipable] = useState<boolean>(false)
	const { next, openAuth, toggleAuth } = props
	return (
		<Stack
			sx={{
				gap: rem(24),
				position: "relative",
				justifyContent: "space-between",
			}}
			mt={rem(24)}
			w='100%'
			mih='100%'
		>
			<Group
				spacing={rem(12)}
				align='center'
				noWrap
			>
				<Stack>
					<Text
						lh={rem(20)}
						fz={rem(16)}
						c='brand.7'
						pl={rem(4)}
						mx={rem(4)}
					>
						Authorization Method
					</Text>

					<Text
						lh={rem(20)}
						fz={rem(14)}
						c='refactor.4'
						pl={rem(4)}
						mx={rem(4)}
					>
						Specify where to locate the authorization parameter in your API requests (header or query).
						We'll use this information to provide private caches for each authorized entity and enforce security at the edge.
					</Text>
				</Stack>

				<Group
					noWrap
					spacing={rem(8)}
				>
					<CustomSwitch
						checked={openAuth}
						onChange={() => {
							if (openAuth) {
								authMethods.resetField("key")
								authMethods.resetField("type")
								authMethods.resetField("variant")
								setSkipable(false)
							}
							toggleAuth()
						}}
					/>
					<Text fz={rem(12)}>{openAuth ? "Disable" : "Enable"}</Text>
				</Group>
			</Group>

			{openAuth ? (
				<>
					<Box
						sx={{
							flex: 1,
							display: "flex",
							flexDirection: "column",
							justifyContent: "space-between",
						}}
						component='form'
						onSubmit={e => {
							e.preventDefault()
							next()
						}}
					>
						<AuthForm />
						<Button
							variant='filled'
							fullWidth
							h={rem(48)}
							type='submit'
							sx={{
								borderRadius: rem(8),
								fontWeight: 500,
								lineHeight: rem(16),
								letterSpacing: -0.2,
							}}
							disabled={
								!authMethods.watch("key") || !authMethods.watch("type")
							}
						>
							Continue
						</Button>
					</Box>
				</>
			) : (
				<Box h={rem(78)}>
					<Checkbox
						size={"xs"}
						label="My API endpoints are public. (Note: You won't be able to configure private or protected endpoints. You can change this later under Settings > Security)"
						mb={rem(12)}
						onChange={(event) => {
							event.currentTarget.checked ? setSkipable(true) : setSkipable(false)
						}}
					/>
					<Button
						fullWidth
						type='submit'
						disabled={!skipable}
						onClick={() => {
							next()
						}}
						sx={{
							borderRadius: rem(8),
							fontWeight: 500,
							lineHeight: rem(16),
							letterSpacing: -0.2,
						}}
					>
						Skip
					</Button>
				</Box>

			)}
		</Stack>
	)
}

export default AuthMethod
