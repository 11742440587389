import { createStyles, rem } from "@mantine/core"

export const useStyles = createStyles(theme => ({
	tr: {
		height: "100%",
	},
	th: {
		borderBottom: `1px solid ${theme.colors["refactor"][2]}!important`,
		padding: "0!important",
	},
	thCheckbox: {
		maxWidth: "fit-content",
		paddingLeft: `${rem(12)}!important`,
		paddingBottom: `${rem(16)}!important`,
	},
	thBody: {
		paddingTop: `${rem(16)}!important`,
	},
	thHead: {
		padding: "0!important",
		borderBottom: `1px solid ${theme.colors["refactor"][2]}!important`,
		verticalAlign: "top",
		lineHeight: rem(12),
	},
	table: {
		borderBottomWidth: "1px",
		overflow: "visible",
		borderBottomColor: theme.colors["refactor"][2],
	},

	rowSelected: {
		backgroundColor:
			theme.colorScheme === "dark"
				? theme.fn.rgba(theme.colors[theme.primaryColor][7], 0.2)
				: theme.colors[theme.primaryColor][0],
	},

	root: {
		height: rem(40),
		width: "100%",
	},
	wrapper: {
		height: "100%",
	},
	input: {
		borderRadius: rem(8),
		webkitBoxOrient: "vertical",
		webkitLineClamp: 1,
		border: `1px solid ${theme.colors["refactor"][5]}`,
		height: "100%",
		lineHeight: rem(20),
		letterSpacing: -0.2,
		color: theme.colors[theme.primaryColor][7],
		fontSize: rem(12),
		"::placeholder": {
			color: theme.colors["refactor"][0],
			letterSpacing: -0.2,
			fontSize: rem(12),
			lineHeight: rem(20),
		},
		":focus": {
			border: `1px solid ${theme.colors["refactor"][5]}`,
		},
		"&[data-with-icon]": {
			paddingLeft: rem(44),
		},
	},
	item: {
		fontSize: rem(12),
	},
	inputIcon: {
		color: "#000",
		width: rem(36),
		paddingLeft: rem(16),
	},
	inputIconRight: {
		color: theme.colors["refactor"][4],
		paddingRight: rem(16),
		width: rem(32),
	},
	selectRoot: {
		">div:first-of-type": {
			height: "100%",
		},
	},
	divider: {
		borderTop: `1px solid ${theme.colors["refactor"][2]}`,
		marginTop: 0,
		marginBottom: 0,
	},
	"chip-get": {
		background: theme.colors["green"][4],
		color: theme.colors["green"][3],
	},

	"chip-post": {
		background: theme.colors["blue"][1],
		color: theme.colors["blue"][0],
	},

	"chip-put": {
		background: theme.colors["yellow"][2],
		color: theme.colors["yellow"][3],
	},
	"chip-delete": {
		background: theme.colors["danger"][2],
		color: theme.colors["danger"][3],
	},
	"chip-options": {
		background: theme.colors["green"][6],
		color: theme.colors["green"][5],
	},
	"chip-head": {
		background: theme.colors["blue"][2],
		color: theme.colors["brand"][9],
	},

	"chip-patch": {
		background: theme.colors["gray"][1],
		color: theme.colors["refactor"][0],
	},

	checkbox: {
		borderRadius: rem(4),
		border: `1px solid ${theme.colors["refactor"][2]}`,
	},
	labelWrapper: {
		display: "none",
	},
	checkboxBody: {
		width: "fit-content",
	},
	checkboxRoot: {
		width: "fit-content",
	},
	dropdown: {
		padding: rem(16),
		borderRadius: rem(6),
		border: "none",
		background: theme.white,
		boxShadow: "0px 10px 40px 0px rgba(0, 0, 0, 0.10)",
		">div": {
			display: "flex",
			flexDirection: "column",
			gap: rem(12),
		},
	},
	menuItem: {
		"&[data-hovered]": {
			background: theme.white,
		},
		background: theme.white,
		padding: 0,
		transition: "none",
	},

	label: {
		fontSize: rem(14),
		lineHeight: rem(20),
		color: theme.colors[theme.primaryColor][7],
		padding: 0,
		fontWeight: 400,
	},

	itemLabel: {
		lineHeight: rem(20),
		fontSize: rem(14),
		color: theme.colors["refactor"][0],
		borderRadius: rem(8),
		maxWidth: "100%",
	},
}))
