import { createStyles, rem } from "@mantine/core"

export const useDashboardStyles = createStyles(theme => ({
	root: {
		borderRadius: rem(10),
		background: "#F4F2F6",
	},
	indicator: {
		padding: `${rem(8)} ${rem(10)}`,
		borderRadius: rem(8),
		boxShadow: "none",
	},
	control: {
		":not(:first-of-type)": {
			borderWidth: 0,
			border: "none",
		},
	},

	label: {
		lineHeight: rem(20),
		fontWeight: 400,
		fontSize: rem(14),
		color: theme.colors["refactor"][4],
		"&[data-active]": {
			fontWeight: 500,
			color: theme.colors[theme.primaryColor][7],
		},
	},
}))
