import DomainDrawer from "./DomainDrawer"
import DomainBody from "./DomainBody"
import { useDocumentTitle } from "@mantine/hooks"

const Domains = () => {
	useDocumentTitle("Domains")
	return (
		<>
			<DomainDrawer />
			<DomainBody />
		</>
	)
}

export default Domains
