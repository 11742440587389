import { createStyles, rem } from "@mantine/core"

export const useDrawerStyles = createStyles(theme => ({
	wrapper: {
		minHeight: rem(56),
		position: "relative",
	},

	root: {
		position: "relative",
	},
	searchInput: {
		color: theme.colors[theme.primaryColor][7],
		transition: "0.1s padding ease",

		"::placeholder": {
			color: theme.colors["refactor"][0],
			letterSpacing: -0.2,
			fontSize: rem(15),
			lineHeight: rem(20),
		},

		"&[data-invalid]": {
			border: `1px solid ${theme.colors["danger"][0]}`,
			color: theme.colors["danger"][0],
			caretColor: theme.colors["danger"][0],
			"::placeholder": {
				color: theme.colors["danger"][0],
				caretColor: theme.colors["danger"][0],
				letterSpacing: -0.2,
				fontSize: rem(15),
				lineHeight: rem(20),
			},
		},
	},
	input: {
		minHeight: rem(56),
		display: "inline-flex",
		alignItems: "center",
		height: "100%",
		borderRadius: rem(8),
		border: `1px solid ${theme.colors["refactor"][5]}`,
		transition: "0.1s padding ease",
		paddingLeft: rem(16),
		"&[data-invalid]": {
			border: `1px solid ${theme.colors["danger"][0]}`,
			color: theme.colors["danger"][0],
			caretColor: theme.colors["danger"][0],
			"::placeholder": {
				color: theme.colors["danger"][0],
				caretColor: theme.colors["danger"][0],
				letterSpacing: -0.2,
				fontSize: rem(15),
				lineHeight: rem(20),
			},

			":focus-within": {
				border: `1px solid ${theme.colors["danger"][0]}`,
			},
			":focus": {
				border: `1px solid ${theme.colors["danger"][0]}`,
			},
		},

		":focus-within": {
			border: `1px solid ${theme.colors["refactor"][5]}`,
		},
		":focus": {
			border: `1px solid ${theme.colors["refactor"][5]}`,
		},
	},
	inputFocus: {
		paddingTop: rem(28),
		paddingBottom: rem(0),
	},

	error: {
		letterSpacing: -0.2,
		fontSize: rem(14),
		color: theme.colors["danger"][0],
		bottom: rem(16),
	},

	margin: {
		marginTop: rem(20),
	},
	label: {
		position: "absolute",
		fontSize: rem(14),
		paddingLeft: rem(16.9),
		paddingTop: rem(9),
		zIndex: 1,
		display: "none",
	},
	labelFocus: {
		display: "inline-block",
	},
}))
