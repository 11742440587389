import { useState } from "react"
import { refreshEndpoints } from "../../../../api/server/onboard/refresh-endpoints"
import { useQueryClient, useMutation } from "@tanstack/react-query"
import { axiosErrorParser } from "../../../../lib/parse-axios-error"
import { notifications } from "@mantine/notifications"
import { IconCheck, IconX } from "@tabler/icons-react"
import { AxiosError } from "axios"
import useAppStore from "../../../../zustand/new-store"
import { CacheConfig } from "../../../../lib/parser"
import { updateEndpoints } from "../../../../api/supabase/update-endpoints"
import { useAuth } from "@clerk/clerk-react"

async function update(
	orgId: string,
	workspaceId: string,
	endpoints: Partial<CacheConfig>,
	getToken: (
		options?: { template: string } | undefined
	) => Promise<string | null>
) {
	try {
		const result = await updateEndpoints(getToken, {
			org_id: orgId,
			workspace_id: workspaceId,
			...endpoints,
		})
		if (result.error || !result.data) {
			return { data: null, error: result.error || result.data }
		}
		return { data: result.data, error: null }
	} catch (error) {
		return { data: null, error }
	}
}
const useRefreshEndpoint = () => {
	const { cacheConfig, setCacheConfig, activeWorkspace } = useAppStore()
	const [supabaseLoading, setSupabaseLoading] = useState(false)
	const { getToken, orgId } = useAuth()
	const queryClient = useQueryClient()

	const mutation = useMutation({
		mutationFn: refreshEndpoints,
		onError(error: AxiosError) {
			const errorMessage = axiosErrorParser(error)
			if (typeof errorMessage === "string") {
				notifications.update({
					withCloseButton: true,
					color: "danger.0",
					title: "Something went wrong",
					icon: <IconX />,
					autoClose: 5000,
					message: errorMessage,
					id: "refresh-path",
				})
			} else {
				if (errorMessage instanceof Array) {
					for (const item of errorMessage) {
						notifications.update({
							withCloseButton: true,
							color: "danger.0",
							title: "Something went wrong",
							icon: <IconX />,
							autoClose: 5000,
							message: item,
							id: "refresh-path",
						})
					}
				} else {
					notifications.update({
						withCloseButton: true,
						color: "danger.0",
						title: "Something went wrong",
						icon: <IconX />,
						autoClose: 5000,
						message: "Something went wrong, it's being reported.",//errorMessage.error,
						id: "refresh-path",
					})
				}
			}

			setSupabaseLoading(false)
		},

		async onSuccess(resp: CacheConfig) {
			const { data, error } = await update(
				orgId || "",
				activeWorkspace?.workspace_id || "",
				resp,
				getToken
			)

			if (error || !data) {
				console.log("SupabaseError:", error)
				notifications.update({
					withCloseButton: true,
					color: "danger.0",
					title: "Something went wrong",
					icon: <IconX />,
					autoClose: 5000,
					message: "Could not update path",
					id: "refresh-path",
				})
				setSupabaseLoading(false)
				return
			}

			notifications.update({
				id: "refresh-path",
				withCloseButton: true,
				color: "green.0",
				title: "Success",
				icon: <IconCheck />,
				autoClose: 5000,
				message: "Config refreshed successfully",
			})
			queryClient.invalidateQueries({ queryKey: [cacheConfig?.host] })

			setCacheConfig(data)
			setSupabaseLoading(false)
		},
	})

	return { supabaseLoading, setSupabaseLoading, ...mutation }
}

export default useRefreshEndpoint
