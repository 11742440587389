import {
	Group,
	rem,
	Stack,
	Text,
	NavLink,
	createStyles,
	Box,
} from "@mantine/core"
import { Link, useLocation, Outlet } from "react-router-dom"
import {
	IconUserCircle,
	IconUsers,
	IconDeviceLaptop,
	IconNotification,
	IconCreditCard,
	IconShieldCode,
	IconPlug,
} from "@tabler/icons-react"
import { isProduction } from "../../../../constants/is-production"

const settingsSubLinks = [
	{ href: "", title: "Profile", icon: IconUserCircle },
	{ href: "teams", title: "Teams", icon: IconUsers },
	{ href: "domain", title: "Domain", icon: IconDeviceLaptop },
	{ href: "notifications", title: "Notifications", icon: IconNotification },
	{ href: "billing", title: "Billing", icon: IconCreditCard },
	{ href: "security", title: "Security", icon: IconShieldCode },
	{ href: "integration", title: "Integration", icon: IconPlug },
]

const useStyles = createStyles(theme => ({
	root: {
		borderRadius: rem(4),
		":hover": {
			background: "transparent",
		},
	},
	rootActive: {
		background: "#F1EEFF",
		":hover": {
			background: "#F1EEFF",
		},
	},

	label: {
		fontSize: rem(14),
		fontWeight: 500,
		lineHeight: rem(20),
		color: theme.colors["refactor"][4],
	},

	labelActive: {
		color: theme.colors[theme.primaryColor][2],
	},
	icon: {
		marginRight: rem(8),
		color: theme.colors["refactor"][0],
	},
	iconActive: {
		color: theme.colors[theme.primaryColor][2],
	},
}))

function isActive(href: string, pathname: string): boolean {
	if (href === "" && pathname === "/settings") {
		return true
	} else if (href === "" && pathname !== "/settings") {
		return false
	} else if (pathname.includes(href) && href !== "") {
		return true
	} else {
		return false
	}
}
const SettingsLayout = () => {
	const { classes, cx } = useStyles()
	const { pathname } = useLocation()
	return (
		<Stack
			w='100%'
			sx={{
				gap: rem(32),
				scrollbarGutter: "stable",
			}}
		>
			<Stack
				pb={rem(24)}
				sx={theme => ({
					gap: rem(8),
					borderBottom: `1px solid ${theme.colors["refactor"][2]}`,
				})}
			>
				<Text
					fz={rem(21)}
					fw={500}
					lh={rem(24)}
					component='h1'
					sx={theme => ({
						color: theme.colors[theme.primaryColor][7],
					})}
					m={0}
				>
					Settings
				</Text>

				<Text
					fw={400}
					component='h2'
					lh={rem(20)}
					fz={rem(16)}
					sx={theme => ({
						color: theme.colors["refactor"][4],
					})}
					m={0}
				>
					Easily review and update your plan, billing information, and
					payment history.
				</Text>
			</Stack>

			<Group
				align='flex-start'
				sx={{
					gap: rem(80),
				}}
				noWrap
			>
				<Stack sx={{ gap: rem(8) }}>
					{settingsSubLinks.map(link => {
						if (isProduction && link.title === "Billing") return null
						return (
							<NavLink
								component={Link}
								to={link.href}
								key={link.title}
								label={link.title}
								icon={<link.icon size={rem(16)} />}
								classNames={{
									root: cx(classes.root, {
										[classes.rootActive]: isActive(
											link.href,
											pathname
										),
									}),
									label: cx(classes.label, {
										[classes.labelActive]: isActive(
											link.href,
											pathname
										),
									}),
									icon: cx(classes.icon, {
										[classes.iconActive]: isActive(
											link.href,
											pathname
										),
									}),
								}}
							/>
						)
					})}
				</Stack>

				<Box
					sx={{
						flexGrow: 1,
					}}
				>
					<Outlet />
				</Box>
			</Group>
		</Stack>
	)
}

export default SettingsLayout
