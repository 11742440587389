import { create } from "zustand"
import { createSelectors } from "./create-selector"
import { TourState } from "../types/tour.types"
import { parseLocalStorage } from "../lib/localstorage-parser"
import { COMPLETED_ENDPOINTS_HINTS } from "../constants/localstorage"
import type { HintTourState, HintActions } from "./header-hint-tour-store"

// type HintTourState = Pick<TourState, "steps" | "run"> & {
// 	isModalOpen: boolean
// 	completedHints: boolean
// }

// interface HintActions {
// 	updateHintState: (hint: Partial<HintTourState>) => void
// }

const useEndpointHintStoreBase = create<HintTourState & HintActions>()(set => ({
	run: false,
	steps: [],
	isModalOpen: false,
	completedHints: parseLocalStorage(COMPLETED_ENDPOINTS_HINTS) || false,
	updateHintState(hint) {
		if (hint.completedHints !== undefined) {
			localStorage.setItem(
				COMPLETED_ENDPOINTS_HINTS,
				JSON.stringify(hint.completedHints)
			)
		}
		return set(state => ({
			...hint,
		}))
	},
}))
const useEndpointHintStore = createSelectors(useEndpointHintStoreBase)

export default useEndpointHintStore
