import {
	Group,
	Text,
	rem,
	Box,
	Stack,
	Center,
	Select,
	createStyles,
	Skeleton,
	useMantineTheme,
	SelectItem,
} from "@mantine/core"
import VariableLineChart from "../../../../Components/LineChart/VariableLineChart"
import ParentSize from "@visx/responsive/lib/components/ParentSize"
import { IconChartHistogram, IconHelpCircle, IconHelpCircleFilled } from "@tabler/icons-react"
import { Intervals } from "../../../../types/dashboard-chart-types"
import { useMemo, useState } from "react"
import useLatencyPercentile from "../api/useLatencyPercentile"
import CustomHoverCard from "../../../../Components/CustomHoverCard/CustomHoverCard"
import countryCoordinates from "./coordinates.json"
const useStyles = createStyles(theme => ({
	root: {
		height: rem(40),
		width: "100%",
		flexGrow: 1,
	},
	wrapper: {
		height: "100%",
	},
	input: {
		borderRadius: rem(8),
		border: `1px solid ${theme.colors["refactor"][5]}`,
		height: "100%",
		lineHeight: rem(20),
		letterSpacing: -0.2,
		color: theme.colors["refactor"][0],
		fontSize: rem(12),
		"::placeholder": {
			color: theme.colors["refactor"][0],
			letterSpacing: -0.2,
			fontSize: rem(12),
			lineHeight: rem(20),
		},
		":focus": {
			border: `1px solid ${theme.colors["refactor"][5]}`,
		},
		"&[data-with-icon]": {
			paddingLeft: rem(44),
		},
	},
	item: {
		fontSize: rem(12),
	},
	selectRoot: {
		">div:first-of-type": {
			height: "100%",
		},
	},
}))
const LatencyChart = ({ interval }: { interval: Intervals }) => {
	const [percentile, setPercentile] = useState<string>("99th")
	const [country, setCountry] = useState<string>("global")
	const { data, isLoading } = useLatencyPercentile(
		interval,
		percentile,
		country
	)

	const lm = useMemo(() => {
		if (!data) {
			return []
		} else {
			return data.data.map(metric => ({
				date: new Date(metric.time_group),
				value: metric.percentile_latency,
			}))
		}
	}, [data])

	const rm = useMemo(() => {
		if (!data) {
			return []
		} else {
			return data.data.map(metric => ({
				date: new Date(metric.time_group),
				value: Number(metric.total_requests),
			}))
		}
	}, [data])

	const er = useMemo(() => {
		if (!data) {
			return []
		} else {
			return data.data.map(metric => ({
				date: new Date(metric.time_group),
				value: metric.error_rate,
			}))
		}
	}, [data])
	const countries: SelectItem[] = useMemo(() => {
		let c = countryCoordinates.ref_country_codes.map(c => ({
			label: c.country,
			value: c.alpha2,
		}))
		c.unshift({ label: "Global", value: "global" })
		return c
	}, [])

	const { classes, cx } = useStyles()
	const chartData = [
		{
			title: "requests",
			stroke: "#cfcdd1",
			data: rm,
			strokeWidth: 1.1,
		},
		{
			title: "latency",
			stroke: "#C74FFF",
			data: lm,
			strokeWidth: 1.5,
		},
		{
			title: "error rate",
			stroke: "#fc819e",
			data: er,
			strokeWidth: 1.2,
		},
	]

	const theme = useMantineTheme()

	return (
		<Stack
			sx={theme => ({
				borderRadius: rem(8),
				border: `1px solid ${theme.colors["refactor"][2]} `,
			})}
			p={rem(32)}
		>
			<Group
				pb={rem(30)}
				w='100%'
				sx={{
					justifyContent: "space-between",
				}}
			>
				<Group
					spacing={rem(12)}
					align='center'
				>
					<Text
						color='brand.7'
						fz={rem(16)}
						fw={500}
						lh={rem(24)}
					>
						Performance
					</Text>

					<CustomHoverCard
						target={
							<Box mt={rem(5)}>
								<IconHelpCircle
									size={16}
								/>
							</Box>
						}
						title='Performance Metrics'
						body={[
							{
								text: "	Performance metrics reflect the measurement of the upstream service.",
							},
						]}
					/>
				</Group>

				<Group
					sx={{
						gap: rem(24),
					}}
					align='center'
					noWrap
				>
					<Group
						spacing={12}
						align='center'
						noWrap
					>
						<Select
							data={countries}
							value={country}
							onChange={e => {
								if (e) setCountry(e)
							}}
							withinPortal
							classNames={{
								input: classes.input,
								wrapper: classes.wrapper,
								item: classes.item,
								root: cx(classes.root, classes.selectRoot),
							}}
							searchable
						/>
						<CustomHoverCard
							target={
								<Box mt={rem(5)}>
									<IconHelpCircle
										size={16}
									/>
								</Box>
							}
							title='Country'
							body={[]}
						/>
					</Group>

					<Group
						spacing={12}
						align='center'
						noWrap
					>
						<Select
							placeholder='Latency Percentile'
							data={[
								{ value: "99th", label: "99th" },
								{ value: "95th", label: "95th" },
								{ value: "90th", label: "90th" },
								{ value: "75th", label: "75th" },
								{ value: "50th", label: "50th" },
							]}
							withinPortal
							classNames={{
								input: classes.input,
								wrapper: classes.wrapper,
								item: classes.item,
								root: cx(classes.root, classes.selectRoot),
							}}
							onChange={e => {
								if (e) {
									setPercentile(e)
								}
							}}
							w={rem(90)}
							value={percentile}
						/>
						<CustomHoverCard
							target={
								<Box mt={rem(5)}>
									<IconHelpCircle
										size={16}
									/>
								</Box>
							}
							title='Percentile'
							body={[]}
						/>
					</Group>
				</Group>
			</Group>

			<Box
				h={rem(392)}
				miw='100%'
				w='100%'
			>
				{isLoading ? (
					<Skeleton
						w='100%'
						h={rem(368)}
					/>
				) : data && data.data.length > 0 ? (
					<>
						<Stack
							h={rem(288)}
							w='100%'
							justify='center'
							sx={{
								gap: rem(6),
								alignItems: "center",
							}}
						>
							<Box
								h='100%'
								w='100%'
							>
								<ParentSize>
									{({ width, height }) => (
										<VariableLineChart
											width={width}
											height={height}
											interval={interval}
											dataSet={chartData}
										/>
									)}
								</ParentSize>
							</Box>
						</Stack>
					</>
				) : (
					<Center
						h='100%'
						w='100%'
						sx={{
							gap: rem(16),
							flexDirection: "column",
						}}
					>
						<Center c='#000'>
							<IconChartHistogram size={rem(24)} />
						</Center>
						<Stack
							sx={{
								gap: rem(8),
							}}
							w={rem(280)}
						>
							<Text
								c='brand.7'
								fz={rem(16)}
								fw={400}
								lh={rem(24)}
								ta='center'
							>
								No Latency Data Available
							</Text>
							<Text
								lh={rem(20)}
								fw={400}
								fz={rem(14)}
								c='refactor.4'
								ta='center'
							>
								To view latency statistics, ensure your APIs are set up
								and receiving requests.
							</Text>
						</Stack>
					</Center>
				)}
			</Box>
		</Stack>
	)
}

export default LatencyChart
