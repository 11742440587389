import { Intervals } from "../../../types/dashboard-chart-types"
import { analyticsAxiosConfig } from "../../../config/axios.config"

export async function getMethodTraffic(body: {
	token: string
	interval: Intervals
	host: string
}) {
	const response = await analyticsAxiosConfig.get(
		`/analytics/traffic/requests_by_method?interval=${body.interval}&host=${body.host}`,
		{
			headers: { Authorization: `Bearer ${body.token}` },
		}
	)
	const { data } = response
	return data
}
