import { useAuth } from "@clerk/clerk-react"
import useAppStore from "../../../zustand/new-store"
import { getWorkspaceIntegrations } from "../../../api/supabase/get-integrations"
import { createWorkspaceIntegrations } from "../../../api/supabase/create-integration"
import { useEffect, useMemo, useState } from "react"
import Loading from "../loading"
import { Box, Button, Stack, Title, rem } from "@mantine/core"
import { useNavigate } from "react-router-dom"
import { IIntegrations } from "../../../types/supabase.types"

const SlackConfirmation = () => {
	const { setIntegrations, cacheConfig, organizationsMetadata } = useAppStore()
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()
	const { getToken } = useAuth()

	const organizationOwner = useMemo(() => {
		return organizationsMetadata?.users.find(
			user => user.user_id === organizationsMetadata.user_id
		)
	}, [organizationsMetadata?.user_id, organizationsMetadata?.users.length])
	const update = async (
		getToken: (
			options?: { template: string } | undefined
		) => Promise<string | null>,
		values: Partial<IIntegrations>
	) => {
		const { hostname, user_id } = values
		setLoading(true)
		try {
			const integrated = await getWorkspaceIntegrations(
				getToken,
				hostname || ""
			)
			if (integrated.data) {
				setIntegrations(integrated.data)
				return
			}
			const newIntegration = await createWorkspaceIntegrations(getToken, {
				hostname,
				slack: true,
				user_id,
			})
			if (!newIntegration.data || newIntegration.error) {
				console.log(newIntegration.error)
				setIntegrations(null)
				return
			}
			setIntegrations(newIntegration.data)
		} catch (error) {
			console.log(error)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		let ignore = false

		if (!ignore) {
			update(getToken, {
				hostname: cacheConfig?.host,
				user_id: organizationOwner?.user_id,
			})
		}

		return () => {
			ignore = true
		}
	}, [organizationOwner, getToken, cacheConfig?.host])

	return (
		<Box w='100%'>
			{loading ? (
				<Loading />
			) : (
				<Stack
					align='center'
					sx={{
						gap: rem(24),
						justifyContent: "center",
					}}
					h={`max(calc(100vh - ${rem(212)}), 300px)`}
				>
					<Title
						c='brand.7'
						order={3}
					>
						Slack Integration Successful!
					</Title>
					<Button
						variant='outline'
						sx={{
							borderRadius: rem(8),
							fontSize: rem(12),
						}}
						onClick={() => {
							navigate("/", { replace: true })
						}}
					>
						Dashboard
					</Button>
				</Stack>
			)}
		</Box>
	)
}

export default SlackConfirmation
