import { IconSquareRoundedCheck } from "@tabler/icons-react"
import { Button, Center, Stack, Text, ThemeIcon, rem } from "@mantine/core"

const DrawerSuccess = ({
	btnAction,
	title,
	subtext,
	btnText,
}: {
	btnAction: () => void
	title: string
	subtext: string
	btnText: string
}) => {
	return (
		<Stack
			mt={rem(56)}
			sx={{ gap: 0 }}
			w='100%'
		>
			<Center>
				<ThemeIcon
					sx={theme => ({
						border: "none",
						color: theme.colors["green"][0],
					})}
					variant='outline'
					size={rem(40)}
				>
					<IconSquareRoundedCheck size={rem(40)} />
				</ThemeIcon>
			</Center>
			<Stack
				mt={rem(24)}
				sx={{
					gap: rem(16),
				}}
			>
				<Text
					ta='center'
					fz={rem(18)}
					fw={500}
					lh={rem(24)}
					c='#030124'
				>
					{title}
				</Text>

				<Text
					ta='center'
					fz={rem(16)}
					fw={400}
					lh={rem(24)}
					sx={theme => ({
						color: theme.colors["refactor"][4],
					})}
				>
					{subtext}
				</Text>
			</Stack>

			<Button
				type='button'
				h={rem(48)}
				onClick={btnAction}
				mt={rem(40)}
				fz={rem(16)}
				sx={{
					borderRadius: rem(8),
					fontWeight: 500,
					lineHeight: rem(16),
					letterSpacing: -0.2,
				}}
			>
				{btnText}
			</Button>
		</Stack>
	)
}

export default DrawerSuccess
