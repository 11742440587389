import { createClient } from "@supabase/supabase-js"
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY

export const supabaseClient = (supabaseToken: string) => {
	const supabase = createClient(supabaseUrl!, supabaseKey!, {
		global: {
			headers: { Authorization: `Bearer ${supabaseToken}` },
		},
	})

	return supabase
}
