import {
	Box,
	Center,
	Group,
	Skeleton,
	Stack,
	Text,
	rem,
	List,
	Button,
	useMantineTheme,
} from "@mantine/core"
import useAppStore from "../../../../zustand/new-store"
import { useDocumentTitle } from "@mantine/hooks"
import useGetSubscription from "../../../../hooks/useGetSubscription"
import React, { useMemo } from "react"
import { parseTitle } from "../../Payments/components/utils"
import ProgressBar from "../../../../Components/ProgressBar/ProgressBar"
import { IconAlertTriangle } from "@tabler/icons-react"

const Billing = () => {
	useDocumentTitle("Billing")
	const {
		data: subscriptionData,
		isLoading: subscriptionLoading,
		isError,
	} = useGetSubscription()
	const theme = useMantineTheme()
	const { organizationsMetadata } = useAppStore()
	const activePlan = useMemo(() => {
		if (!subscriptionData) {
			return null
		}
		const plan = subscriptionData.subscription.items.data.find(
			p => !p.price.lookup_key.includes("payg")
		)

		if (!plan) {
			return null
		} else {
			return {
				id: plan.price.id,
				lookup_key: plan.price.lookup_key,
				unit_amount: plan.price.unit_amount,
				currency: plan.price.currency,
			}
		}
	}, [subscriptionData])

	return (
		<Stack
			w='100%'
			spacing={rem(32)}
		>
			<Stack
				w='100%'
				p={rem(32)}
				sx={theme => ({
					borderRadius: rem(8),
					border: `1px solid ${theme.colors["refactor"][2]}`,
					gap: rem(30),
				})}
			>
				{!subscriptionLoading && !activePlan ? (
					<Center w='100%'>
						<Button
							h={rem(40)}
							sx={{
								letterSpacing: -0.2,
								lineHeight: rem(16),
								fontWeight: 500,
								borderRadius: rem(8),
								fontSize: rem(16),
							}}
							w='fit-content'
						>
							Upgrade
						</Button>
					</Center>
				) : (
					<React.Fragment>
						{subscriptionLoading ? (
							<Skeleton
								w={rem(120)}
								h={rem(24)}
							/>
						) : (
							<Text
								pb={rem(16)}
								c='brand.7'
								fw={500}
								lh={rem(24)}
								fz={rem(16)}
								sx={theme => ({
									borderBottom: `1px solid ${theme.colors["refactor"][2]}`,
								})}
							>
								Active Plan
							</Text>
						)}

						<Group
							sx={{
								justifyContent: "space-between",
							}}
							align='flex-start'
						>
							<Stack spacing={rem(16)}>
								<Group
									pt={rem(4)}
									spacing={rem(8)}
								>
									{subscriptionLoading ? (
										<Skeleton
											h={rem(28)}
											w={rem(80)}
										/>
									) : (
										<Text
											c='#101828'
											fz={rem(16)}
											fw={500}
											lh={rem(28)}
										>
											{activePlan?.lookup_key
												? parseTitle(activePlan?.lookup_key).split(
														"-"
												  )[0]
												: ""}
										</Text>
									)}

									{subscriptionLoading ? (
										<Skeleton
											h={rem(28)}
											w={rem(80)}
										/>
									) : (
										<Center
											c='brand.2'
											px={rem(12)}
											py={rem(6)}
											bg='#F6F3FF'
											lh={rem(20)}
											fw={500}
											fz={rem(14)}
											sx={{
												borderRadius: rem(6),
											}}
										>
											{activePlan?.lookup_key.includes("monthly")
												? "Monthly"
												: "Yearly"}
										</Center>
									)}
								</Group>

								{subscriptionLoading ? (
									<Stack spacing={rem(8)}>
										<Skeleton
											w={rem(320)}
											h={rem(20)}
										/>
										<Skeleton
											w={rem(160)}
											h={rem(20)}
										/>
									</Stack>
								) : (
									<Text
										w={rem(320)}
										fw={400}
										lh={rem(20)}
										fz={rem(14)}
										c='refactor.0'
									>
										Advanced optimization with priority support
										tailored for ambitious teams
									</Text>
								)}

								<Button
									sx={{
										letterSpacing: -0.2,
										lineHeight: rem(16),
										fontWeight: 500,
										borderRadius: rem(8),
										fontSize: rem(14),
									}}
									w='fit-content'
									h={rem(40)}
									mt={rem(12)}
									onClick={() => {
										const billingURL =
											process.env.REACT_APP_STRIPE_BILLING_URL || ""
										const customerId =
											organizationsMetadata?.org_id || ""
										window.open(billingURL + customerId, "_blank")
									}}
									disabled={subscriptionLoading}
									loading={subscriptionLoading}
								>
									Manage Subscription
								</Button>
							</Stack>

							{subscriptionLoading ? (
								<Skeleton
									w={rem(170)}
									h={rem(60)}
								/>
							) : (
								<Group
									spacing={0}
									noWrap
								>
									<Box
										component='span'
										c='brand.7'
										fz={rem(36)}
										fw={600}
										lh={rem(44)}
										lts={-0.72}
									>
										$
									</Box>
									<Text
										lts={-0.96}
										lh={rem(60)}
										fw={600}
										fz={rem(48)}
										c='brand.7'
									>
										{Number(activePlan?.unit_amount) / 100}
									</Text>

									<Box
										component='sub'
										c='refactor.0'
										lh={rem(24)}
										fw={500}
										fz={rem(16)}
									>
										{activePlan?.lookup_key.includes("monthly")
											? "/month"
											: "/year"}
									</Box>
								</Group>
							)}
						</Group>
					</React.Fragment>
				)}
			</Stack>
			{!subscriptionLoading && !activePlan ? null : (
				<Stack
					w='100%'
					p={rem(32)}
					sx={theme => ({
						borderRadius: rem(8),
						border: `1px solid ${theme.colors["refactor"][2]}`,
						gap: rem(24),
					})}
				>
					<Stack
						sx={theme => ({
							borderBottom: `1px solid ${theme.colors["refactor"][2]}`,
						})}
						spacing={rem(8)}
						pb={rem(12)}
					>
						<Text
							c='brand.7'
							fw={500}
							lh={rem(24)}
							fz={rem(16)}
						>
							Quota Usage
						</Text>
						<Text
							c='refactor.0'
							fw={400}
							lh={rem(20)}
							fz={rem(14)}
						>
							Review your free usage this month
						</Text>
					</Stack>
					<Group
						spacing={rem(24)}
						grow
						align='stretch'
					>
						<Stack
							mih='100%'
							spacing={rem(20)}
							p={rem(16)}
							sx={theme => ({
								border: `1px solid ${theme.colors["refactor"][2]}`,
								borderRadius: rem(6),
								flex: 1,
							})}
						>
							<Stack spacing={rem(8)}>
								<Text
									lh={rem(18)}
									fz={rem(15)}
									c='#101828'
									fw={500}
								>
									Requests Used
								</Text>
								<Text
									c='brand.7'
									fw={600}
									lh={rem(20)}
									fz={rem(18)}
								>
									4500{" "}
									<Box
										component='sub'
										fw={400}
										lh={rem(20)}
										fz={rem(14)}
										c='refactor.4'
									>
										/ 10000
									</Box>
								</Text>
							</Stack>
							<ProgressBar
								value={4500}
								max={10000}
								color='primary'
								w='100%'
							/>
						</Stack>
						<Stack
							mih='100%'
							spacing={rem(10)}
							p={rem(16)}
							sx={theme => ({
								border: `1px solid ${theme.colors["refactor"][2]}`,
								borderRadius: rem(6),
								flex: 1,
							})}
						>
							<Text
								lh={rem(18)}
								fz={rem(15)}
								c='#101828'
								fw={500}
							>
								Projected Monthly Cost
							</Text>

							<Stack spacing={rem(4)}>
								<Text
									c='brand.7'
									fz={rem(18)}
									fw={600}
									lh={rem(20)}
								>
									$500
								</Text>
								<Text
									fz={rem(14)}
									c='refactor.0'
									lh={rem(18)}
									fw={400}
								>
									Based on current usage
								</Text>
							</Stack>
						</Stack>
						<Stack
							mih='100%'
							spacing={rem(10)}
							p={rem(16)}
							sx={theme => ({
								border: `1px solid ${theme.colors["refactor"][2]}`,
								flex: 1,
								borderRadius: rem(6),
							})}
						>
							<Text
								lh={rem(18)}
								fz={rem(15)}
								c='#101828'
								fw={500}
							>
								Projected Monthly Request
							</Text>
							<Text
								lh={rem(20)}
								fz={rem(18)}
								c='brand.7'
								fw={600}
							>
								9000
							</Text>
						</Stack>
					</Group>
					<Group
						p={rem(16)}
						spacing={rem(8)}
						sx={theme => ({
							borderRadius: rem(8),
							border: `1px solid ${theme.colors[theme.primaryColor][2]}`,
						})}
					>
						<IconAlertTriangle
							size={20}
							color={theme.colors["danger"][0]}
						/>
						<Text
							c='refactor.0'
							fw={400}
							lh={rem(20)}
							fz={rem(16)}
						>
							You are close to exceeding your allocated quota!
						</Text>
					</Group>

					<Stack
						sx={theme => ({
							borderTop: `1px solid ${theme.colors["refactor"][2]}`,
						})}
						spacing={rem(8)}
						pt={rem(16)}
					>
						<Text
							c='brand.7'
							fw={500}
							lh={rem(20)}
							fz={rem(16)}
						>
							Optional Total Cost for Excess Usage:
						</Text>

						<List type='ordered'>
							<List.Item
								c='refactor.0'
								fw={400}
								lh={rem(20)}
								fz={rem(14)}
								mb={rem(8)}
							>
								$0.01 per additional request
							</List.Item>
							<List.Item
								c='refactor.0'
								fw={400}
								lh={rem(20)}
								fz={rem(14)}
							>
								Total Cost for 4500 Extra:
								<Box
									component='span'
									c='brand.7'
									fw={500}
									fz={rem(14)}
									lh={rem(20)}
								>
									{" "}
									$45
								</Box>{" "}
							</List.Item>
						</List>
					</Stack>
				</Stack>
			)}
		</Stack>
	)
}

export default Billing
