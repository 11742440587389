import { FC } from "react"
import { TooltipRenderProps } from "react-joyride"
import { Button, Group, Stack, Text, rem } from "@mantine/core"

interface IProps extends TooltipRenderProps {}
const Tooltip: FC<IProps> = ({
	index,
	step,
	backProps,
	closeProps,
	primaryProps,
	tooltipProps,
	isLastStep,
}) => {
	return (
		<Stack
			{...tooltipProps}
			bg='white'
			w={rem(350)}
			sx={{
				borderRadius: rem(16),
				boxShadow: "0px 10px 40px 0px rgba(0, 0, 0, 0.10)",
			}}
			p={rem(16)}
		>
			{step?.title ? (
				<Text
					component='h1'
					m={0}
					lts={-0.2}
					fw={500}
					lh={rem(20)}
					fz={rem(14)}
					c='brand.7'
					tt='capitalize'
				>
					{step.title}
				</Text>
			) : null}

			{step?.content ? (
				<Text
					component='p'
					m={0}
					fw={500}
					fz={rem(12)}
				>
					{step.content}
				</Text>
			) : null}
			<Group position='right'>
				{index > 0 ? (
					<Button
						{...backProps}
						sx={{
							borderRadius: rem(8),
							fontWeight: 500,
							lineHeight: rem(16),
							letterSpacing: -0.2,
						}}
					>
						Back
					</Button>
				) : null}
				{!isLastStep ? (
					<Button
						sx={{
							borderRadius: rem(8),
							fontWeight: 500,
							lineHeight: rem(16),
							letterSpacing: -0.2,
						}}
						{...primaryProps}
					>
						Next
					</Button>
				) : null}
				{isLastStep ? (
					<Button
						sx={{
							borderRadius: rem(8),
							fontWeight: 500,
							lineHeight: rem(16),
							letterSpacing: -0.2,
						}}
						{...closeProps}
					>
						Close
					</Button>
				) : null}
			</Group>
		</Stack>
	)
}

export default Tooltip
