import { analyticsAxiosConfig } from "../../../config/axios.config"

export async function getSubscription(body: {
	token: string
	subscriptionId: string
}) {
	const response = await analyticsAxiosConfig.get(
		`/payments/session/${body.subscriptionId}`,
		{
			headers: {
				Authorization: `Bearer ${body.token}`,
			},
		}
	)
	const { data } = response
	return data
}
