import { useDocumentTitle } from "@mantine/hooks"
import { getEndpoints } from "../../../../api/supabase/get-config"
import useAppStore from "../../../../zustand/new-store"
import { useAuth } from "@clerk/clerk-react"
import { useEffect } from "react"
import { EndpointsTable } from "../components/Table/EndpointsTable"
import useTourStore from "../../../../zustand/tour-store"
const Endpoints = () => {
	useDocumentTitle("Endpoints")
	const { activeWorkspace, setCacheConfig } = useAppStore()
	const { getToken, orgId } = useAuth()

	const { updateTourState, tourActive, completedTour } = useTourStore()

	async function fetchEndpoints() {
		try {
			const result = await getEndpoints(
				orgId || "",
				activeWorkspace?.workspace_id || "",
				getToken
			)
			if (result.data) {
				setCacheConfig(result.data)
			} else {
				console.log(result.error)
			}
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		if (tourActive && !completedTour) {
			updateTourState({ run: true, stepIndex: 1 })
		}
	}, [tourActive, completedTour])
	useEffect(() => {
		let ignore = false

		if (!ignore) {
			fetchEndpoints()
		}
		return () => {
			ignore = true
		}
	}, [])
	return (
		<>
			<EndpointsTable />
		</>
	)
}

export default Endpoints
