import { Box, Center, Group, Skeleton, Stack, Text, rem } from "@mantine/core"
import { IconChartPie } from "@tabler/icons-react"
import PieChart from "../../../../Components/PieChart/PieChart"
import ParentSize from "@visx/responsive/lib/components/ParentSize"
import { useMemo } from "react"
import { Intervals } from "../../../../types/dashboard-chart-types"
import useMethodMetrics from "../api/useMethodMetrics"

const colorMap = {
	get: "#FFB077",
	post: "#0A007E",
	patch: "#7350FF",
	delete: "#DC3545",
	put: "#007BFF",
	head: "#7c7887",
	options: "#18B76B",
}
const MethodTraffic = ({ interval }: { interval: Intervals }) => {
	const { data, isLoading } = useMethodMetrics(interval)

	const trafficData = useMemo(() => {
		if (!data) {
			return []
		} else {
			return data.data.map(metric => ({
				label: metric.request_method,
				value: Number(metric.count),
				color: colorMap[
					metric.request_method.toLowerCase() as keyof typeof colorMap
				],
			}))
		}
	}, [data])

	return (
		<Stack
			sx={theme => ({
				borderRadius: rem(8),
				border: `1px solid ${theme.colors["refactor"][2]} `,
				gap: rem(30),
				flexGrow: 1,
				flexBasis: "48%",
			})}
			p={rem(32)}
			h='100%'
		>
			<Stack
				pb={rem(24)}
				sx={theme => ({
					borderBottom: `1px solid ${theme.colors["refactor"][2]} `,
				})}
			>
				<Text
					c='brand.7'
					fz={rem(16)}
					fw={500}
					lh={rem(24)}
				>
					HTTP Method Request
				</Text>
			</Stack>

			{isLoading ? (
				<Skeleton
					w='100%'
					h='100%'
					mih={rem(210)}
				/>
			) : data && trafficData && trafficData.length > 0 ? (
				<Group
					align='center'
					sx={{
						gap: rem(48),
					}}
					h='100%'
				>
					<Box
						h={rem(240)}
						w={rem(240)}
					>
						<ParentSize>
							{({ width, height }) => (
								<PieChart
									height={height}
									width={width}
									pieThickness={24}
									data={trafficData}
									radius={width / 2}
								/>
							)}
						</ParentSize>
					</Box>

					<Stack sx={{ gap: rem(16) }}>
						{Object.keys(colorMap).map(method => (
							<Group
								align='center'
								key={method}
								sx={{
									gap: rem(8),
								}}
							>
								<Box
									sx={{
										borderRadius: "50%",
										border: `1.5px solid ${colorMap[method as keyof typeof colorMap]}`,
										background: `${colorMap[method as keyof typeof colorMap]}`,
									}}
									h={rem(8)}
									w={rem(8)}
								/>
								<Text
									tt='capitalize'
									lh={rem(16)}
									c='brand.7'
									fz={rem(14)}
									fw={400}
								>
									{method}
								</Text>
							</Group>
						))}
					</Stack>
				</Group>
			) : (
				<Center
					sx={{
						gap: rem(16),
						flexDirection: "column",
					}}
					h='100%'
					mih={rem(210)}
				>
					<Center c='#292D32'>
						<IconChartPie size={24} />
					</Center>
					<Stack
						sx={{
							gap: rem(8),
						}}
						w={`max(${rem(340)}, 75%)`}
					>
						<Text
							c='brand.7'
							fz={rem(16)}
							fw={400}
							lh={rem(24)}
							ta='center'
						>
							No Method Traffic Data
						</Text>
						<Text
							fw={400}
							lh={rem(20)}
							fz={rem(14)}
							ta='center'
							c='refactor.4'
						>
							Once your APIs are set up and handling requests, you'll see
							traffic data by endpoint here.
						</Text>
					</Stack>
				</Center>
			)}
		</Stack>
	)
}

export default MethodTraffic
