import { useMemo, useState, useEffect, memo, useCallback } from "react"
import { useAuth } from "@clerk/clerk-react"
import { useNavigate } from "react-router-dom"
import ParentSize from "@visx/responsive/lib/components/ParentSize"
import {
	Table,
	Group,
	Text,
	Center,
	TextInput,
	rem,
	Stack,
	Flex,
	Select,
	Menu,
	ActionIcon,
	Box,
	Checkbox,
	ScrollArea,
	Skeleton,
	MediaQuery,
	Pagination,
} from "@mantine/core"
import {
	IconCheck,
	IconChevronDown,
	IconSearch,
	IconFilter,
	IconCopy,
	IconSettingsCode,
	IconEdit,
	IconX,
	IconHelpCircleFilled,
	IconHelpCircle,
} from "@tabler/icons-react"
import { RowData } from "../../../../../types/endpoint-table.types"
import Th from "./Th"
import LineChart from "../../../../../Components/LineChart/LineChart"
import { IconMore } from "../../../../../assets/icons"
import useAppStore from "../../../../../zustand/new-store"
import { parsePathsData, parseTableToForm } from "./utils"
import { useStyles } from "./styles"
import { useClipboard } from "@mantine/hooks"
import useTableAnalytics from "../../api/useTableAnalytics"
import { sortData } from "./table-utils"
import ProgressBar from "../../../../../Components/ProgressBar/ProgressBar"
import {
	formatNumber,
	generateLineChartForEndpointsWithoutMetadata,
	parseReplaceMethod,
} from "./utils"
import RingProgress from "../../../../../Components/RingProgress/RingProgress"
import EndpointDrawer from "../Drawer/EndpointDrawer"
import { CacheConfigSchemaType } from "../../../../../lib/parser"
import EndpointStatus from "./EndpointStatus/EndpointStatus"
import useEditEndpoint from "../../api/useEditEndpoint"
import { notifications } from "@mantine/notifications"
import { ENDPOINTS_HITS_PER_PAGE } from "../../../../../constants/hits_per_page"
import { usePaginationData } from "../../../../../hooks/use-pagination"
import CustomSwitch from "../../../../../Components/CustomSwitch/CustomSwitch"
import CustomHoverCard from "../../../../../Components/CustomHoverCard/CustomHoverCard"
export const EndpointsTable = () => {
	const {
		cacheConfig,
		activeWorkspace,
		setActiveEndpoint,
		setEndpointDrawerOpen,
		publishEndpointLoading,
		setEndpointDrawerType,
		setEditConfigFormValues,
		endpointTableSelection,
		setEndpointTableSelection,
	} = useAppStore()
	const { copied, copy } = useClipboard()
	const { orgId, getToken } = useAuth()
	const { data, isLoading } = useTableAnalytics()
	const [search, setSearch] = useState("")
	const [filter, setFilter] = useState("all")
	const [debouncedSearch, setDebouncedSearch] = useState<string>("")
	const [activePage, setActivePage] = useState(1)
	const [sortBy, setSortBy] = useState<keyof RowData | null>(null)
	const [reverseSortDirection, setReverseSortDirection] = useState(false)
	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setDebouncedSearch(search)
		}, 500)

		return () => {
			clearTimeout(timeoutId)
		}
	}, [search])
	const modifiedData = useMemo(() => {
		if (cacheConfig) {
			const parsedData = parsePathsData(cacheConfig)

			if (debouncedSearch) {
				const data = sortData(parsedData, {
					sortBy,
					reversed: reverseSortDirection,
					search: debouncedSearch,
				})

				if (filter === "all") {
					setActivePage(1)
					return data
				} else {
					return data.filter(
						datum => datum.method.toLowerCase() === filter
					)
				}
			} else {
				if (filter === "all") {
					return [...parsedData]
				} else {
					return [...parsedData].filter(
						datum => datum.method.toLowerCase() === filter
					)
				}
			}
		} else {
			return []
		}
	}, [
		cacheConfig,
		activeWorkspace?.workspace_id,
		orgId,
		debouncedSearch,
		filter,
	])

	const { totalPages, currentPageData, setUnprocessedPageData } =
		usePaginationData<RowData>(
			modifiedData,
			activePage,
			ENDPOINTS_HITS_PER_PAGE
		)

	const totalRequests = useMemo(() => {
		if (data) {
			return data.data.reduce((a, b) => {
				return a + Number(b.total_requests)
			}, 0)
		} else {
			return 0
		}
	}, [data])

	const navigate = useNavigate()
	const [sortedData, setSortedData] = useState<RowData[]>(currentPageData)

	const { classes, cx } = useStyles()

	const origins = useMemo(() => {
		if (
			!cacheConfig ||
			!cacheConfig.origins ||
			cacheConfig?.origins.length === 0
		) {
			return []
		} else {
			const o = cacheConfig.origins.map(item => ({
				text: item,
			}))

			return [
				{
					text: "Use this generated domain name as a replacement for existing API hostnames in client applications, or as a CNAME when setting up custom domains.",
					excludeFromList: true,
				},
				{
					text: <b>Origin Hosts: </b>,
					excludeFromList: true,
				},
				...o,
			]
		}
	}, [cacheConfig?.origins])
	useEffect(() => {
		setSortedData(currentPageData)
	}, [currentPageData])

	const toggleRow = (id: string) => {
		const selected = endpointTableSelection.includes(id)
			? [...endpointTableSelection].filter(item => item !== id)
			: [...endpointTableSelection, id]

		setEndpointTableSelection(selected)
	}

	const toggleAll = () => {
		const selection =
			endpointTableSelection.length === currentPageData.length
				? []
				: currentPageData.map(item => item.id)
		setEndpointTableSelection(selection)
	}

	const setSorting = (field: keyof Omit<RowData, "published">) => {
		const reversed = field === sortBy ? !reverseSortDirection : false
		setReverseSortDirection(reversed)
		setSortBy(field)
		setActivePage(1)
		setUnprocessedPageData(
			sortData(modifiedData, { sortBy: field, reversed, search })
		)
	}
	const protocol = cacheConfig?.protocol || "REST"

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.currentTarget
		setSearch(value)
	}

	const parseTableDataToFormData = (rowData: RowData) => {
		if (!cacheConfig) {
			return
		}

		const {
			workspace_id,
			source,
			user_id,
			org_id,
			uuid,
			created_at,
			updated_at,
			...dataWithoutSupabaseConfig
		} = structuredClone(cacheConfig)
		const resp = parseTableToForm(rowData, dataWithoutSupabaseConfig)
		setEditConfigFormValues(resp as CacheConfigSchemaType)
	}

	const {
		setServerSideValidationErrors: setServerSideEditErrors,
		mutate: edit,
		isLoading: isEditLoading,
		setSuccess: setEditSuccess,
		supabaseEditLoading,
		setSupabaseEditLoading,
	} = useEditEndpoint()

	const toggleEndpointStatus = useCallback(
		async (row: RowData) => {
			const token = await getToken()
			if (!cacheConfig || !token) {
				return
			}

			const endpoint = row.path
			const method = row.method.toLowerCase()
			setSupabaseEditLoading(true)
			notifications.show({
				id: "edit-path",
				loading: true,
				title: "Saving",
				message: "Saving changes, please wait",
				autoClose: false,
				withCloseButton: false,
			})
			if (
				cacheConfig.cache.paths[endpoint] &&
				cacheConfig.cache.paths[endpoint][method]
			) {
				const currentPathConfig = cacheConfig.cache.paths[endpoint][method]

				const fD: CacheConfigSchemaType = {
					purge: currentPathConfig.purge || [],
					description: currentPathConfig.description || "",
					tags: currentPathConfig.tags || [],
					ttl: currentPathConfig.ttl || 5,
					path: endpoint,
					method: method as
						| ""
						| "option"
						| "post"
						| "put"
						| "patch"
						| "delete"
						| "get"
						| "head",

					async: currentPathConfig.async || false,
					summary: currentPathConfig.summary || "",
					enabled: currentPathConfig.enabled || true,
					type: currentPathConfig.type || "public",
					unmanaged: !currentPathConfig.unmanaged,
					cache: true//currentPathConfig.cache,
				}

				const {
					workspace_id,
					source,
					user_id,
					org_id,
					uuid,
					created_at,
					updated_at,
					...dataWithoutSupabaseConfig
				} = structuredClone(cacheConfig)
				const editedConfig = parseReplaceMethod(
					{
						path: endpoint,
						method: row.method as
							| ""
							| "option"
							| "post"
							| "put"
							| "patch"
							| "delete"
							| "get"
							| "head",
					},
					fD,
					dataWithoutSupabaseConfig
				)
				const { data, error } = editedConfig

				if (error || !data) {
					setSupabaseEditLoading(false)
					setServerSideEditErrors([error])
					setEditSuccess(false)
					notifications.update({
						withCloseButton: true,
						color: "danger.0",
						title: "Something went wrong",
						icon: <IconX />,
						autoClose: 5000,
						message: "Something went wrong",
						id: "edit-path",
					})
					return
				}

				edit({
					config: data,
					token,
				})
			}
		},
		[cacheConfig?.cache?.paths]
	)

	const rows = sortedData.map((row, idx) => {
		const selected = endpointTableSelection.includes(row.id)
		const meta = data?.data.find(datum => datum.method_path === row.id)
		const errorRate = meta ? meta.error_rate.toFixed(1) : 0
		const latencyMeta = meta
			? meta.time_series.map(t => ({
				date: new Date(t.time_group),
				value: t.avg_latency,
			}))
			: generateLineChartForEndpointsWithoutMetadata(1)

		return (
			<tr
				key={row.id}
				className={cx(classes.tr, {
					[classes.rowSelected]: selected,
				})}
			>
				<td
					width={28}
					className={classes.th}
				>
					<Box
						w='100%'
						className={cx(classes.thCheckbox)}
						py={rem(16)}
					>
						{row.published ? (
							<Checkbox
								checked={selected}
								onChange={() => toggleRow(row.id)}
								transitionDuration={0}
								size={rem(16)}
								classNames={{
									input: classes.checkbox,
									labelWrapper: classes.labelWrapper,
									body: classes.checkboxBody,
									root: classes.checkboxRoot,
								}}
								disabled={publishEndpointLoading}
							/>
						) : null}
					</Box>
				</td>
				<td
					className={classes.th}
					width={350}
					height='100%'

				>
					<Group
						sx={{
							gap: rem(8),
							cursor: "pointer"
						}}
						pl={rem(12)}
						noWrap
						maw='100%'
						mih='100%'
						py={rem(16)}
						h='100%'
						onClick={() => {
							setActiveEndpoint({
								path: row.path,
								method: row.method,
							})
							navigate(
								`/endpoints/m/${row.method.toLowerCase()}/metrics?p=${row.path
								}`
							)
						}}
					>
						<Center
							pt={rem(10)}
							pb={rem(10)}
							fw={500}
							tt='capitalize'
							lts={0.5}
							lh={rem(16)}
							w={rem(50)}
							ff='Outfit, sans-serif'
							fz={rem(10)}
							sx={{
								borderRadius: rem(4),
							}}
							h={rem(20)}
							// @ts-ignore
							className={classes[`chip-${row.method.toLowerCase()}`]}
						>
							{row.method}
						</Center>
						<MediaQuery
							query='(min-width: 85.625rem)'
							styles={{
								maxWidth: rem(340),
							}}
						>
							<MediaQuery
								query='(min-width: 83.875rem) and (max-width: calc(85.625rem - 1px))'
								styles={{
									maxWidth: rem(330),
								}}
							>
								<MediaQuery
									query='(min-width: 82rem) and (max-width: calc(83.875rem - 1px))'
									styles={{
										maxWidth: rem(300),
									}}
								>
									<MediaQuery
										query='(min-width: 80.75rem) and (max-width: calc(82rem - 1px))'
										styles={{
											maxWidth: rem(280),
										}}
									>
										<MediaQuery
											query='(min-width: 71rem) and (max-width: 75.25rem)'
											styles={{
												maxWidth: rem(220),
											}}
										>
											<CustomHoverCard
												width={400}
												target={
													<Text
														c='brand.7'
														fz={rem(14)}
														fw={400}
														lh={rem(18)}
														sx={{
															overflow: "hidden",
															textOverflow: "ellipsis",
															whiteSpace: "nowrap",
														}}
														maw={rem(260)}
													>
														{row.path}
													</Text>
												}
												body={[
													{
														text:
															protocol === "JSON-RPC"
																? cacheConfig?.host +
																" - " +
																row.path
																: `https://${cacheConfig?.host}${cacheConfig?.basePath ? cacheConfig?.basePath.replace(/\/$/, "") : ""}${row.path}`,
													},
												]}
											/>
										</MediaQuery>
									</MediaQuery>
								</MediaQuery>
							</MediaQuery>
						</MediaQuery>
					</Group>
				</td>
				<td
					className={classes.th}
					width={rem(60)}
				>
					<Box
						display='flex'
						sx={{
							alignItems: "center",
							justifyContent: "center",
						}}
						mih='100%'
						py={rem(16)}
						h='100%'
						w='100%'
					>
						<EndpointStatus endpoint={row} />
					</Box>
				</td>
				<td
					width={50}
					className={classes.th}
				>
					<Box
						display='flex'
						sx={{
							":hover": {
								textDecoration: "none",
							},
							alignItems: "center",
						}}
						mih='100%'
						py={rem(16)}
						ml={rem(20)}
						h='100%'
					>
						<Box
							display='grid'
							sx={{
								gridTemplateColumns: "0.9fr 0.8fr 0.5fr",
								gap: rem(5),
							}}
							pl={rem(24)}
							w='100%'
						>
							<Stack
								sx={{
									gap: rem(10),
									justifyContent: "center",
								}}
							>
								<MediaQuery
									query='(min-width: calc(68.5em + 1px)) and (max-width: 78.75em)'
									styles={{
										display: "inline",
									}}
								>
									<Text
										fw={400}
										lh={rem(16)}
										c='refactor.0'
										fz={rem(12)}
										display='none'
									>
										Requests
									</Text>
								</MediaQuery>
								{isLoading ? (
									<Group align='center'>
										<Skeleton
											h={rem(8)}
											w={rem(120)}
										/>
										<Skeleton
											h={rem(20)}
											w={rem(40)}
										/>
									</Group>
								) : (
									<Group
										align='center'
										noWrap
									>
										<MediaQuery
											query='(min-width: calc(68.5em + 1px)) and (max-width: 78.75em)'
											styles={{
												display: "none",
											}}
										>
											<Text
												fw={400}
												lh={rem(16)}
												c='refactor.0'
												display='inline'
												fz={rem(12)}
											>
												Requests
											</Text>
										</MediaQuery>
										<ProgressBar
											max={totalRequests}
											value={Number(meta?.total_requests || 0)}
										/>
										<Text
											c='brand.7'
											fz={rem(12)}
											lh={rem(20)}
											fw={500}
											w={rem(40)}
										>
											{meta
												? formatNumber(Number(meta.total_requests))
												: "0"}
										</Text>
									</Group>
								)}
							</Stack>

							<Group
								align='center'
								noWrap
								w={rem(180)}
								spacing={rem(16)}
							>
								<Text
									lh={rem(16)}
									fw={400}
									fz={rem(12)}
									c='refactor.0'
								>
									Latency
								</Text>

								{isLoading ? (
									<Skeleton
										h={rem(24)}
										w={rem(72)}
									/>
								) : (
									<Box
										h={rem(24)}
										w={rem(72)}
									>
										<Box
											h={rem(16)}
											w={rem(68)}
										>
											<ParentSize>
												{({ width, height }) => (
													<LineChart
														height={height}
														width={width}
														data={latencyMeta}
													/>
												)}
											</ParentSize>
										</Box>
									</Box>
								)}

								{isLoading ? (
									<Skeleton
										h={rem(12)}
										w={rem(36)}
									/>
								) : (
									<Text
										c='brand.7'
										fz={rem(12)}
										lh={rem(20)}
										fw={500}
										sx={{
											whiteSpace: "nowrap",
										}}
									>
										{meta
											? formatNumber(meta.avg_latency) + " " + "ms"
											: "0 ms"}
									</Text>
								)}
							</Group>

							<Group
								align='center'
								noWrap
							>
								<Text
									lh={rem(16)}
									fw={400}
									fz={rem(12)}
									c='refactor.0'
								>
									Error
								</Text>

								{isLoading ? (
									<Skeleton
										h={rem(48)}
										w={rem(48)}
										sx={{
											borderRadius: "50%",
										}}
									/>
								) : (
									<RingProgress
										label={`${Number(errorRate) === 0 ? 0 : errorRate
											}%`}
										value={Number(errorRate)}
										textSize='small'
										color='error'
									/>
								)}
							</Group>
						</Box>
					</Box>
				</td>
				<td
					className={classes.th}
					width={30}
				>
					<Menu
						shadow='md'
						width={200}
						withinPortal
						classNames={{
							item: classes.menuItem,
							dropdown: classes.dropdown,
							label: classes.label,
							itemLabel: classes.itemLabel,
							divider: classes.divider,
						}}
					>
						<Menu.Target>
							<ActionIcon
								pl={rem(12)}
								sx={{
									height: "fit-content",
									minHeight: "fit-content",
									width: rem(48),
									minWidth: "fit-content",
									":hover": {
										background: "transparent",
									},
								}}
							>
								<IconMore id={`more-${idx}`} />
							</ActionIcon>
						</Menu.Target>

						<Menu.Dropdown
							sx={theme => ({
								boxShadow: "0px 10px 40px 0px rgba(0, 0, 0, 0.10)",
								borderRadius: rem(6),
								border: "none",
								background: theme.white,
							})}
						>
							<Menu.Item closeMenuOnClick={false}>
								<Group
									align='center'
									noWrap
									spacing={rem(8)}
								>
									<CustomSwitch
										checked={!row.unmanaged}
										disabled={supabaseEditLoading || isEditLoading}
										onChange={() => {
											toggleEndpointStatus(row)
										}}
									/>

									<Text>Resilis managed</Text>
								</Group>
							</Menu.Item>

							{row.unmanaged ? null : (
								<>
									<Menu.Divider />
									<Menu.Item
										icon={<IconEdit size={14} />}
										onClick={() => {
											parseTableDataToFormData(row)
											setEndpointDrawerType("edit")
											setEndpointDrawerOpen(true)
										}}
									>
										Edit
									</Menu.Item>
									{/* <Menu.Item
										icon={<IconSettingsCode size={14} />}
										onClick={() => {
											setActiveEndpoint({
												path: row.path,
												method: row.method,
											})
											navigate(
												`/endpoints/m/${row.method.toLowerCase()}/metrics?p=${row.path
												}`
											)
										}}
									>
										Settings
									</Menu.Item> */}
								</>
							)}
						</Menu.Dropdown>
					</Menu>
				</td>
			</tr>
		)
	})

	return (
		<>
			<Stack sx={{ gap: rem(50) }}>
				<Flex justify='space-between'>
					{cacheConfig?.host ? (
						<Group
							pl={rem(16)}
							pr={rem(16)}
							align='center'
							sx={theme => ({
								border: `1px solid ${theme.colors["refactor"][5]}`,
								borderRadius: rem(8),
								gap: rem(8),
							})}
							h={rem(40)}
							noWrap
						>
							<Group spacing={rem(4)}>
								<Text
									lts={-0.2}
									fw={500}
									lh={rem(20)}
									fz={rem(12)}
									c='brand.7'
									tt='capitalize'
								>
									Base URL:
								</Text>
								<CustomHoverCard
									target={<IconHelpCircle size={16} />}
									title=''
									body={origins}
									spacing={4}
									isList
								/>
							</Group>

							<Text
								w={rem(300)}
								lts={-0.2}
								lh={rem(20)}
								fz={rem(15)}
								c='refactor.0'
								fw={400}
								sx={{
									overflow: "hidden",
									textOverflow: "ellipsis",
									whiteSpace: "nowrap",
								}}
							>
								{cacheConfig?.host}<small>{cacheConfig?.basePath}</small>

							</Text>
							<Group
								sx={{
									gap: rem(8),
									alignItems: "center",
									cursor: "pointer",
								}}
								onClick={() => {
									copy(`${cacheConfig?.host}${cacheConfig?.basePath}`)
								}}
								noWrap
							>
								{/* <Text
									lts={-0.2}
									fw={400}
									lh={rem(20)}
									fz={rem(12)}
									c='#262626'
									w={rem(40)}
									ta='right'
								>
									{copied ? "Copied" : "Copy"}
								</Text> */}
								<Center c='#000'>
									{copied ? (
										<IconCheck size={rem(24)} />
									) : (
										<IconCopy size={rem(24)} />
									)}
								</Center>
							</Group>
						</Group>
					) : null}
					<Group
						sx={{
							gap: rem(16),
						}}
					>
						<Select
							placeholder='Method'
							data={[
								{ value: "all", label: "ALL" },
								{ value: "post", label: "POST" },
								{ value: "put", label: "PUT" },
								{ value: "patch", label: "PATCH" },
								{ value: "get", label: "GET" },
								{ value: "head", label: "HEAD" },
								{ value: "option", label: "OPTION" },
								{ value: "delete", label: "DELETE" },
							]}
							disabled={publishEndpointLoading}
							withinPortal
							icon={
								<IconFilter
									size={rem(20)}
									stroke={1.5}
								/>
							}
							rightSection={
								<IconChevronDown
									size={rem(16)}
									stroke={2.5}
								/>
							}
							defaultValue='all'
							classNames={{
								input: classes.input,
								wrapper: classes.wrapper,
								icon: classes.inputIcon,
								root: cx(classes.root, classes.selectRoot),
								rightSection: classes.inputIconRight,
								item: classes.item,
							}}
							onChange={e => {
								if (e) {
									setFilter(e)
								} else {
									setFilter("all")
								}
							}}
							w={rem(150)}
						/>
						<TextInput
							placeholder='Search by Path'
							size='xs'
							icon={
								<IconSearch
									size={rem(20)}
									stroke={1.5}
								/>
							}
							value={search}
							onChange={handleSearchChange}
							classNames={{
								input: classes.input,
								wrapper: classes.wrapper,
								root: classes.root,
								icon: classes.inputIcon,
							}}
							w={rem(300)}
							disabled={publishEndpointLoading}
						/>
					</Group>

				</Flex>

				<Box
					p={0}
					maw='100%'
					w='100%'
					sx={{
						overflowX: "auto",
					}}
				>
					<ScrollArea>
						<Table
							horizontalSpacing={rem(24)}
							verticalSpacing={rem(16)}
							maw={rem(1200)}
							w='100%'
							className={classes.table}
						>
							<thead>
								<tr>
									<th className={cx(classes.th, classes.thCheckbox)}>
										<Checkbox
											onChange={toggleAll}
											checked={
												endpointTableSelection.length ===
												modifiedData.length
											}
											indeterminate={
												endpointTableSelection.length > 0 &&
												endpointTableSelection.length !==
												modifiedData.length
											}
											transitionDuration={0}
											size={rem(16)}
											classNames={{
												input: classes.checkbox,
												labelWrapper: classes.labelWrapper,
												body: classes.checkboxBody,
												root: classes.checkboxRoot,
											}}
											disabled={publishEndpointLoading}
										/>
									</th>
									<Th
										sorted={sortBy === "path"}
										reversed={reverseSortDirection}
										onSort={() => setSorting("path")}
									>
										<Box pl={rem(12)}>
											{protocol === "JSON-RPC" ? "Method" : "Path"}
										</Box>
									</Th>

									<Th>
										<Center pl={rem(12)}>Status</Center>
									</Th>

									<Th>
										<Box pl={rem(45)}>Performance</Box>
									</Th>

									<Th>
										<></>
									</Th>
								</tr>
							</thead>

							<tbody>
								{rows.length <= 0 ? (
									<tr>
										<td colSpan={5}>
											<Text
												weight={500}
												align='center'
											>
												Nothing found
											</Text>
										</td>
									</tr>
								) : (
									<>{rows}</>
								)}
							</tbody>
						</Table>
					</ScrollArea>
				</Box>

				<Flex justify='flex-end'>
					<Pagination
						disabled={totalPages === 1}
						total={totalPages}
						size='xs'
						value={activePage}
						onChange={e => {
							setActivePage(e)
						}}
						onFirstPage={() => {
							setActivePage(1)
						}}
						onLastPage={() => {
							if (activePage === totalPages) {
								return
							}
							setActivePage(totalPages)
						}}
						onPreviousPage={() => {
							if (activePage === 1) {
								return
							}
							setActivePage(curr => curr - 1)
						}}
						onNextPage={() => {
							if (activePage === totalPages) {
								return
							}
							setActivePage(curr => curr + 1)
						}}
					/>
				</Flex>
			</Stack>
			<EndpointDrawer />
		</>
	)
}

export default memo(EndpointsTable)
