import { useMemo, useCallback } from "react"
import { rem, useMantineTheme } from "@mantine/core"
import { RowData } from "../../../../../../types/endpoint-table.types"
import {
	IconActivity,
	IconAlertCircle,
	IconBolt,
	IconCheck,
	IconClock,
	IconDisc,
	IconForbid,
	IconLock,
	IconLockOpen,
	IconRefresh,
	IconX,
} from "@tabler/icons-react"
import CustomHoverCard from "../../../../../../Components/CustomHoverCard/CustomHoverCard"

const EndpointStatus = ({ endpoint }: { endpoint: RowData }) => {
	const theme = useMantineTheme()

	const getIcon = useCallback(
		(row: RowData) => {
			if (row.unmanaged) {
				return (
					<IconAlertCircle
						color={theme.colors.yellow[0]}
						size={rem(20)}
					/>
				)
			} else {
				if (!row.enabled) {
					return (
						<IconForbid
							color={theme.colors.brand[2]}
							size={rem(20)}
						/>
					)
				} else if (row.async) {
					return (
						<IconRefresh
							color={theme.colors.brand[2]}
							size={rem(20)}
						/>
					)
				} else {
					return (
						<IconBolt
							color={theme.colors.brand[2]}
							size={rem(20)}
						/>
					)
				}
			}
		},
		[endpoint]
	)

	const description = useMemo(() => {
		let header = ""
		const details: {
			text: string
			icon: JSX.Element
		}[] = []
		if (!endpoint.enabled) {
			header = "Disabled Endpoint"
			details.push({
				text: "This endpoint is currently disabled, rendering it non-operational and inaccessible.",
				icon: <IconX />,
			})
		} else if (endpoint.unmanaged) {
			header = "Unmanaged Endpoint"
			details.push({
				text: "Requests are relayed directly to origin servers, no edge optimization.",
				icon: <IconActivity />,
			})
		} else {
			header = "Optimized Endpoint"
			details.push({
				text: "Optimized for better performance and efficiency.",
				icon: <IconCheck />,
			})

			if (endpoint.type === "private" || endpoint.type === "public") {
				details.push({
					text:
						endpoint.type === "private"
							? "Requires authentication for restricted access."
							: "Open for access without authentication.",
					icon:
						endpoint.type === "private" ? <IconLock /> : <IconLockOpen />,
				})
			}

			if (
				!endpoint.async &&
				(endpoint.method === "GET" ||
					endpoint.method === "HEAD" ||
					endpoint.method === "OPTIONS")
			) {
				details.push({
					text: `Responses are cached for ${endpoint.ttl} seconds, ensuring rapid data retrieval.`,
					icon: <IconClock />,
				})
			}

			if (endpoint.async) {
				details.push({
					text: "Supports asynchronous API requests, enabling queueing and processing at an adaptive rate.",
					icon: <IconRefresh />,
				})
			}

			if (endpoint.cache) {
				details.push({
					text: "Responses are cached.",
					icon: <IconDisc />,
				})
			}
		}
		return { header, details }
	}, [endpoint])
	return (
		<CustomHoverCard
			target={getIcon(endpoint)}
			title={description.header}
			body={description.details}
		/>
	)
}

export default EndpointStatus
