import { create } from "zustand"
import { createSelectors } from "./create-selector"
import {
	CacheConfig,
	CacheConfigSchemaType,
	ConfigSupabaseMetadata,
} from "../lib/parser"
import { parseLocalStorage } from "../lib/localstorage-parser"
import { IOrganization, Service, IIntegrations } from "../types/supabase.types"
import {
	ACTIVE_WORKSPACE,
	ORGANIZATION_METADATA,
	ENDPOINTS,
	ACTIVE_ENDPOINT,
	INTEGRATIONS,
} from "../constants/localstorage"

interface ActiveEndpoints {
	method: string
	path: string
}
interface AppState {
	cacheConfig: (CacheConfig & ConfigSupabaseMetadata) | null
	cacheConfigSrc: "local" | "Postman" | "Swagger"
	organizationsMetadata: IOrganization | null
	activeWorkspace: Service | null
	onboardLoading: boolean
	getStartedLoading: boolean
	workspaceModalOpen: boolean
	notificationDrawerOpen: boolean
	teamsDrawerOpen: boolean
	domainDrawerOpen: boolean
	activeDomain: string
	endpointDrawerOpen: boolean
	deleteModalOpen: boolean
	activeEndpoint: ActiveEndpoints | null
	publishEndpointLoading: boolean
	integrations: IIntegrations | null
	isSwitchingWorkspace: boolean
	editConfigFormValues: CacheConfigSchemaType | null
	endpointDrawerType: "new" | "edit"
	endpointTableSelection: string[]
}

interface AppActions {
	setActiveWorkSpace: (workspace: Service | null) => void
	setCacheConfig: (
		newSchema: (CacheConfig & ConfigSupabaseMetadata) | null
	) => void
	setOrganizationMetadata: (metadata: IOrganization) => void
	setCacheConfigSrc: (newSrc: "local" | "Postman" | "Swagger") => void
	setOnboardLoading: (loading: boolean) => void
	setPublishEndpointLoading: (loading: boolean) => void
	setGetStartedLoading: (loading: boolean) => void
	resetState: () => void
	openNewWorkspaceModal: (open: boolean) => void
	setNotificationDrawerOpen: (open: boolean) => void
	setTeamsDrawerOpen: (open: boolean) => void
	setDomainDrawerOpen: (open: boolean) => void
	setEndpointDrawerOpen: (open: boolean) => void
	setActiveDomain: (domainId: string) => void
	setDeleteModalOpen: (open: boolean) => void
	setActiveEndpoint: (endpoint: ActiveEndpoints | null) => void
	setIntegrations: (integrations: null | IIntegrations) => void
	setISSwitchingWorkspace: (value: boolean) => void
	setEndpointDrawerType: (type: "new" | "edit") => void
	setEditConfigFormValues: (
		configFormValue: CacheConfigSchemaType | null
	) => void
	setEndpointTableSelection: (paths: string[]) => void
}

const useAppStoreBase = create<AppActions & AppState>()(set => ({
	cacheConfig: parseLocalStorage(ENDPOINTS),
	organizationsMetadata: parseLocalStorage(ORGANIZATION_METADATA),
	activeWorkspace: parseLocalStorage(ACTIVE_WORKSPACE),
	integrations: parseLocalStorage(INTEGRATIONS),
	onboardLoading: true,
	getStartedLoading: true,
	workspaceModalOpen: false,
	cacheConfigSrc: "local",
	notificationDrawerOpen: false,
	teamsDrawerOpen: false,
	domainDrawerOpen: false,
	activeDomain: "",
	endpointDrawerOpen: false,
	deleteModalOpen: false,
	publishEndpointLoading: false,
	editConfigFormValues: null,
	isSwitchingWorkspace: false,
	endpointTableSelection: [],
	setEndpointTableSelection(paths) {
		return set(state => ({ endpointTableSelection: paths }))
	},
	setISSwitchingWorkspace(value) {
		return set(state => ({ isSwitchingWorkspace: value }))
	},
	endpointDrawerType: "new",
	setEndpointDrawerType(type) {
		return set(state => ({ endpointDrawerType: type }))
	},
	setEditConfigFormValues(configFormValue) {
		return set(state => ({ editConfigFormValues: configFormValue }))
	},
	setPublishEndpointLoading(loading) {
		return set(state => ({ publishEndpointLoading: loading }))
	},
	activeEndpoint: parseLocalStorage(ACTIVE_ENDPOINT),

	setIntegrations(integrations: null | IIntegrations) {
		localStorage.setItem(INTEGRATIONS, JSON.stringify(integrations))
		return set(state => ({ integrations }))
	},
	setActiveDomain(domainId) {
		return set(state => ({ activeDomain: domainId }))
	},
	setActiveEndpoint(endpoint) {
		localStorage.setItem(ACTIVE_ENDPOINT, JSON.stringify(endpoint))
		return set(state => ({ activeEndpoint: endpoint }))
	},
	setDeleteModalOpen(open) {
		return set(state => ({ deleteModalOpen: open }))
	},
	setNotificationDrawerOpen(open) {
		return set(state => ({ notificationDrawerOpen: open }))
	},
	setTeamsDrawerOpen(open) {
		return set(state => ({ teamsDrawerOpen: open }))
	},
	setDomainDrawerOpen(open) {
		return set(state => ({ domainDrawerOpen: open }))
	},
	setEndpointDrawerOpen(open) {
		return set(state => ({ endpointDrawerOpen: open }))
	},
	setActiveWorkSpace(workspace) {
		localStorage.setItem(ACTIVE_WORKSPACE, JSON.stringify(workspace))
		return set(state => ({ activeWorkspace: workspace }))
	},
	setCacheConfig(schema) {
		localStorage.setItem(ENDPOINTS, JSON.stringify(schema))
		return set(state => ({ cacheConfig: schema }))
	},
	setOrganizationMetadata(metadata) {
		localStorage.setItem(ORGANIZATION_METADATA, JSON.stringify(metadata))
		return set(state => ({ organizationsMetadata: metadata }))
	},
	openNewWorkspaceModal(open) {
		return set(state => ({ workspaceModalOpen: open }))
	},
	setOnboardLoading(loading) {
		return set(state => ({ onboardLoading: loading }))
	},
	setGetStartedLoading(loading) {
		return set(state => ({ getStartedLoading: loading }))
	},
	setCacheConfigSrc(newSrc) {
		return set(state => ({ cacheConfigSrc: newSrc }))
	},
	resetState() {
		return set(state => ({
			cacheConfig: parseLocalStorage(ENDPOINTS),
			organizationsMetadata: parseLocalStorage(ORGANIZATION_METADATA),
			activeWorkspace: parseLocalStorage(ACTIVE_WORKSPACE),
			integrations: parseLocalStorage(INTEGRATIONS),
			onboardLoading: true,
			getStartedLoading: true,
			workspaceModalOpen: false,
			cacheConfigSrc: "local",
			notificationDrawerOpen: false,
			teamsDrawerOpen: false,
			domainDrawerOpen: false,
			activeDomain: "",
			endpointDrawerOpen: false,
			deleteModalOpen: false,
			publishEndpointLoading: false,
			editConfigFormValues: null,
			isSwitchingWorkspace: false,
		}))
	},
}))

export default createSelectors(useAppStoreBase)
