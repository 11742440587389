import { useQuery } from "@tanstack/react-query"
import { getNotificationPreferences } from "../../../../api/server/notifications/get-notifs"
import { useAuth } from "@clerk/clerk-react"
import useAppStore from "../../../../zustand/new-store"
import { AxiosError } from "axios"
const useGetNotifPreferences = () => {
	const { cacheConfig, activeWorkspace } = useAppStore()
	const { getToken } = useAuth()

	const query = useQuery({
		queryFn: async () => {
			const token = await getToken()

			if (!token || !cacheConfig) {
				throw new AxiosError("Invalid Config")
			}
			return getNotificationPreferences({
				token,
				subscriberId: cacheConfig.host,
			})
		},
		queryKey: [activeWorkspace?.workspace_id + "notifications"],
		refetchOnWindowFocus: false,
	})
	return query
}

export default useGetNotifPreferences
