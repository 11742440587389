import { Box, Center, Group, Skeleton, Stack, Text, rem } from "@mantine/core"
import useTableAnalytics from "../api/useTableAnalytics"
import ParentSize from "@visx/responsive/lib/components/ParentSize"
import LineChartWithAxis from "../../../../Components/LineChart/LineChartWithAxis"
import RingProgress from "../../../../Components/RingProgress/RingProgress"
import { formatNumber } from "../components/Table/utils"
import { IconBug, IconChartHistogram } from "@tabler/icons-react"
import { useMemo } from "react"
import useAppStore from "../../../../zustand/new-store"
import { useParams, useSearchParams } from "react-router-dom"
import BarChart from "../../../../Components/BarChart/BarChart"

const EndpointMetrics = () => {
	const { data, isLoading } = useTableAnalytics()

	const { activeWorkspace, cacheConfig } = useAppStore()

	const { method } = useParams<{
		method:
			| ""
			| "get"
			| "option"
			| "post"
			| "put"
			| "patch"
			| "delete"
			| "head"
	}>()
	const [searchParams] = useSearchParams()
	const endpoint = searchParams.get("p")

	const endpointData = useMemo(() => {
		if (data) {
			return data.data.find(
				datum =>
					datum.method_path === `${method?.toUpperCase()}-${endpoint}`
			)
		} else {
			return undefined
		}
	}, [
		activeWorkspace?.workspace_id,
		cacheConfig?.host,
		method,
		endpoint,
		data,
	])
	const latencyMeta = useMemo(() => {
		if (endpointData && endpointData.time_series) {
			return endpointData?.time_series.map(t => ({
				date: new Date(t.time_group),
				value: t.avg_latency,
			}))
		} else {
			return []
		}
	}, [endpointData])
	const errorCodeMeta = useMemo(() => {
		if (endpointData && endpointData.error_codes) {
			return endpointData?.error_codes
				.map(t => ({
					code: t.error_code,
					count: t.error_count,
				}))
				.sort((a, b) => a.code - b.code)
		} else {
			return []
		}
	}, [endpointData])

	return (
		<Stack
			w='100%'
			mih='fit-content'
			sx={theme => ({
				borderRadius: rem(8),
				gap: rem(30),
			})}
			miw={rem(720)}
		>
			<Stack
				w='100%'
				p={rem(32)}
				mih='fit-content'
				miw='100%'
				sx={theme => ({
					borderRadius: rem(8),
					border: `1px solid ${theme.colors["refactor"][2]}`,
					gap: rem(30),
				})}
			>
				<Group
					pb={rem(16)}
					sx={theme => ({
						gap: rem(16),
					})}
					align='center'
				>
					<Text
						c='brand.7'
						fz={rem(16)}
						fw={500}
						lh={rem(24)}
					>
						Latency
					</Text>

					{isLoading ? (
						<Skeleton
							h={rem(24)}
							w={rem(124)}
						/>
					) : endpointData ? (
						<Text
							c='#000'
							fz={rem(12)}
							fw={400}
							lh={rem(20)}
						>
							{endpointData?.avg_latency.toFixed(1) || 0}ms Average
						</Text>
					) : null}
				</Group>
				<Box
					h={rem(350)}
					w='100%'
				>
					{isLoading ? (
						<Skeleton
							w='100%'
							h={rem(350)}
						/>
					) : endpointData ? (
						<Center
							h={rem(270)}
							w='100%'
						>
							<ParentSize>
								{({ width, height }) => (
									<LineChartWithAxis
										height={height}
										width={width}
										data={latencyMeta}
									/>
								)}
							</ParentSize>
						</Center>
					) : (
						<Center
							h='100%'
							w='100%'
							sx={{
								gap: rem(16),
								flexDirection: "column",
							}}
						>
							<Center c='#000'>
								<IconChartHistogram size={rem(24)} />
							</Center>
							<Stack
								sx={{
									gap: rem(8),
								}}
								w={rem(280)}
							>
								<Text
									c='brand.7'
									fz={rem(16)}
									fw={400}
									lh={rem(24)}
									ta='center'
								>
									No Latency Data Available
								</Text>
								<Text
									lh={rem(20)}
									fw={400}
									fz={rem(14)}
									c='refactor.4'
									ta='center'
								>
									To view latency statistics, ensure your APIs are set
									up and receiving requests.
								</Text>
							</Stack>
						</Center>
					)}
				</Box>
			</Stack>
			<Stack
				w='100%'
				p={rem(32)}
				mih='fit-content'
				sx={theme => ({
					borderRadius: rem(8),
					border: `1px solid ${theme.colors["refactor"][2]}`,
					gap: rem(0),
					maxWidth: `calc(100vw - ${rem(454)})`,
				})}
			>
				<Text
					c='brand.7'
					pb={rem(0)}
					fz={rem(16)}
					fw={500}
					lh={rem(24)}
				>
					Error Rate
				</Text>
				<Group
					sx={{
						gap: rem(30),
					}}
					align='center'
					noWrap
					grow
				>
					<Group w='fit-content'>
						<RingProgress
							size={140}
							value={endpointData?.error_rate || 0}
							label={`${
								!endpointData?.error_rate
									? 0
									: endpointData?.error_rate.toFixed(2)
							}%`}
							thickness={16}
							textSize='big'
							color='error'
						/>
						<Stack
							sx={{
								gap: 16,
							}}
						>
							<Stack
								sx={{
									gap: rem(10),
								}}
							>
								{isLoading ? (
									<Skeleton
										h={rem(18)}
										w={rem(27)}
									/>
								) : (
									<Text
										c='brand.7'
										fz={rem(14)}
										fw={500}
										lh={rem(16)}
									>
										{formatNumber(
											Number(endpointData?.total_requests || 0)
										)}
									</Text>
								)}

								<Text
									c='refactor.0'
									fz={rem(12)}
									fw={400}
									lh={rem(14)}
								>
									Total Requests
								</Text>
							</Stack>
						</Stack>
					</Group>
					<Box h={rem(332)}>
						{isLoading ? (
							<Skeleton
								w='100%'
								h={rem(332)}
							/>
						) : endpointData ? (
							<Center
								w='100%'
								h={rem(264)}
							>
								<ParentSize>
									{({ width, height }) => (
										<BarChart
											width={width}
											height={height}
											data={errorCodeMeta}
										/>
									)}
								</ParentSize>
							</Center>
						) : (
							<Center
								h='100%'
								w='100%'
								sx={{
									gap: rem(16),
									flexDirection: "column",
								}}
							>
								<Center c='#000'>
									<IconBug size={rem(24)} />
								</Center>
								<Stack
									sx={{
										gap: rem(8),
									}}
									w={rem(280)}
								>
									<Text
										c='brand.7'
										fz={rem(16)}
										fw={400}
										lh={rem(24)}
										ta='center'
									>
										No Error Code Data Available
									</Text>
									<Text
										lh={rem(20)}
										fw={400}
										fz={rem(14)}
										c='refactor.4'
										ta='center'
									>
										To view error statistics, ensure your APIs are set
										up and receiving requests.
									</Text>
								</Stack>
							</Center>
						)}
					</Box>
				</Group>
			</Stack>
		</Stack>
	)
}

export default EndpointMetrics
