import { analyticsAxiosConfig } from "../../../config/axios.config"
import { Intervals } from "../../../types/dashboard-chart-types"

export async function getDashboardLatencyChartData(body: {
	token: string
	interval: Intervals
	host: string
	percentile: number | string
	country?: string
}) {
	const { country = "", host, interval, percentile } = body

	let url = `/analytics/performance/performance-metrics?interval=${interval}&host=${host}&percentile=${percentile}`

	if (country) {
		url = url + `&country=${country}`
	}
	const response = await analyticsAxiosConfig.get(url, {
		headers: {
			Authorization: `Bearer ${body.token}`,
		},
	})
	const { data } = response
	return data
}
