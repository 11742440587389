import { CustomProjection } from "@visx/geo"
import * as topojson from "topojson-client"
import topology from "./world-topo.json"
import { geoNaturalEarth1 } from "d3-geo"
import { useTooltip, useTooltipInPortal } from "@visx/tooltip"
import { localPoint } from "@visx/event"
import React from "react"
import { Box, Group, Stack, Text, rem } from "@mantine/core"

interface Datum {
	name: string
	code: string
	value: number
	flag: JSX.Element
	coordinates: {
		lat: number
		lng: number
	}
}
export type GeoMercatorProps = {
	width: number
	height: number
	data: Datum[]
	events?: boolean
}

interface FeatureShape {
	type: "Feature"
	id: string
	geometry: { coordinates: [number, number][][]; type: "Polygon" }
	properties: { name: string }
}

// @ts-expect-error
const world = topojson.feature(topology, topology.objects.units) as {
	type: "FeatureCollection"
	features: FeatureShape[]
}
const GeoChart = ({
	width,
	height,
	events = false,
	data,
}: GeoMercatorProps) => {
	const centerX = width / 2.5
	const centerY = height / 1.1
	const scale = 150
	const projection = geoNaturalEarth1()
		.translate([centerX, centerY])
		.scale(scale)

	const {
		tooltipData,
		tooltipLeft,
		tooltipTop,
		tooltipOpen,
		showTooltip,
		hideTooltip,
	} = useTooltip<Datum>()

	const { containerRef, TooltipInPortal } = useTooltipInPortal({
		detectBounds: true,
		scroll: true,
	})
	const handleClick = (event: React.MouseEvent<SVGElement>, datum: Datum) => {
		const coords = localPoint(event)
		if (tooltipOpen) {
			if (datum.code === tooltipData?.code) {
				hideTooltip()
			} else {
				showTooltip({
					tooltipLeft: (coords?.x || 0) + 5,
					tooltipTop: (coords?.y || 0) + 5,
					tooltipData: datum,
				})
			}
		} else {
			showTooltip({
				tooltipLeft: coords?.x || 0,
				tooltipTop: coords?.y || 0,
				tooltipData: datum,
			})
		}
	}

	return (
		<>
			<svg
				width={width}
				height={height + 124}
				ref={containerRef}
			>
				<rect
					x={0}
					y={0}
					width={width}
					height={height + 124}
					fill='#fff'
					rx={14}
				/>
				<defs>
					<pattern
						id='dots'
						width='5'
						height='5'
						patternUnits='userSpaceOnUse'
					>
						<circle
							cx='2.5'
							cy='2.5'
							r='2'
							fill='#D4D4D4'
						/>
					</pattern>
				</defs>

				<CustomProjection<FeatureShape>
					data={world.features}
					scale={scale}
					translate={[centerX, centerY]}
					projection={geoNaturalEarth1}
				>
					{mercator => (
						<g>
							{mercator.features.map(({ feature, path }, i) => (
								<path
									key={`map-feature-${i}`}
									d={path || ""}
									fill='url(#dots)'
									strokeWidth={0}
								/>
							))}
						</g>
					)}
				</CustomProjection>

				<g>
					{data.map(country => (
						<g
							key={country.code}
							onClick={e => {
								handleClick(e, country)
							}}
						>
							<circle
								transform={`translate(${projection([
									country.coordinates.lng,
									country.coordinates.lat,
								])})`}
								r='20'
								fill='#7350ff1a'
								cursor='pointer'
							/>

							<circle
								transform={`translate(${projection([
									country.coordinates.lng,
									country.coordinates.lat,
								])})`}
								r='12'
								fill='#7350ff33'
								cursor='pointer'
							/>

							<circle
								transform={`translate(${projection([
									country.coordinates.lng,
									country.coordinates.lat,
								])})`}
								r='4'
								fill='#7350FF'
								cursor='pointer'
							/>
						</g>
					))}
				</g>
			</svg>
			{tooltipOpen ? (
				<Box pos='relative'>
					<TooltipInPortal
						key={Math.random()}
						top={tooltipTop}
						left={tooltipLeft}
						applyPositionStyle
						unstyled
					>
						<Stack
							p={rem(16)}
							sx={theme => ({
								borderRadius: rem(8),
								border: `1px solid ${theme.colors["refactor"][2]} `,
								background: theme.white,
								gap: rem(12),
							})}
						>
							<Box
								pb={rem(12)}
								sx={theme => ({
									borderBottom: `1px solid ${theme.colors["refactor"][2]}`,
								})}
								w='100%'
								h='fit-content'
							>
								<Text
									fw={500}
									fz={rem(14)}
									lh={rem(16)}
									color='brand.7'
								>
									Traffic
								</Text>
							</Box>
							<Group
								align='center'
								sx={{
									justifyContent: "space-between",
								}}
								noWrap
							>
								<Group
									align='center'
									noWrap
									sx={{
										gap: rem(8),
									}}
									h='fit-content'
								>
									{tooltipData?.flag}
									<Text
										fw={400}
										lh={rem(16)}
										fz={rem(13)}
										c='refactor.0'
										sx={{
											overflow: "hidden",
											textOverflow: "ellipsis",
											whiteSpace: "nowrap",
										}}
									>
										{tooltipData?.name}
									</Text>
								</Group>
								<Text
									fw={400}
									lh={rem(14)}
									fz={rem(13)}
									c='refactor.0'
								>
									{tooltipData?.value.toFixed(2)}%
								</Text>
							</Group>
						</Stack>
					</TooltipInPortal>
				</Box>
			) : null}
		</>
	)
}

export default GeoChart
