import { create } from "zustand"
import { createSelectors } from "./create-selector"
import { TourState } from "../types/tour.types"
import { COMPLETED_TOUR } from "../constants/localstorage"
import { parseLocalStorage } from "../lib/localstorage-parser"

type TourGlobalState = TourState & {
	completedTour: boolean
}

interface TourActions {
	updateTourState: (tour: Partial<TourGlobalState>) => void
}
const useTourStoreBase = create<TourActions & TourGlobalState>()(set => ({
	run: false,
	stepIndex: 0,
	steps: [],
	tourActive: false,
	completedTour: parseLocalStorage(COMPLETED_TOUR) || false,
	updateTourState(tour) {
		if (tour.completedTour !== undefined) {
			localStorage.setItem(
				COMPLETED_TOUR,
				JSON.stringify(tour.completedTour)
			)
		}
		return set(state => ({
			...tour,
		}))
	},
}))
const useTourStore = createSelectors(useTourStoreBase)

export default useTourStore
