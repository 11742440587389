import { useAuth, useOrganization, useOrganizationList } from "@clerk/clerk-react"
import { Navigate, useLocation } from "react-router-dom"
import { ACTIVE_WORKSPACE } from "../../constants/localstorage"
import useOnboardingCompleted from "../../hooks/useOnboardingCompleted"
import { isProduction } from "../../constants/is-production"
import { useEffect, useState } from "react"

interface IProps {
	children: React.ReactNode
}
const successRedirect = isProduction ? "/get-started" : "/pricing-auth"
const OnboardGuard = ({ children }: IProps) => {
	const { organization } = useOrganization()
	const location = useLocation()
	const { isLoaded, setActive, userMemberships } = useOrganizationList({
		userMemberships: true
	});
	
	const [orgId, setOrgId] = useState("");
	const { data, error, activeWorkspaceLocal } = useOnboardingCompleted({
		orgId: organization?.id || orgId,
	});

	useEffect(() => {
		if (!organization && isLoaded && userMemberships.data && userMemberships.data.length > 0) {
			let firstOrg = userMemberships.data[0].organization.id
			setOrgId(firstOrg);
			setActive({ organization: firstOrg });
		} else if (organization) {
			setOrgId(organization.id);
		}
	}, [organization, isLoaded, userMemberships]);

	if (!orgId && location.pathname !== "/onboard") {
		localStorage.removeItem(ACTIVE_WORKSPACE)

		return (
			<Navigate
				to='/onboard'
				replace
				state={{ path: location.pathname }}
			/>
		)
	}
	if ((error || !data) && location.pathname !== "/onboard") {
		localStorage.removeItem(ACTIVE_WORKSPACE)
		return (
			<Navigate
				to='/onboard'
				replace
				state={{ path: location.pathname }}
			/>
		)
	}
	if (
		!error &&
		data &&
		orgId &&
		location.pathname === "/onboard" &&
		activeWorkspaceLocal
	) {
		return (
			<Navigate
				to={successRedirect}
				replace
				state={{ path: location.pathname }}
			/>
		)
		// return <>{children}</>
	}
	return <>{children}</>
}

export default OnboardGuard
