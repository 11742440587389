import { notificationsAxiosConfig } from "../../../config/axios.config"

export async function updateNotificationPreferences({
	token,
	subscriberId,
	type,
	enabled,
}: {
	subscriberId: string
	token: string
	type: "email" | "chat" | "in_app"
	enabled: boolean
}) {
	const response = await notificationsAxiosConfig.patch(
		`/subscriber/preferences/${subscriberId}`,
		{ type, enabled },
		{
			headers: {
				"Content-Type": "application/json",

				Authorization: `Bearer ${token}`,
			},
		}
	)

	const { data } = response
	return data
}
