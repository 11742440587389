import {
	Box,
	Button,
	Group,
	Stack,
	Text,
	createStyles,
	rem,
} from "@mantine/core"
import CustomTextInput from "../../../../Components/TextInput/TextInput"
import * as z from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import {
	useForm,
	SubmitHandler,
	FormProvider,
	useFormContext,
} from "react-hook-form"
import { useMutation } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { useState } from "react"
import { axiosErrorParser } from "../../../../lib/parse-axios-error"
import {
	CacheConfig,
	ConfigSupabaseMetadata,
	serviceAuthSchemaType,
} from "../../../../lib/parser"
import useAppStore from "../../../../zustand/new-store"
import { useAuth } from "@clerk/clerk-react"
import { uploadSwagger } from "../../../../api/supabase/upload-swagger-supabase"
import DrawerError from "../../../../Components/DrawerError/DrawerError"
import DrawerSuccess from "../../../../Components/DrawerSuccess/DrawerSuccess"
import {
	basePathRegex,
	domainOriginRegex,
	urlPattern,
} from "../../../../constants/regex"
import CustomSelect from "../../../../Components/Select/Select"
import { uploadSwaggerURL } from "../../../../api/server/onboard/upload-swagger"
import { useDisclosure } from "@mantine/hooks"
import CustomHoverCard from "../../../../Components/CustomHoverCard/CustomHoverCard"
import { IconHelpCircleFilled } from "@tabler/icons-react"

const swaggerSchema = z.object({
	url: z
		.string({
			required_error: "URL is required",
			invalid_type_error: "URL is required",
		})
		.min(1, { message: "URL is required" })
		.regex(urlPattern, { message: "URL must be a valid url" }),
	protocol: z.enum(["REST", "JSON-RPC"]),
	origin: z
		.string()
		.min(1, { message: "Origin host must have between 2 and 63 characters" })
		.regex(domainOriginRegex, {
			message: "Origin host must be a valid top-level domain",
		}),
	basePath: z
		.string()
		.min(1, { message: "Base path is required" })
		.regex(basePathRegex, {
			message: "Base path must be a valid url path",
		})
		.startsWith("/", { message: "Base path must begin with /" }),
})

const useStyles = createStyles({
	mt: {
		marginTop: rem(16),
	},
})
type SwaggerFormType = z.infer<typeof swaggerSchema>
const SwaggerForm = ({
	failureAction,
	successAction,
	retryAction,
	onError,
	success,
}: {
	failureAction: () => void
	retryAction: () => void
	successAction: () => void
	onError: () => void
	success: boolean
	hideTitle: boolean
}) => {
	const [serverSideJSONErrors, setServerSideValidationErrors] = useState<
		string[]
	>([])
	const [isUploading, setIsUploading] = useState(false)
	const [supabaseResult, setSupabaseResult] = useState<
		(CacheConfig & ConfigSupabaseMetadata) | null
	>(null)
	const { activeWorkspace, setCacheConfig, setCacheConfigSrc } = useAppStore()
	const { userId, orgId, getToken } = useAuth()

	const authMethods = useFormContext<serviceAuthSchemaType>()
	const { classes } = useStyles()

	const [openAuth, { toggle: toggleAuth }] = useDisclosure(false)
	const {
		handleSubmit,
		control,
		reset: resetInputFields,
		formState,
		watch,
	} = useForm<SwaggerFormType>({
		resolver: zodResolver(swaggerSchema),
		defaultValues: {
			url: "",
			protocol: undefined,
		},
	})

	const uploadSwaggerToSupabase = async (
		values: CacheConfig & {
			user_id: string
			org_id: string
			source: string
			workspace_id: string
			source_url: string
		}
	) => {
		try {
			const result = await uploadSwagger(getToken, values)
			if (result.data) {
				return { data: result.data, error: null }
			} else {
				return { data: null, error: result.data || result.error }
			}
		} catch (error) {
			console.log(error)
			console.log(JSON.stringify(error, null, 2))

			return { data: null, error }
		}
	}

	const {
		mutate,
		isLoading: isMutationLoading,
		reset,
	} = useMutation({
		mutationFn: uploadSwaggerURL,
		onError(error: AxiosError) {
			console.log(error)
			failureAction()
			const errorMessage = axiosErrorParser(error)
			if (typeof errorMessage === "string") {
				setServerSideValidationErrors([errorMessage])
			} else {
				if (errorMessage instanceof Array) {
					setServerSideValidationErrors(errorMessage)
				} else {
					setServerSideValidationErrors(errorMessage.error)
				}
			}
			setIsUploading(false)
			reset()
			resetInputFields()
		},
		async onSuccess(result: CacheConfig, variables) {
			setServerSideValidationErrors([])

			const dataWithSupabaseMetadata = {
				...result,
				workspace_id: activeWorkspace?.workspace_id || "",
				source: "Swagger",
				user_id: userId || "",
				org_id: orgId || "",
				source_url: variables.url,
			}
			const { data, error } = await uploadSwaggerToSupabase(
				dataWithSupabaseMetadata
			)

			if (data) {
				setSupabaseResult(data)
				resetInputFields()
				reset()
				successAction()
			}

			if (error) {
				console.log(error)
				setServerSideValidationErrors(["Something went wrong"])
				resetInputFields()
				reset()
			}

			setIsUploading(false)
		},
	})

	const submitHandler: SubmitHandler<SwaggerFormType> = async values => {
		const token = await getToken()

		if (!token) {
			return
		}

		setSupabaseResult(null)
		setServerSideValidationErrors([])
		const auth = authMethods.watch()
		const authorization = !!auth.type
			? {
					type: auth.type,
					value: auth.key || "",
			  }
			: null
		const req = {
			token,
			authorization,
			...values,
		}
		mutate(req)
		setIsUploading(true)
	}

	return (
		<>
			{serverSideJSONErrors.length > 0 ? (
				<DrawerError
					errors={serverSideJSONErrors}
					btnAction={() => {
						retryAction()
						setServerSideValidationErrors([])
						authMethods.reset()
						if (openAuth) {
							toggleAuth()
						}
					}}
					title='Setup Incomplete!'
					subtext="We encountered some hiccups while importing your OpenAPI documentation into Resilis. Here's what went wrong:"
					secondaryBtnAction={onError}
					secondaryBtnText='Use Manual Setup Instead'
				/>
			) : success ? (
				<DrawerSuccess
					title='Import Successful!'
					subtext='Your OpenAPI documentation has been successfully imported into Resilis. Get started.'
					btnAction={() => {
						setCacheConfig(supabaseResult)
						setCacheConfigSrc("Swagger")
					}}
					btnText='Go to Dashboard'
				/>
			) : (
				<Stack
					sx={{ gap: rem(24) }}
					mt={rem(24)}
					w='100%'
				>
					<Text
						fw={400}
						lh={rem(24)}
						fz={rem(16)}
						sx={theme => ({ color: theme.colors["refactor"][4] })}
					>
						Import API endpoints with your OpenAPI JSON URL
					</Text>
					<Box
						component='form'
						display='flex'
						sx={{
							gap: rem(24),
							flexDirection: "column",
						}}
						onSubmit={handleSubmit(submitHandler)}
					>
						<CustomTextInput
							name='url'
							placeholder='Swagger/OpenAPI JSON URL'
							label={
								<Group spacing={rem(8)}>
									<Text>Swagger/OpenAPI JSON URL</Text>
									<CustomHoverCard
										target={<IconHelpCircleFilled size={rem(16)} />}
										body={[
											{
												text: "Enter the URL where your Swagger or OpenAPI document is hosted. This document describes your API's endpoints, request/response formats, authentication methods, and more. Ensure the URL is publicly accessible.",
											},
										]}
										width={380}
										position='top'
										spacing={8}
									/>
								</Group>
							}
							keepLabel={!!watch("url")}
							control={control}
							errorMessage={formState.errors.url?.message}
							disabled={isMutationLoading || isUploading}
						/>

						<CustomSelect
							name='protocol'
							control={control}
							placeholder='Protocol'
							label={
								<Group spacing={rem(8)}>
									<Text>Protocol</Text>
									<CustomHoverCard
										target={<IconHelpCircleFilled size={rem(16)} />}
										body={[
											{
												text: "Select the API communication protocol. This defines how clients interact with your API and how data is transmitted.",
											},
										]}
										width={380}
										position='top'
										spacing={8}
									/>
								</Group>
							}
							disabled={isMutationLoading || isUploading}
							keepLabel={!!watch("protocol")}
							data={[
								{ value: "REST", label: "REST" },
								{ value: "JSON-RPC", label: "JSON-RPC" },
							]}
							errorMessage={formState.errors.protocol?.message}
							mt={formState.errors.url?.message ? rem(16) : 0}
						/>
						<CustomTextInput
							name='origin'
							className={
								formState.errors.protocol?.message ? classes.mt : ""
							}
							placeholder='Origin domain (e.g: api.disco.com)'
							label={
								<Group spacing={rem(8)}>
									<Text>Origin domain (e.g: api.disco.com)</Text>
									<CustomHoverCard
										target={<IconHelpCircleFilled size={rem(16)} />}
										title={""}
										body={[
											{
												text: "Enter the domain name of your API's origin server.",
											},
										]}
										width={380}
										position='top'
										spacing={8}
									/>
								</Group>
							}
							control={control}
							errorMessage={formState.errors.origin?.message}
							disabled={isMutationLoading || isUploading}
						/>

						<CustomTextInput
							name='basePath'
							placeholder='Base Path (e.g: /api/v1)'
							label={
								<Group spacing={rem(8)}>
									<Text>Base Path (e.g: /api/v1)</Text>
									<CustomHoverCard
										target={<IconHelpCircleFilled size={rem(16)} />}
										title={""}
										body={[
											{
												text: "Enter the base path for your API endpoints. This is the initial part of the URL path used to access your API, such as /api/v1.",
											},
										]}
										width={380}
										position='top'
										spacing={8}
									/>
								</Group>
							}
							control={control}
							errorMessage={formState.errors.basePath?.message}
							disabled={isMutationLoading || isUploading}
							className={
								formState.errors.origin?.message ? classes.mt : ""
							}
						/>
						<Button
							variant='filled'
							fullWidth
							h={rem(48)}
							type='submit'
							mt={
								Boolean(formState.errors.basePath?.message)
									? rem(24)
									: 0
							}
							disabled={
								isMutationLoading ||
								isUploading ||
								!watch("url") ||
								!watch("basePath") ||
								!watch("origin") ||
								!!formState.errors.url?.message ||
								(openAuth && !authMethods.formState.isValid)
							}
							sx={{
								borderRadius: rem(8),
								fontWeight: 500,
								lineHeight: rem(16),
								letterSpacing: -0.2,
							}}
							loading={isMutationLoading || isUploading}
						>
							Import
						</Button>
					</Box>
					<Box
						p={rem(12)}
						sx={{
							borderRadius: "4px",
							background: "#F7F5FF",
						}}
					>
						<Text
							fw={400}
							lh={rem(20)}
							fz={rem(14)}
							c='#262626'
						>
							Note: Ensure your OpenAPI spec is publicly accessible
						</Text>
					</Box>
				</Stack>
			)}
		</>
	)
}

export default SwaggerForm
