import { extent, max } from "@visx/vendor/d3-array"
import * as allCurves from "@visx/curve"
import { Group } from "@visx/group"
import { LinePath } from "@visx/shape"
import { scaleTime, scaleLinear } from "@visx/scale"
import { createStyles, rem } from "@mantine/core"

const useStyles = createStyles(theme => ({
	padding: {
		padding: rem(12),
	},
}))

interface Datum {
	date: Date
	value: number
}

const getX = (d: Datum) => d.date
const getY = (d: Datum) => d.value

export type CurveProps = {
	width: number
	height: number
	data: Datum[]
	stroke?: string
	strokeWidth?: number
}

export default function LineChart({
	width,
	height,
	data = [],
	stroke = "#C74FFF",
	strokeWidth = 0.8,
}: CurveProps) {
	const { classes } = useStyles()
	const xScale = scaleTime<number>({
		domain: extent(data, getX) as [Date, Date],
	})
	const yScale = scaleLinear<number>({
		domain: [0, max(data, getY) as number],
	})

	xScale.range([0, width])
	yScale.range([height, 0])

	return (
		<svg
			width={width + 4}
			height={height + 8}
		>
			<rect
				width={width + 4}
				height={height + 8}
				fill='#fff'
				rx={14}
				ry={14}
			/>

			{
				<Group
					left={3}
					top={3}
				>
					<LinePath<Datum>
						curve={allCurves["curveNatural"]}
						data={data}
						x={d => xScale(getX(d)) ?? 0}
						y={d => yScale(getY(d)) ?? 0}
						stroke={stroke}
						strokeWidth={strokeWidth}
						strokeOpacity={1}
						shapeRendering='geometricPrecision'
						width={width}
						height={height}
						className={classes.padding}
					/>
				</Group>
			}
		</svg>
	)
}
