import { AddDomainResponse } from "../../../lib/parser"
import { axiosConfig } from "../../../config/axios.config"
export const getDomain = async (
	id: string,
	token: string
): Promise<AddDomainResponse> => {
	const response = await axiosConfig.get(`/hostname/${id}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	})
	const { data } = response
	return data
}
