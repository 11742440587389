import { Navigate, useLocation } from "react-router-dom"
import useAppStore from "../../zustand/new-store"
import { useAuth, useOrganization } from "@clerk/clerk-react"
import useCompletedGetStarted from "../../hooks/useCompletedGetStarted"
import { ENDPOINTS } from "../../constants/localstorage"

interface IProps {
	children: React.ReactNode
}

const ConfigGuard = ({ children }: IProps) => {
	const { orgId } = useAuth()
	const { organization } = useOrganization()
	const location = useLocation()
	const { data, error } = useCompletedGetStarted()
	const { activeWorkspace } = useAppStore()

	if (!activeWorkspace || !orgId || !organization) {
		localStorage.removeItem(ENDPOINTS)
		return (
			<Navigate
				to='/onboard'
				replace
				state={{ path: location.pathname }}
			/>
		)
	}

	if ((!data || error) && location.pathname !== "/get-started") {
		localStorage.removeItem(ENDPOINTS)
		return (
			<Navigate
				to='/get-started'
				replace
				state={{ path: location.pathname }}
			/>
		)
	}

	if (data && location.pathname === "/get-started") {
		return (
			<Navigate
				to='/'
				replace
			/>
		)
	}
	if (data && location.pathname !== "/get-started") {
		return <>{children}</>
	}
	return <>{children}</>
}

export default ConfigGuard
