import { useMutation } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { CacheConfig, ConfigSupabaseMetadata } from "../../../../lib/parser"
import useAppStore from "../../../../zustand/new-store"
import { useAuth } from "@clerk/clerk-react"
import { updatePostmanSwagger } from "../../../../api/server/onboard/update-postman-swagger"
import { updateEndpoints } from "../../../../api/supabase/update-endpoints"

const useUpdateAuth = (props: {
	setIsLoading: (loading: boolean) => void
	errorFn?: () => void
	successFn?: (data: CacheConfig & ConfigSupabaseMetadata) => void
}) => {
	const {
		setIsLoading = () => null,
		errorFn = () => null,
		successFn = () => null,
	} = props
	const { activeWorkspace, setCacheConfig } = useAppStore()
	const { userId, orgId, getToken } = useAuth()

	return useMutation({
		mutationFn: updatePostmanSwagger,
		onError(error: AxiosError) {
			console.log(error)
			errorFn()

			setIsLoading(false)
		},
		async onSuccess(result: CacheConfig) {
			const dataWithSupabaseMetadata = {
				...result,
				workspace_id: activeWorkspace?.workspace_id || "",
				user_id: userId || "",
				org_id: orgId || "",
				authorization: result?.authorization ?? null,
			}
			const { data, error } = await updateEndpoints(
				getToken,
				dataWithSupabaseMetadata
			)

			if (data) {
				setCacheConfig(data)
				successFn(data)
			}

			if (error) {
				console.log(error)
				errorFn()
			}

			setIsLoading(false)
		},
	})
}

export default useUpdateAuth
