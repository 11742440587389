export const variants = {
	edit: {
		title: "Edit Endpoint Route",
		subtext:
			"Modify your selected endpoint's route for accurate data routing.",
		errorSubtext:
			"Failed to update the endpoint route. Please check your inputs and try again.",
		successSubtext: "The endpoint route has been updated successfully.",
	},
	new: {
		title: "Add New Endpoint Route",
		subtext: "Add endpoint route for accurate data routing.",
		errorSubtext:
			"Failed to add the endpoint route. Please check your inputs and try again.",
		successSubtext: "The endpoint route has been added successfully.",
	},
}

export const inputLabelInfo = () => {
	return {
		purge: {
			body: [
				{
					text: "Choose the endpoints whose cache should be cleared when this endpoint is called.",
				},
			],
			title: "Mutation Based Invalidation: ",
		},
		type: {
			body: [
				{
					text: (
						<>
							<b style={{ fontWeight: 600 }}>
								<i>Private </i>
							</b>{" "}
							for user-specific resources.
						</>
					),
				},
				{
					text: (
						<>
							<b style={{ fontWeight: 600 }}>
								<i>Protected </i>
							</b>{" "}
							for resources accessible to all authenticated users.
						</>
					),
				},
				{
					text: (
						<>
							<b style={{ fontWeight: 600 }}>
								<i>Public </i>
							</b>{" "}
							for unrestricted access.
						</>
					),
				},
				{
					text: (
						<>
							<b style={{ fontWeight: 600 }}>
								<i>Login/Logout </i>
							</b>{" "}
							for session control.
						</>
					),
				},
				{
					text: (
						<>
							<b style={{ fontWeight: 600 }}>
								<i>Refresh </i>
							</b>{" "}
							for updating tokens or sessions.
						</>
					),
				},
			],
			title: "Access Level",
		},
	}
}
