import {
	Button,
	Text,
	Group,
	rem,
	Flex,
	Stack,
	createStyles,
	Box,
} from "@mantine/core"
import { useDisclosure, useDocumentTitle } from "@mantine/hooks"
import { IconApi, IconDeviceDesktop } from "@tabler/icons-react"
import Card from "./Card"
import { useState } from "react"
import Loading from "../../dashboard/loading"
import useAppStore from "../../../zustand/new-store"
import SpecIntegration from "./APISpec/SpecOptions"
import ManualForm from "./Manual/ManualForm"

const useStyles = createStyles(theme => ({
	apiIcon: {
		stroke: theme.colors["yellow"][1],
	},
	desktopIcon: {
		stroke: theme.colors["green"][1],
	},
}))
const GetStarted = () => {
	const [isSpecOptionsOpened, { close: closeSpecOptions, open: openSpecOptions }] =
		useDisclosure(false)
	const [isManualOpened, { close: closeManual, open: openManual }] =
		useDisclosure(false)
	const { getStartedLoading } = useAppStore()
	const { classes } = useStyles()
	useDocumentTitle("Getting started")

	if (getStartedLoading) {
		return <Loading />
	}

	return (
		<>
			<ManualForm
				opened={isManualOpened}
				open={openManual}
				close={closeManual}
				onError={() => {
					closeManual()
					openSpecOptions()
				}}
			/>
			<SpecIntegration
				opened={isSpecOptionsOpened}
				open={openSpecOptions}
				close={closeSpecOptions}
				onError={() => {
					closeSpecOptions()
					openManual()
				}}
			/>
			<Flex
				w='100%'
				h='100%'
				direction='column'
				mt={rem(56)}
				pos='relative'
			>
				<Flex
					justify='center'
					direction='column'
					w={rem(616)}
					sx={{
						margin: "0 auto",
					}}
				>
					<Stack
						sx={{
							gap: rem(12),
						}}
						mb={rem(64)}
					>
						<Text
							component='h1'
							m={0}
							align='center'
							sx={theme => ({
								lineHeight: rem(24),
								fontWeight: 500,
								fontSize: rem(21),
								color: theme.colors[theme.primaryColor][7],
							})}
						>
							Welcome to Resilis - Getting Started
						</Text>
						<Text
							component='h2'
							m={0}
							align='center'
							sx={theme => ({
								lineHeight: rem(24),
								fontSize: rem(16),
								color: theme.colors["refactor"][4],
								fontWeight: 400,
							})}
						>
							Set up your API configurations to get the best out of
							Resilis.
						</Text>
					</Stack>

					<Group
						sx={{
							gap: rem(24),
						}}
					>
						<Card
							title='OpenAPI/Postman Import'
							text='Import your API with existing OpenAPI definitions or Postman collections.'
							recommended
							icon={<IconApi className={classes.apiIcon} />}
							active={isSpecOptionsOpened}
							setActive={() => {
								// if (active === "swagger") {
								// 	setActive("")
								// } else {
								// 	setActive("swagger")
								// }
								openSpecOptions()
							}}
						/>
						<Card
							title='Manual Setup'
							text='Start with a blank slate and gradually define your API spec at your own pace.'
							icon={
								<IconDeviceDesktop className={classes.desktopIcon} />
							}
							active={isManualOpened}
							setActive={() => {
								// if (active === "manual") {
								// 	setActive("")
								// } else {
								// 	setActive("manual")
								// }
								openManual()
							}}
						/>
					</Group>
					<Box
						p={rem(16)}
						sx={theme => ({
							background: "#F7F5FF",
							borderRadius: rem(8),
							marginTop: rem(24),
						})}
						mb={rem(96)}
					>
						<Text
							component='h6'
							m={0}
							sx={theme => ({
								fontSize: rem(14),
								lineHeight: rem(20),
								color: "#262626",
								fontWeight: 400,
							})}
						>
							OpenAPI allows for easy API change synchronization with Resilis,
							so you can quickly update your OpenAPI documents
							directly in Resilis as you make changes.
						</Text>
					</Box>
				</Flex>
				{/* <Flex
					w='100%'
					sx={theme => ({
						borderTop: `1px solid ${theme.colors["refactor"][2]}`,
						background: theme.white,
						maxWidth: "1440px",
						margin: "0 auto",
						width: "100vw",
					})}
					h={rem(80)}
					align='center'
					justify='flex-end'
					pr={rem(40)}
					pos='fixed'
					bottom={0}
					left={0}
				>
					 <Button
						disabled={!active}
						sx={{
							letterSpacing: -0.2,
							lineHeight: rem(16),
							fontWeight: 500,
							fontSize: rem(14),
							borderRadius: rem(8),
						}}
						onClick={() => {
							if (active === "swagger") {
								openSpecOptions()
							}
							if (active === "manual") {
								openManual()
							}
						}}
					>
						Continue
					</Button> 
				</Flex> */}
			</Flex>
		</>
	)
}

export default GetStarted
