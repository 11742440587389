import { supabaseClient } from "../../config/supabase.config"
import { INTEGRATIONS } from "../../constants/supabase-tables"
export const getWorkspaceIntegrations = async (
	getToken: (
		options?: { template: string } | undefined
	) => Promise<string | null>,
	hostname: string
) => {
	const supabaseAccessToken = (await getToken({
		template: "supabase",
	})) as string
	const supabase = supabaseClient(supabaseAccessToken)
	return await supabase
		.from(INTEGRATIONS)
		.select("*")
		.eq("hostname", hostname)
		.single()
}
