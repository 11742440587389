import { supabaseClient } from "../../config/supabase.config"
import { Service, User } from "../../types/supabase.types"
import { SUPABASE_ORGANIZATION } from "../../constants/supabase-tables"

export const createOrganizationMetadata = async (
	getToken: (
		options?: { template: string } | undefined
	) => Promise<string | null>,
	userId: string,
	name: string,
	org_id: string,
	services: Service[],
	users: User[],
	website: string
) => {
	const supabaseAccessToken = (await getToken({
		template: "supabase",
	})) as string
	const supabase = supabaseClient(supabaseAccessToken)

	return await supabase
		.from(SUPABASE_ORGANIZATION)
		.insert({
			user_id: userId,
			users,
			name,
			org_id,
			services,
			website,
		})
		.select()
		.single()
}
