import { useAuth } from "@clerk/clerk-react"
import { useQuery } from "@tanstack/react-query"
import useAppStore from "../zustand/new-store"
import { getSubscription } from "../api/server/billing/get-subscription"
import { AxiosError } from "axios"

interface ISubscription {
	id: string
	object: string
	application: null | string
	application_fee_percent: null
	automatic_tax: { enabled: boolean }
	billing_cycle_anchor: number
	billing_thresholds: null
	cancel_at: null
	cancel_at_period_end: boolean
	canceled_at: null
	cancellation_details: {
		comment: null | string
		feedback: null | string
		reason: null | string
	}
	collection_method: string
	created: number
	currency: string
	current_period_end: number

	current_period_start: number
	customer: string
	days_until_due: null
	default_payment_method: number
	default_source: null
	default_tax_rates: []
	description: null
	discount: null
	ended_at: null
	items: {
		object: string
		data: {
			id: string
			object: string
			billing_thresholds: null
			created: number
			metadata: {}
			plan: {
				id: string
				object: string
				active: boolean
				aggregate_usage: null
				amount: number
				amount_decimal: string
				billing_scheme: string
				created: number
				currency: string
				interval: string
				interval_count: number
				livemode: boolean
				metadata: {}
				nickname: null | string
				product: string
				tiers_mode: null
				transform_usage: null
				trial_period_days: null
				usage_type: string
			}
			price: {
				id: string
				object: string
				active: boolean
				billing_scheme: string
				created: number
				currency: string
				custom_unit_amount: null
				livemode: boolean
				lookup_key: string
				metadata: {}
				nickname: null | string
				product: string
				recurring: {
					aggregate_usage: null
					interval: string
					interval_count: number
					trial_period_days: null
					usage_type: string
				}
				tax_behavior: string
				tiers_mode: null
				transform_quantity: null
				type: string
				unit_amount: number
				unit_amount_decimal: string
			}
			quantity: number
			subscription: string
			tax_rates: []
		}[]
		has_more: boolean
		total_count: number
		url: string
	}
	latest_invoice: string
	livemode: boolean
	metadata: {}
	next_pending_invoice_item_invoice: null
	on_behalf_of: null
	pause_collection: null
	payment_settings: {
		payment_method_options: null
		payment_method_types: null
		save_default_payment_method: null
	}
	pending_invoice_item_interval: null
	pending_setup_intent: null
	pending_update: null

	plan: {
		id: string
		object: string
		active: boolean
		aggregate_usage: null
		amount: number
		amount_decimal: number
		billing_scheme: string
		created: number
		currency: string
		interval: string
		interval_count: number
		livemode: boolean
		metadata: {}
		nickname: null | string
		product: string
		tiers_mode: null
		transform_usage: null
		trial_period_days: null
		usage_type: string
	}
	quantity: number
	schedule: null
	start_date: number
	status: string
	test_clock: null
	transfer_data: null
	trial_end: null
	trial_settings: {
		end_behavior: { missing_payment_method: string }
	}
	trial_start: null
}
export type SubscriptionData = {
	id: string
	client_reference_id: string
	subscription: ISubscription
}
const useGetSubscription = () => {
	const { getToken } = useAuth()
	const { organizationsMetadata } = useAppStore()

	return useQuery<SubscriptionData>({
		queryFn: async () => {
			const token = await getToken()
			if (
				!token ||
				!organizationsMetadata ||
				!organizationsMetadata?.billing?.plan?.stripe_session_id
			) {
				throw new AxiosError("Unauthenticated")
			}

			return getSubscription({
				token,
				subscriptionId:
					organizationsMetadata?.billing?.plan?.stripe_session_id || "",
			})
		},
		queryKey: [
			"subscription",
			organizationsMetadata?.billing?.plan?.stripe_session_id,
			organizationsMetadata?.org_id,
		],
		refetchOnWindowFocus: false,
		refetchOnMount: false,
		structuralSharing: false,
	})
}

export default useGetSubscription
