import useGetPlans from "../api/useGetPlans"
import { Grid } from "@mantine/core"
import { Periods } from "../../../../types/pricing.types"
import { useMemo } from "react"
import PricingCard from "./PricingCard"
import { SubscriptionData } from "../../../../hooks/useGetSubscription"

const PricingTable = ({
	period,
	subscriptionData,
	subscriptionLoading,
}: {
	period: Periods
	subscriptionLoading: boolean
	subscriptionData: SubscriptionData | undefined
}) => {
	const { isLoading, data } = useGetPlans()

	const periodPlans = useMemo(() => {
		if (!data) {
			return []
		}

		switch (period) {
			case "yearly":
				return data
					.filter(
						p =>
							p.lookup_key.includes(period) ||
							p.lookup_key.includes("annual")
					)
					.sort((a, b) => +a.unit_amount - +b.unit_amount)
			case "monthly":
				return data
					.filter(p => p.lookup_key.includes(period))
					.sort((a, b) => +a.unit_amount - +b.unit_amount)
		}
	}, [period, data])

	const activePlan = useMemo(() => {
		if (!subscriptionData) {
			return null
		}

		const plan = subscriptionData.subscription.items.data.find(
			p => !p.price.lookup_key.includes("payg")
		)

		if (!plan) {
			return null
		} else {
			return {
				id: plan.price.id,
				lookup_key: plan.price.lookup_key,
				unit_amount: plan.price.unit_amount,
				currency: plan.price.currency,
			}
		}
	}, [subscriptionData])
	return (
		<Grid
			w='100%'
			align='stretch'
		>
			<Grid.Col span={4}>
				<PricingCard
					pricing={periodPlans[0]}
					loading={isLoading || subscriptionLoading}
					active={Boolean(
						activePlan &&
							periodPlans[0] &&
							activePlan.unit_amount === +periodPlans[0]?.unit_amount
					)}
				/>
			</Grid.Col>
			<Grid.Col span={4}>
				<PricingCard
					pricing={periodPlans[1]}
					loading={isLoading || subscriptionLoading}
					active={Boolean(
						activePlan &&
							periodPlans[1] &&
							activePlan.unit_amount === +periodPlans[1]?.unit_amount
					)}
					popular
				/>
			</Grid.Col>
			<Grid.Col span={4}>
				<PricingCard
					pricing={periodPlans[2]}
					loading={isLoading || subscriptionLoading}
					active={Boolean(
						activePlan &&
							periodPlans[2] &&
							activePlan.unit_amount === +periodPlans[2]?.unit_amount
					)}
				/>
			</Grid.Col>
		</Grid>
	)
}

export default PricingTable
