import { useMutation } from "@tanstack/react-query"
import { inviteTeamMembers } from "../../../../../api/server/teams/invite-members"

const useInviteMembers = ({
	successFn,
	errorFn,
}: {
	successFn: () => void
	errorFn: () => void
}) => {
	return useMutation({
		mutationFn: inviteTeamMembers,
		onSuccess: () => {
			successFn()
		},
		onError: () => {
			errorFn()
		},
	})
}

export default useInviteMembers
