import { NumberDictionary, adjectives, names, uniqueNamesGenerator } from "unique-names-generator";

export async function generateHostname() {

	let environment = '';
	const numberDictionary = NumberDictionary.generate({ min: 100, max: 9999 });
	const characterName: string = uniqueNamesGenerator({
		dictionaries: [adjectives, names, numberDictionary],
		length: 3,
		separator: '-',
	});

	switch (process.env.REACT_APP_ENVIRONMENT) {
		case 'development':
			environment = 'apps-dev';
			break;
		case 'staging':
			environment = 'apps-staging';
			break;
		case 'production':
			//hack to enable staging name generation under prod env in local dev.
			environment = window.location.hostname != "localhost" ? 'apps' : 'apps-staging';
			break;
		default:
			throw new Error('Invalid environment');
	}
	const hostname = `${characterName}.${environment}.resilis.net`
	return hostname.toLocaleLowerCase();
}
