import { notificationsAxiosConfig } from "../../../config/axios.config"

export async function getNotificationPreferences({
	token,
	subscriberId,
}: {
	subscriberId: string
	token: string
}) {
	const response = await notificationsAxiosConfig.get(
		`/subscriber/preferences/${subscriberId}`,
		{
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		}
	)

	const { data } = response
	return data
}
