import CustomDrawer from "../Drawer/Drawer"
import useAppState from "../../zustand/new-store"
import {
	Box,
	Button,
	Center,
	Collapse,
	Group,
	Stack,
	Text,
	ThemeIcon,
	rem,
} from "@mantine/core"
import { IconBellOff, IconX } from "@tabler/icons-react"
import { useMemo, useState } from "react"
import { useAuth } from "@clerk/clerk-react"
import useConfigureNotifs from "./api/useConfigureNotifs"
import { notifications } from "@mantine/notifications"
import isToday from "dayjs/plugin/isToday"
import isYesterday from "dayjs/plugin/isYesterday"
import dayjs from "dayjs"
import { useNotification } from "../../context/NotificationsProvider"
dayjs.extend(isToday)
dayjs.extend(isYesterday)

const NotificationDrawer = () => {
	const { orgId, getToken } = useAuth()
	const {
		notifications: novuNotifs,
		markNotificationsAsRead,
		hasMore,
		loading,
		pageNum,
		setPage,
	} = useNotification()
	const [active, setActive] = useState("")

	const {
		mutate: configure,
		supabaseLoading,
		setSupabaseLoading,
		isLoading,
	} = useConfigureNotifs()

	const {
		setNotificationDrawerOpen,
		notificationDrawerOpen,
		organizationsMetadata,
		activeWorkspace,
		cacheConfig,
	} = useAppState()

	const organizationOwner = useMemo(() => {
		return organizationsMetadata?.users.find(
			user => user.user_id === organizationsMetadata.user_id
		)
	}, [organizationsMetadata?.user_id, organizationsMetadata?.users.length])

	const workspaceAlertsConfigured = useMemo(() => {
		if (
			organizationsMetadata &&
			organizationsMetadata.alerts &&
			organizationsMetadata.alerts &&
			activeWorkspace
		) {
			const workspaceAlert = organizationsMetadata.alerts.find(
				alert => alert.workspace_id === activeWorkspace?.workspace_id
			)

			return !!workspaceAlert?.configured
		} else {
			return false
		}
	}, [activeWorkspace?.workspace_id, organizationsMetadata?.alerts])
	return (
		<CustomDrawer
			opened={notificationDrawerOpen}
			onClose={() => {
				setNotificationDrawerOpen(false)
				setPage(0)
			}}
			title={
				workspaceAlertsConfigured && novuNotifs.length > 0
					? "Notifications"
					: ""
			}
		>
			{workspaceAlertsConfigured ? (
				novuNotifs.length > 0 ? (
					<Stack
						mt={rem(20)}
						mih={`calc(100vh - ${rem(140)})`}
						sx={{
							justifyContent: "space-between",
						}}
						w='100%'
					>
						<Stack
							sx={{
								gap: rem(12),
							}}
						>
							{novuNotifs?.map(notif => {
								const { _id, createdAt, read, payload } = notif

								const time = dayjs(createdAt).isToday()
									? "Today"
									: dayjs(createdAt).isYesterday()
									? "Yesterday"
									: dayjs(createdAt).format("MMM. D")
								return (
									<Stack
										key={_id}
										p={rem(16)}
										sx={theme => ({
											gap: rem(12),
											background: !read ? "#F9F9FF" : theme.white,
											borderRadius: rem(8),
											cursor: "pointer",
										})}
										h='fit-content'
										onClick={() => {
											if (!read) {
												markNotificationsAsRead([_id])
											}

											if (active === _id) {
												setActive("")
											} else {
												setActive(_id)
											}
										}}
									>
										<Group
											sx={theme => ({
												justifyContent: "space-between",
											})}
											align='center'
										>
											<Text
												lts={-0.2}
												c='brand.7'
												fw={400}
												fz={rem(16)}
												sx={{
													overflow: "hidden",
													textOverflow: "ellipsis",
													whiteSpace: "nowrap",
												}}
												maw={rem(222)}
											>
												{payload?.title as string}
											</Text>
											<Group
												sx={{
													gap: rem(4),
												}}
											>
												<Text
													fz={rem(12)}
													fw={400}
													lh={rem(20)}
													c='refactor.4'
												>
													{time}
												</Text>
												<Center>
													{read ? null : (
														<Box
															bg='brand.2'
															h={rem(6)}
															w={rem(6)}
															sx={theme => ({
																borderRadius: "50%",
															})}
														/>
													)}
												</Center>
											</Group>
										</Group>

										<Collapse in={active === _id}>
											<Text
												c='refactor.4'
												fz={rem(14)}
												fw={400}
												lh={rem(20)}
											>
												{payload?.summary as string}
											</Text>
										</Collapse>
									</Stack>
								)
							})}
						</Stack>

						{!hasMore && pageNum === 0 ? null : (
							<Group
								w='100%'
								pb={rem(12)}
								pt={rem(12)}
								sx={{
									justifyContent: "flex-end",
									gap: rem(12),
								}}
							>
								<Button
									disabled={pageNum === 0 || loading}
									h={rem(32)}
									onClick={() => {
										if (pageNum === 0) {
											return
										}
										setActive("")
										setPage(pageNum - 1)
									}}
									sx={{
										letterSpacing: -0.2,
										lineHeight: rem(16),
										fontWeight: 500,
										borderRadius: rem(8),
										fontSize: rem(12),
									}}
								>
									Previous
								</Button>
								<Button
									disabled={!hasMore || loading}
									h={rem(32)}
									sx={{
										letterSpacing: -0.2,
										lineHeight: rem(16),
										fontWeight: 500,
										borderRadius: rem(8),
										fontSize: rem(12),
									}}
									onClick={() => {
										if (!hasMore) {
											return
										}
										setActive("")

										setPage(pageNum + 1)
									}}
								>
									Next
								</Button>
							</Group>
						)}
					</Stack>
				) : (
					<Stack
						mt={rem(160)}
						sx={{ gap: 0 }}
						w='100%'
					>
						<Center>
							<ThemeIcon
								sx={theme => ({
									border: "none",
									color: theme.colors["refactor"][4],
								})}
								variant='outline'
								size={rem(40)}
							>
								<IconBellOff size={rem(40)} />
							</ThemeIcon>
						</Center>
						<Stack
							mt={rem(24)}
							sx={{
								gap: rem(8),
							}}
						>
							<Text
								ta='center'
								fz={rem(18)}
								fw={500}
								lh={rem(24)}
								c='#030124'
							>
								No notifications at the moment.
							</Text>
						</Stack>
					</Stack>
				)
			) : (
				<Stack
					mt={rem(160)}
					sx={{ gap: 0 }}
					w='100%'
				>
					<Center>
						<ThemeIcon
							sx={theme => ({
								border: "none",
								color: theme.colors["refactor"][4],
							})}
							variant='outline'
							size={rem(40)}
						>
							<IconBellOff size={rem(40)} />
						</ThemeIcon>
					</Center>
					<Stack
						mt={rem(24)}
						sx={{
							gap: rem(8),
						}}
					>
						<Text
							ta='center'
							fz={rem(18)}
							fw={500}
							lh={rem(24)}
							c='#030124'
						>
							No notifications at the moment.
						</Text>

						<Text
							ta='center'
							fz={rem(16)}
							fw={400}
							lh={rem(24)}
							sx={theme => ({
								color: theme.colors["refactor"][4],
							})}
						>
							Set up and configure alerts to stay updated on critical
							events.
						</Text>
					</Stack>

					<Button
						type='button'
						h={rem(48)}
						mt={rem(40)}
						radius='md'
						onClick={async () => {
							const token = await getToken()
							if (workspaceAlertsConfigured) {
								notifications.show({
									withCloseButton: true,
									color: "danger.0",
									title: "Something went wrong",
									icon: <IconX />,
									autoClose: 5000,
									message: "You have already configured notifications",
								})
								return
							}

							if (
								!activeWorkspace ||
								!organizationsMetadata ||
								!orgId ||
								!token ||
								!cacheConfig ||
								!organizationOwner
							) {
								notifications.show({
									withCloseButton: true,
									color: "danger.0",
									title: "Something went wrong",
									icon: <IconX />,
									autoClose: 5000,
									message: "Missing config",
								})
								return
							}
							setSupabaseLoading(true)
							configure({
								token,
								email: organizationOwner.email,
								subscriberId: cacheConfig.host,
							})
						}}
						sx={{
							letterSpacing: -0.2,
							lineHeight: rem(16),
							fontWeight: 500,
							fontSize: rem(14),
						}}
						disabled={isLoading || supabaseLoading}
						loading={isLoading || supabaseLoading}
					>
						Configure Alerts
					</Button>
				</Stack>
			)}
		</CustomDrawer>
	)
}

export default NotificationDrawer
