import { useEffect, useState } from "react"

export const usePaginationData = <T,>(
	data: T[],
	currentPage: number,
	hitsPerPage: number
) => {
	const [currentPageData, setCurrentPageData] = useState<T[]>([])
	const [unprocessedPageData, setUnprocessedPageData] = useState<T[]>(data)

	useEffect(() => {
		setUnprocessedPageData(data)
	}, [data])
	useEffect(() => {
		if (unprocessedPageData.length <= 0) {
			setCurrentPageData([])
			return
		}
		const firstItem = currentPage * hitsPerPage - hitsPerPage
		const lastItem = currentPage * hitsPerPage

		if (firstItem === lastItem) {
			setCurrentPageData(unprocessedPageData.slice(lastItem))
		} else {
			setCurrentPageData(unprocessedPageData.slice(firstItem, lastItem))
		}
	}, [currentPage, unprocessedPageData])

	const totalPages =
		data.length === 0
			? 0
			: Math.ceil(unprocessedPageData.length / hitsPerPage)
	return {
		totalPages,
		currentPageData,
		currentPage,
		hitsPerPage: hitsPerPage,
		setCurrentPageData,
		setUnprocessedPageData,
	}
}
