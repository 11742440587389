import { useMutation } from "@tanstack/react-query"
import { checkout } from "../../../../api/server/billing/checkout"
import { AxiosError } from "axios"
import { axiosErrorParser } from "../../../../lib/parse-axios-error"
import { notifications } from "@mantine/notifications"
import { IconX } from "@tabler/icons-react"

const useCheckout = () => {
	return useMutation({
		mutationFn: checkout,
		onError(error: AxiosError) {
			const errorMessage = axiosErrorParser(error)
			if (typeof errorMessage === "string") {
				notifications.show({
					withCloseButton: true,
					color: "danger.0",
					title: "Something went wrong",
					icon: <IconX />,
					autoClose: 5000,
					message: errorMessage,
				})
			} else {
				if (errorMessage instanceof Array) {
					notifications.show({
						withCloseButton: true,
						color: "danger.0",
						title: "Something went wrong",
						icon: <IconX />,
						autoClose: 5000,
						message: errorMessage[0],
					})
				} else {
					notifications.show({
						withCloseButton: true,
						color: "danger.0",
						title: "Something went wrong",
						icon: <IconX />,
						autoClose: 5000,
						message: errorMessage.error,
					})
				}
			}
		},
		onSuccess(data: { url: string }) {
			console.log(data)
			window.location.href = data.url
		},
	})
}

export default useCheckout
