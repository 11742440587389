import {
	Box,
	Button,
	Group,
	Stack,
	Text,
	createStyles,
	rem,
} from "@mantine/core"
import CustomTextInput from "../../../../Components/TextInput/TextInput"
import * as z from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import {
	serviceAuthSchema,
	type serviceAuthSchemaType,
} from "../../../../lib/parser"
import { useForm, SubmitHandler, FormProvider } from "react-hook-form"
import { useState } from "react"
import { CacheConfig, ConfigSupabaseMetadata } from "../../../../lib/parser"
import useAppStore from "../../../../zustand/new-store"
import { useAuth } from "@clerk/clerk-react"
import DrawerError from "../../../../Components/DrawerError/DrawerError"
import DrawerSuccess from "../../../../Components/DrawerSuccess/DrawerSuccess"
import { basePathRegex, urlPattern } from "../../../../constants/regex"
import { uploadCustomConfigToSupabase } from "../../../../api/supabase/upload-custom"
import CustomSelect from "../../../../Components/Select/Select"
import CustomDrawer from "../../../../Components/Drawer/Drawer"
import { useDisclosure } from "@mantine/hooks"
import AuthMethod from "../AuthMethod"
import { IconCornerUpLeft, IconHelpCircleFilled } from "@tabler/icons-react"
import CustomHoverCard from "../../../../Components/CustomHoverCard/CustomHoverCard"
const manualSchema = z.object({
	url: z
		.string()
		.min(1, { message: "Origin host is required" })
		.regex(urlPattern, { message: "Origin host must be a valid url" }),
	basePath: z
		.string()
		.min(1, { message: "Base path is required" })
		.regex(basePathRegex, {
			message: "Base path must be a valid url path",
		})
		.startsWith("/", { message: "Base path must begin with /" }),
	protocol: z.enum(["REST", "JSON-RPC"]),
})

type ManualFormType = z.infer<typeof manualSchema>
const useStyles = createStyles(theme => ({
	cornerIcon: {
		stroke: theme.colors["refactor"][0],
	},
	mt: {
		marginTop: rem(16),
	},
}))

export default function ManualForm({
	opened,
	close,
}: {
	opened: boolean
	open: () => void
	close: () => void
	onError: () => void
}) {
	const { classes } = useStyles()

	const [serverSideJSONErrors, setServerSideValidationErrors] = useState<
		string[]
	>([])
	const [current, setCurrent] = useState<"local" | "auth">("auth")
	const [success, setSuccess] = useState(false)
	const [hideTitle, setHideTitle] = useState(false)
	const [isUploading, setIsUploading] = useState(false)
	const [supabaseResult, setSupabaseResult] = useState<
		(CacheConfig & ConfigSupabaseMetadata) | null
	>(null)
	const { activeWorkspace, setCacheConfig, setCacheConfigSrc } = useAppStore()
	const { userId, orgId, getToken } = useAuth()
	const [title, setTitle] = useState("Choose Your Authorization Method")

	const {
		handleSubmit,
		control,
		reset: resetInputFields,
		formState,
		watch,
	} = useForm<ManualFormType>({
		resolver: zodResolver(manualSchema),
		defaultValues: {
			url: "",
			basePath: "",
			protocol: undefined,
		},
		mode: "all",
	})

	const authMethods = useForm<serviceAuthSchemaType>({
		resolver: zodResolver(serviceAuthSchema),
		defaultValues: {
			key: "",
		},
		mode: "all",
	})
	const [openAuth, { toggle: toggleAuth }] = useDisclosure(false)
	const uploadCustomToSupabase = async (
		values: Partial<CacheConfig> & {
			user_id: string
			org_id: string
			source: string
			workspace_id: string
			source_url: string | null
		}
	) => {
		try {
			const result = await uploadCustomConfigToSupabase(getToken, values)
			if (result.data) {
				return { data: result.data, error: null }
			} else {
				return { data: null, error: result.data || result.error }
			}
		} catch (error) {
			console.log(error)
			console.log(JSON.stringify(error, null, 2))

			return { data: null, error }
		}
	}

	const submitHandler: SubmitHandler<ManualFormType> = async values => {
		setSupabaseResult(null)
		setServerSideValidationErrors([])
		setIsUploading(true)

		const host = ""
		const basePath = values.basePath
		const origins = [values.url]
		const workspace_id = activeWorkspace?.workspace_id || ""
		const source = "local"
		const user_id = userId || ""
		const org_id = orgId || ""
		const source_url = null
		const auth = authMethods.watch()
		const authorization =
			!!auth.type && openAuth
				? {
						type: auth.type,
						value: auth.key || "",
				  }
				: null
		const request: Partial<CacheConfig> & {
			user_id: string
			org_id: string
			source: string
			workspace_id: string
			source_url: string | null
		} = {
			user_id,
			org_id,
			workspace_id,
			source_url,
			source,
			host,
			origins,
			basePath,
			protocol: values.protocol,
			authorization,
		}

		const { data, error } = await uploadCustomToSupabase(request)

		if (data) {
			setSupabaseResult(data)
			setHideTitle(true)
			resetInputFields()
			setSuccess(true)
		}
		if (error) {
			console.log(error)
			setHideTitle(true)
			setServerSideValidationErrors(["Something went wrong"])
			resetInputFields()
		}
		setIsUploading(false)
	}

	return (
		<>
			<CustomDrawer
				opened={opened}
				onClose={() => {
					if (success) {
						setCacheConfig(supabaseResult)
						setCacheConfigSrc("local")
						setSuccess(false)
					}
					if (openAuth) {
						toggleAuth()
					}
					close()
					authMethods.reset({
						key: "",
					})
					setCurrent("auth")
				}}
				backButtonContent={
					current !== "auth" && !hideTitle ? (
						<Group
							align='center'
							sx={{ gap: rem(8) }}
						>
							<IconCornerUpLeft className={classes.cornerIcon} />
							<Text
								sx={theme => ({
									fontSize: rem(14),
									lineHeight: rem(16),
									letterSpacing: -0.2,
									color: theme.colors["refactor"][0],
								})}
							>
								Back to {current === "local" ? "Auth" : "Setup"}
							</Text>
						</Group>
					) : null
				}
				backButtonAction={() => {
					if (current === "auth") {
						return
					}

					if (current === "local") {
						setCurrent("auth")
						setTitle("Choose Your Authorization Method")
					}
				}}
				title={hideTitle ? undefined : title}
			>
				{serverSideJSONErrors.length > 0 ? (
					<DrawerError
						errors={serverSideJSONErrors}
						btnAction={() => {
							setHideTitle(false)
							setServerSideValidationErrors([])
							authMethods.reset()
							if (openAuth) {
								toggleAuth()
							}
						}}
						title='Configuration Incomplete'
						subtext='We encountered issues while applying your custom configurations to Resilis. Below are the detected problems.'
					/>
				) : success ? (
					<DrawerSuccess
						title='Configuration Successful!'
						subtext='Your API have been successfully configured with Resilis. Get started.'
						btnAction={() => {
							setCacheConfig(supabaseResult)
							setCacheConfigSrc("local")
						}}
						btnText='Go to Dashboard'
					/>
				) : (
					<>
						{current === "auth" ? (
							<FormProvider {...authMethods}>
								<AuthMethod
									openAuth={openAuth}
									toggleAuth={toggleAuth}
									next={() => {
										setCurrent("local")
										setTitle("Manual Form")
									}}
								/>
							</FormProvider>
						) : null}
						{current === "local" ? (
							<Stack
								sx={{ gap: rem(24) }}
								mt={rem(24)}
								w='100%'
							>
								<Text
									fw={400}
									lh={rem(24)}
									fz={rem(16)}
									sx={theme => ({
										color: theme.colors["refactor"][4],
									})}
								>
									Tell us about your API server.
								</Text>
								<Box
									component='form'
									display='flex'
									sx={{
										gap: rem(24),
										flexDirection: "column",
									}}
									onSubmit={handleSubmit(submitHandler)}
								>
									<CustomTextInput
										name='url'
										placeholder='Origin domain (e.g: api.disco.com)'
										label={
											<Group spacing={rem(8)}>
												<Text>
													Origin domain (e.g: api.disco.com)
												</Text>
												<CustomHoverCard
													target={
														<IconHelpCircleFilled
															size={rem(16)}
														/>
													}
													body={[
														{
															text: "Enter the domain name of your API's origin server.",
														},
													]}
													width={380}
													position='top'
													spacing={8}
												/>
											</Group>
										}
										control={control}
										errorMessage={formState.errors.url?.message}
										disabled={isUploading}
									/>

									<CustomTextInput
										name='basePath'
										placeholder='Base Path (e.g: /api/v1)'
										className={
											formState.errors.url?.message ? classes.mt : ""
										}
										label={
											<Group spacing={rem(8)}>
												<Text>Base Path (e.g: /api/v1)</Text>
												<CustomHoverCard
													target={
														<IconHelpCircleFilled
															size={rem(16)}
														/>
													}
													body={[
														{
															text: "Enter the base path for your API endpoints. This is the initial part of the URL path used to access your API, such as /api/v1.",
														},
													]}
													width={380}
													position='top'
													spacing={8}
												/>
											</Group>
										}
										control={control}
										errorMessage={formState.errors.basePath?.message}
										disabled={isUploading}
									/>
									<CustomSelect
										name='protocol'
										control={control}
										placeholder='Protocol'
										mt={
											formState.errors.basePath?.message
												? rem(16)
												: 0
										}
										label={
											<Group spacing={rem(8)}>
												<Text>Protocol</Text>
												<CustomHoverCard
													target={
														<IconHelpCircleFilled
															size={rem(16)}
														/>
													}
													body={[
														{
															text: "Select the API communication protocol. This defines how clients interact with your API and how data is transmitted.",
														},
													]}
													width={380}
													position='top'
													spacing={8}
												/>
											</Group>
										}
										disabled={isUploading}
										keepLabel={!!watch("protocol")}
										data={[
											{ value: "REST", label: "REST" },
											{ value: "JSON-RPC", label: "JSON-RPC" },
										]}
										errorMessage={formState.errors.protocol?.message}
									/>

									<Button
										variant='filled'
										fullWidth
										h={rem(48)}
										type='submit'
										mt={
											Boolean(formState.errors.protocol?.message)
												? rem(24)
												: 0
										}
										disabled={
											isUploading ||
											!watch("basePath") ||
											!watch("url") ||
											!!formState.errors.basePath?.message ||
											!!formState.errors.url?.message ||
											(openAuth &&
												(!!authMethods.formState.errors?.key ||
													!!authMethods.formState.errors?.type))
										}
										loading={isUploading}
										sx={{
											borderRadius: rem(8),
											fontWeight: 500,
											lineHeight: rem(16),
											letterSpacing: -0.2,
										}}
									>
										Setup
									</Button>
								</Box>
								<Box
									p={rem(12)}
									sx={{
										borderRadius: "4px",
										background: "#F7F5FF",
									}}
								>
									<Text
										fw={400}
										lh={rem(20)}
										fz={rem(14)}
										c='#262626'
									>
										Note: You can also set up more API services later.
									</Text>
								</Box>
							</Stack>
						) : null}
					</>
				)}
			</CustomDrawer>
		</>
	)
}
