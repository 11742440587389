import axios from "axios"

const baseURL = process.env.REACT_APP_API_BASE_URL
const analyticsURL = process.env.REACT_APP_ANALYTICS_BASE_URL
const notificationsBaseURL = process.env.REACT_APP_NOTIFICATIONS_BASE_URL

export const axiosConfig = axios.create({
	baseURL,
	headers: {
		"Content-Type": "application/json",
	},
})

export const notificationsAxiosConfig = axios.create({
	baseURL: notificationsBaseURL,
	headers: {
		"Content-Type": "application/json",
	},
})

export const analyticsAxiosConfig = axios.create({
	baseURL: analyticsURL,
	headers: {
		"Content-Type": "application/json",
	},
})
