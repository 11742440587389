import CustomDrawer from "../../../../Components/Drawer/Drawer"
import useAppState from "../../../../zustand/new-store"
import { useState } from "react"
import { Box, Button, Stack, Text, rem } from "@mantine/core"
import DrawerSuccess from "../../../../Components/DrawerSuccess/DrawerSuccess"
import { SubmitHandler, useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import * as z from "zod"
import CustomTextInput from "../../../../Components/TextInput/TextInput"
import { useAuth, useOrganization } from "@clerk/clerk-react"
import CustomSelect from "../../../../Components/Select/Select"
import DrawerError from "../../../../Components/DrawerError/DrawerError"
import useInviteMembers from "./api/useInviteMembers"

const memberSchema = z.object({
	emailAddress: z
		.string()
		.min(1, { message: "Email is required" })
		.email({ message: "Input must be an email" }),
	role: z.enum(["admin", "basic_member", ""], {
		required_error: "Role is required",
		invalid_type_error: "Role must be either Admin or Basic Member",
	}),
})

export type InviteMemberType = z.infer<typeof memberSchema>

const TeamsDrawer = () => {
	const { teamsDrawerOpen, setTeamsDrawerOpen } = useAppState()
	const [success, setSuccess] = useState(false)
	const [error, setError] = useState<string>()
	const { handleSubmit, control, formState, getValues, reset } =
		useForm<InviteMemberType>({
			resolver: zodResolver(memberSchema),
			defaultValues: {
				emailAddress: "",
				role: "",
			},
		})

	const { mutate, isLoading } = useInviteMembers({
		successFn: () => {
			setSuccess(true)
			setError("")
			reset()
		},
		errorFn: () => {
			setSuccess(false)
			setError("Something went wrong")
			reset()
		},
	})
	const { getToken } = useAuth()
	const { organization } = useOrganization()
	organization

	const submitHandler: SubmitHandler<InviteMemberType> = async values => {
		setError("")
		const token = await getToken()
		if (!token) {
			return
		}
		mutate({
			token,
			email: values.emailAddress,
			role: values.role,
		})
	}

	return (
		<CustomDrawer
			opened={teamsDrawerOpen}
			onClose={() => {
				reset()
				setError("")
				setSuccess(false)
				setTeamsDrawerOpen(false)
			}}
			title={success || error ? "" : "Invite New Member"}
		>
			{error ? (
				<DrawerError
					errors={[]}
					btnAction={() => {
						setError("")
					}}
					title='Invitation Failed'
					subtext={`Oops! There was an issue sending the invite to ${getValues(
						"emailAddress"
					)}. Try again.`}
				/>
			) : success ? (
				<DrawerSuccess
					title='Invitation Sent Successfully!'
					subtext={`Your invitation to ${getValues(
						"emailAddress"
					)} has been dispatched. Awaiting their response`}
					btnAction={() => {
						reset()
						setError("")
						setSuccess(false)
						setTeamsDrawerOpen(false)
					}}
					btnText='Done'
				/>
			) : (
				<Stack
					sx={{ gap: rem(24) }}
					mt={rem(24)}
					w='100%'
				>
					<Text
						fw={400}
						lh={rem(24)}
						fz={rem(16)}
						sx={theme => ({ color: theme.colors["refactor"][4] })}
					>
						Invite team members to collaborate, share insights, and
						enhance productivity.
					</Text>
					<Box
						component='form'
						display='flex'
						sx={{
							gap: rem(24),
							flexDirection: "column",
						}}
						onSubmit={handleSubmit(submitHandler)}
					>
						<CustomTextInput
							name='emailAddress'
							placeholder='Email Address'
							label='Email Address'
							control={control}
							errorMessage={formState.errors.emailAddress?.message}
							disabled={isLoading}
						/>
						<CustomSelect
							name='role'
							placeholder='Role'
							label='Role'
							control={control}
							data={[
								{ label: "Admin", value: "admin" },
								{ label: "Basic Member", value: "basic_member" },
							]}
							allowDeselect
							disabled={isLoading}
							mt={
								Boolean(formState.errors.emailAddress?.message)
									? rem(24)
									: 0
							}
							errorMessage={formState.errors.role?.message}
						/>
						<Button
							variant='filled'
							fullWidth
							h={rem(48)}
							type='submit'
							mt={Boolean(formState.errors.role?.message) ? rem(24) : 0}
							disabled={isLoading}
							loading={isLoading}
							sx={{
								borderRadius: rem(8),
								fontWeight: 500,
								lineHeight: rem(16),
								letterSpacing: -0.2,
							}}
						>
							Invite Member
						</Button>
					</Box>
				</Stack>
			)}
		</CustomDrawer>
	)
}

export default TeamsDrawer
