import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { BrowserRouter, useNavigate } from "react-router-dom"
import { ClerkProvider } from "@clerk/clerk-react"
import { MantineProvider } from "@mantine/core"
import { Router } from "./router/Router"
import { CustomFonts } from "./assets/fonts/CustomFonts"
import { Notifications } from "@mantine/notifications"
import ErrorBoundary from "./Components/ErrorBoundary/ErrorBoundary"
if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
	throw new Error("Missing Publishable Key")
}

if (!process.env.REACT_APP_STRIPE_KEY) {
	throw new Error("Missing STRIPE_KEY")
}

if (!process.env.REACT_APP_SUPABASE_URL) {
	throw new Error("Missing SUPABASE_URL")
}

if (!process.env.REACT_APP_SUPABASE_KEY) {
	throw new Error("Missing SUPABASE_KEY")
}

if (!process.env.REACT_APP_STRIPE_BILLING_URL) {
	throw new Error("Missing BILLING_URL")
}

if (!process.env.REACT_APP_API_BASE_URL) {
	throw new Error("Missing API_BASE_URL")
}
if (!process.env.REACT_APP_ANALYTICS_BASE_URL) {
	throw new Error("Missing ANALYTICS_BASE_URL")
}

if (!process.env.REACT_APP_ONBOARD_BASE_URL) {
	throw new Error("Missing API_BASE_URL")
}

if (!process.env.REACT_APP_NOTIFICATIONS_BASE_URL) {
	throw new Error("Missing NOTIFICATIONS_BASE_URL")
}

if (!process.env.REACT_APP_NOVU_ENVIRONMENT_ID) {
	throw new Error("Missing NOVU_ENVIRONMENT_ID")
}
if (!process.env.REACT_APP_NOVU_APPLICATION_ID) {
	throw new Error("Missing NOVU_APPLICATION_ID")
}
const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY

export const App = () => {
	const queryClient = new QueryClient({})
	return (
		<ErrorBoundary>
			<BrowserRouter>
				<QueryClientProvider client={queryClient}>
					<ClerkProviderWithRoutes />
				</QueryClientProvider>
			</BrowserRouter>
		</ErrorBoundary>
	)
}

function ClerkProviderWithRoutes() {
	const navigate = useNavigate()
	return (
		<ClerkProvider
			publishableKey={clerkPubKey}
			navigate={to => navigate(to)}
		>
			<MantineProvider
				withGlobalStyles
				withNormalizeCSS
				theme={{
					fontFamily: "Inter, sans-serif",
					colorScheme: "light",
					black: "#54515c",
					colors: {
						// Add your color
						brand: [
							"#EBF5FF",
							"#7FFDEF",
							"#7350FF",
							"#3E3CFF",
							"#F7BF40",
							"#7FFDEF",
							"#7350FF",
							"#0E0423",
							"#5E626A",
							"#6F42C1",
						],
						gray: [
							"#f2f1f3",
							"#e5e4e7",
							"#d8d6db",
							"#cbc9cf",
							"#bdbbc3",
							"#b0aeb7",
							"#a3a0ab",
							"#96939f",
							"#898593",
							"#7c7887",
						],
						refactor: [
							"#525252",
							"#F9F8FF",
							"#EEF1F8",
							"#F5F5F5",
							"#737373",
							"#E5E5E5",
							"#D9D9D9",
							"#F8F8FF",
							"#FAFBFB",
							"#F7F6FF",
						],
						danger: [
							"#DB1B24",
							"#FFEBEC",
							"#FFEEEF",
							"#DC3545",
							"#FA4A52",
						],
						yellow: ["#F28C30", "#FF823C", "#FDF1E7", "#FD7E14"],
						green: [
							"#18B76B",
							"#20BC71",
							"#15A336",
							"#28A745",
							"#E1FFE8",
							"#20C997",
							"#1AAC66",
							"#06B856",
						],

						blue: ["#007BFF", "#EDF6FF", "#F1EAFE"],
					},
					primaryColor: "brand",
					shadows: {
						md: "1px 1px 3px rgba(0, 0, 0, .25)",
						xl: "5px 5px 3px rgba(0, 0, 0, .25)",
					},
				}}
			>
				<Notifications position='top-left' />
				<CustomFonts />
				<Router />
			</MantineProvider>
		</ClerkProvider>
	)
}
