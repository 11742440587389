import {
	Stack,
	rem,
	Text,
	Group,
	Collapse,
	Box,
	createStyles,
	MultiSelect,
	Button,
} from "@mantine/core"
import { SubmitHandler, useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import * as z from "zod"
import CustomSwitch from "../../../../Components/CustomSwitch/CustomSwitch"
import { useState, useMemo, useEffect } from "react"
import useAppStore from "../../../../zustand/new-store"
import { updateEndpoints } from "../../../../api/supabase/update-endpoints"
import { IconCheck, IconX } from "@tabler/icons-react"
import { notifications } from "@mantine/notifications"
import { useAuth } from "@clerk/clerk-react"
import { countries } from "countries-list"
import CustomTextInput from "../../../../Components/TextInput/TextInput"
import { useDocumentTitle } from "@mantine/hooks"
import CustomNumberInput from "../../../../Components/NumberInput/NumberInput"
import useUpdateAuth from "./useUpdateAuth"
import {
	serviceAuthSchema,
	serviceAuthSchemaType,
} from "../../../../lib/parser"
import CustomSelect from "../../../../Components/Select/Select"

const countryData = Object.values(countries).map(c => {
	return { label: c.name, value: c.name }
})

// @ts-ignore
const useStyles = createStyles(theme => ({
	wrapper: {
		height: "100%",
	},
	input: {
		borderRadius: rem(8),
		border: "none",

		"::placeholder": {
			color: theme.colors["refactor"][0],
		},
	},
	error: {
		paddingLeft: rem(12),
	},
	mt: {
		marginBottom: rem(24),
	},
	selectRoot: {
		flexGrow: "0 !important",
	},
}))

const rateLimitSchema = z.object({
	enabled: z.boolean({
		invalid_type_error: "Enabled is required",
		required_error: "Enabled is required",
	}),

	limit: z.coerce
		.number({
			required_error: "Limit is required",
			invalid_type_error: "Limit must be a digit",
		})
		.min(1, { message: "Limit must be between 1 and 100" })
		.max(100, { message: "Limit must be between 1 and 100" }),
	window: z.coerce
		.number({
			required_error: "Window is required",
			invalid_type_error: "Window must be a digit",
		})
		.min(1, { message: "window must be greater than 1" }),
})

type RateLimitType = z.infer<typeof rateLimitSchema>

const Security = () => {
	useDocumentTitle("Security")
	const { setCacheConfig, cacheConfig, activeWorkspace } = useAppStore()
	const { classes } = useStyles()

	const { getToken, orgId, isLoaded, userId } = useAuth()
	const strictRoute =
		cacheConfig && cacheConfig.security && cacheConfig.security.strictRouting
			? cacheConfig.security.strictRouting
			: false
	const jwt =
		cacheConfig && cacheConfig.security && cacheConfig.security.jwtVerify
			? cacheConfig.security.jwtVerify
			: false
	const geo =
		cacheConfig &&
		cacheConfig.security &&
		cacheConfig.security.geoRestrictions &&
		(cacheConfig.security.geoRestrictions.allowed.length > 0 ||
			cacheConfig.security.geoRestrictions.blocked.length > 0)
			? true
			: false

	const blocked =
		cacheConfig &&
		cacheConfig.security &&
		cacheConfig.security.geoRestrictions
			? cacheConfig?.security?.geoRestrictions?.blocked
			: []
	const allowed =
		cacheConfig &&
		cacheConfig.security &&
		cacheConfig.security.geoRestrictions
			? cacheConfig?.security?.geoRestrictions?.allowed
			: []

	const rate =
		cacheConfig &&
		cacheConfig.security &&
		cacheConfig.security.rateLimit &&
		cacheConfig.security.rateLimit.enabled
			? true
			: false

	const limit = useMemo(() => {
		return cacheConfig &&
			cacheConfig.security &&
			cacheConfig.security.rateLimit &&
			cacheConfig.security.rateLimit.limit
			? cacheConfig.security.rateLimit.limit
			: undefined
	}, [cacheConfig?.security?.rateLimit])

	const window = useMemo(() => {
		return cacheConfig &&
			cacheConfig.security &&
			cacheConfig.security.rateLimit &&
			cacheConfig.security.rateLimit.window
			? cacheConfig.security.rateLimit.window
			: undefined
	}, [cacheConfig?.security?.rateLimit])

	const auth = useMemo(() => {
		return (
			!!cacheConfig &&
			!!cacheConfig?.authorization &&
			!!cacheConfig?.authorization?.type
		)
	}, [cacheConfig?.authorization])

	const [strictRouting, setStrictRouting] = useState(strictRoute)
	const [jwtValidation, setJWTValidation] = useState(jwt)
	const [geoRestrictionEnabled, setGeoRestrictionEnabled] = useState(geo)
	const [rateLimitEnabled, setRateLimitEnabled] = useState(rate)
	const [authorizationEnabled, setAuthorizationEnabled] = useState(auth)
	const [allowedList, setAllowedList] = useState(allowed)
	const [blockedList, setBlockedList] = useState(blocked)
	const [whiteList, setWhiteList] = useState(allowed.length > 0 ? true : false)
	const [error, setError] = useState("")
	const [isLoading, setIsLoading] = useState(false)

	const authMethods = useForm<serviceAuthSchemaType>({
		resolver: zodResolver(serviceAuthSchema),
		defaultValues: {
			key: cacheConfig?.authorization?.value,
			type: cacheConfig?.authorization?.type || undefined,
			variant:
				cacheConfig?.authorization?.type === "header"
					? cacheConfig?.authorization?.value === "Authorization"
						? "Authorization"
						: "custom"
					: undefined,
		},
		mode: "all",
	})

	useEffect(() => {
		authMethods.reset({
			key: cacheConfig?.authorization?.value || "",
			type: cacheConfig?.authorization?.type || undefined,
			variant:
				cacheConfig?.authorization?.type === "header"
					? cacheConfig?.authorization?.value === "Authorization"
						? "Authorization"
						: "custom"
					: undefined,
		})
	}, [cacheConfig?.authorization])
	const variant = authMethods.watch("variant")
	useEffect(() => {
		if (authMethods.watch("variant") === "Authorization") {
			authMethods.setValue("key", "Authorization")
		}
	}, [variant])
	const updateMethods = useUpdateAuth({
		setIsLoading: loading => {
			setIsLoading(loading)
		},
		errorFn() {
			authMethods.reset({
				key: cacheConfig?.authorization?.value || "",
				type: cacheConfig?.authorization?.type || undefined,
				variant:
					cacheConfig?.authorization?.type === "header"
						? cacheConfig?.authorization?.value === "Authorization"
							? "Authorization"
							: "custom"
						: undefined,
			})
			notifications.show({
				withCloseButton: true,
				color: "danger.0",
				title: "Error",
				icon: <IconX />,
				autoClose: 5000,
				message: "Something went wrong",
			})
		},
		successFn(data) {
			authMethods.reset({
				key: data?.authorization?.value || "",
				type: data?.authorization?.type || undefined,
				variant:
					data?.authorization?.type === "header"
						? data?.authorization?.value === "Authorization"
							? "Authorization"
							: "custom"
						: undefined,
			})
			notifications.show({
				withCloseButton: true,
				color: "green.0",
				title: "Success",
				icon: <IconCheck />,
				autoClose: 5000,
				message: "Authorization settings updated successfully",
			})
		},
	})

	const { handleSubmit, control, formState, reset, watch } =
		useForm<RateLimitType>({
			resolver: zodResolver(rateLimitSchema),
			defaultValues: {
				enabled: rateLimitEnabled,
				limit,
				window: window ? window / 1000 : 0,
			},
			mode: "all",
		})

	async function update(
		field: "jwt" | "strict routing" | "geo restriction" | "rate limit"
	) {
		setIsLoading(true)

		if (field === "jwt") {
			setJWTValidation(!jwtValidation)
		} else if (field === "strict routing") {
			setStrictRouting(!strictRouting)
		} else if (field === "geo restriction") {
			setBlockedList([])
			setAllowedList([])
			setGeoRestrictionEnabled(false)
		} else if (field === "rate limit") {
			setRateLimitEnabled(false)
		}

		if (!cacheConfig || !isLoaded || !orgId || !activeWorkspace) {
			notifications.show({
				withCloseButton: true,
				color: "danger.0",
				title: "Error",
				icon: <IconX />,
				autoClose: 5000,
				message: "Endpoints not found",
			})
			setIsLoading(false)
			return
		}

		const security = cacheConfig.security
			? cacheConfig?.security
			: {
					jwtVerify: false,
					rateLimit: {
						limit: 0,
						window: 0,
						enabled: false,
					},
					strictRouting: false,
					geoRestrictions: {
						allowed: [],
						blocked: [],
					},
			  }
		if (field === "jwt") {
			security.jwtVerify = !jwtValidation
		} else if (field === "strict routing") {
			security.strictRouting = !strictRouting
		} else if (field === "geo restriction") {
			if (
				!security.geoRestrictions ||
				(security.geoRestrictions &&
					security.geoRestrictions?.allowed.length < 1 &&
					security.geoRestrictions?.blocked.length < 1)
			) {
				setIsLoading(false)
				return
			}
			security.geoRestrictions = {
				allowed: [],
				blocked: [],
			}
		} else if (field === "rate limit") {
			// @ts-ignore
			reset({ enabled: false, limit: "", window: "" })

			if (
				!security.rateLimit ||
				(security.rateLimit && !security.rateLimit.enabled)
			) {
				setIsLoading(false)
				return
			}

			security.rateLimit = {
				enabled: false,
				limit: 0,
				window: 0,
			}
		}

		try {
			const result = await updateEndpoints(getToken, {
				security,
				org_id: orgId,
				workspace_id: activeWorkspace.workspace_id,
			})
			const messages: Record<typeof field, string> = {
				jwt: "JWT validation settings updated successfully",
				"strict routing": "Strict routing settings updated successfully",
				"geo restriction": "Geo restriction settings updated successfully",
				"rate limit": "Rate Limit settings updated successfully",
			}
			if (result.data) {
				setCacheConfig(result.data)
				notifications.show({
					withCloseButton: true,
					color: "green.0",
					title: "Success",
					icon: <IconCheck />,
					autoClose: 5000,
					message: messages[field],
				})
			} else {
				console.log(result.error)
				notifications.show({
					withCloseButton: true,
					color: "danger.0",
					title: "Error",
					icon: <IconX />,
					autoClose: 5000,
					message: "Something went wrong",
				})
				if (field === "jwt") {
					setJWTValidation(jwtValidation)
				} else if (field === "strict routing") {
					setStrictRouting(strictRouting)
				} else if (field === "geo restriction") {
					setGeoRestrictionEnabled(true)
				} else if (field === "rate limit") {
					setRateLimitEnabled(true)
				}
			}
		} catch (error) {
			notifications.show({
				withCloseButton: true,
				color: "danger.0",
				title: "Error",
				icon: <IconX />,
				autoClose: 5000,
				message: "Something went wrong",
			})
			if (field === "jwt") {
				setJWTValidation(jwtValidation)
			} else if (field === "strict routing") {
				setStrictRouting(strictRouting)
			} else if (field === "geo restriction") {
				setGeoRestrictionEnabled(true)
			} else if (field === "rate limit") {
				setRateLimitEnabled(true)
			}
			console.log(error)
		} finally {
			setIsLoading(false)
		}
	}
	const arrayEqual = useMemo(() => {
		if (whiteList) {
			return (
				Array.isArray(allowedList) &&
				Array.isArray(cacheConfig?.security?.geoRestrictions?.allowed) &&
				allowedList.length ===
					cacheConfig?.security?.geoRestrictions?.allowed.length &&
				allowedList.every(
					(val, idx) =>
						val === cacheConfig?.security?.geoRestrictions?.allowed[idx]
				)
			)
		} else {
			return (
				Array.isArray(blockedList) &&
				Array.isArray(cacheConfig?.security?.geoRestrictions?.blocked) &&
				blockedList.length ===
					cacheConfig?.security?.geoRestrictions?.blocked.length &&
				blockedList.every(
					(val, idx) =>
						val === cacheConfig?.security?.geoRestrictions?.blocked[idx]
				)
			)
		}
	}, [
		cacheConfig?.security?.geoRestrictions,
		allowedList,
		blockedList,
		whiteList,
	])
	async function updateGeo() {
		setIsLoading(true)

		if (!cacheConfig || !isLoaded || !orgId || !activeWorkspace) {
			notifications.show({
				withCloseButton: true,
				color: "danger.0",
				title: "Error",
				icon: <IconX />,
				autoClose: 5000,
				message: "Endpoints not found",
			})
			setIsLoading(false)
			return
		}

		const security = cacheConfig.security
			? cacheConfig?.security
			: {
					jwtVerify: false,
					rateLimit: {
						limit: 0,
						window: 0,
						enabled: false,
					},
					strictRouting: false,
					geoRestrictions: {
						allowed: [],
						blocked: [],
					},
			  }

		if (security.geoRestrictions) {
			if (whiteList) {
				security.geoRestrictions.allowed = allowedList
				security.geoRestrictions.blocked = []
			} else {
				security.geoRestrictions.allowed = []
				security.geoRestrictions.blocked = blockedList
			}
		}

		try {
			const result = await updateEndpoints(getToken, {
				security,
				org_id: orgId,
				workspace_id: activeWorkspace.workspace_id,
			})
			if (result.data) {
				notifications.show({
					withCloseButton: true,
					color: "green.0",
					title: "Success",
					icon: <IconCheck />,
					autoClose: 5000,
					message: "Geo restriction settings updated successfully",
				})
				setCacheConfig(result.data)
			} else {
				console.log(result.error)
				notifications.show({
					withCloseButton: true,
					color: "danger.0",
					title: "Error",
					icon: <IconX />,
					autoClose: 5000,
					message: "Something went wrong",
				})
			}
		} catch (error) {
			notifications.show({
				withCloseButton: true,
				color: "danger.0",
				title: "Error",
				icon: <IconX />,
				autoClose: 5000,
				message: "Something went wrong",
			})

			console.log(error)
		} finally {
			setIsLoading(false)
		}
	}

	async function updateRate(values: RateLimitType) {
		setIsLoading(true)

		if (!cacheConfig || !isLoaded || !orgId || !activeWorkspace) {
			notifications.show({
				withCloseButton: true,
				color: "danger.0",
				title: "Error",
				icon: <IconX />,
				autoClose: 5000,
				message: "Endpoints not found",
			})
			setIsLoading(false)
			return
		}

		const security = cacheConfig.security
			? cacheConfig?.security
			: {
					jwtVerify: false,
					rateLimit: {
						limit: 0,
						window: 0,
						enabled: false,
					},
					strictRouting: false,
					geoRestrictions: {
						allowed: [],
						blocked: [],
					},
			  }
		security.rateLimit = {
			enabled: rateLimitEnabled,
			limit: values.limit,
			window: values.window,
		}
		try {
			const result = await updateEndpoints(getToken, {
				security,
				org_id: orgId,
				workspace_id: activeWorkspace.workspace_id,
			})

			if (result.data) {
				setCacheConfig(result.data)
				notifications.show({
					withCloseButton: true,
					color: "green.0",
					title: "Success",
					icon: <IconCheck />,
					autoClose: 5000,
					message: "Rate Limit settings updated successfully",
				})
			} else {
				console.log(result.error)
				notifications.show({
					withCloseButton: true,
					color: "danger.0",
					title: "Error",
					icon: <IconX />,
					autoClose: 5000,
					message: "Something went wrong",
				})
			}
		} catch (error) {
			notifications.show({
				withCloseButton: true,
				color: "danger.0",
				title: "Error",
				icon: <IconX />,
				autoClose: 5000,
				message: "Something went wrong",
			})

			console.log(error)
		} finally {
			setIsLoading(false)
		}
	}
	async function updateAuth(
		values: serviceAuthSchemaType,
		action: "enable" | "disable" = "enable"
	) {
		const token = await getToken()
		if (!token) {
			return
		}
		setIsLoading(true)

		if (!cacheConfig || !isLoaded || !orgId || !activeWorkspace) {
			notifications.show({
				withCloseButton: true,
				color: "danger.0",
				title: "Error",
				icon: <IconX />,
				autoClose: 5000,
				message: "Endpoints not found",
			})
			setIsLoading(false)
			return
		}
		let authorization = !!values.key
			? {
					type: values.type,
					value: values.key || "",
			  }
			: null

		if (authorizationEnabled && !authorization) {
			setAuthorizationEnabled(false)
			setIsLoading(false)
			return
		}

		if (action === "disable") {
			setAuthorizationEnabled(false)
			authorization = null
		}

		const dataWithSupabaseMetadata = {
			...cacheConfig,
			workspace_id: activeWorkspace?.workspace_id || "",
			user_id: userId || "",
			org_id: orgId || "",
			authorization,
		}
		if (cacheConfig.source === "local") {
			const { data, error } = await updateEndpoints(
				getToken,
				dataWithSupabaseMetadata
			)
			if (data) {
				setCacheConfig(data)
				authMethods.reset({
					key: data?.authorization?.value || "",
					type: data?.authorization?.type || undefined,
					variant:
						data?.authorization?.type === "header"
							? data?.authorization?.value === "Authorization"
								? "Authorization"
								: "custom"
							: undefined,
				})
				setIsLoading(false)
				notifications.show({
					withCloseButton: true,
					color: "green.0",
					title: "Success",
					icon: <IconCheck />,
					autoClose: 5000,
					message: "Authorization settings updated successfully",
				})
			}
			if (error) {
				console.log(error)
				setIsLoading(false)

				notifications.show({
					withCloseButton: true,
					color: "danger.0",
					title: "Error",
					icon: <IconX />,
					autoClose: 5000,
					message: "Something went wrong",
				})
			}
		} else {
			updateMethods.mutate({
				token,
				authorization,
				protocol: cacheConfig.protocol || "REST",
				host: cacheConfig.host,
				url: cacheConfig.source_url || "",
			})
		}
	}

	const submitHandler: SubmitHandler<RateLimitType> = async values => {
		values.window = Number(values.window)
		updateRate(values)
	}

	return (
		<Stack
			miw='100%'
			p={rem(32)}
			sx={theme => ({
				borderRadius: rem(8),
				border: `1px solid ${theme.colors["refactor"][2]}`,
				gap: rem(30),
			})}
		>
			<Stack
				sx={{
					flexGrow: 1,
					gap: rem(24),
				}}
			>
				<Group
					pb={rem(24)}
					sx={theme => ({
						borderBottom: ` 1px solid  ${theme.colors["refactor"][2]}`,
						gap: rem(48),
					})}
					noWrap
				>
					<Stack
						sx={{
							gap: rem(16),
						}}
						w={rem(478)}
					>
						<Text
							fw={600}
							fz={rem(16)}
							lh={rem(24)}
							c='#171717'
						>
							Strict Routing
						</Text>
						<Text
							lh={rem(22)}
							fz={rem(14)}
							fw={400}
							sx={theme => ({
								color: theme.colors["refactor"][4],
							})}
						>
							Strict Routing blocks requests to unlisted endpoints in
							your API definitions, enhancing security by minimizing
							potential attack surfaces. It prevents mis-routing, saves
							system resources, and improves security monitoring.
							Remember to keep your API definitions updated as valid
							requests to unlisted routes will also be blocked. This is
							an important part of a comprehensive security strategy
						</Text>
					</Stack>
					<Group
						sx={{ gap: rem(16), flexGrow: 1 }}
						noWrap
					>
						<CustomSwitch
							checked={strictRouting}
							onChange={() => {
								update("strict routing")
							}}
							disabled={isLoading || updateMethods.isLoading}
						/>
						<Text
							lh={rem(22)}
							fz={rem(14)}
							fw={400}
							sx={theme => ({
								color: theme.colors["refactor"][4],
								whiteSpace: "nowrap",
							})}
						>
							Enable Strict Routing
						</Text>
					</Group>
				</Group>

				<Group
					pb={rem(24)}
					sx={theme => ({
						gap: rem(48),
						borderBottom: ` 1px solid  ${theme.colors["refactor"][2]}`,
					})}
					noWrap
				>
					<Stack
						sx={{
							gap: rem(16),
						}}
						w={rem(478)}
					>
						<Text
							fw={600}
							fz={rem(16)}
							lh={rem(24)}
							c='#171717'
						>
							JWT Validation
						</Text>
						<Text
							lh={rem(22)}
							fz={rem(14)}
							fw={400}
							sx={theme => ({
								color: theme.colors["refactor"][4],
							})}
						>
							JWT Validation enforces a security measure on private or
							protected routes of your API. By checking for a valid JSON
							Web Token (JWT) in requests, this feature ensures only
							authorized entities have access to these specific
							endpoints. Any requests to private or protected routes
							without a valid JWT will be blocked, providing a layer of
							security against unauthorized data access
						</Text>
					</Stack>
					<Group
						sx={{ gap: rem(16), flexGrow: 1 }}
						noWrap
					>
						<CustomSwitch
							checked={jwtValidation}
							onChange={() => {
								update("jwt")
							}}
							disabled={isLoading || updateMethods.isLoading}
						/>
						<Text
							lh={rem(22)}
							fz={rem(14)}
							fw={400}
							sx={theme => ({
								color: theme.colors["refactor"][4],
								whiteSpace: "nowrap",
							})}
						>
							Enable JWT Validation
						</Text>
					</Group>
				</Group>
				<Group
					pb={rem(24)}
					sx={theme => ({
						gap: rem(48),
						borderBottom: ` 1px solid  ${theme.colors["refactor"][2]}`,
					})}
					noWrap
				>
					<Stack
						sx={{
							gap: rem(16),
						}}
						w={rem(478)}
					>
						<Group sx={{ justifyContent: "space-between" }}>
							<Text
								fw={600}
								fz={rem(16)}
								lh={rem(24)}
								c='#171717'
							>
								Geo Restrictions
							</Text>
							{geoRestrictionEnabled ? (
								<Box
									pt={rem(4)}
									pb={rem(4)}
									pl={rem(10)}
									pr={rem(10)}
									sx={theme => ({
										borderRadius: rem(4),
										background: whiteList
											? theme.colors["green"][4]
											: theme.colors["danger"][1],
										":empty": {
											display: "none",
										},
									})}
								>
									<Text
										fw={500}
										fz={rem(14)}
										lh={rem(16)}
										sx={theme => ({
											color: whiteList
												? theme.colors["green"][0]
												: theme.colors["danger"][0],
											fontFamily: "Outfit, sans-serif",
										})}
									>
										{whiteList ? "allowlist" : "denylist"}
									</Text>
								</Box>
							) : null}
						</Group>
						<Text
							lh={rem(22)}
							fz={rem(14)}
							fw={400}
							sx={theme => ({
								color: theme.colors["refactor"][4],
							})}
						>
							Geo Restriction Setting enables you to control access to
							your proxy services based on the geographic location of the
							user.
						</Text>
						<Collapse in={geoRestrictionEnabled}>
							<Stack sx={{ gap: rem(8) }}>
								<MultiSelect
									maw='100%'
									miw='100%'
									size='sm'
									mah={rem(200)}
									sx={theme => ({
										overflow: "auto",
										"::-webkit-scrollbar": {
											display: "none",
										},
										borderRadius: rem(8),
										paddingTop: rem(8),
										paddingBottom: rem(8),
										scrollbarWidth: "none",
										msOverflowStyle: "none",
										border: `1px solid ${theme.colors["refactor"][5]}`,
									})}
									name={whiteList ? "Allow List" : "Deny List"}
									value={whiteList ? allowedList : blockedList}
									searchable
									clearable
									error={error}
									placeholder={whiteList ? "Allow List" : "Deny List"}
									data={countryData}
									onChange={e => {
										setError("")
										if (whiteList) {
											setAllowedList(e)
										} else {
											setBlockedList(e)
										}
									}}
									classNames={{
										input: classes.input,
										wrapper: classes.wrapper,
										error: classes.error,
									}}
								/>
								<Group sx={{ justifyContent: "flex-end", gap: rem(8) }}>
									<Box
										w={rem(30)}
										h={rem(18)}
										p={rem(1)}
										pos='relative'
										sx={theme => ({
											background: whiteList
												? theme.colors[theme.primaryColor][2]
												: theme.colors["refactor"][5],
											transition: "background 0.1s ease",
											cursor:
												isLoading || updateMethods.isLoading
													? "not-allowed"
													: "pointer",
											borderRadius: rem(16),
										})}
										onClick={() => {
											setError("")
											if (
												!isLoaded ||
												isLoading ||
												updateMethods.isLoading
											) {
												return
											}

											if (whiteList) {
												setAllowedList([])
											} else {
												setBlockedList([])
											}
											setWhiteList(!whiteList)
										}}
										role='switch'
										opacity={
											isLoading || updateMethods.isLoading ? 0.7 : 1
										}
									>
										<Box
											h={rem(16)}
											w={rem(16)}
											sx={theme => ({
												background: theme.white,
												borderRadius: "50%",
												transition: "transform 0.1s ease",
												position: "relative",
												transform: whiteList
													? `translateX(${rem(12)})`
													: "initial",
											})}
										/>
									</Box>
									<Text
										lh={rem(22)}
										fz={rem(10)}
										fw={400}
										sx={theme => ({
											color: theme.colors["refactor"][4],
										})}
									>
										{whiteList ? "Deny List" : "Allow List"}
									</Text>
								</Group>
								<Button
									w='fit-content'
									h={rem(48)}
									sx={{
										borderRadius: rem(8),
									}}
									disabled={
										isLoading || arrayEqual || updateMethods.isLoading
									}
									loading={isLoading || updateMethods.isLoading}
									onClick={() => {
										if (blockedList.length < 1 && !whiteList) {
											setError(
												"Please pick countries to add to your blacklist"
											)
											return
										}

										if (allowedList.length < 1 && whiteList) {
											setError(
												"Please pick countries to add to your whitelist"
											)
											return
										}
										updateGeo()
									}}
								>
									Save Changes
								</Button>
							</Stack>
						</Collapse>
					</Stack>

					<Stack sx={{ alignItems: "flex-end" }}>
						<Group
							sx={{ gap: rem(16) }}
							noWrap
						>
							<CustomSwitch
								checked={geoRestrictionEnabled}
								onChange={() => {
									if (geoRestrictionEnabled) {
										update("geo restriction")
									} else {
										setGeoRestrictionEnabled(true)
									}
								}}
								disabled={isLoading || updateMethods.isLoading}
							/>
							<Text
								lh={rem(22)}
								fz={rem(14)}
								fw={400}
								sx={theme => ({
									color: theme.colors["refactor"][4],
									whiteSpace: "nowrap",
								})}
							>
								Enable Geo Restriction
							</Text>
						</Group>
					</Stack>
				</Group>

				<Group
					pb={rem(24)}
					noWrap
					sx={theme => ({
						gap: rem(48),
						borderBottom: ` 1px solid  ${theme.colors["refactor"][2]}`,
					})}
				>
					<Stack
						sx={{
							gap: rem(16),
						}}
						w={rem(478)}
					>
						<Text
							fw={600}
							fz={rem(16)}
							lh={rem(24)}
							c='#171717'
						>
							Rate Limit
						</Text>
						<Text
							lh={rem(22)}
							fz={rem(14)}
							fw={400}
							sx={theme => ({
								color: theme.colors["refactor"][4],
							})}
						>
							Rate Limit Setting allows you to control the number of
							requests a user can make to your proxy services within a
							specified time period
						</Text>
						<Collapse in={rateLimitEnabled}>
							<form onSubmit={handleSubmit(submitHandler)}>
								<Stack>
									<Group noWrap>
										<CustomNumberInput
											name='limit'
											control={control}
											placeholder='Limit (requests)'
											label='Limit (requests)'
											hideControls
											disabled={isLoading || updateMethods.isLoading}
											keepLabel={
												!!watch("limit") ||
												!!cacheConfig?.security?.rateLimit?.limit
											}
											errorMessage={formState.errors.limit?.message}
										/>
										<CustomNumberInput
											hideControls
											name='window'
											control={control}
											placeholder='Window (seconds)'
											label='Window (seconds)'
											errorMessage={formState.errors.window?.message}
											disabled={isLoading || updateMethods.isLoading}
											keepLabel={
												!!watch("window") ||
												!!cacheConfig?.security?.rateLimit?.window
											}
										/>
									</Group>

									<Button
										w='fit-content'
										h={rem(48)}
										sx={{
											borderRadius: rem(8),
										}}
										disabled={isLoading || updateMethods.isLoading}
										loading={isLoading || updateMethods.isLoading}
										type='submit'
										mt={
											formState.errors.limit?.message ||
											formState.errors.window?.message
												? rem(16)
												: 0
										}
									>
										Save Changes
									</Button>
								</Stack>
							</form>
						</Collapse>
					</Stack>
					<Group
						sx={{ gap: rem(16), flexGrow: 1 }}
						noWrap
					>
						<CustomSwitch
							checked={rateLimitEnabled}
							onChange={() => {
								if (rateLimitEnabled) {
									update("rate limit")
								} else {
									setRateLimitEnabled(true)
								}
							}}
							disabled={isLoading || updateMethods.isLoading}
						/>
						<Text
							lh={rem(22)}
							fz={rem(14)}
							fw={400}
							sx={theme => ({
								color: theme.colors["refactor"][4],
								whiteSpace: "nowrap",
							})}
						>
							Enable Rate Limit
						</Text>
					</Group>
				</Group>

				<Group
					pb={rem(24)}
					noWrap
					sx={theme => ({
						gap: rem(48),
						borderBottom: ` 1px solid  ${theme.colors["refactor"][2]}`,
					})}
				>
					<Stack
						sx={{
							gap: rem(16),
						}}
						w={rem(478)}
					>
						<Text
							fw={600}
							fz={rem(16)}
							lh={rem(24)}
							c='#171717'
						>
							Authorization
						</Text>
						<Text
							lh={rem(22)}
							fz={rem(14)}
							fw={400}
							sx={theme => ({
								color: theme.colors["refactor"][4],
							})}
						>
							Specify authorization method. If disabled,
							private/protected endpoints types would not be available.
						</Text>
						<Collapse in={authorizationEnabled}>
							<Box
								component='form'
								sx={{
									display: "flex",
									flexDirection: "column",
									gap: rem(24),
								}}
								onSubmit={e => {
									e.preventDefault()
									// authMethods.handleSubmit(updateAuth)
								}}
							>
								<CustomSelect
									name='type'
									control={authMethods.control}
									placeholder='Auth Type'
									label='Auth Type'
									keepLabel={!!authMethods.watch("type")}
									data={[
										{ value: "header", label: "Header" },
										{ value: "query", label: "Query Param" },
									]}
									errorMessage={
										authMethods.formState.errors.type?.message
									}
									mb={
										Boolean(
											authMethods.formState.errors.type?.message
										)
											? rem(24)
											: 0
									}
									classNames={{
										root: classes.selectRoot,
									}}
								/>

								{authMethods.watch("type") === "header" ? (
									<>
										<CustomSelect
											name='variant'
											control={authMethods.control}
											placeholder='Auth Variant'
											label='Auth Variant'
											keepLabel={!!authMethods.watch("variant")}
											data={[
												{
													value: "Authorization",
													label: "Authorization",
												},
												{ value: "custom", label: "Custom" },
											]}
											errorMessage={
												"variant" in authMethods.formState.errors
													? authMethods.formState.errors.variant
															?.message
													: ""
											}
											mb={
												"variant" in authMethods.formState.errors &&
												Boolean(
													authMethods.formState.errors.variant
														?.message
												)
													? rem(24)
													: 0
											}
											classNames={{
												root: classes.selectRoot,
											}}
										/>
									</>
								) : null}

								{authMethods.watch("type") === "query" ||
								(authMethods.watch("type") === "header" &&
									authMethods.watch("variant")) === "custom" ? (
									<CustomTextInput
										name='key'
										placeholder='Key'
										keepLabel={!!authMethods.watch("key")}
										label='Key'
										control={authMethods.control}
										errorMessage={
											authMethods.formState.errors.key?.message
										}
										className={
											Boolean(
												authMethods.formState.errors.key?.message
											)
												? classes.mt
												: ""
										}
									/>
								) : null}

								<Button
									w='fit-content'
									h={rem(48)}
									sx={{
										borderRadius: rem(8),
									}}
									disabled={
										isLoading ||
										!authMethods.watch("key") ||
										!authMethods.watch("type") ||
										updateMethods.isLoading
									}
									loading={isLoading || updateMethods.isLoading}
									type='submit'
									mt={
										!authMethods.watch("key") ||
										!authMethods.watch("type")
											? rem(16)
											: 0
									}
									onClick={() => {
										updateAuth(
											{
												...authMethods.getValues(),
											},
											"enable"
										)
									}}
								>
									Save Changes
								</Button>
							</Box>
						</Collapse>
					</Stack>
					<Group
						sx={{ gap: rem(16), flexGrow: 1 }}
						noWrap
					>
						<CustomSwitch
							checked={authorizationEnabled}
							onChange={() => {
								if (authorizationEnabled) {
									updateAuth(
										{
											...authMethods.getValues(),
										},
										"disable"
									)
								} else {
									setAuthorizationEnabled(b => !b)
								}
							}}
							disabled={isLoading || updateMethods.isLoading}
						/>
						<Text
							lh={rem(22)}
							fz={rem(14)}
							fw={400}
							sx={theme => ({
								color: theme.colors["refactor"][4],
								whiteSpace: "nowrap",
							})}
						>
							Enable Authorization
						</Text>
					</Group>
				</Group>
			</Stack>
		</Stack>
	)
}

export default Security
