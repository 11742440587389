import {
	Accordion,
	Button,
	CopyButton,
	Flex,
	Group,
	List,
	Stack,
	Text,
	Tooltip,
	rem,
	createStyles,
	ThemeIcon,
	Box,
	Center,
} from "@mantine/core"
import useAppStore from "../../../../zustand/new-store"
import { SubDomains } from "../../../../types/supabase.types"
import { useLayoutEffect, useMemo, useState } from "react"
import {
	IconCheck,
	IconCopy,
	IconInfoCircle,
	IconWorldWww,
	IconX,
} from "@tabler/icons-react"
import { useAuth } from "@clerk/clerk-react"
import { useMutation, useQueries, useQueryClient } from "@tanstack/react-query"
import { getDomain } from "../../../../api/server/onboard/get-domain"
import { updateOrganizationMetadata } from "../../../../api/supabase/update-org-metadata"
import { updateDomain } from "../../../../api/server/onboard/update-domain"
import { AxiosError } from "axios"
import { axiosErrorParser } from "../../../../lib/parse-axios-error"
import { notifications } from "@mantine/notifications"
import { AddDomainResponse } from "../../../../lib/parser"

const useStyles = createStyles(theme => ({
	control: {
		paddingLeft: 0,
		paddingRight: 0,
	},
	controlActive: {
		paddingBottom: rem(20),
		borderBottom: `1px solid ${theme.colors["refactor"][2]}`,
	},
	label: {
		paddingTop: 0,
		paddingBottom: 0,
	},
	content: {
		padding: 0,
		marginTop: rem(24),
	},
	chevron: {
		color: "#A3A3A3",
		size: rem(24),
	},
}))

const DomainBody = () => {
	const {
		setDomainDrawerOpen,
		organizationsMetadata,
		setOrganizationMetadata,
		activeWorkspace,
		setActiveDomain,
		activeDomain,
		cacheConfig,
	} = useAppStore()
	const { orgId, getToken, sessionId } = useAuth()

	const { classes } = useStyles()
	const subDomains: (SubDomains & { date?: Date })[] = useMemo(() => {
		if (!organizationsMetadata || !organizationsMetadata.sub_domains) {
			return []
		}
		return organizationsMetadata.sub_domains
			.filter(
				domain => domain.workspace_id === activeWorkspace?.workspace_id
			)
			.map(domain => {
				return { ...domain, date: new Date(domain.created_at) }
			})
			.sort((a, b) => {
				return b.date.getTime() - a.date.getTime()
			})
	}, [organizationsMetadata, activeWorkspace?.workspace_id])

	const asd = subDomains.length > 0 ? subDomains[0].id : null

	useLayoutEffect(() => {
		setActiveDomain(asd || "")
	}, [])
	const queryClient = useQueryClient()

	const updateOrg = async (orgId: string, sub_domains: SubDomains[]) => {
		const { data } = await updateOrganizationMetadata(getToken, {
			org_id: orgId,
			sub_domains,
		})

		if (data) {
			setOrganizationMetadata(data)
		}
	}

	const queries = useQueries({
		queries:
			subDomains.length < 1
				? []
				: subDomains
						.filter(domain => !domain.active)
						.map(domain => {
							const { id } = domain

							return {
								queryFn: () => {
									if (!sessionId) {
										throw new Error("Unauthenticated")
									}
									return getDomain(id, sessionId)
								},
								queryKey: ["sub_domain", id],

								refetchInterval: (data: any) => {
									if (!data) {
										return 1000 * 60
									}
									const {
										result: { status, id },
									} = data

									if (status === "active") {
										if (
											!orgId ||
											!organizationsMetadata ||
											!organizationsMetadata.sub_domains
										) {
											return false
										}

										const sub_domains =
											organizationsMetadata?.sub_domains.filter(
												sd => sd.id !== id
											)

										const { date, ...sd } = structuredClone(domain)

										sub_domains.unshift({
											...sd,
											active: true,
										})
										updateOrg(orgId, sub_domains)

										return false
									} else {
										return 1000 * 60
									}
								},
							}
						}),
	})

	const { mutate, isLoading, reset } = useMutation({
		mutationFn: updateDomain,
		onError: (data: AxiosError) => {
			const errorMessage = axiosErrorParser(data)
			if (typeof errorMessage === "string") {
				notifications.show({
					withCloseButton: true,
					color: "danger.0",
					title: "Something went wrong",
					icon: <IconX />,
					autoClose: 5000,
					message: errorMessage,
				})
			} else {
				if (errorMessage instanceof Array) {
					notifications.show({
						withCloseButton: true,
						color: "danger.0",
						title: "Something went wrong",
						icon: <IconX />,
						autoClose: 5000,
						message: errorMessage[0],
					})
				} else {
					notifications.show({
						withCloseButton: true,
						color: "danger.0",
						title: "Something went wrong",
						icon: <IconX />,
						autoClose: 5000,
						message: errorMessage.error[0],
					})
				}
			}
			reset()
		},

		onSuccess: async (data: AddDomainResponse) => {
			const { result } = data
			const {
				status,
				ssl: { method },
			} = result

			const message =
				method === "txt"
					? "Ensure you've added the DNS records as instructed"
					: "Ensure your HTTP URL is reachable on the internet and returns the text body as provided"
			if (status !== "active") {
				notifications.show({
					withCloseButton: true,
					title: "Verification in progress",
					icon: <IconInfoCircle />,
					autoClose: 5000,
					message,
				})
				return
			}
			await queryClient.invalidateQueries({
				queryKey: ["sub_domain", data.result.id],
			})
		},
	})
	return (
		<Stack
			sx={{ gap: rem(40) }}
			w='100%'
		>
			<Group
				w='100%'
				sx={{ justifyContent: "space-between" }}
			>
				<Stack sx={{ gap: rem(8) }}>
					<Text
						fw={500}
						fz={rem(16)}
						lh={rem(20)}
						color='brand.7'
					>
						Sub Domains
					</Text>

					<Text
						fz={rem(14)}
						fw={400}
						lh={rem(20)}
						color='refactor.4'
					>
						Add a sub domain to your service
					</Text>
				</Stack>
				<Button
					h={rem(40)}
					sx={{
						borderRadius: rem(8),
						fontWeight: 500,
						lineHeight: rem(16),
						letterSpacing: -0.2,
					}}
					onClick={() => {
						setDomainDrawerOpen(true)
					}}
				>
					+ Link Domain
				</Button>
			</Group>
			<Stack sx={{ gap: rem(24) }}>
				{subDomains.length > 0 ? (
					<Accordion
						onChange={e => {
							setActiveDomain(e || "")
						}}
						value={activeDomain}
						classNames={{
							label: classes.label,
							control: classes.control,
							content: classes.content,
							chevron: classes.chevron,
						}}
					>
						{subDomains.map(sub_domain => {
							const { active, method, id, domain } = sub_domain
							const sub = domain.split(".")[0] || ""

							return (
								<Accordion.Item
									value={id}
									key={id}
									pl={rem(24)}
									pr={rem(24)}
									sx={theme => ({
										borderRadius: rem(12),
										border: `1px solid ${theme.colors["refactor"][2]}`,
										":not(:first-of-type)": {
											marginTop: rem(24),
										},
									})}
								>
									<Accordion.Control
										pt={rem(32)}
										pb={activeDomain === id ? rem(20) : rem(32)}
										sx={theme => ({
											borderBottom:
												activeDomain === id
													? `1px solid ${theme.colors["refactor"][2]}`
													: "none",

											":hover": {
												background: theme.white,
											},
										})}
									>
										<Flex
											align='center'
											gap={rem(36)}
										>
											<Group
												sx={{ gap: rem(16), alignItems: "center" }}
											>
												<ThemeIcon
													color='brand.7'
													size={rem(24)}
													variant='outline'
													sx={{ border: "none" }}
												>
													<IconWorldWww strokeWidth={rem(1.2)} />
												</ThemeIcon>
												<Text
													fw={600}
													lh={rem(20)}
													fz={rem(16)}
													color='brand.7'
												>
													{domain}
												</Text>
											</Group>
											<Box
												pt={rem(4)}
												pb={rem(4)}
												pl={rem(10)}
												pr={rem(10)}
												sx={{
													borderRadius: rem(4),
													background: active
														? "rgba(24, 183, 107, 0.10)"
														: "rgba(242, 140, 48, 0.10)",
												}}
											>
												<Text
													fz={rem(14)}
													fw={400}
													lh={rem(16)}
													color={active ? "green.0" : "yellow.0"}
													sx={{
														fontFamily: "Outfit, sans-serif",
													}}
												>
													{active ? "Verified" : "Verifying"}
												</Text>
											</Box>
										</Flex>
									</Accordion.Control>

									<Accordion.Panel
										p={0}
										sx={
											{
												// padding: 24,
											}
										}
									>
										<List
											type='ordered'
											w={"80%"}
											maw={600}
										>
											{active ? (
												<>
													<List.Item
														sx={theme => ({
															"::marker": {
																lineHeight: rem(20),
																fontWeight: 500,
																color: theme.colors[
																	theme.primaryColor
																][7],
																fontSize: rem(16),
															},
														})}
													>
														<Text
															lh={rem(20)}
															fz={rem(16)}
															fw={500}
															color='brand.7'
														>
															Copy and use domain
														</Text>
													</List.Item>
													<Flex
														pt={12}
														pl={24}
														pb={40}
														gap={24}
														direction='column'
													>
														<Text
															lh={rem(24)}
															fw={400}
															fz={rem(14)}
															color='refactor.0'
														>
															Your configured sub domain is ready
															to use after logging into your DNS
															provider and adding the relevant
															CNAME record
														</Text>
														<Flex
															gap={rem(24)}
															w='max(80%, 584px)'
														>
															<Flex
																w={rem(160)}
																gap={8}
																direction='column'
															>
																<Text
																	lh={rem(18)}
																	fz={rem(14)}
																	fw={400}
																	color='refactor.0'
																>
																	Name
																</Text>

																<Flex
																	sx={theme => ({
																		borderRadius: rem(12),
																		padding: `0 ${rem(16)}`,
																		overflowX: "hidden",
																		border: `0.0625rem solid ${theme.colors["refactor"][5]}`,
																	})}
																	h={rem(56)}
																	align='center'
																	w={"100%"}
																	justify='space-between'
																>
																	<Text
																		sx={theme => ({
																			overflowX: "hidden",
																			textOverflow:
																				"ellipsis",
																			whiteSpace: "nowrap",
																		})}
																		color='refactor.0'
																		fz={rem(15)}
																		fw={400}
																		lh={rem(20)}
																		lts={-0.2}
																		maw='80%'
																	>
																		{sub}
																	</Text>
																	<CopyButton
																		value={sub}
																		timeout={2000}
																	>
																		{({ copied, copy }) => (
																			<Tooltip
																				label={
																					copied
																						? "Copied"
																						: "Copy"
																				}
																				withArrow
																				position='right'
																			>
																				<Center
																					onClick={copy}
																					sx={theme => ({
																						cursor:
																							"pointer",
																						color: copied
																							? theme
																									.colors[
																									"green"
																							  ][0]
																							: theme
																									.colors[
																									theme
																										.primaryColor
																							  ][7],
																					})}
																				>
																					{copied ? (
																						<IconCheck
																							size={rem(
																								24
																							)}
																						/>
																					) : (
																						<IconCopy
																							size={rem(
																								24
																							)}
																						/>
																					)}
																				</Center>
																			</Tooltip>
																		)}
																	</CopyButton>
																</Flex>
															</Flex>
															<Flex
																w='55%'
																gap={8}
																direction='column'
															>
																<Text
																	lh={rem(18)}
																	fz={rem(14)}
																	fw={400}
																	color='refactor.0'
																>
																	Value
																</Text>
																<Flex
																	h={rem(56)}
																	sx={theme => ({
																		borderRadius: rem(12),
																		padding: `0 ${rem(16)}`,
																		overflowX: "hidden",
																		border: `0.0625rem solid ${theme.colors["refactor"][5]}`,
																	})}
																	align='center'
																	w={"100%"}
																	justify='space-between'
																>
																	<Text
																		sx={theme => ({
																			overflowX: "hidden",
																			textOverflow:
																				"ellipsis",
																			whiteSpace: "nowrap",
																		})}
																		color='refactor.0'
																		fz={rem(15)}
																		fw={400}
																		lh={rem(20)}
																		lts={-0.2}
																		maw='80%'
																	>
																		{cacheConfig?.host || ""}
																	</Text>
																	<CopyButton
																		value={
																			cacheConfig?.host || ""
																		}
																		timeout={2000}
																	>
																		{({ copied, copy }) => (
																			<Tooltip
																				label={
																					copied
																						? "Copied"
																						: "Copy"
																				}
																				withArrow
																				position='right'
																			>
																				<Center
																					onClick={copy}
																					sx={theme => ({
																						cursor:
																							"pointer",
																						color: copied
																							? theme
																									.colors[
																									"green"
																							  ][0]
																							: theme
																									.colors[
																									theme
																										.primaryColor
																							  ][7],
																					})}
																				>
																					{copied ? (
																						<IconCheck
																							size={rem(
																								24
																							)}
																						/>
																					) : (
																						<IconCopy
																							size={rem(
																								24
																							)}
																						/>
																					)}
																				</Center>
																			</Tooltip>
																		)}
																	</CopyButton>
																</Flex>
															</Flex>
														</Flex>
													</Flex>
												</>
											) : (
												<>
													<List.Item
														sx={theme => ({
															"::marker": {
																lineHeight: rem(20),
																fontWeight: 500,
																color: theme.colors[
																	theme.primaryColor
																][7],
																fontSize: rem(16),
															},
														})}
													>
														<Text
															lh={rem(20)}
															fz={rem(16)}
															fw={500}
															color='brand.7'
														>
															Log into your DNS provider
														</Text>
													</List.Item>
													<Flex
														pt={12}
														pl={24}
														pb={24}
														gap={24}
														direction='column'
													>
														<Text
															lh={rem(24)}
															fw={400}
															fz={rem(14)}
															color='refactor.0'
														>
															Add the following{" "}
															{method.toUpperCase()} record
														</Text>
														<Flex gap={rem(24)}>
															<Flex direction='column'>
																<Text
																	lh={rem(18)}
																	fz={rem(14)}
																	fw={400}
																	color='refactor.0'
																>
																	{method === "txt"
																		? "Name"
																		: "HTTP url"}
																</Text>
																<Flex
																	sx={theme => ({
																		borderRadius: rem(12),
																		padding: `0 ${rem(16)}`,
																		overflowX: "hidden",
																		border: `0.0625rem solid ${theme.colors["refactor"][5]}`,
																		flexGrow: 1,
																	})}
																	h={rem(56)}
																	align='center'
																	justify='space-between'
																>
																	<Text
																		sx={theme => ({
																			overflowX: "hidden",
																			textOverflow:
																				"ellipsis",
																			whiteSpace: "nowrap",
																		})}
																		color='refactor.0'
																		fz={rem(15)}
																		fw={400}
																		lh={rem(20)}
																		lts={-0.2}
																		maw='80%'
																	>
																		{method === "txt"
																			? sub_domain.name
																			: sub_domain.http_url}
																	</Text>
																	<CopyButton
																		value={
																			method === "txt"
																				? sub_domain.name
																				: sub_domain.http_url
																		}
																		timeout={2000}
																	>
																		{({ copied, copy }) => (
																			<Tooltip
																				label={
																					copied
																						? "Copied"
																						: "Copy"
																				}
																				withArrow
																				position='right'
																			>
																				<Center
																					onClick={copy}
																					sx={theme => ({
																						cursor:
																							"pointer",
																						color: copied
																							? theme
																									.colors[
																									"green"
																							  ][0]
																							: theme
																									.colors[
																									theme
																										.primaryColor
																							  ][7],
																					})}
																				>
																					{copied ? (
																						<IconCheck
																							size={rem(
																								24
																							)}
																						/>
																					) : (
																						<IconCopy
																							size={rem(
																								24
																							)}
																						/>
																					)}
																				</Center>
																			</Tooltip>
																		)}
																	</CopyButton>
																</Flex>
															</Flex>
															<Flex
																// w={"45%"}
																// gap={0}
																direction='column'
															>
																<Text
																	lh={rem(18)}
																	fz={rem(14)}
																	fw={400}
																	color='refactor.0'
																>
																	{method === "txt"
																		? "Value"
																		: "HTTP body"}
																</Text>
																<Flex
																	sx={theme => ({
																		borderRadius: rem(12),
																		padding: `0 ${rem(16)}`,
																		overflowX: "hidden",
																		border: `0.0625rem solid ${theme.colors["refactor"][5]}`,
																		flexGrow: 1,
																	})}
																	h={rem(56)}
																	align='center'
																	justify='space-between'
																>
																	<Text
																		sx={theme => ({
																			overflowX: "hidden",
																			textOverflow:
																				"ellipsis",
																			whiteSpace: "nowrap",
																		})}
																		color='refactor.0'
																		fz={rem(15)}
																		fw={400}
																		lh={rem(20)}
																		lts={-0.2}
																		maw='80%'
																	>
																		{method === "txt"
																			? sub_domain.value
																			: sub_domain.http_body}
																	</Text>
																	<CopyButton
																		value={
																			method === "txt"
																				? sub_domain.value
																				: sub_domain.http_body
																		}
																		timeout={2000}
																	>
																		{({ copied, copy }) => (
																			<Tooltip
																				label={
																					copied
																						? "Copied"
																						: "Copy"
																				}
																				withArrow
																				position='right'
																			>
																				<Center
																					onClick={copy}
																					sx={theme => ({
																						cursor:
																							"pointer",
																						color: copied
																							? theme
																									.colors[
																									"green"
																							  ][0]
																							: theme
																									.colors[
																									theme
																										.primaryColor
																							  ][7],
																					})}
																				>
																					{copied ? (
																						<IconCheck
																							size={rem(
																								24
																							)}
																						/>
																					) : (
																						<IconCopy
																							size={rem(
																								24
																							)}
																						/>
																					)}
																				</Center>
																			</Tooltip>
																		)}
																	</CopyButton>
																</Flex>
															</Flex>
														</Flex>
													</Flex>
													<List.Item
														sx={theme => ({
															"::marker": {
																lineHeight: rem(20),
																fontWeight: 500,
																color: theme.colors[
																	theme.primaryColor
																][7],
																fontSize: rem(16),
															},
														})}
													>
														<Text
															lh={rem(20)}
															fz={rem(16)}
															fw={500}
															color='brand.7'
														>
															Save your changes
														</Text>
													</List.Item>
													<Flex
														pt={12}
														pl={24}
														pb={40}
														direction='column'
														gap={24}
													>
														<Text
															lh={rem(24)}
															fw={400}
															fz={rem(14)}
															color='refactor.0'
														>
															Once you've added your records,{" "}
															<Box
																display='inline'
																fw={500}
																fz={rem(14)}
																lh={rem(20)}
																sx={{
																	color: "#404040",
																}}
															>
																check DNS records
															</Box>{" "}
															to initiate a verification check
														</Text>
														<Button
															h={rem(40)}
															w='fit-content'
															fw={500}
															fz={rem(14)}
															lh={rem(16)}
															lts={-0.2}
															sx={theme => ({
																borderRadius: rem(8),
															})}
															disabled={isLoading}
															loading={isLoading}
															onClick={async () => {
																const token = await getToken()
																if (!token) {
																	return
																}
																mutate({
																	hostname: domain,
																	method,
																	id,
																	token,
																})
															}}
														>
															Check DNS records
														</Button>
													</Flex>
												</>
											)}
										</List>
									</Accordion.Panel>
								</Accordion.Item>
							)
						})}
					</Accordion>
				) : (
					<></>
				)}
			</Stack>
		</Stack>
	)
}

export default DomainBody
