import { Badge, Box, Center, Flex, Stack, Text, rem } from "@mantine/core"

interface IProps {
	icon: React.ReactNode
	active: boolean
	setActive: () => void
	recommended?: true
	title: string
	text: string
}
const Card = ({
	icon,
	active,
	setActive,
	recommended,
	title,
	text,
}: IProps) => {
	return (
		<Flex
			p={rem(24)}
			gap={rem(20)}
			pos='relative'
			onClick={() => {
				setActive()
			}}
			w={rem(296)}
			align='center'
			justify='center'
			direction='column'
			sx={theme => ({
				cursor: "pointer",
				borderRadius: rem(12),
				border: active
					? `1px solid ${theme.colors[theme.primaryColor][2]}`
					: `1px solid ${theme.colors["refactor"][2]} `,
				flex: "1 0 0",
				position: "relative",
			})}
			h={rem(216)}
		>
			{recommended ? (
				<Badge
					sx={{
						position: "absolute",
						top: rem(16),
						borderRadius: rem(6),
						right: rem(16),
						paddingTop: rem(8),
						paddingBottom: rem(8),
						paddingRight: rem(6),
						paddingLeft: rem(6),
						height: "fit-content",
						fontSize: rem(14),
						lineHeight: rem(16),
						textTransform: "capitalize",
						border: "none",
						background: "#F6F3FF",
					}}
				>
					Recommended
				</Badge>
			) : null}
			<Box w='100%'>
				<Center
					w='fit-content'
					sx={theme => ({
						border: `1px solid ${theme.colors["refactor"][2]} `,
						borderRadius: rem(8),
					})}
					p={rem(8)}
				>
					{icon}
				</Center>
			</Box>
			<Stack
				sx={{
					gap: rem(12),
				}}
			>
				<Text
					sx={theme => ({
						color: theme.colors[theme.primaryColor][7],
						fontSize: rem(18),
						fontWeight: 500,
						lineHeight: rem(24),
					})}
				>
					{title}
				</Text>
				<Text
					sx={theme => ({
						lineHeight: rem(24),
						fontSize: rem(16),
						color: theme.colors["refactor"][4],
					})}
				>
					{text}
				</Text>
			</Stack>
		</Flex>
	)
}

export default Card
