import { axiosConfig } from "../../../config/axios.config"
import { Authorization, Protocols } from "../../../lib/parser"

export const refreshEndpoints = async (body: {
	token: string
	url: string
	host: string
	authorization: Authorization | null,
	protocol: Protocols,
	basePath: string
}) => {
	const response = await axiosConfig.patch(
		`/service/${body.host}`,
		{
			url: body.url,
			basePath: body.basePath,
			authorization: body.authorization,
			protocol: body.protocol
		},
		{
			headers: {
				Authorization: `Bearer ${body.token}`,
				"Content-Type": "application/json",
			},
		}
	)
	const { data } = response

	return data
}
