import { IconAlertTriangle } from "@tabler/icons-react"
import { Button, Center, Stack, Text, ThemeIcon, rem } from "@mantine/core"
const DrawerError = ({
	errors,
	btnAction,
	title,
	subtext,
	secondaryBtnAction,
	secondaryBtnText,
}: {
	errors: string[]
	btnAction: () => void
	title: string
	subtext: string
	secondaryBtnText?: string
	secondaryBtnAction?: () => void
}) => {
	return (
		<Stack
			mt={rem(56)}
			sx={{ gap: 0 }}
			w='100%'
		>
			<Center>
				<ThemeIcon
					sx={theme => ({
						border: "none",
						color: theme.colors["danger"][0],
					})}
					variant='outline'
					size={rem(40)}
				>
					<IconAlertTriangle size={rem(40)} />
				</ThemeIcon>
			</Center>
			<Stack
				mt={rem(24)}
				sx={{
					gap: rem(16),
				}}
			>
				<Text
					ta='center'
					fz={rem(18)}
					fw={500}
					lh={rem(24)}
					c='#030124'
				>
					{title}
				</Text>

				<Text
					ta='center'
					fz={rem(16)}
					fw={400}
					lh={rem(24)}
					sx={theme => ({
						color: theme.colors["refactor"][4],
					})}
				>
					{subtext}
				</Text>
			</Stack>
			<Stack
				mt={rem(24)}
				p={rem(20)}
				sx={theme => ({
					gap: rem(12),
					":empty": {
						display: "none",
					},
					borderRadius: rem(8),
					border: `1px solid ${theme.colors["danger"][0]}`,
				})}
			>
				{errors.map((error, idx) => (
					<Text
						key={idx}
						fz={rem(16)}
						lh={rem(24)}
						sx={theme => ({
							color: theme.colors["danger"][0],
						})}
					>
						{error}
					</Text>
				))}
			</Stack>

			<Button
				type='button'
				h={rem(48)}
				onClick={btnAction}
				mt={rem(40)}
				fz={rem(16)}
				sx={{
					borderRadius: rem(8),
					fontWeight: 500,
					lineHeight: rem(16),
					letterSpacing: -0.2,
				}}
			>
				Try Again
			</Button>
			{secondaryBtnAction && secondaryBtnText ? (
				<Button
					type='button'
					h={rem(48)}
					onClick={secondaryBtnAction}
					mt={rem(24)}
					fz={rem(16)}
					variant='outline'
					sx={{
						borderRadius: rem(8),
						fontWeight: 500,
						lineHeight: rem(16),
						letterSpacing: -0.2,
					}}
				>
					{secondaryBtnText}
				</Button>
			) : null}
		</Stack>
	)
}

export default DrawerError
