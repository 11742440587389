import { supabaseClient } from "../../config/supabase.config"
import { SUPABASE_ENDPOINTS } from "../../constants/supabase-tables"
import { CacheConfig } from "../../lib/parser"
import utc from "dayjs/plugin/utc"
import dayjs from "dayjs"
dayjs.extend(utc)

export const updateEndpoints = async (
	getToken: (
		options?: { template: string } | undefined
	) => Promise<string | null>,
	values: Partial<CacheConfig> & {
		org_id: string
		workspace_id: string
	}
) => {
	const { org_id, workspace_id } = values

	const supabaseAccessToken = (await getToken({
		template: "supabase",
	})) as string
	const supabase = supabaseClient(supabaseAccessToken)
	delete values?.orgId
	return await supabase
		.from(SUPABASE_ENDPOINTS)
		.update({ ...values, updated_at: dayjs().utc().format() })
		.match({
			org_id,
			workspace_id,
		})
		.select()
		.single()
}
