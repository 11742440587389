import { Outlet } from "react-router-dom"
import {
	Flex,
	AppShell as MantineAppShell,
	MediaQuery,
	createStyles,
	rem,
} from "@mantine/core"
import AppHeader from "./Header"
import { NotificationProvider } from "../../context/NotificationsProvider"
import { getScrollbarWidth } from "../../lib/get-scrollbar-width"
import { SCROLLBAR_WIDTH_OFFSET } from "../../constants/scrollbar-offset"
import useHeaderHintStore from "../../zustand/header-hint-tour-store"
import useTourStore from "../../zustand/tour-store"
import HintModal from "../Tour/Modal"
import { Step } from "react-joyride"
const useStyles = createStyles(theme => ({
	body: {
		scrollbarGutter: "stable",
		width: `calc(100vw - ${getScrollbarWidth() + SCROLLBAR_WIDTH_OFFSET}px)`,
	},
	main: {
		padding: `${rem(80)}  0`,
		height: "100%",
		minHeight: `calc(100vh - ${rem(80)})`,
		maxWidth: "1440px",
		margin: "0 auto",
		minWidth: "1096px",
		scrollbarGutter: "stable",
	},
}))
const AppShell = () => {
	const { classes } = useStyles()
	const { updateHintState, steps, run, isModalOpen, completedHints } =
		useHeaderHintStore()

	const { completedTour } = useTourStore()
	const stepList: Step[] = [
		{
			content:
				"Create and manage multiple service workspaces for managing APIs.",
			placement: "bottom",
			target: "#service-manager",
		},
		{
			content: "View and manage notification settings.",
			placement: "bottom",
			target: "#notifications-bell",
		},
	]
	return (
		<MantineAppShell
			header={
				<NotificationProvider>
					<HintModal
						updateHintState={updateHintState}
						steps={steps}
						stepsList={stepList}
						run={run}
						isModalOpen={isModalOpen}
						completedHints={completedHints || !completedTour}
					>
						<AppHeader />
					</HintModal>
				</NotificationProvider>
			}
			classNames={{ main: classes.main, body: classes.body }}
		>
			<MediaQuery
				query='(max-width: 68.5em)'
				styles={{
					paddingLeft: rem(40),
					paddingRight: rem(40),
				}}
			>
				<Flex
					direction='column'
					justify='center'
					pl={rem(120)}
					pr={rem(120)}
					maw={rem(1440)}
					miw={rem(1096)}
					mt={rem(32)}
					w='100%'
				>
					<Outlet />
				</Flex>
			</MediaQuery>
		</MantineAppShell>
	)
}

export default AppShell
