import { Laptop } from "../../../assets/icons"
import { Stack, Text, Button, Group, rem } from "@mantine/core"

const OnboardSuccessful = ({ btnAction }: { btnAction: () => void }) => {
	return (
		<Stack sx={{ gap: 0, alignItems: "center" }}>
			<Group sx={{ gap: rem(12), justifyContent: "center" }}>
				<Laptop />
				<Text
					sx={theme => ({
						fontWeight: 600,
						fontSize: rem(21),
						lineHeight: rem(24),
						color: theme.colors[theme.primaryColor][7],
					})}
				>
					You’re All Set!
				</Text>
			</Group>
			<Text
				mt={rem(24)}
				sx={theme => ({
					lineHeight: rem(24),
					fontSize: rem(16),
					color: theme.colors[theme.primaryColor][8],
				})}
			>
				Your Resilis account has been successfully created and your
				workspace is ready. Dive in and start optimizing your API
				infrastructure!
			</Text>
			<Button
				sx={theme => ({
					borderRadius: rem(8),
					height: rem(48),
					marginTop: rem(40),
					fontWeight: 500,
					lineHeight: rem(16),
					letterSpacing: -0.2,
				})}
				fullWidth
				onClick={() => {
					btnAction()
				}}
			>
				Continue
			</Button>
		</Stack>
	)
}

export default OnboardSuccessful
