import { useAuth } from "@clerk/clerk-react"
import useAppStore from "../../../../zustand/new-store"
import DrawerSuccess from "../../../../Components/DrawerSuccess/DrawerSuccess"
import DrawerError from "../../../../Components/DrawerError/DrawerError"
import { SubmitHandler, useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import * as z from "zod"
import CustomTextInput from "../../../../Components/TextInput/TextInput"
import { hostnameRegex } from "../../../../constants/regex"
import CustomDrawer from "../../../../Components/Drawer/Drawer"
import { useState } from "react"
import { Box, Button, Stack, rem } from "@mantine/core"
import CustomSelect from "../../../../Components/Select/Select"
import { useMutation } from "@tanstack/react-query"
import { createNewDomain } from "../../../../api/server/onboard/add-domain"
import { AxiosError } from "axios"
import { axiosErrorParser } from "../../../../lib/parse-axios-error"
import { SubDomains } from "../../../../types/supabase.types"
import { AddDomainResponse } from "../../../../lib/parser"
import { updateOrganizationMetadata } from "../../../../api/supabase/update-org-metadata"
import { notifications } from "@mantine/notifications"
import { IconX } from "@tabler/icons-react"

const domainSchema = z.object({
	hostname: z
		.string()
		.min(1, { message: "Hostname is required" })
		.regex(hostnameRegex, { message: "Hostname must be a valid hostname" }),
	method: z.enum(["http", "txt"]),
})

export type DomainType = z.infer<typeof domainSchema>
const DomainDrawer = () => {
	const {
		organizationsMetadata,
		setOrganizationMetadata,
		activeWorkspace,
		domainDrawerOpen,
		setDomainDrawerOpen,
		setActiveDomain,
		cacheConfig,
	} = useAppStore()
	const { getToken, orgId } = useAuth()
	const [success, setSuccess] = useState(false)
	const [serverSideJSONErrors, setServerSideValidationErrors] = useState<
		string[]
	>([])
	const [supabaseLoading, setSupabaseLoading] = useState(false)
	const { handleSubmit, control, formState, getValues, reset, watch } =
		useForm<DomainType>({
			resolver: zodResolver(domainSchema),
			defaultValues: { hostname: "", method: "txt" },
		})

	const { mutate, isLoading } = useMutation({
		mutationFn: createNewDomain,
		onError: (error: AxiosError) => {
			console.log(error)

			const errorMessage = axiosErrorParser(error)

			if (typeof errorMessage === "string") {
				setServerSideValidationErrors([errorMessage])
			} else {
				if (errorMessage instanceof Array) {
					setServerSideValidationErrors(errorMessage)
				} else {
					setServerSideValidationErrors(errorMessage.error)
				}
			}
			setSupabaseLoading(false)
			reset()
		},
		onSuccess: async (data: AddDomainResponse) => {
			setServerSideValidationErrors([])
			if (!orgId || !organizationsMetadata) {
				return
			}
			const {
				result: {
					id,
					status,
					created_at,
					hostname,
					ssl: { method },
					ownership_verification: { name, value },
					ownership_verification_http: { http_body, http_url },
				},
			} = data

			const details =
				method === "txt"
					? { name, value, method }
					: { http_body, http_url, method }

			const supabaseBody: SubDomains = {
				id,
				domain: hostname,
				active:
					status.toLowerCase() === "pending" ||
					status.toLowerCase() === "failed"
						? false
						: true,
				created_at,
				workspace_id: activeWorkspace?.workspace_id || "",
				...details,
			}

			let sub_domains = organizationsMetadata?.sub_domains
			if (!sub_domains) {
				sub_domains = [supabaseBody]
			} else {
				sub_domains.unshift(supabaseBody)
			}

			const { data: resData, error } = await updateOrganizationMetadata(
				getToken,
				{ sub_domains, org_id: orgId || organizationsMetadata.org_id }
			)

			if (resData) {
				setSuccess(true)
				setOrganizationMetadata(resData)
				setActiveDomain(id)
			}
			if (error) {
				setSuccess(false)
				setServerSideValidationErrors(["Something went wrong"])
			}
			setSupabaseLoading(false)
			reset()
		},
	})
	const submitHandler: SubmitHandler<DomainType> = async values => {
		const token = await getToken()
		if (!token) {
			return
		}

		if (!cacheConfig?.host) {
			notifications.show({
				withCloseButton: true,
				color: "danger.0",
				title: "No hostname found",
				icon: <IconX />,
				autoClose: 5000,
				message:
					"Navigate to the endpoints page and add endpoints to create a unique hostname",
			})
			return
		}
		setServerSideValidationErrors([])
		setSupabaseLoading(true)

		mutate({ ...values, org_id: orgId || "", token })
	}
	return (
		<CustomDrawer
			opened={domainDrawerOpen}
			onClose={() => {
				setDomainDrawerOpen(false)
				setSuccess(false)
				setServerSideValidationErrors([])
				reset()
			}}
			title={
				success || serverSideJSONErrors.length > 0 ? "" : "Add New Domain"
			}
		>
			{serverSideJSONErrors.length > 0 ? (
				<DrawerError
					errors={[]}
					btnAction={() => {
						setServerSideValidationErrors([])
					}}
					title='Oops! Something Went Wrong'
					subtext={`We couldn’t configure ${getValues(
						"hostname"
					)} using the ${getValues(
						"method"
					)} method. Please check your inputs and try again or refer to our documentation for help`}
				/>
			) : success ? (
				<DrawerSuccess
					title='Domain added successfully!'
					subtext="Domain successfully added! It's now being verified - check back soon."
					btnAction={() => {
						setDomainDrawerOpen(false)
					}}
					btnText='Go to Dashboard'
				/>
			) : (
				<Stack
					sx={{ gap: rem(24) }}
					mt={rem(24)}
					w='100%'
				>
					<Box
						component='form'
						display='flex'
						sx={{
							gap: rem(24),
							flexDirection: "column",
						}}
						onSubmit={handleSubmit(submitHandler)}
					>
						<CustomTextInput
							name='hostname'
							placeholder='Hostname'
							label='Hostname'
							control={control}
							errorMessage={formState.errors.hostname?.message}
							disabled={isLoading || supabaseLoading}
							keepLabel={!!watch("hostname")}
						/>
						<CustomSelect
							name='method'
							placeholder='Method'
							label='Method'
							control={control}
							data={[
								{ value: "http", label: "HTTP" },
								{ value: "txt", label: "TXT" },
							]}
							mt={
								Boolean(formState.errors.hostname?.message)
									? rem(24)
									: 0
							}
							errorMessage={formState.errors.method?.message}
							disabled={isLoading || supabaseLoading}
							keepLabel={!!watch("method")}
						/>
						<Button
							variant='filled'
							fullWidth
							h={rem(48)}
							type='submit'
							mt={
								Boolean(formState.errors.method?.message) ? rem(24) : 0
							}
							sx={{
								borderRadius: rem(8),
								fontWeight: 500,
								lineHeight: rem(16),
								letterSpacing: -0.2,
							}}
							disabled={isLoading || supabaseLoading}
							loading={isLoading || supabaseLoading}
						>
							Add Domain
						</Button>
					</Box>
				</Stack>
			)}
		</CustomDrawer>
	)
}

export default DomainDrawer
