import { Global } from "@mantine/core"
import interSemiBold from "./Inter/Inter-SemiBold.ttf"
import interMedium from "./Inter/Inter-Medium.ttf"
import interRegular from "./Inter/Inter-Regular.ttf"
import interBold from "./Inter/Inter-Bold.ttf"
import interLight from "./Inter/Inter-Light.ttf"
import outfitLight from "./Outfit/Outfit-Light.ttf"
import outfitRegular from "./Outfit/Outfit-Regular.ttf"
import outfitMedium from "./Outfit/Outfit-Medium.ttf"
import outfitSemiBold from "./Outfit/Outfit-SemiBold.ttf"
import outfitBold from "./Outfit/Outfit-Bold.ttf"

export function CustomFonts() {
	return (
		<Global
			styles={[
				{
					"@font-face": {
						fontFamily: "Inter",
						src: `url('${interSemiBold}') format("truetype")`,
						fontWeight: 600,
						fontStyle: "normal",
					},
				},
				{
					"@font-face": {
						fontFamily: "Inter",
						src: `url('${interLight}') format("truetype")`,
						fontWeight: 300,
						fontStyle: "normal",
					},
				},
				{
					"@font-face": {
						fontFamily: "Inter",
						src: `url('${interMedium}') format("truetype")`,
						fontWeight: 500,
						fontStyle: "normal",
					},
				},
				{
					"@font-face": {
						fontFamily: "Inter",
						src: `url('${interRegular}') format("truetype")`,
						fontWeight: 400,
						fontStyle: "normal",
					},
				},
				{
					"@font-face": {
						fontFamily: "Inter",
						src: `url('${interBold}') format("truetype")`,
						fontWeight: 700,
						fontStyle: "normal",
					},
				},

				{
					"@font-face": {
						fontFamily: "Outfit",
						src: `url('${outfitSemiBold}') format("truetype")`,
						fontWeight: 600,
						fontStyle: "normal",
					},
				},
				{
					"@font-face": {
						fontFamily: "Outfit",
						src: `url('${outfitLight}') format("truetype")`,
						fontWeight: 300,
						fontStyle: "normal",
					},
				},
				{
					"@font-face": {
						fontFamily: "Outfit",
						src: `url('${outfitMedium}') format("truetype")`,
						fontWeight: 500,
						fontStyle: "normal",
					},
				},
				{
					"@font-face": {
						fontFamily: "Outfit",
						src: `url('${outfitRegular}') format("truetype")`,
						fontWeight: 400,
						fontStyle: "normal",
					},
				},
				{
					"@font-face": {
						fontFamily: "Outfit",
						src: `url('${outfitBold}') format("truetype")`,
						fontWeight: 700,
						fontStyle: "normal",
					},
				},
			]}
		/>
	)
}
