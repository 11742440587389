import { Center, Group, Skeleton, Stack, Text, rem } from "@mantine/core"
import { useMemo } from "react"
import ProgressBar from "../../../../Components/ProgressBar/ProgressBar"
import { formatNumber } from "../../Endpoints/components/Table/utils"
import { IconChartBar } from "@tabler/icons-react"
import { Intervals } from "../../../../types/dashboard-chart-types"
import useEndpointTraffic from "../api/useEndpointTraffic"
const EndpointTraffic = ({ interval }: { interval: Intervals }) => {
	const { data, isLoading } = useEndpointTraffic(interval)

	const endpointData: { count: string; endpoint: string }[] = useMemo(() => {
		if (!data) {
			return []
		} else {
			return data.data
				.sort(
					(firstItem, secondItem) =>
						Number(secondItem.count) - Number(firstItem.count)
				)
				.slice(0, 5)
		}
	}, [data])

	const totalRequests = useMemo(() => {
		if (!data) {
			return 0
		} else {
			return data.data.reduce((a, b) => {
				return a + Number(b.count)
			}, 0)
		}
	}, [data])

	return (
		<Stack
			sx={theme => ({
				borderRadius: rem(8),
				border: `1px solid ${theme.colors["refactor"][2]} `,
				gap: rem(30),
				flexGrow: 1,
				flexBasis: "48%",
			})}
			p={rem(32)}
			h='100%'
		>
			<Stack
				pb={rem(24)}
				sx={theme => ({
					gap: rem(8),
					borderBottom: `1px solid ${theme.colors["refactor"][2]} `,
				})}
			>
				<Text
					c='brand.7'
					fz={rem(16)}
					fw={500}
					lh={rem(24)}
				>
					Endpoint Traffic
				</Text>
				{endpointData && endpointData.length > 0 ? (
					<Text
						c='refactor.4'
						fz={rem(14)}
						fw={400}
						lh={rem(20)}
					>
						Your Top {endpointData.length} Endpoint traffic
					</Text>
				) : null}
			</Stack>
			<Stack sx={{ gap: rem(32) }}>
				{isLoading ? (
					<Skeleton
						w='100%'
						h='100%'
						mih={rem(210)}
					/>
				) : data && endpointData && endpointData.length > 0 ? (
					endpointData.map(endpoint => (
						<Group
							key={endpoint.count + endpoint.endpoint}
							align='center'
							sx={{
								justifyContent: "space-between",
							}}
						>
							<Text
								c='brand.7'
								fz={rem(14)}
								lh={rem(20)}
								fw={500}
								sx={{
									whiteSpace: "nowrap",
								}}
							>
								{endpoint.endpoint}
							</Text>

							<Group
								align='center'
								sx={{
									gap: rem(16),
								}}
								noWrap
							>
								<ProgressBar
									value={Number(endpoint.count)}
									max={totalRequests}
									color='primary'
								/>

								<Text
									fz={rem(14)}
									fw={500}
									lh={rem(20)}
									c=' #404040'
									w={rem(40)}
								>
									{formatNumber(Number(endpoint.count))}
								</Text>
							</Group>
						</Group>
					))
				) : (
					<Center
						sx={{
							gap: rem(16),
							flexDirection: "column",
						}}
						h='100%'
						mih={rem(210)}
					>
						<Center c='#292D32'>
							<IconChartBar size={24} />
						</Center>
						<Stack
							sx={{
								gap: rem(8),
							}}
							w={`max(${rem(340)}, 75%)`}
						>
							<Text
								c='brand.7'
								fz={rem(16)}
								fw={400}
								lh={rem(24)}
								ta='center'
							>
								No Endpoint Traffic Data
							</Text>
							<Text
								fw={400}
								lh={rem(20)}
								fz={rem(14)}
								ta='center'
								c='refactor.4'
							>
								Once your APIs are set up and handling requests, you'll
								see traffic data by endpoint here.
							</Text>
						</Stack>
					</Center>
				)}
			</Stack>
		</Stack>
	)
}

export default EndpointTraffic
