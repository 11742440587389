import { useAuth, useOrganizationList, useUser } from "@clerk/clerk-react"
import { Logo } from "../../../assets/icons"
import {
	Stack,
	Text,
	rem,
	createStyles,
	Button,
	Box,
	MediaQuery,
	Flex,
} from "@mantine/core"
import CustomTextInput from "../../../Components/TextInput/TextInput"
import * as z from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm, SubmitHandler } from "react-hook-form"
import { useDisclosure, useDocumentTitle } from "@mantine/hooks"
import { useState } from "react"
import CustomModal from "../../../Components/Modal/Modal"
import { useNavigate } from "react-router-dom"
import { IOrganization, Service, User } from "../../../types/supabase.types"
import { createOrganizationMetadata } from "../../../api/supabase/create-org-metadata"
import useAppStore from "../../../zustand/new-store"
import { nanoid } from "nanoid"
import OnboardSuccessful from "./OnboardSuccessful"
import Loading from "../../dashboard/loading"
import { urlPattern } from "../../../constants/regex"
import { isProduction } from "../../../constants/is-production"
const useStyles = createStyles(theme => ({
	logo: {
		marginBottom: rem(75),
		marginTop: rem(66),
	},

	modalContent: {
		borderRadius: rem(16),
		background: theme.white,
		boxShadow: "none",
		padding: rem(40),
		width: rem(480),
		maxWidth: "fit-content",
	},
	modalBody: {
		padding: 0,
	},
}))

const onboardSchema = z.object({
	workspaceName: z
		.string({
			required_error: "service name is required",
		})
		.min(1, { message: "service name is required" }),
	companyName: z
		.string({
			required_error: "company name is required",
		})
		.min(1, { message: "company name is required" }),
	companyWebsite: z
		.string({
			required_error: "company website is required",
			invalid_type_error: "company website must be a valid url",
		})
		.regex(urlPattern, { message: "company website must be a valid url" })
		.min(1, { message: "company website is required" }),
})

type onboardFormType = z.infer<typeof onboardSchema>

const successRedirect = isProduction ? "/get-started" : "/pricing-auth"
const Onboard = () => {
	useDocumentTitle("Onboarding")
	const { classes } = useStyles()
	const { getToken } = useAuth()
	const { user, isLoaded: isUserLoaded } = useUser()
	const [loading, setLoading] = useState(false)
	const [opened, { close, open }] = useDisclosure(false)
	const { createOrganization, setActive, isLoaded } = useOrganizationList()
	const [supabaseResponse, setSupabaseResponse] =
		useState<IOrganization | null>(null)

	const { setOrganizationMetadata, onboardLoading, setActiveWorkSpace } =
		useAppStore()

	const navigate = useNavigate()

	const { control, handleSubmit, watch, formState, reset } =
		useForm<onboardFormType>({
			resolver: zodResolver(onboardSchema),
			defaultValues: {
				workspaceName: "",
				companyName: "",
				companyWebsite: "",
			},
		})

	async function saveOrganizationToSupabase(
		userId: string,
		name: string,
		org_id: string,
		services: Service[],
		users: User[],
		website: string
	) {
		try {
			const result = await createOrganizationMetadata(
				getToken,
				userId,
				name,
				org_id,
				services,
				users,
				website
			)

			if (result.data) {
				return { data: result.data, error: null }
			} else {
				return { data: null, error: result.data || result.error }
			}
		} catch (error) {
			return { data: null, error }
		}
	}
	const submit: SubmitHandler<onboardFormType> = async values => {
		if (!isLoaded || !isUserLoaded || !user) {
			return
		}

		setLoading(true)
		const organization = await createOrganization({
			name: values.companyName,
		})

		setActive({
			organization,
		})
		const service: Service[] = [
			{
				workspace_id: nanoid().toLowerCase(),
				workspace_name: values.workspaceName,
			},
		]
		const users: User[] = [
			{ user_id: user.id, email: user.primaryEmailAddress!.emailAddress },
		]
		const { data, error } = await saveOrganizationToSupabase(
			user.id,
			values.companyName,
			organization.id,
			service,
			users,
			values.companyWebsite
		)

		if (data) {
			open()
			setSupabaseResponse(data)
			reset()
		}

		if (error) {
			console.log(error)
		}
		setLoading(false)
	}
	if (onboardLoading) {
		return <Loading />
	}
	return (
		<>
			<MediaQuery
				query='(min-width: 75em)'
				styles={{
					width: rem(360),
				}}
			>
				<Flex
					sx={{
						width: `max(${rem(360)}, 45vw)`,
						alignItems: "flex-start",
					}}
				>
					<CustomModal
						opened={opened}
						onClose={() => {
							close()

							if (supabaseResponse) {
								setOrganizationMetadata(supabaseResponse)
								const workspace = supabaseResponse?.services[0] || null
								setActiveWorkSpace(workspace)
							}
							navigate(successRedirect)
						}}
					>
						<OnboardSuccessful
							btnAction={() => {
								navigate(successRedirect)
								if (supabaseResponse) {
									setOrganizationMetadata(supabaseResponse)
									const workspace =
										supabaseResponse?.services[0] || null
									setActiveWorkSpace(workspace)
								}
								close()
							}}
						/>
					</CustomModal>
					<Stack
						justify='center'
						sx={{
							gap: rem(0),
						}}
					>
						<Logo className={classes.logo} />

						<Stack sx={{ gap: rem(8) }}>
							<Text
								component='h1'
								sx={theme => ({
									fontSize: rem(36),
									fontWeight: 600,
									lineHeight: rem(40),
									letterSpacing: rem(-0.2),
									color: theme.colors[theme.primaryColor][7],
								})}
								mt={0}
								mb={0}
							>
								Setup Service
							</Text>
							<Text
								component='h2'
								mt={0}
								mb={0}
								sx={theme => ({
									lineHeight: rem(24),
									fontSize: rem(16),
									fontWeight: 400,
									letterSpacing: rem(-0.2),
									color: theme.colors["refactor"][4],
								})}
							>
								Name your default service where all your API
								configurations will reside.
							</Text>
						</Stack>
						<Box
							component='form'
							sx={{
								alignItems: "center",
								justifyContent: "center",
								display: "flex",
								flexDirection: "column",
								gap: rem(24),
								marginTop: rem(40),
							}}
							w={"100%"}
							onSubmit={handleSubmit(submit)}
						>
							<CustomTextInput
								name='workspaceName'
								placeholder='Service Name'
								label='Service Name'
								control={control}
								value={watch("workspaceName")}
								disabled={loading}
								errorMessage={formState.errors.workspaceName?.message}
							/>
							<CustomTextInput
								name='companyName'
								placeholder='Company Name'
								label='Company Name'
								control={control}
								value={watch("companyName")}
								disabled={loading}
								errorMessage={formState.errors.companyName?.message}
							/>
							<CustomTextInput
								name='companyWebsite'
								placeholder='Company Website'
								label='Company Website'
								control={control}
								value={watch("companyWebsite")}
								disabled={loading}
								errorMessage={formState.errors.companyWebsite?.message}
							/>
							<Button
								variant='filled'
								type='submit'
								radius='md'
								fullWidth
								sx={{
									padding: rem(16),
									height: rem(52),
									marginTop: formState.errors.companyWebsite?.message
										? rem(16)
										: 0,
									borderRadius: rem(8),
									fontWeight: 500,
									lineHeight: rem(16),
									letterSpacing: -0.2,
								}}
								disabled={loading}
								loading={loading}
							>
								Create Service
							</Button>
						</Box>
					</Stack>
				</Flex>
			</MediaQuery>
		</>
	)
}

export default Onboard
