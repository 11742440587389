import { useQuery } from "@tanstack/react-query"
import useAppStore from "../../../../zustand/new-store"
import { AxiosError } from "axios"
import { useAuth } from "@clerk/clerk-react"
import { Intervals } from "../../../../types/dashboard-chart-types"
import { getEndpointTraffic } from "../../../../api/server/analytics/route-traffic"
import { ANALYTICS_REFETCH_INTERVAL } from "../../../../constants/analytics-refetch"

const useEndpointTraffic = (interval: Intervals) => {
	const { cacheConfig } = useAppStore()
	const { getToken } = useAuth()
	return useQuery<{
		meta: {
			name: string
			type: string
		}[]
		data: {
			endpoint: string
			count: string
		}[]
		rows: number
	}>({
		queryKey: [cacheConfig?.host, interval, "endpoint traffic"],
		refetchOnWindowFocus: false,
		refetchInterval: data => {
			if (!data) {
				return false
			} else {
				return ANALYTICS_REFETCH_INTERVAL
			}
		},
		retry: 1,
		queryFn: async () => {
			const token = await getToken()
			if (!token) {
				throw new AxiosError("Unauthenticated")
			}
			return getEndpointTraffic({
				token,
				interval,
				host: cacheConfig?.host || "",
			})
		},
	})
}

export default useEndpointTraffic
