import { axiosConfig } from "../../../config/axios.config"
export const createNewDomain = async (body: {
	hostname: string
	method: "http" | "txt"
	org_id: string
	token: string
}) => {
	const response = await axiosConfig.post(
		"/hostname",
		{ ...body },
		{
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${body.token}`,
			},
		}
	)
	const { data } = response

	return data
}
