import { useEffect, useState } from "react"
import useAppStore from "../zustand/new-store"
import { IOrganization } from "../types/supabase.types"
import { useAuth } from "@clerk/clerk-react"
import { getOrganizationMetadata } from "../api/supabase/get-org-metadata"

const useOnboardingCompleted = ({ orgId }: { orgId: string }) => {
	const {
		organizationsMetadata,
		setActiveWorkSpace,
		activeWorkspace,
		setOrganizationMetadata,
		setOnboardLoading,
	} = useAppStore()

	/**
	 * Fetches the organization metadata by checking if the organization id exists, the organization metadata exists and the metadata matches the organization id
	 */
	const onboardCompleted: IOrganization | null =
		orgId && organizationsMetadata && orgId === organizationsMetadata?.org_id
			? organizationsMetadata
			: null

	/**
	 * fetches the active workspace by checking if the organization metadata exists,
	 * if there is an active workspace in global state and if the active workspace belongs to the current active organization
	 */
	const activeWorkspaceLocal =
		onboardCompleted &&
		activeWorkspace &&
		onboardCompleted.services.find(
			service => service.workspace_id === activeWorkspace.workspace_id
		)
			? activeWorkspace
			: null

	const [data, setData] = useState<IOrganization | null>(onboardCompleted)
	const [error, setError] = useState<null | any>(null)
	const { getToken } = useAuth()

	async function fetchData() {
		setError(null)
		setOnboardLoading(true)

		try {
			const data = await getOrganizationMetadata(getToken, orgId)

			if (data.data) {
				setOrganizationMetadata(data.data)
				setActiveWorkSpace(data.data.services[0])
				setData(data.data)
				setError(null)
			} else {
				setData(null)
				setError(data.data || data.error)
			}
		} catch (error) {
			setError(error)
			setData(null)
		} finally {
			setOnboardLoading(false)
		}
	}
	useEffect(() => {
		if (data && activeWorkspaceLocal) {
			setOnboardLoading(false)
			setError(null)
			return
		}

		if (data && !activeWorkspaceLocal) {
			setOnboardLoading(false)
			setError(null)
			const newActiveWorkSpace = data.services[0]
			setActiveWorkSpace(newActiveWorkSpace)
			return
		}
		fetchData()
	}, [organizationsMetadata])

	if (error || !data) {
		return { data: null, error: true, activeWorkspaceLocal }
	}

	return { data, error: false, activeWorkspaceLocal }
}

export default useOnboardingCompleted
