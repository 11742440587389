import { analyticsAxiosConfig } from "../../../config/axios.config"
export async function getEndpointAnalytics(body: {
	host: string
	token: string
}) {
	const response = await analyticsAxiosConfig.get(
		`/analytics/performance/endpoint-metrics?host=${body.host}&percentile=95&interval=6hour`,
		{
			headers: {
				Authorization: `Bearer ${body.token}`,
			},
		}
	)
	const { data } = response
	return data
}
