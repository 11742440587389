import {
	CacheConfig,
	CacheConfigSchemaType,
	ConfigSupabaseMetadata,
} from "../../../../../lib/parser"
import { RowData } from "../../../../../types/endpoint-table.types"
import { generateHostname } from "../../../../../lib/generate-hostname"

export function formatNumber(number: number) {
	if (number >= 1000000) {
		const roundedNumber = (number / 1000000).toFixed(1)
		return roundedNumber + "m"
	}
	if (number >= 1000) {
		const roundedNumber = (number / 1000).toFixed(1)
		return roundedNumber + "k"
	}
	return number.toFixed(0).toString()
}

export function formatBytes(bytes: number): string {
	if (bytes === 0) return "0 Bytes"

	const k = 1024
	const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB"]
	const i = Math.floor(Math.log(bytes) / Math.log(k))

	return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i]
}

export const parsePathsData = (data: CacheConfig & ConfigSupabaseMetadata) => {
	const paths = data?.cache?.paths
	const formattedData = []

	for (const path in paths) {
		for (const method in paths[path]) {
			const methodDetails = paths[path][method]
			let entry = {
				method: method.toUpperCase(),
				ttl: methodDetails.ttl?.toString() || "5",
				id: method.toUpperCase() + "-" + path,
				summary: methodDetails.summary || "",
				published: true,
				type: methodDetails.type!,
				async: methodDetails.async,
				enabled: methodDetails.enabled,
				unmanaged: methodDetails.unmanaged || false,
				path,
				cache: methodDetails.cache || false,
			}
			formattedData.push(entry)
		}
	}
	return formattedData
}
/**
 * * This function takes table data and parses it to form
 * * data to populate edit form
 */

export const parseTableToForm = (
	formValueToBeEdited: RowData,
	cacheConfig: CacheConfig
) => {
	const { path, method } = formValueToBeEdited

	const metadata = cacheConfig.cache.paths[path][method.toLowerCase()]

	return {
		path,
		method: method.toLowerCase(),
		type: metadata.type || "",
		summary: metadata.summary || "",
		description: metadata.description || "",
		ttl: metadata.ttl || 5,
		purge: metadata.purge || [],
		enabled: metadata.enabled || false,
		async: metadata.async || false,
		tags: metadata.tags || [],
		unmanaged: metadata.unmanaged,
		cache: metadata.cache,
	}
}
export const parseFormData = async (
	oldData: CacheConfig | null,
	formData: CacheConfigSchemaType[],
	originURL: string[]
) => {
	try {
		let data: Partial<CacheConfig> = {}
		for (const formDatum of formData) {
			const {
				path,
				method,
				type,
				purge,
				summary,
				description,
				ttl,
				enabled,
				tags,
				cache,
			} = formDatum

			if (!oldData) {
				data.host = await generateHostname()
				data.basePath = ""
				data.origins = []
			} else {
				data = oldData
				if (!oldData.host) {
					data.host = await generateHostname()
				}
				if (!oldData.origins) {
					data.origins = []
				}
				if (!oldData.basePath) {
					data.basePath = ""
				}
			}
			if (!data?.cache) {
				data = Object.assign(data, {
					cache: {
						apis: [...originURL],
						paths: {
							[path]: {
								[method]: {
									enabled,
									ttl,
									description,
									summary,
									type,
									tags,
									purge: purge.length < 1 ? null : purge,
									cache,
								},
							},
						},
					},
				})
			} else {
				const newPath = {
					[method]: {
						enabled,
						ttl,
						description,
						summary,
						type: type,
						tags,
						purge: purge.length < 1 ? null : purge,
						cache,
					},
				}

				if (data.cache.paths[path]) {
					data.cache.paths[path] = {
						...data.cache.paths[path],
						...newPath,
					}
				} else {
					data.cache.paths[path] = newPath
				}
			}
		}

		return { data, error: null }
	} catch (error) {
		console.log(error)
		return { data: null, error }
	}
}

export const removePaths = (
	oldData: CacheConfig,
	unwantedData: ReturnType<typeof parsePathsData>
) => {
	// * Get  paths object from the old config
	const paths = oldData.cache.paths
	// * Clone the old config
	const processedData = Object.assign({}, oldData)

	// * Loop through the array of paths to be deleted
	for (const datum of unwantedData) {
		// * Destructure the path and method from the current path to be deleted
		let { path, method } = datum

		// * Loop through the paths from the old config
		for (const singlePath in paths) {
			// * If the current path matches the path to be deleted, loop through the methods in the path object
			for (const pathMethod in paths[path]) {
				// * Get the purge array of the current item
				const purge = paths[singlePath][pathMethod.toLowerCase()]?.purge

				// * If there is a purge array in the and the current method to be deleted is a Get method, remove it from the purge array
				if (purge && method.toLowerCase() === "get") {
					const newPurge = purge?.filter(item => item !== path)
					processedData.cache.paths[singlePath][
						pathMethod.toLowerCase()
					].purge = newPurge?.length > 0 ? newPurge : null
				}
			}

			// * If the current path of the item does not match the current path from the old config,
			// * skip to the next item on the list of paths of the old config
			if (path !== singlePath) {
				continue
			}

			// * If the current path matches the path to be deleted, loop through the methods in the path object
			for (const pathMethod in paths[path]) {
				// * Get the purge array of the current item
				const purge = paths[singlePath][pathMethod.toLowerCase()]?.purge

				// * If there is a purge array in the and the current method to be deleted is a Get method, remove it from the purge array
				if (purge && method.toLowerCase() === "get") {
					const newPurge = purge?.filter(item => item !== path)
					processedData.cache.paths[singlePath][
						pathMethod.toLowerCase()
					].purge = newPurge?.length > 0 ? newPurge : null
				}

				// * Check if the method of the item to be deleted matches the current method
				// * skips to the next item if they don't match
				if (method.toLowerCase() !== pathMethod.toLowerCase()) {
					continue
				}
				// * If the path and method matches, delete the method object from the path object
				delete processedData.cache.paths[path][method.toLowerCase()]
			}
			// * Check if the path object is empty, it is deleted if it is
			const pathKeys = Object.keys(processedData.cache.paths[singlePath])
			if (pathKeys.length < 1) {
				delete processedData.cache.paths[singlePath]
			}
		}
	}
	return processedData
}

export const removePath = (
	oldData: CacheConfig,
	method: string,
	path: string
) => {
	// * Get  paths object from the old config
	const paths = oldData.cache.paths
	// * Clone the old config
	const processedData = Object.assign({}, oldData)

	// * Loop through the paths from the old config
	for (const singlePath in paths) {
		// * If the current path matches the path to be deleted, loop through the methods in the path object
		for (const pathMethod in paths[path]) {
			// * Get the purge array of the current item
			const purge = paths[singlePath][pathMethod.toLowerCase()]?.purge
			// * If there is a purge array in the and the current method to be deleted is a Get method, remove it from the purge array
			if (purge && method.toLowerCase() === "get") {
				const newPurge = purge?.filter(item => item !== path)
				processedData.cache.paths[singlePath][
					pathMethod.toLowerCase()
				].purge = newPurge?.length > 0 ? newPurge : null
			}
		}
		// * If the current path of the item does not match the current path from the old config,
		// * skip to the next item on the list of paths of the old config
		if (path !== singlePath) {
			continue
		}

		// * If the current path matches the path to be deleted, loop through the methods in the path object
		for (const pathMethod in paths[path]) {
			// * Get the purge array of the current item
			const purge = paths[singlePath][pathMethod.toLowerCase()]?.purge

			// * If there is a purge array in the and the current method to be deleted is a Get method, remove it from the purge array
			if (purge && method.toLowerCase() === "get") {
				const newPurge = purge?.filter(item => item !== path)
				processedData.cache.paths[singlePath][
					pathMethod.toLowerCase()
				].purge = newPurge?.length > 0 ? newPurge : null
			}

			// * Check if the method of the item to be deleted matches the current method
			// * skips to the next item if they don't match
			if (method.toLowerCase() !== pathMethod.toLowerCase()) {
				continue
			}
			// * If the path and method matches, delete the method object from the path object
			delete processedData.cache.paths[path][method.toLowerCase()]
		}
		// * Check if the path object is empty, it is deleted if it is
		const pathKeys = Object.keys(processedData.cache.paths[singlePath])
		if (pathKeys.length < 1) {
			delete processedData.cache.paths[singlePath]
		}
	}

	return processedData
}

/**
 * * This function replaces a path method in the cache config
 */

export const parseReplaceMethod = (
	oldPathMethod: { path: string; method: CacheConfigSchemaType["method"] },
	replacement: CacheConfigSchemaType,
	config: CacheConfig
) => {
	const { path: oPath, method: oMethod } = oldPathMethod
	const processedData = Object.assign({}, config)

	const {
		path,
		method,
		type,
		summary,
		description,
		ttl,
		purge,
		enabled,
		tags,
		async,
		unmanaged,
		cache,
	} = replacement
	const pathsData = processedData.cache.paths
	const pathData = processedData.cache.paths[oPath]
	const newPathData = processedData.cache.paths[path]

	// * checks if the endpoint path has changed
	if (path !== oPath) {
		// * if it has changed, delete the old entry
		delete processedData.cache.paths[oPath]

		// * Check if the old endpoint route is a get request
		if (oMethod === "get") {
			// * check if it is a get request and the new method is also a get request
			if (method === "get") {
				// * if the new request is also a get request, loop through all the requests that have that in their purge arrays and replace with the new endpoint
				for (const datum in pathsData) {
					const data = pathsData[datum]
					for (const p in data) {
						const singlePath = data[p]
						if (singlePath.purge && singlePath.purge.includes(oPath)) {
							const idx = singlePath.purge.indexOf(oPath)
							singlePath.purge[idx] = path
						}
					}
				}
			} else {
				// * else loop through all the requests and remove the old endpoint from their purge arrays
				for (const datum in pathsData) {
					const data = pathsData[datum]
					for (const p in data) {
						const singlePath = data[p]
						if (singlePath.purge && singlePath.purge.includes(oPath)) {
							singlePath.purge = singlePath.purge?.filter(
								p => p !== oPath
							)
						}
					}
				}
			}
		}
		// * check any request has the same path and method as the new path and method
		if (pathsData[path] && pathsData[path][method]) {
			return {
				error: "A path with this method already exists",
				data: null,
			}
		}
	} else {
		// * Check if the method has changed
		if (oMethod.toLowerCase() !== method.toLowerCase()) {
			// * if method has changed check if that method already exists
			const methodExists = pathData[method]

			if (methodExists) {
				// * if method exists, return error
				return {
					error: "A path with this method already exists",
					data: null,
				}
			}
			delete processedData.cache.paths[path][oMethod.toLowerCase()]
		}
	}

	if (path === oPath) {
		processedData.cache.paths[path] = {
			...pathData,
			[method.toLowerCase()]: {
				type: type ? type : undefined,
				summary,
				description,
				ttl,
				purge:
					method.toLowerCase() === "post" ||
					method.toLowerCase() === "put" ||
					method.toLowerCase() === "patch" ||
					method.toLowerCase() === "delete"
						? purge
						: null,
				enabled,
				tags,
				async,
				unmanaged,
				cache,
			},
		}
	} else {
		if (newPathData) {
			processedData.cache.paths[path] = {
				...newPathData,
				[method.toLowerCase()]: {
					type: type ? type : undefined,
					summary,
					description,
					ttl,
					purge:
						method.toLowerCase() === "post" ||
						method.toLowerCase() === "put" ||
						method.toLowerCase() === "patch" ||
						method.toLowerCase() === "delete"
							? purge
							: null,
					enabled,
					tags,
					async,
					unmanaged,
					cache,
				},
			}
		} else {
			processedData.cache.paths[path] = {
				[method.toLowerCase()]: {
					type: type ? type : undefined,
					summary,
					description,
					ttl,
					purge:
						method.toLowerCase() === "post" ||
						method.toLowerCase() === "put" ||
						method.toLowerCase() === "patch" ||
						method.toLowerCase() === "delete"
							? purge
							: null,
					enabled,
					tags,
					async,
					unmanaged,
					cache,
				},
			}
		}
	}

	return { data: processedData, error: null }
}

export const generateLineChartForEndpointsWithoutMetadata = (
	interval: number
) => {
	const date = new Date()

	const values = []

	for (let i = 1; i <= 12; i++) {
		values.push({
			value: 0,
			date: new Date(date.getTime()),
		})
		date.setHours(date.getHours() - interval)
	}
	return values
}
