import { Alert, Text, rem, createStyles } from "@mantine/core"
import React from "react"

interface IPropTypes {
	title?: string
	icon: React.ReactNode
	text: string
	variant: "outline" | "light"
	mt?: number | string
}

const useStyles = createStyles(theme => ({
	alertRoot: {
		borderRadius: rem(8),
	},
	alertGrey: {
		border: "none",
		background: theme.colors["gray"][0],
	},
	alertDanger: {
		background: theme.colors["danger"][2],
	},
	alertTitle: {
		color: theme.colors["refactor"][0],
	},
	alertDangerTitle: {
		color: theme.colors["danger"][0],
	},
	alertIcon: {
		color: theme.colors["refactor"][0],
	},
	alertDangerIcon: {
		color: theme.colors["danger"][0],
	},
}))
const CustomAlert = ({ title, icon, text, variant, mt }: IPropTypes) => {
	const { classes, cx } = useStyles()
	return (
		<Alert
			variant={variant}
			title={title ? title : ""}
			icon={icon}
			mt={mt}
			classNames={{
				root: cx(classes.alertRoot, {
					[classes.alertDanger]: variant === "light",
					[classes.alertGrey]: variant === "outline",
				}),
				title: classes.alertTitle,
				icon: cx({
					[classes.alertDangerIcon]: variant === "light",
					[classes.alertIcon]: variant === "outline",
				}),
			}}
		>
			<Text
				lh={rem(20)}
				fz={rem(12)}
				c='refactor.4'
				pl={rem(4)}
			>
				{text}
			</Text>
		</Alert>
	)
}

export default CustomAlert
