import { Box, Flex, rem } from "@mantine/core"
import usePasswordStrength from "../../hooks/usePasswordStrength"

const PasswordStrength = ({ password }: { password: string | undefined }) => {
	const strength = usePasswordStrength(password)

	return (
		<Flex
			gap={rem(4)}
			w='100%'
		>
			{new Array(10).fill("").map((item, idx) => {
				const percentage = (idx + 1) * 10
				return (
					<Box
						key={idx}
						h={rem(3)}
						sx={theme => {
							const neutral = theme.colors["refactor"][6]
							const red = theme.colors.danger[0]
							const yellow = theme.colors.yellow[0]
							const green = theme.colors.green[0]
							return {
								borderRadius: rem(8),
								flexGrow: 1,
								width: "100%",
								transition: "0.3s all ease",
								background:
									strength <= 40 && percentage <= strength
										? red
										: strength > 40 &&
										  strength <= 80 &&
										  percentage <= 80
										? yellow
										: strength > 80 &&
										  strength <= 100 &&
										  percentage <= strength
										? green
										: neutral,
							}
						}}
					/>
				)
			})}
		</Flex>
	)
}

export default PasswordStrength
