import { useState } from "react"
import CustomDrawer from "../../../../Components/Drawer/Drawer"
import Cards from "./Cards"
import { Group, Text, rem, createStyles } from "@mantine/core"
import { IconCornerUpLeft } from "@tabler/icons-react"
import PostmanForm from "./PostmanForm"
import { useDisclosure } from "@mantine/hooks"
import SwaggerForm from "./SwaggerForm"
import { zodResolver } from "@hookform/resolvers/zod"
import { FormProvider, useForm } from "react-hook-form"
import {
	serviceAuthSchema,
	serviceAuthSchemaType,
} from "../../../../lib/parser"
import AuthMethod from "../AuthMethod"
const useStyles = createStyles(theme => ({
	cornerIcon: {
		stroke: theme.colors["refactor"][0],
	},
}))

const SpecIntegration = ({
	opened,
	close,
	onError,
}: {
	opened: boolean
	open: () => void
	close: () => void
	onError: () => void
}) => {
	const [hideTitle, setHideTitle] = useState(false)
	const [title, setTitle] = useState("Choose Your Authorization Method")
	const [current, setCurrent] = useState<"postman" | "swagger" | "" | "auth">(
		"auth"
	)
	const { classes } = useStyles()
	const [success, setSuccess] = useState(false)
	const [openAuth, { toggle: toggleAuth }] = useDisclosure(false)

	const authMethods = useForm<serviceAuthSchemaType>({
		resolver: zodResolver(serviceAuthSchema),
		defaultValues: {
			key: "",
		},
		mode: "all",
	})

	return (
		<CustomDrawer
			opened={opened}
			onClose={() => {
				close()
				setTitle("Choose Your Authorization Method")
				setCurrent("auth")
				authMethods.reset({
					key: "",
				})

				if (openAuth) {
					toggleAuth()
				}
			}}
			title={hideTitle ? undefined : title}
			backButtonContent={
				current !== "auth" ? (
					<Group
						align='center'
						sx={{ gap: rem(8) }}
					>
						<IconCornerUpLeft className={classes.cornerIcon} />
						<Text
							sx={theme => ({
								fontSize: rem(14),
								lineHeight: rem(16),
								letterSpacing: -0.2,
								color: theme.colors["refactor"][0],
							})}
						>
							Back to {current === "" ? "Auth" : "Setup"}
						</Text>
					</Group>
				) : null
			}
			backButtonAction={() => {
				if (current === "auth") {
					return
				}

				if (current === "") {
					setCurrent("auth")
					setTitle("Choose Your Authorization Method")
				}
				if (current === "postman" || current === "swagger") {
					setCurrent("")
					setTitle("Choose Your Setup Method")
				}
			}}
		>
			<FormProvider {...authMethods}>
				<>
					{current === "" ? (
						<Cards
							openPostman={() => {
								setTitle("Postman Collection Setup")
								setCurrent("postman")
							}}
							openSwagger={() => {
								setTitle("OpenAPI Setup")
								setCurrent("swagger")
							}}
						/>
					) : null}

					{current === "auth" ? (
						<AuthMethod
							openAuth={openAuth}
							toggleAuth={toggleAuth}
							next={() => {
								setTitle("Choose Your Setup Method")
								setCurrent("")
							}}
						/>
					) : null}
					{current === "postman" ? (
						<PostmanForm
							failureAction={() => {
								setHideTitle(true)
								setSuccess(false)
								setHideTitle(true)
							}}
							onError={onError}
							successAction={() => {
								setHideTitle(true)
								setSuccess(true)
								setHideTitle(true)
							}}
							retryAction={() => {
								setHideTitle(false)
								setSuccess(false)
							}}
							success={success}
						/>
					) : null}

					{current === "swagger" ? (
						<SwaggerForm
							failureAction={() => {
								setHideTitle(true)
								setSuccess(false)
								setHideTitle(true)
							}}
							hideTitle={hideTitle}
							onError={onError}
							successAction={() => {
								setHideTitle(true)
								setHideTitle(true)

								setSuccess(true)
							}}
							retryAction={() => {
								setHideTitle(false)
								setSuccess(false)
							}}
							success={success}
						/>
					) : null}
				</>
			</FormProvider>
		</CustomDrawer>
	)
}

export default SpecIntegration
