import { TextInput } from "react-hook-form-mantine"
import {
	TextInput as MantineTextInput,
	Stack,
	TextInputProps,
	createStyles,
	rem,
} from "@mantine/core"
import { Control } from "react-hook-form"
import { useState } from "react"
import { IconInfoCircle } from "@tabler/icons-react"
import CustomAlert from "../CustomAlert/CustomAlert"

interface IProps {
	name: string
	disabled?: boolean
	label?: React.ReactNode
	placeholder?: string
	required?: true
	control?: Control<any, any>
	rightSection?: React.ReactNode
	value?: string
	errorMessage?: string
	onChange?: React.ChangeEventHandler<HTMLInputElement>
	className?: string
	keepLabel?: boolean
	info?: {
		infoLabel?: string
		infoText: string
	}
}
const useStyles = createStyles(theme => ({
	root: {
		height: rem(56),
		width: "100%",
		position: "relative",
	},
	wrapper: {
		height: "100%",
	},
	error: {
		letterSpacing: -0.2,
		fontSize: rem(14),
		color: theme.colors["danger"][0],
		bottom: rem(16),
	},
	input: {
		borderRadius: rem(8),
		border: `1px solid ${theme.colors["refactor"][5]}`,
		padding: `${rem(18)} ${rem(16)}`,
		height: "100%",
		lineHeight: rem(20),
		fontSize: rem(16),
		letterSpacing: -0.2,
		transition: "0.1s padding ease",
		color: theme.colors[theme.primaryColor][7],
		"::placeholder": {
			color: theme.colors["refactor"][0],
			letterSpacing: -0.2,
			fontSize: rem(15),
			lineHeight: rem(20),
		},
		":focus": {
			border: `1px solid ${theme.colors["refactor"][5]}`,
		},
		"&[data-invalid]": {
			border: `1px solid ${theme.colors["danger"][0]}`,
			color: theme.colors["danger"][0],
			caretColor: theme.colors["danger"][0],
			"::placeholder": {
				color: theme.colors["danger"][0],
				caretColor: theme.colors["danger"][0],
				letterSpacing: -0.2,
				fontSize: rem(15),
				lineHeight: rem(20),
			},
		},
	},
	inputFocus: {
		paddingTop: rem(32),
		paddingBottom: rem(9),
	},

	label: {
		cursor: "pointer",
		position: "absolute",
		fontSize: rem(14),
		paddingLeft: rem(16.9),
		paddingTop: rem(9),
		zIndex: 1,
		display: "none",
	},
	labelFocus: {
		display: "inline-block",
	},
}))

export default function CustomTextInput({
	name,
	disabled,
	label,
	placeholder,
	required,
	control,
	rightSection,
	value,
	onChange,
	errorMessage,
	className,
	keepLabel,
	info,
}: IProps) {
	const [focused, setFocused] = useState(false)
	const { classes, cx } = useStyles()

	if (control) {
		return (
			<Stack
				spacing={rem(8)}
				w='100%'
				// @ts-ignore
				sx={{
					flexGrow: `1!important`,
				}}
			>
				<TextInput
					name={name}
					disabled={disabled}
					label={label}
					placeholder={focused ? "" : placeholder}
					control={control}
					required={required}
					rightSection={rightSection}
					className={className}
					classNames={{
						input: cx(classes.input, {
							[classes.inputFocus]: focused || !!value || keepLabel,
						}),
						wrapper: classes.wrapper,
						root: classes.root,
						label: cx(classes.label, {
							[classes.labelFocus]: focused || !!value || keepLabel,
						}),
						error: classes.error,
					}}
					error={errorMessage}
					onFocus={() => setFocused(true)}
					onBlur={() => setFocused(false)}
				/>

				{focused && !!info ? (
					<CustomAlert
						title={info?.infoLabel ? info.infoLabel : ""}
						icon={<IconInfoCircle />}
						text={info.infoText}
						variant='outline'
						mt={errorMessage ? rem(24) : 0}
					/>
				) : null}
			</Stack>
		)
	} else {
		return (
			<Stack spacing={rem(8)}>
				<MantineTextInput
					value={value}
					onChange={onChange}
					name={name}
					disabled={disabled}
					label={label}
					placeholder={focused ? "" : placeholder}
					required={required}
					classNames={{
						input: cx(classes.input, {
							[classes.inputFocus]: focused || !!value || keepLabel,
						}),
						wrapper: classes.wrapper,
						root: classes.root,
						label: cx(classes.label, {
							[classes.labelFocus]: focused || !!value || keepLabel,
						}),
						error: classes.error,
					}}
					error={errorMessage && !info ? errorMessage : ""}
					onFocus={() => setFocused(true)}
					onBlur={() => setFocused(false)}
					rightSection={rightSection}
					className={className}
				/>
				{focused && !!info ? (
					<CustomAlert
						title={info?.infoLabel ? info.infoLabel : ""}
						icon={<IconInfoCircle />}
						text={info.infoText}
						variant='outline'
					/>
				) : null}
			</Stack>
		)
	}
}
