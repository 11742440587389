import { Group, Text, SegmentedControl, rem } from "@mantine/core"
import { Intervals } from "../../../../types/dashboard-chart-types"
import { useDashboardStyles } from "./styles"

interface IProps {
	interval: Intervals
	onChange: (e: Intervals) => void
}
const HomeHeader = ({ interval, onChange }: IProps) => {
	const { classes } = useDashboardStyles()

	return (
		<Group
			align='center'
			sx={{
				justifyContent: "space-between",
			}}
		>
			<Text
				component='h1'
				m={0}
				c='brand.7'
				fw={500}
				lh={rem(24)}
				fz={rem(21)}
			>
				Overview
			</Text>
			<SegmentedControl
				value={interval}
				classNames={{
					indicator: classes.indicator,
					root: classes.root,
					control: classes.control,
					label: classes.label,
				}}
				onChange={e => {
					onChange(e as Intervals)
				}}
				data={[
					{
						label: "15m",
						value: Intervals["15minute"],
					},

					{
						label: "1h",
						value: Intervals["1hour"],
					},
					{
						label: "6h",
						value: Intervals["6hour"],
					},
					{
						label: "1d",
						value: Intervals["1day"],
					},
					{
						label: "7d",
						value: Intervals["7days"],
					},
					{
						label: "30d",
						value: Intervals["1month"],
					},
				]}
			/>
		</Group>
	)
}

export default HomeHeader
