export enum Status {
	Invited = "Invited",
	Joined = "Joined",
}

export interface RowData {
	id: string
	email: string
	role: string
	created: string
	status: Status
	deleteFn: () => Promise<void>
	updateFn: (newRole?: "admin" | "basic_member") => Promise<void>
}
export interface ThProps {
	children: React.ReactNode
	reversed: boolean
	sorted: boolean
	onSort(): void
}
