import {
	Group,
	Stack,
	Text,
	UnstyledButton,
	rem,
	createStyles,
	Center,
	NavLink,
} from "@mantine/core"
import { useDocumentTitle } from "@mantine/hooks"
import { IconCornerUpLeft } from "@tabler/icons-react"
import {
	Outlet,
	Link,
	useParams,
	useSearchParams,
	useLocation,
	Navigate,
} from "react-router-dom"
import useAppStore from "../../../../../zustand/new-store"
import { useEffect, useLayoutEffect, useMemo } from "react"
import { parsePathsData } from "../../components/Table/utils"
import { useAuth } from "@clerk/clerk-react"
import { getEndpoints } from "../../../../../api/supabase/get-config"
const endpointsSubLinks = [
	{ href: "metrics", title: "Metrics" },
	{ href: "logs", title: "Logs" },
	{ href: "endpoint-settings", title: "Settings" },
]

const useStyles = createStyles(theme => ({
	"chip-get": {
		background: theme.colors["green"][4],
		color: theme.colors["green"][3],
	},
	"chip-patch": {
		background: theme.colors["gray"][1],
		color: theme.colors["refactor"][0],
	},

	"chip-post": {
		background: theme.colors["blue"][1],
		color: theme.colors["blue"][0],
	},

	"chip-put": {
		background: theme.colors["yellow"][2],
		color: theme.colors["yellow"][3],
	},
	"chip-delete": {
		background: theme.colors["danger"][2],
		color: theme.colors["danger"][3],
	},
	"chip-options": {
		background: theme.colors["green"][6],
		color: theme.colors["green"][5],
	},
	"chip-head": {
		background: theme.colors["blue"][2],
		color: theme.colors["brand"][9],
	},
	root: {
		borderRadius: rem(4),
		":hover": {
			background: "transparent",
		},
	},
	rootActive: {
		background: "#F1EEFF",
		":hover": {
			background: "#F1EEFF",
		},
	},

	label: {
		fontSize: rem(14),
		fontWeight: 500,
		lineHeight: rem(20),
		color: theme.colors["refactor"][4],
	},

	labelActive: {
		color: theme.colors[theme.primaryColor][2],
	},

	iconActive: {
		color: theme.colors[theme.primaryColor][2],
	},
}))

function isActive(href: string, pathname: string): boolean {
	if (pathname.includes(href) && href !== "") {
		return true
	} else {
		return false
	}
}
const EndpointDetailsLayout = () => {
	const { classes, cx } = useStyles()
	const { method } = useParams()
	const [searchParams] = useSearchParams()
	const { orgId, getToken } = useAuth()
	const {
		cacheConfig,
		activeWorkspace,
		setActiveEndpoint,
		activeEndpoint,
		setCacheConfig,
	} = useAppStore()
	const endpoint = searchParams.get("p")
	useDocumentTitle(
		`${method?.toUpperCase() || ""} - ${endpoint || "Endpoints"}`
	)

	async function fetchEndpoints() {
		try {
			const result = await getEndpoints(
				orgId || "",
				activeWorkspace?.workspace_id || "",
				getToken
			)
			if (result.data) {
				setCacheConfig(result.data)
			} else {
				console.log(result.error)
			}
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		let ignore = false

		if (!ignore) {
			fetchEndpoints()
		}
		return () => {
			ignore = true
		}
	}, [])

	const { pathname } = useLocation()
	const modifiedData = useMemo(() => {
		if (cacheConfig && method && endpoint) {
			const parsedData = parsePathsData(cacheConfig)
			return parsedData.map(
				datum =>
					datum.method.toLowerCase() === method?.toLowerCase() &&
					datum.path === endpoint
			)
		} else {
			return false
		}
	}, [cacheConfig, activeWorkspace?.workspace_id, orgId])
	useLayoutEffect(() => {
		if (modifiedData && method && endpoint) {
			setActiveEndpoint({
				method,
				path: endpoint,
			})
		} else {
			setActiveEndpoint(null)
		}
	}, [cacheConfig])

	if (!activeEndpoint) {
		return (
			<Navigate
				to='/endpoints'
				replace
			/>
		)
	}
	return (
		<Stack
			w='100%'
			sx={{
				gap: rem(32),
			}}
		>
			<UnstyledButton
				to='/endpoints'
				component={Link}
				sx={theme => ({
					borderRadius: rem(8),
					borderColor: theme.colors["refactor"][3],
					padding: `${rem(8)} ${rem(12)}`,
					borderWidth: "1px",
					borderStyle: "solid",
					width: "fit-content",
				})}
			>
				<Group
					sx={{
						gap: rem(8),
					}}
					c='refactor.0'
				>
					<IconCornerUpLeft size={14} />
					<Text
						lts={-0.2}
						lh={rem(14)}
						fz={rem(12)}
						c='refactor.0'
						fw={400}
					>
						Back
					</Text>
				</Group>
			</UnstyledButton>

			<Stack
				pb={rem(24)}
				sx={theme => ({
					gap: rem(16),
					borderBottom: `1px solid ${theme.colors["refactor"][2]}`,
				})}
			>
				<Group
					align='center'
					sx={{
						gap: rem(10),
					}}
				>
					<Center
						pt={rem(4)}
						pb={rem(4)}
						fw={500}
						tt='capitalize'
						lts={0.5}
						lh={rem(16)}
						pl={rem(8)}
						pr={rem(8)}
						ff='Outfit, sans-serif'
						fz={rem(13)}
						sx={{
							borderRadius: rem(4),
						}}
						h={rem(28)}
						// @ts-ignore
						className={classes[`chip-${method.toLowerCase()}`]}
					>
						{method?.toUpperCase()}
					</Center>
					<Text
						component='h1'
						m={0}
						c='brand.7'
						fz={rem(21)}
						fw={500}
						lh={rem(24)}
					>
						{searchParams.get("p")}
					</Text>
				</Group>
			</Stack>

			<Group
				align='flex-start'
				sx={{
					gap: rem(40),
				}}
				noWrap
			>
				<Stack sx={{ gap: rem(8) }}>
					{endpointsSubLinks.map(link => (
						<NavLink
							component={Link}
							to={`${link.href}?p=${searchParams.get("p")}`}
							key={link.title}
							label={link.title}
							w={rem(136)}
							classNames={{
								root: cx(classes.root, {
									[classes.rootActive]: isActive(link.href, pathname),
								}),
								label: cx(classes.label, {
									[classes.labelActive]: isActive(link.href, pathname),
								}),
							}}
						/>
					))}
				</Stack>
				<Outlet />
			</Group>
		</Stack>
	)
}

export default EndpointDetailsLayout
