import DrawerSuccess from "../../../../../Components/DrawerSuccess/DrawerSuccess"
import DrawerError from "../../../../../Components/DrawerError/DrawerError"
import useAppStore from "../../../../../zustand/new-store"
import { useAuth } from "@clerk/clerk-react"
import { useLayoutEffect, useMemo, useState } from "react"
import CustomDrawer from "../../../../../Components/Drawer/Drawer"
import {
	addSinglePathSchema,
	CacheConfigSchemaType,
} from "../../../../../lib/parser"
import { Box, Button, rem, Stack, Text, Group } from "@mantine/core"
import { SubmitHandler, useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import CustomTextInput from "../../../../../Components/TextInput/TextInput"
import CustomSelect from "../../../../../Components/Select/Select"
import { MultiSelect } from "react-hook-form-mantine"
import useAddEndpoint from "../../api/useAddEndpoints"
import {
	parseFormData,
	parseReplaceMethod,
	parsePathsData,
} from "../Table/utils"
import { notifications } from "@mantine/notifications"
import { useParams, useSearchParams } from "react-router-dom"
import useEditEndpoint from "../../api/useEditEndpoint"
import { IconHelpCircleFilled } from "@tabler/icons-react"
import CustomSwitch from "../../../../../Components/CustomSwitch/CustomSwitch"
import { useDrawerStyles } from "./styles"
import CustomHoverCard from "../../../../../Components/CustomHoverCard/CustomHoverCard"
import { variants, inputLabelInfo } from "./meta"
import CustomNumberInput from "../../../../../Components/NumberInput/NumberInput"
import DangerAlert from "../../../../../Components/DangerAlert/DangerAlert"

const EndpointDrawer = () => {
	const { method } = useParams<{
		method:
			| ""
			| "get"
			| "option"
			| "post"
			| "put"
			| "patch"
			| "delete"
			| "head"
	}>()
	const [searchParams] = useSearchParams()
	const [multiSelectFocused, setMultiSelectFocused] = useState(false)
	const endpoint = searchParams.get("p")
	const {
		activeWorkspace,
		setEndpointDrawerOpen,
		endpointDrawerOpen,
		cacheConfig,
		editConfigFormValues,
		setEditConfigFormValues,
		endpointDrawerType: type,
	} = useAppStore()
	const { getToken } = useAuth()

	const [searchPathValue, onPathSearchChange] = useState("")
	const { classes, cx } = useDrawerStyles()

	const endPointTypes = useMemo(() => {
		if (cacheConfig?.authorization && cacheConfig?.authorization?.type) {
			return [
				{ value: "private", label: "Private" },
				{ value: "public", label: "Public" },
				{ value: "protected", label: "Protected" },
				{ value: "login", label: "Login" },
				{ value: "logout", label: "Logout" },
				{ value: "refresh", label: "Refresh" },
			]
		} else {
			return [
				{ value: "public", label: "Public" },
				{ value: "login", label: "Login" },
			]
		}
	}, [])

	/**
	 * Computed and memoized options for purge multiselect.
	 * @constant
	 */
	const modifiedData = useMemo(() => {
		if (cacheConfig) {
			return parsePathsData(cacheConfig)
				?.filter(object => {
					return object.method.toLowerCase() === "get"
				})
				?.filter(object => {
					return object.path !== endpoint
				})
				?.map(object => {
					return {
						value: object.path,
						label: object.path,
					}
				})
		} else {
			return []
		}
	}, [cacheConfig?.cache?.paths, activeWorkspace?.workspace_id])
	const protocol = cacheConfig?.protocol || "REST"

	/**
	 * Computed and memoized initial state for endpoint form. Takes into account the type of drawer (new or edit), search and path params, and global editConfigFormValues variable
	 * @constant
	 */
	const formData: Partial<CacheConfigSchemaType> = useMemo(() => {
		if (!cacheConfig) {
			return {
				purge: [],
				description: "",
				ttl: 5,
				path: "",
				tags: [],
				summary: "",
				enabled: true,
				async: false,
				method: protocol === "JSON-RPC" ? "post" : "",
				cache: false,
			}
		}
		if (type === "edit") {
			if (endpoint && method) {
				if (
					cacheConfig.cache.paths[endpoint] &&
					cacheConfig.cache.paths[endpoint][method]
				) {
					const currentPathConfig =
						cacheConfig.cache.paths[endpoint][method]

					return {
						purge: currentPathConfig.purge || [],
						description: currentPathConfig.description || "",
						tags: currentPathConfig.tags || [],
						ttl: currentPathConfig.ttl || 5,
						path: endpoint,
						method: method,
						type: currentPathConfig.type as
							| "private"
							| "public"
							| "protected"
							| "login"
							| "logout"
							| "refresh",
						async: currentPathConfig.async || false,
						summary: currentPathConfig.summary || "",
						enabled: currentPathConfig.enabled || true,
						cache: currentPathConfig.cache || false,
					}
				} else {
					return {
						purge: [],
						description: "",
						ttl: 5,
						summary: "",
						tags: [],
						path: "",
						enabled: true,
						method: "",
						async: false,
						cache: false,
					}
				}
			} else {
				if (editConfigFormValues) {
					return editConfigFormValues
				} else {
					return {
						purge: [],
						description: "",
						ttl: 5,
						summary: "",
						tags: [],
						enabled: true,
						async: false,
						path: "",
						method: protocol === "JSON-RPC" ? "post" : "",
						cache: false,
					}
				}
			}
		} else {
			return {
				purge: [],
				description: "",
				ttl: 5,
				summary: "",
				tags: [],
				enabled: true,
				async: false,
				path: "",
				cache: false,
				method: protocol === "JSON-RPC" ? "post" : "",
			}
		}
	}, [cacheConfig, activeWorkspace?.workspace_id, editConfigFormValues])
	const {
		handleSubmit,
		control,
		reset: resetInputFields,
		watch,
		formState,
		getValues,
		setValue,
		setError,
	} = useForm<CacheConfigSchemaType>({
		resolver: zodResolver(addSinglePathSchema),
		defaultValues: formData,
	})

	useLayoutEffect(() => {
		resetInputFields({ ...formData })
	}, [formData])

	const {
		setPublishEndpointLoading,
		publishEndpointLoading,
		serverSideJSONErrors,
		setServerSideValidationErrors,
		mutate: publish,
		isLoading: isPublishLoading,
		success,
		setSuccess,
	} = useAddEndpoint(resetInputFields)

	const {
		serverSideJSONErrors: serverSideEditJSONErrors,
		setServerSideValidationErrors: setServerSideEditErrors,
		mutate: edit,
		isLoading: isEditLoading,
		success: editSuccess,
		setSuccess: setEditSuccess,
		supabaseEditLoading,
		setSupabaseEditLoading,
	} = useEditEndpoint()

	const submitHandler: SubmitHandler<CacheConfigSchemaType> = async values => {
		if (!cacheConfig) {
			return
		}
		const basePath = cacheConfig.basePath

		if (basePath !== "/" && values.path.startsWith(basePath)) {
			setError("path", {
				message:
					"The basePath should not be included in the endpoint path.",
			})
			return
		}
		const token = await getToken()

		if ((endpoint && method) || type === "edit") {
			if (!activeWorkspace || !token) {
				setServerSideValidationErrors(["Something went wrong"])
				return
			}
			setSupabaseEditLoading(true)
			const {
				workspace_id,
				source,
				user_id,
				org_id,
				uuid,
				created_at,
				updated_at,
				...dataWithoutSupabaseConfig
			} = structuredClone(cacheConfig)
			const editedConfig = parseReplaceMethod(
				{ path: getValues("path"), method: getValues("method") },
				values,
				dataWithoutSupabaseConfig
			)
			const { data, error } = editedConfig

			if (error || !data) {
				setSupabaseEditLoading(false)
				setServerSideEditErrors([error])
				setEditSuccess(false)
				return
			}
			notifications.show({
				id: "edit-path",
				loading: true,
				title: "Saving",
				message: "Saving changes, please wait",
				autoClose: false,
				withCloseButton: false,
			})
			edit({
				config: data,
				token,
			})
		} else {
			if (!cacheConfig || !activeWorkspace || !token) {
				setServerSideValidationErrors(["Something went wrong"])
				return
			}
			setServerSideValidationErrors([])
			setPublishEndpointLoading(true)
			const {
				workspace_id,
				source,
				user_id,
				org_id,
				uuid,
				created_at,
				updated_at,
				...dataWithoutSupabaseConfig
			} = structuredClone(cacheConfig)

			const parsed = await parseFormData(
				dataWithoutSupabaseConfig,
				[values],
				cacheConfig.origins
			)

			const { error, data } = parsed

			if (error || !data) {
				console.log(error)
				setPublishEndpointLoading(false)
				setServerSideValidationErrors(["Something went wrong"])
				return
			}

			publish({ config: data, token })

			notifications.show({
				id: "publish-path",
				loading: true,
				title: "Publishing",
				message: "Publishing saved paths, please wait",
				autoClose: false,
				withCloseButton: false,
			})
		}
	}

	return (
		<CustomDrawer
			opened={endpointDrawerOpen}
			onClose={() => {
				if (
					isPublishLoading ||
					publishEndpointLoading ||
					isEditLoading ||
					supabaseEditLoading
				) {
					return
				}
				setSuccess(false)
				setServerSideValidationErrors([])
				setEditSuccess(false)
				setServerSideEditErrors([])
				setEndpointDrawerOpen(false)
				resetInputFields()
				setEditConfigFormValues(null)
			}}
			title={
				success || serverSideJSONErrors.length > 0
					? ""
					: variants[type].title
			}
		>
			{serverSideJSONErrors.length > 0 ||
			serverSideEditJSONErrors.length > 0 ? (
				<DrawerError
					errors={[]}
					btnAction={() => {
						setServerSideValidationErrors([])
						setServerSideEditErrors([])
						resetInputFields()
					}}
					title='Oops! Something Went Wrong'
					subtext={variants[type].errorSubtext}
				/>
			) : success || editSuccess ? (
				<DrawerSuccess
					title={
						type === "new"
							? "Endpoint Route added successfully!"
							: "Edit successful"
					}
					subtext={variants[type].successSubtext}
					btnAction={() => {
						setSuccess(false)
						setEditSuccess(false)
						setServerSideEditErrors([])
						setServerSideValidationErrors([])
						setEndpointDrawerOpen(false)
						resetInputFields()
						setEditConfigFormValues(null)
					}}
					btnText='Done'
				/>
			) : (
				<Stack
					sx={{
						gap: rem(24),
						alignSelf: "stretch",
					}}
					mt={rem(24)}
					mih='100%'
					w='100%'
				>
					<Text
						c='refactor.4'
						fz={rem(16)}
						fw={400}
						lh={rem(24)}
					>
						{variants[type].subtext}
					</Text>
					<Box
						component='form'
						onSubmit={handleSubmit(submitHandler)}
						h='100%'
					>
						<Stack
							h='100%'
							sx={{
								justifyContent: "space-between",
								gap: rem(32),
							}}
						>
							<Stack
								sx={{
									gap: rem(20),
								}}
							>
								<CustomTextInput
									control={control}
									name='path'
									label={
										protocol === "JSON-RPC"
											? "Method (e.g. eth_getLogs)"
											: "Path (e.g: /apples/:id)"
									}
									placeholder={
										protocol === "JSON-RPC"
											? "Method (e.g. eth_getLogs)"
											: "Path (e.g: /apples/:id)"
									}
									errorMessage={formState.errors.path?.message}
									disabled={
										isPublishLoading ||
										publishEndpointLoading ||
										isEditLoading ||
										supabaseEditLoading ||
										(!!endpoint && !!method && !!cacheConfig) ||
										type === "edit"
									}
									keepLabel={!!watch("path")}
									info={{
										infoText: "Do not include base path",
									}}
								/>

								{protocol === "JSON-RPC" ? null : (
									<CustomSelect
										name='method'
										control={control}
										placeholder='Method'
										label='Method'
										disabled={
											isPublishLoading ||
											publishEndpointLoading ||
											isEditLoading ||
											supabaseEditLoading ||
											(!!endpoint && !!method && !!cacheConfig) ||
											type === "edit"
										}
										keepLabel={!!watch("method")}
										data={[
											{ value: "post", label: "POST" },
											{ value: "put", label: "PUT" },
											{ value: "patch", label: "PATCH" },
											{ value: "get", label: "GET" },
											{ value: "head", label: "HEAD" },
											{ value: "option", label: "OPTION" },
											{ value: "delete", label: "DELETE" },
										]}
										className={cx({
											[classes.margin]:
												!!formState.errors.path?.message,
										})}
										errorMessage={formState.errors.method?.message}
									/>
								)}

								{watch("method") === "post" ||
								watch("method") === "put" ||
								watch("method") === "patch" ||
								watch("method") === "delete" ? (
									<MultiSelect
										name='purge'
										control={control}
										onFocus={() => {
											setMultiSelectFocused(true)
										}}
										onBlur={() => {
											setMultiSelectFocused(false)
										}}
										label={
											<Group spacing={rem(8)}>
												<Text>Purge (Optional)</Text>
												<CustomHoverCard
													target={
														<IconHelpCircleFilled
															size={rem(16)}
														/>
													}
													title={inputLabelInfo().purge.title}
													body={inputLabelInfo().purge.body}
													width={400}
													position='left'
												/>
											</Group>
										}
										data={modifiedData}
										w={"100%"}
										classNames={{
											input: cx(classes.input, {
												[classes.inputFocus]:
													multiSelectFocused ||
													watch("purge").length > 0,
											}),
											wrapper: classes.wrapper,
											error: classes.error,
											searchInput: classes.searchInput,
											label: cx(classes.label, {
												[classes.labelFocus]:
													multiSelectFocused ||
													watch("purge").length > 0,
											}),
											root: classes.root,
										}}
										className={cx({
											[classes.margin]:
												!!formState.errors.method?.message ||
												(!!formState.errors.path?.message &&
													protocol === "JSON-RPC"),
										})}
										disabled={
											isPublishLoading ||
											publishEndpointLoading ||
											isEditLoading ||
											supabaseEditLoading
										}
										placeholder='Purge'
										error={formState.errors.purge?.message}
										clearButtonProps={{
											"aria-label": "Clear selection",
										}}
										clearable
										maxDropdownHeight={160}
										searchable
										withinPortal
										searchValue={searchPathValue}
										getCreateLabel={query => `+ Create ${query}`}
										onSearchChange={onPathSearchChange}
										nothingFound='Nothing found'
									/>
								) : null}

								<CustomSelect
									name='type'
									control={control}
									placeholder='Type'
									errorMessage={formState.errors.type?.message}
									disabled={
										isPublishLoading ||
										publishEndpointLoading ||
										isEditLoading ||
										supabaseEditLoading
									}
									label={
										<Group spacing={rem(8)}>
											<Text>Type</Text>
											<CustomHoverCard
												target={
													<IconHelpCircleFilled size={rem(16)} />
												}
												title={inputLabelInfo().type.title}
												body={inputLabelInfo().type.body}
												width={380}
												position='left'
												isList
												spacing={8}
											/>
										</Group>
									}
									keepLabel={!!watch("type")}
									data={endPointTypes}
									className={cx({
										[classes.margin]:
											watch("method") === "post" ||
											watch("method") === "put" ||
											watch("method") === "patch" ||
											watch("method") === "delete"
												? !!formState.errors.purge?.message
												: !!formState.errors.method?.message,
									})}
								/>

								{(watch("method") == "post" ||
									watch("method") == "put" ||
									watch("method") == "patch") && (
									<Box
										mt={
											!!formState.errors.type?.message ? rem(24) : 0
										}
									>
										<DangerAlert title='Asynchronous Requests'>
											<Group
												spacing={rem(12)}
												align='center'
												noWrap
											>
												<Text
													lh={rem(20)}
													fz={rem(12)}
													c='refactor.4'
													pl={rem(4)}
												>
													Ideal for queueing and requests retries,
													but avoid for non-idempotent operations.
												</Text>

												<Group
													noWrap
													spacing={rem(8)}
												>
													<CustomSwitch
														checked={
															watch("async") && !watch("cache")
														}
														disabled={
															isPublishLoading ||
															publishEndpointLoading ||
															isEditLoading ||
															supabaseEditLoading ||
															watch("cache")
														}
														onChange={() => {
															setValue("async", !watch("async"))
															console.log(watch("async"))
														}}
													/>
													<Text fz={rem(12)}>Enable</Text>
												</Group>
											</Group>
										</DangerAlert>
									</Box>
								)}

								<Group
									position='apart'
									spacing={rem(8)}
									mt={
										Boolean(
											(!!formState.errors.type?.message &&
												!(
													watch("method") == "post" ||
													watch("method") == "put" ||
													watch("method") == "patch"
												) &&
												watch("method") != "get") ||
												(watch("method") == "get" &&
													!!formState.errors.ttl?.message)
										)
											? rem(24)
											: 0
									}
								>
									<Text fz={rem(16)}>Accept Traffic</Text>
									<CustomSwitch
										checked={watch("enabled")}
										disabled={
											isPublishLoading ||
											publishEndpointLoading ||
											isEditLoading ||
											supabaseEditLoading
										}
										onChange={() => {
											setValue("enabled", !watch("enabled"))
										}}
									/>
								</Group>

								<Group
									position='apart'
									spacing={rem(8)}
									mt={
										Boolean(
											(!!formState.errors.type?.message &&
												!(
													watch("method") == "post" ||
													watch("method") == "put" ||
													watch("method") == "patch"
												) &&
												watch("method") != "get") ||
												(watch("method") == "get" &&
													!!formState.errors.ttl?.message)
										)
											? rem(24)
											: 0
									}
								>
									<Text fz={rem(16)}>Cache Response</Text>
									<CustomSwitch
										checked={!!watch("cache")}
										disabled={
											isPublishLoading ||
											publishEndpointLoading ||
											isEditLoading ||
											supabaseEditLoading
										}
										onChange={() => {
											setValue("cache", !watch("cache"))

											/**
											 * This code turns off async for this endpoint,
											 *  since a cache enabled endpoint can't be asynchronous
											 * */
											if (watch("async") == true) {
												setValue("async", !watch("cache"))
											}
										}}
									/>
								</Group>

								{watch("cache") == true && (
									<CustomNumberInput
										control={control}
										name='ttl'
										label='Cache TTL (seconds)'
										placeholder='TTL'
										errorMessage={formState.errors.ttl?.message}
										className={cx({
											[classes.margin]:
												!!formState.errors.description?.message ||
												!!formState.errors.type?.message,
										})}
										keepLabel={!!watch("ttl").toString()}
										disabled={
											isPublishLoading ||
											publishEndpointLoading ||
											isEditLoading ||
											supabaseEditLoading
										}
									/>
								)}
							</Stack>

							<Button
								type='submit'
								fullWidth
								variant='filled'
								h={rem(48)}
								sx={{
									borderRadius: rem(8),
									fontWeight: 500,
									lineHeight: rem(16),
									letterSpacing: -0.2,
									alignSelf: "flex-end",
								}}
								loading={
									isPublishLoading ||
									publishEndpointLoading ||
									isEditLoading ||
									supabaseEditLoading
								}
								disabled={
									isPublishLoading ||
									publishEndpointLoading ||
									isEditLoading ||
									supabaseEditLoading
								}
							>
								Save
							</Button>
						</Stack>
					</Box>
				</Stack>
			)}
		</CustomDrawer>
	)
}

export default EndpointDrawer
