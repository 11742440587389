import {
	SegmentedControl,
	Stack,
	Text,
	rem,
	Box,
	UnstyledButton,
	Group,
} from "@mantine/core"
import PricingTable from "./components/PricingTable"
import { useState } from "react"
import { useDocumentTitle } from "@mantine/hooks"
import { Periods } from "../../../types/pricing.types"
import { useNavigate, useLocation } from "react-router-dom"
import { IconCornerUpLeft } from "@tabler/icons-react"
import { useStyles } from "./styles"
import useGetSubscription from "../../../hooks/useGetSubscription"

const Payments = () => {
	useDocumentTitle("Pricing")
	const [period, setPeriod] = useState<Periods>("monthly")
	const { classes } = useStyles()
	const navigate = useNavigate()
	const { state } = useLocation()

	const { data: subscriptionData, isLoading: subscriptionLoading } =
		useGetSubscription()

	return (
		<Stack
			w='100%'
			sx={{
				gap: rem(44),
			}}
		>
			<Stack
				sx={{
					gap: rem(8),
				}}
			>
				<UnstyledButton
					onClick={() => {
						if (state && state.from) {
							navigate(state.from)
						} else {
							navigate("/")
						}
					}}
					mb={rem(16)}
					sx={theme => ({
						borderRadius: rem(8),
						borderColor: theme.colors["refactor"][3],
						padding: `${rem(8)} ${rem(12)}`,
						borderWidth: "1px",
						borderStyle: "solid",
						width: "fit-content",
					})}
				>
					<Group
						sx={{
							gap: rem(8),
						}}
						c='refactor.0'
					>
						<IconCornerUpLeft size={14} />
						<Text
							lts={-0.2}
							lh={rem(14)}
							fz={rem(12)}
							c='refactor.0'
							fw={400}
						>
							Back
						</Text>
					</Group>
				</UnstyledButton>
				<Text
					fz={rem(21)}
					fw={500}
					lh={rem(24)}
					component='h1'
					sx={theme => ({
						color: theme.colors[theme.primaryColor][7],
					})}
					m={0}
				>
					Billing
				</Text>

				<Text
					fw={400}
					component='h2'
					lh={rem(20)}
					fz={rem(16)}
					sx={theme => ({
						color: theme.colors["refactor"][4],
					})}
					m={0}
				>
					Easily review and update your plan, billing information, and
					payment history.
				</Text>
			</Stack>
			<Stack
				sx={{
					gap: rem(24),
				}}
			>
				<Box>
					<SegmentedControl
						value={period}
						classNames={{
							indicator: classes.indicator,
							root: classes.root,
							control: classes.control,
							label: classes.label,
						}}
						onChange={e => {
							setPeriod(e as Periods)
						}}
						data={[
							{ label: "Pay Monthly", value: "monthly" },
							{ label: "Pay Yearly", value: "yearly" },
						]}
					/>
				</Box>
				<PricingTable
					subscriptionData={subscriptionData}
					subscriptionLoading={subscriptionLoading}
					period={period}
				/>
			</Stack>
		</Stack>
	)
}

export default Payments
