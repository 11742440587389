import { Pie } from "@visx/shape"
import { Group } from "@visx/group"
import { PieArcDatum } from "@visx/shape/lib/shapes/Pie"

interface Datum {
	label: string
	value: number
	color: string
}

const colors = (d: PieArcDatum<Datum>) => d.data.color
const requests = (d: Datum) => d.value

export type PieProps = {
	width: number
	height: number
	data: Datum[]

	pieThickness?: number
	radius?: number
}

const PieChart = ({
	width,
	height,
	pieThickness = 24,
	data,
	radius = 120,
}: PieProps) => {
	return (
		<svg
			width={width}
			height={height}
		>
			<rect
				rx={14}
				width={width}
				height={height}
				fill='#fff'
			/>

			<Group
			// top={centerY + margin.top}
			// left={centerX + margin.left}
			>
				<Pie
					data={data}
					pieValue={requests}
					outerRadius={radius}
					innerRadius={radius - pieThickness}
					cornerRadius={0}
					padAngle={0}
					fill={colors}
					top={radius}
					left={radius}
				/>
			</Group>
		</svg>
	)
}

export default PieChart
