import { extent, max } from "@visx/vendor/d3-array"
import * as allCurves from "@visx/curve"
import { Group } from "@visx/group"
import { LinePath } from "@visx/shape"
import { scaleTime, scaleLinear } from "@visx/scale"
import { createStyles, rem } from "@mantine/core"
import { GridColumns } from "@visx/grid"
import { AxisLeft, AxisBottom } from "@visx/axis"
import { timeFormat } from "@visx/vendor/d3-time-format"
import { NumberValue } from "@visx/vendor/d3-scale"

const useStyles = createStyles(theme => ({
	padding: {
		padding: rem(12),
	},
	xAxisLabels: {
		lineHeight: rem(20),
		fontWeight: 400,
		fontSize: rem(12),
		fontFamily: "Inter, sans-serif",
		color: "#404040",
	},
}))

interface Datum {
	date: Date
	value: number
}

const getX = (d: Datum) => d.date
const getY = (d: Datum) => d.value

export type CurveProps = {
	width: number
	height: number
	data: Datum[]
	stroke?: string
	strokeWidth?: number
	columnStroke?: string
	columnStrokeWidth?: number
}

const LineChartWithAxis = ({
	width,
	height,
	data = [],
	stroke = "#C74FFF",
	columnStroke = "#E5E5E5",
	strokeWidth = 1.67,
	columnStrokeWidth = 1,
}: CurveProps) => {
	const { classes } = useStyles()
	const xScale = scaleTime<number>({
		domain: extent(data, getX) as [Date, Date],
	})
	const yScale = scaleLinear<number>({
		domain: [0, max(data, getY) as number],
	})

	xScale.range([0, width])
	yScale.range([height, 0])

	return (
		<svg
			width={width}
			height={height + 80}
		>
			<rect
				width={width}
				height={height + 80}
				fill='#fff'
				rx={14}
				ry={14}
			/>
			<Group
				left={50}
				top={10}
			>
				<GridColumns
					scale={xScale}
					height={height + 40}
					strokeWidth={columnStrokeWidth}
					stroke={columnStroke}
					strokeDasharray='4'
				/>
				<LinePath<Datum>
					curve={allCurves["curveNatural"]}
					data={data}
					x={d => xScale(getX(d)) ?? 0}
					y={d => yScale(getY(d)) ?? 0}
					stroke={stroke}
					strokeWidth={strokeWidth}
					strokeOpacity={1}
					shapeRendering='geometricPrecision'
					className={classes.padding}
				/>
				<AxisLeft
					scale={yScale}
					stroke='#404040'
					tickLabelProps={{
						className: classes.xAxisLabels,
					}}
					hideTicks
					hideAxisLine
					tickFormat={(value, index) => {
						if (index === 0) {
							return "0"
						}
						return `${value}ms`
					}}
				/>
				<AxisBottom
					top={height + 44}
					scale={xScale}
					stroke='#404040'
					tickLabelProps={{
						className: classes.xAxisLabels,
					}}
					hideTicks
					hideAxisLine
					tickFormat={(
						value: NumberValue | Date,
						index: number,
						values: { value: NumberValue | Date; index: number }[]
					) => {
						const format = timeFormat("%b %d")
						const date = new Date(value.toLocaleString())
						if (index === 1) {
							return format(date)
						} else if (index === values.length - 2) {
							return format(date)
						} else {
							return ""
						}
					}}
				/>
			</Group>
		</svg>
	)
}

export default LineChartWithAxis
