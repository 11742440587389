import { Group } from "@visx/group"
import { scaleLinear, scaleBand } from "@visx/scale"
import { createStyles, rem } from "@mantine/core"
import { Bar } from "@visx/shape"
import { useMemo } from "react"
import { AxisBottom } from "@visx/axis"

interface Datum {
	code: number
	count: number
}

const getX = (d: Datum) => d.code
const getY = (d: Datum) => d.count

export type BarProps = {
	data: Datum[]
	width: number
	height: number
}

const useStyles = createStyles(theme => ({
	padding: {
		padding: rem(12),
	},
	xAxisLabels: {
		lineHeight: rem(20),
		fontWeight: 400,
		fontSize: rem(12),
		fontFamily: "Inter, sans-serif",
		color: theme.colors["refactor"][0],
		fill: theme.colors["refactor"][0],
	},
}))

export default function BarChart({ width, height, data = [] }: BarProps) {
	const { classes, cx } = useStyles()
	// bounds
	const xMax = width
	const yMax = height
	// scales, memoize for performance
	const xScale = useMemo(
		() =>
			scaleBand({
				range: [0, xMax],
				round: true,
				domain: data.map(getX),
				padding: 0.5,
			}),
		[xMax]
	)
	const yScale = useMemo(
		() =>
			scaleLinear<number>({
				range: [yMax, 0],
				round: true,
				domain: [0, Math.max(...data.map(getY))],
			}),
		[yMax]
	)

	return (
		<>
			<svg
				width={width + 68}
				height={height + 68}
			>
				<rect
					width={width}
					height={height}
					fill='#ffffff'
					rx={14}
				/>
				<Group top={4}>
					{data.map(d => {
						const barHeight = yMax - (yScale(getY(d)) ?? 0)
						const barX = xScale(getX(d))
						const barY = yMax - barHeight
						const barWidth = xScale.bandwidth()

						return (
							<Bar
								key={`bar-${getX(d)}`}
								x={barX}
								y={barY}
								width={barWidth}
								height={barHeight}
								fill='#FA4A52'
								rx={4}
								ry={2}
							/>
						)
					})}

					<AxisBottom
						top={height}
						label='Error Codes'
						scale={xScale}
						stroke='#737373'
						tickStroke='#737373'
						labelClassName={cx(classes.xAxisLabels)}
						labelOffset={24}
						tickLabelProps={{
							className: classes.xAxisLabels,
						}}
					/>
				</Group>
			</svg>
		</>
	)
}
