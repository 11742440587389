import { useFormContext } from "react-hook-form"
import { serviceAuthSchemaType } from "../../../lib/parser"
import CustomSelect from "../../../Components/Select/Select"
import CustomTextInput from "../../../Components/TextInput/TextInput"
import { rem, createStyles, Button, Box, Group, Text } from "@mantine/core"
import { useEffect } from "react"
import CustomHoverCard from "../../../Components/CustomHoverCard/CustomHoverCard"
import { IconHelpCircleFilled } from "@tabler/icons-react"

const useStyles = createStyles({
	mt: {
		marginBottom: rem(24),
	},
	selectRoot: {
		// @ts-expect-error mismatched types
		flexGrow: "0 !important",
	},
})
const AuthForm = () => {
	const methods = useFormContext<serviceAuthSchemaType>()
	const { classes } = useStyles()

	const variant = methods.watch("variant")
	useEffect(() => {
		if (methods.watch("variant") === "Authorization") {
			methods.setValue("key", "Authorization")
		} else if (methods.watch("variant") === "custom") {
			methods.setValue("key", "")
		}
	}, [variant])

	return (
		<Box
			sx={{ gap: rem(24), display: "flex", flexDirection: "column" }}
			w='100%'
		>
			<CustomSelect
				name='type'
				control={methods.control}
				placeholder="Auth Parameter Location (Header or Query)"
				// label='Auth Type'
				label={
					<Group spacing={rem(8)}>
						<Text>Auth Parameter Location (Header or Query)</Text>
						<CustomHoverCard
							target={<IconHelpCircleFilled size={rem(16)} />}
							// ! CARD TITLE HERE
							title={""}
							// ! CARD BODY HERE
							body={[{ text: "Select where to find the authorization parameter in your API requests. This can be either in the request header or as a query parameter. For better security practices, using the request header is recommended." }]}
							width={380}
							position='top'
							spacing={8}
						/>
					</Group>
				}
				keepLabel={!!methods.watch("type")}
				data={[
					{ value: "header", label: "Request Header" },
					{ value: "query", label: "Query Param" },
				]}
				errorMessage={methods.formState.errors.type?.message}
				mb={Boolean(methods.formState.errors.type?.message) ? rem(24) : 0}
				classNames={{
					root: classes.selectRoot,
				}}
			/>

			{methods.watch("type") === "header" ? (
				<>
					<CustomSelect
						name='variant'
						control={methods.control}
						placeholder='Parameter Key'
						label={
							<Group spacing={rem(8)}>
								<Text>Parameter Key</Text>
								<CustomHoverCard
									target={<IconHelpCircleFilled size={rem(16)} />}
									// ! CARD TITLE HERE
									title={""}
									// ! CARD BODY HERE
									body={[{ text: "Enter the key name used for the authorization parameter in your API requests. This is the specific field name that contains the authorization token or credentials." }]}
									width={380}
									position='top'
									spacing={8}
								/>
							</Group>
						}
						keepLabel={!!methods.watch("variant")}
						data={[
							{ value: "Authorization", label: "Authorization" },
							{ value: "custom", label: "Custom" },
						]}
						errorMessage={
							"variant" in methods.formState.errors
								? methods.formState.errors.variant?.message
								: ""
						}
						mb={
							"variant" in methods.formState.errors &&
							Boolean(methods.formState.errors.variant?.message)
								? rem(24)
								: 0
						}
						classNames={{
							root: classes.selectRoot,
						}}
					/>
				</>
			) : null}

			{methods.watch("type") === "query" ||
			(methods.watch("type") === "header" && methods.watch("variant")) ===
				"custom" ? (
				<CustomTextInput
					name='key'
					placeholder='Key'
					label={
						<Group spacing={rem(8)}>
							<Text>Key</Text>
							<CustomHoverCard
								target={<IconHelpCircleFilled size={rem(16)} />}
								// ! CARD TITLE HERE
								title={""}
								// ! CARD BODY HERE
								body={[{ text: "" }]}
								width={380}
								position='top'
								spacing={8}
							/>
						</Group>
					}
					control={methods.control}
					errorMessage={methods.formState.errors.key?.message}
					className={
						Boolean(methods.formState.errors.key?.message)
							? classes.mt
							: ""
					}
				/>
			) : null}
		</Box>
	)
}

export default AuthForm
