import {
	Box,
	Group,
	MediaQuery,
	Stack,
	Text,
	rem,
	createStyles,
	Center,
	Button,
} from "@mantine/core"
import { useDocumentTitle } from "@mantine/hooks"
import CustomSwitch from "../../../../Components/CustomSwitch/CustomSwitch"
import { useMemo, useState } from "react"
import { TextInput } from "react-hook-form-mantine"
import { SubmitHandler, useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import * as z from "zod"
import useAppState from "../../../../zustand/new-store"
import { useAuth } from "@clerk/clerk-react"
import { Alert } from "../../../../types/supabase.types"
import { updateOrganizationMetadata } from "../../../../api/supabase/update-org-metadata"
import { notifications } from "@mantine/notifications"
import { IconCheck, IconX } from "@tabler/icons-react"
import useGetNotifPreferences from "./useGetNotifPreferences"
import useConfigureNotifs from "../../../../Components/AppShell/api/useConfigureNotifs"
import useUpdateNotifPreferences from "./updateNotifPreferences"
const useStyles = createStyles(theme => ({
	root: {
		width: rem(480),
	},
	wrapper: {
		height: rem(56),
	},
	error: {
		letterSpacing: -0.2,
		fontSize: rem(14),
		color: theme.colors["danger"][0],
		bottom: rem(16),
	},
	label: {
		lineHeight: rem(18),
		fontWeight: 400,
		fontSize: rem(14),
		textTransform: "capitalize",
		color: theme.colors["refactor"][0],
	},
	input: {
		borderRadius: rem(8),
		border: `1px solid ${theme.colors["refactor"][5]}`,
		padding: `${rem(18)} ${rem(16)}`,
		height: "100%",
		lineHeight: rem(20),
		fontSize: rem(16),
		letterSpacing: -0.2,
		transition: "0.1s padding ease",
		marginTop: rem(8),
		color: theme.colors[theme.primaryColor][7],
		"::placeholder": {
			color: theme.colors["refactor"][0],
			letterSpacing: -0.2,
			fontSize: rem(15),
			lineHeight: rem(20),
		},
		":focus": {
			border: `1px solid ${theme.colors["refactor"][5]}`,
		},
		"&[data-invalid]": {
			border: `1px solid ${theme.colors["danger"][0]}`,
			color: theme.colors["danger"][0],
			caretColor: theme.colors["danger"][0],
			"::placeholder": {
				color: theme.colors["danger"][0],
				caretColor: theme.colors["danger"][0],
				letterSpacing: -0.2,
				fontSize: rem(15),
				lineHeight: rem(20),
			},
		},
	},
	right: {
		width: "fit-content",
	},
}))

const notificationsSchema = z.object({
	high_response_time: z.coerce
		.number({
			required_error: "Latency is required",
			invalid_type_error: "Latency cannot be less than 1ms",
		})
		.nonnegative()
		.min(1, { message: "Latency cannot be less than 1ms" }),

	api_error_rate: z.coerce
		.number({
			required_error: "Error rate is required",
			invalid_type_error:
				"Error rate cannot be less than 1% or greater than 100%",
		})
		.nonnegative()
		.min(1, { message: "Error rate cannot be less than 1%" })
		.max(100, { message: "Error rate cannot be more than 100%" }),
})

export type NotificationsSchemaType = z.infer<typeof notificationsSchema>

const Notifications = () => {
	useDocumentTitle("Notifications")
	const { classes } = useStyles()

	const [loading, setLoading] = useState(false)
	const {
		organizationsMetadata,
		setOrganizationMetadata,
		activeWorkspace,
		cacheConfig,
	} = useAppState()

	const { orgId, userId, getToken } = useAuth()
	const { data, isLoading } = useGetNotifPreferences()
	const { mutate, isLoading: isUpdateLoading } = useUpdateNotifPreferences()

	const enabled: { email: boolean; in_app: boolean; chat: boolean } =
		useMemo(() => {
			if (!data) {
				return { email: false, in_app: false, chat: false }
			} else {
				return data.data[0].preference.channels
			}
		}, [data, activeWorkspace?.workspace_id])

	const alerts = useMemo(() => {
		return organizationsMetadata &&
			organizationsMetadata.alerts &&
			organizationsMetadata.alerts.length > 0
			? organizationsMetadata.alerts.find(
					meta => meta.workspace_id === activeWorkspace?.workspace_id
			  )
			: undefined
	}, [organizationsMetadata?.alerts, activeWorkspace?.workspace_id])
	const { handleSubmit, control, formState } =
		useForm<NotificationsSchemaType>({
			resolver: zodResolver(notificationsSchema),
			defaultValues: alerts,
		})
	const {
		mutate: configure,
		setSupabaseLoading,
		isLoading: isConfigureLoading,
	} = useConfigureNotifs()
	const organizationOwner = useMemo(() => {
		return organizationsMetadata?.users.find(
			user => user.user_id === organizationsMetadata.user_id
		)
	}, [organizationsMetadata?.user_id, organizationsMetadata?.users.length])
	const workspaceAlertsConfigured = useMemo(() => {
		if (
			organizationsMetadata &&
			organizationsMetadata.alerts &&
			organizationsMetadata.alerts &&
			activeWorkspace
		) {
			const workspaceAlert = organizationsMetadata.alerts.find(
				alert => alert.workspace_id === activeWorkspace?.workspace_id
			)

			return !!workspaceAlert?.configured
		} else {
			return false
		}
	}, [activeWorkspace?.workspace_id, organizationsMetadata?.alerts])
	async function update(orgId: string, alerts: Alert[]) {
		try {
			const result = await updateOrganizationMetadata(getToken, {
				org_id: orgId,
				alerts,
			})

			if (result.data) {
				setOrganizationMetadata(result.data)
				notifications.show({
					withCloseButton: true,
					color: "green.0",
					title: "Success",
					icon: <IconCheck />,
					autoClose: 5000,
					message: "Alert settings updated successfully",
				})
			} else {
				console.log(result.error)
				notifications.show({
					withCloseButton: true,
					color: "danger.0",
					title: "Error",
					icon: <IconX />,
					autoClose: 5000,
					message: "Something went wrong",
				})
			}
		} catch (error) {
			notifications.show({
				withCloseButton: true,
				color: "danger.0",
				title: "Error",
				icon: <IconX />,
				autoClose: 5000,
				message: "Something went wrong",
			})

			console.log(error)
		}
	}
	const submitHandler: SubmitHandler<
		NotificationsSchemaType
	> = async values => {
		if (
			!cacheConfig ||
			!orgId ||
			!userId ||
			!organizationsMetadata ||
			!activeWorkspace
		) {
			return
		}

		setLoading(true)

		const allAlertsWithoutCurrent = organizationsMetadata.alerts?.filter(
			alert => alert.workspace_id !== activeWorkspace?.workspace_id
		)

		const current = organizationsMetadata.alerts?.find(
			alert => alert.workspace_id === activeWorkspace?.workspace_id
		)
		const newAlert: Alert = current
			? {
					...values,
					workspace_id: activeWorkspace?.workspace_id,
					configured: current.configured,
			  }
			: {
					...values,
					workspace_id: activeWorkspace?.workspace_id,
					configured: false,
			  }

		const alerts = allAlertsWithoutCurrent
			? [...allAlertsWithoutCurrent, newAlert]
			: [newAlert]

		await update(orgId, alerts)

		setLoading(false)
	}
	return (
		<Stack
			w='100%'
			p={rem(32)}
			sx={theme => ({
				borderRadius: rem(8),
				border: `1px solid ${theme.colors["refactor"][2]}`,
				gap: rem(30),
			})}
		>
			<Stack
				pb={rem(40)}
				sx={theme => ({
					gap: rem(24),
					borderBottom: `1px solid ${theme.colors["refactor"][2]}`,
				})}
			>
				<Stack sx={{ gap: rem(8) }}>
					<Text
						lh={rem(24)}
						fw={600}
						fz={rem(21)}
						component='h1'
						color='brand.7'
						m={0}
					>
						Notification Channels
					</Text>
					<Text
						component='h6'
						m={0}
						fw={400}
						lh={rem(20)}
						color='refactor.4'
						fz={rem(14)}
					>
						Manage how you receive updates and alerts from Resilis
					</Text>
				</Stack>

				{workspaceAlertsConfigured ? (
					<Stack
						w={`min(100%, ${rem(520)})`}
						sx={{ gap: rem(24) }}
					>
						<Group
							align='flex-start'
							sx={{
								justifyContent: "space-between",
							}}
						>
							<Stack
								sx={{
									gap: rem(8),
								}}
							>
								<Text
									color='brand.7'
									fw={500}
									fz={rem(16)}
									lh={rem(20)}
								>
									In-App Notifications
								</Text>

								<Text
									color='refactor.4'
									fw={400}
									fz={rem(14)}
									lh={rem(20)}
									w={`min(${rem(400)}, 100%)`}
								>
									Get instant notifications within the Resilis platform
								</Text>
							</Stack>

							<CustomSwitch
								checked={enabled.in_app}
								onChange={async () => {
									const token = await getToken()

									if (!token || !cacheConfig) {
										return
									}

									mutate({
										token,
										type: "in_app",
										enabled: !enabled.in_app,
										subscriberId: cacheConfig.host,
									})
								}}
								disabled={isLoading || isUpdateLoading}
							/>
						</Group>

						<Group
							align='flex-start'
							sx={{
								justifyContent: "space-between",
							}}
						>
							<Stack
								sx={{
									gap: rem(8),
								}}
							>
								<Text
									color='brand.7'
									fw={500}
									fz={rem(16)}
									lh={rem(20)}
								>
									Mail Notifications
								</Text>

								<Text
									color='refactor.4'
									fw={400}
									fz={rem(14)}
									lh={rem(20)}
									w={`min(${rem(400)}, 100%)`}
								>
									Receive updates and alerts directly to your email.
								</Text>
							</Stack>

							<CustomSwitch
								checked={enabled.email}
								disabled={isLoading || isUpdateLoading}
								onChange={async () => {
									const token = await getToken()

									if (!token || !cacheConfig) {
										return
									}

									mutate({
										token,
										type: "email",
										enabled: !enabled.email,
										subscriberId: cacheConfig.host,
									})
								}}
							/>
						</Group>

						<Group
							align='flex-start'
							sx={{
								justifyContent: "space-between",
							}}
						>
							<Stack
								sx={{
									gap: rem(8),
								}}
							>
								<Text
									color='brand.7'
									fw={500}
									fz={rem(16)}
									lh={rem(20)}
								>
									Chat Notifications
								</Text>

								<Text
									color='refactor.4'
									fw={400}
									fz={rem(14)}
									lh={rem(20)}
									w={`min(${rem(400)}, 100%)`}
								>
									Get instant notifications within the Resilis platform
								</Text>
							</Stack>

							<CustomSwitch
								checked={enabled.chat}
								disabled={isLoading || isUpdateLoading}
								onChange={async () => {
									const token = await getToken()

									if (!token || !cacheConfig) {
										return
									}
									mutate({
										token,
										type: "chat",
										enabled: !enabled.chat,
										subscriberId: cacheConfig.host,
									})
								}}
							/>
						</Group>
					</Stack>
				) : (
					<Group
						align='center'
						sx={{
							justifyContent: "space-between",
						}}
						w={`min(100%, ${rem(520)})`}
					>
						<Text
							color='brand.7'
							fw={500}
							fz={rem(16)}
							lh={rem(20)}
						>
							Configure Notifications
						</Text>

						<Button
							type='button'
							h={rem(40)}
							radius='md'
							disabled={isConfigureLoading}
							loading={isConfigureLoading}
							sx={{
								letterSpacing: -0.2,
								lineHeight: rem(16),
								fontWeight: 500,
								fontSize: rem(14),
							}}
							onClick={async () => {
								const token = await getToken()

								if (workspaceAlertsConfigured) {
									notifications.show({
										withCloseButton: true,
										color: "danger.0",
										title: "Something went wrong",
										icon: <IconX />,
										autoClose: 5000,
										message:
											"You have already configured notifications",
									})
									return
								}

								if (
									!activeWorkspace ||
									!organizationsMetadata ||
									!orgId ||
									!token ||
									!cacheConfig ||
									!organizationOwner
								) {
									notifications.show({
										withCloseButton: true,
										color: "danger.0",
										title: "Something went wrong",
										icon: <IconX />,
										autoClose: 5000,
										message: "Missing config",
									})
									return
								}

								setSupabaseLoading(true)
								configure({
									token,
									email: organizationOwner.email,
									subscriberId: cacheConfig.host,
								})
							}}
						>
							Configure
						</Button>
					</Group>
				)}
			</Stack>

			<Stack sx={{ gap: rem(24) }}>
				<Stack
					w={`min(${rem(480)}, 100%)`}
					sx={{
						gap: rem(8),
					}}
				>
					<Text
						lh={rem(24)}
						fw={600}
						fz={rem(21)}
						component='h2'
						color='brand.7'
						m={0}
					>
						Custom Alerts
					</Text>
					<Text
						component='h6'
						m={0}
						fw={400}
						lh={rem(20)}
						color='refactor.4'
						fz={rem(14)}
					>
						Customize alerts for Latency and Error Rate to stay informed
						of any significant changes or issues in real-time.
					</Text>
				</Stack>

				<Box
					component='form'
					onSubmit={handleSubmit(submitHandler)}
				>
					<Stack sx={{ gap: rem(24) }}>
						<Group sx={{ justifyContent: "space-between" }}>
							<Stack
								sx={{ gap: rem(8) }}
								w={rem(300)}
							>
								<Text
									color='brand.7'
									fw={500}
									fz={rem(16)}
									lh={rem(20)}
								>
									Latency Alert
								</Text>
								<Text
									color='refactor.4'
									fw={400}
									fz={rem(14)}
									lh={rem(20)}
								>
									Monitor response times to ensure optimal performance
								</Text>
							</Stack>
							<TextInput
								label='THRESHOLD'
								placeholder='Enter threshold'
								name='high_response_time'
								control={control}
								disabled={loading}
								rightSection={
									<Center
										pl={rem(16)}
										pr={rem(16)}
										h='100%'
										sx={theme => ({
											borderLeft: formState.errors.high_response_time
												?.message
												? `1px solid ${theme.colors["danger"][0]}`
												: `1px solid #E4E6EB`,
										})}
									>
										<Text
											lts={-0.2}
											fw={400}
											lh={rem(20)}
											fz={rem(16)}
											sx={theme => ({
												color: formState.errors.high_response_time
													?.message
													? theme.colors["danger"][0]
													: "e4e6e8",
											})}
										>
											ms
										</Text>
									</Center>
								}
								classNames={{
									root: classes.root,
									wrapper: classes.wrapper,
									error: classes.error,
									label: classes.label,
									input: classes.input,
									rightSection: classes.right,
								}}
							/>
						</Group>

						<Group sx={{ justifyContent: "space-between" }}>
							<Stack
								sx={{ gap: rem(8) }}
								w={rem(300)}
							>
								<Text
									color='brand.7'
									fw={500}
									fz={rem(16)}
									lh={rem(20)}
								>
									Error Rate Alert
								</Text>
								<Text
									color='refactor.4'
									fw={400}
									fz={rem(14)}
									lh={rem(20)}
								>
									Track the percentage of all requests that result in
									an error
								</Text>
							</Stack>
							<TextInput
								label='THRESHOLD'
								placeholder='Enter threshold'
								name='api_error_rate'
								control={control}
								disabled={loading}
								rightSection={
									<Center
										pl={rem(16)}
										pr={rem(16)}
										h='100%'
										sx={theme => ({
											borderLeft: formState.errors.api_error_rate
												?.message
												? `1px solid ${theme.colors["danger"][0]}`
												: `1px solid #E4E6EB`,
										})}
									>
										<Text
											lts={-0.2}
											fw={400}
											lh={rem(20)}
											fz={rem(16)}
											sx={theme => ({
												color: formState.errors.api_error_rate
													?.message
													? theme.colors["danger"][0]
													: "e4e6e8",
											})}
										>
											%
										</Text>
									</Center>
								}
								classNames={{
									root: classes.root,
									wrapper: classes.wrapper,
									error: classes.error,
									label: classes.label,
									input: classes.input,
									rightSection: classes.right,
								}}
							/>
						</Group>
						<MediaQuery
							query='(max-width: 1316px)'
							styles={{
								justifyContent: "flex-start!important",
							}}
						>
							<Group
								w='100%'
								sx={{
									justifyContent: "flex-end",
								}}
							>
								<Group w={rem(480)}>
									<Button
										type='submit'
										h={rem(40)}
										sx={{
											borderRadius: rem(8),
											fontWeight: 500,
											lineHeight: rem(16),
											letterSpacing: -0.2,
										}}
										disabled={loading}
										loading={loading}
									>
										Save Changes
									</Button>
								</Group>
							</Group>
						</MediaQuery>
					</Stack>
				</Box>
			</Stack>
		</Stack>
	)
}

export default Notifications
