import { useMutation, useQueryClient } from "@tanstack/react-query"
import { axiosErrorParser } from "../../../../lib/parse-axios-error"
import { AxiosError } from "axios"
import { IconCheck, IconX } from "@tabler/icons-react"
import { notifications } from "@mantine/notifications"
import useAppStore from "../../../../zustand/new-store"
import { updateEndpoint } from "../../../../api/server/onboard/update-endpoint"
import { updateEndpoints } from "../../../../api/supabase/update-endpoints"
import { CacheConfig } from "../../../../lib/parser"
import { useAuth } from "@clerk/clerk-react"
import { useState } from "react"

async function update(
	orgId: string,
	workspaceId: string,
	endpoints: Partial<CacheConfig>,
	getToken: (
		options?: { template: string } | undefined
	) => Promise<string | null>
) {
	try {
		const result = await updateEndpoints(getToken, {
			org_id: orgId,
			workspace_id: workspaceId,
			...endpoints,
		})
		if (result.error || !result.data) {
			return { data: null, error: result.error || result.data }
		}
		return { data: result.data, error: null }
	} catch (error) {
		return { data: null, error }
	}
}
const useEditEndpoint = () => {
	const { cacheConfig, setCacheConfig, activeWorkspace } = useAppStore()
	const [supabaseEditLoading, setSupabaseEditLoading] = useState(false)
	const [serverSideJSONErrors, setServerSideValidationErrors] = useState<
		string[]
	>([])
	const [success, setSuccess] = useState(false)

	const { getToken, orgId } = useAuth()
	const queryClient = useQueryClient()

	const mutation = useMutation({
		mutationFn: updateEndpoint,
		onError(error: AxiosError) {
			const errorMessage = axiosErrorParser(error)
			if (typeof errorMessage === "string") {
				notifications.update({
					withCloseButton: true,
					color: "danger.0",
					title: "Something went wrong",
					icon: <IconX />,
					autoClose: 5000,
					message: errorMessage,
					id: "edit-path",
				})
			} else {
				if (errorMessage instanceof Array) {
					notifications.update({
						withCloseButton: true,
						color: "danger.0",
						title: "Something went wrong",
						icon: <IconX />,
						autoClose: 5000,
						message: errorMessage[0],
						id: "edit-path",
					})
				} else {
					notifications.update({
						withCloseButton: true,
						color: "danger.0",
						title: "Something went wrong",
						icon: <IconX />,
						autoClose: 5000,
						message: errorMessage.error,
						id: "edit-path",
					})
				}
			}
			setSuccess(false)
			setSupabaseEditLoading(false)
		},
		async onSuccess(data, variables) {
			const { config } = variables
			setSupabaseEditLoading(true)

			const response = await update(
				orgId || "",
				activeWorkspace?.workspace_id || "",
				config,
				getToken
			)

			if (response.error || !response.data) {
				console.log("SupabaseError:", response.error)
				setSupabaseEditLoading(false)
				setSuccess(false)
				notifications.update({
					withCloseButton: true,
					color: "danger.0",
					title: "Something went wrong",
					icon: <IconX />,
					autoClose: 5000,
					message: "Something went wrong",
					id: "edit-path",
				})
				return
			}

			setCacheConfig(response.data)

			queryClient.invalidateQueries({ queryKey: [cacheConfig?.host] })
			setSupabaseEditLoading(false)
			setSuccess(true)

			notifications.update({
				id: "edit-path",
				withCloseButton: true,
				color: "green.0",
				title: "Success",
				icon: <IconCheck />,
				autoClose: 5000,
				message: `Endpoint route for host ${cacheConfig?.host} updated successfully`,
			})
		},
	})

	return {
		supabaseEditLoading,
		setSupabaseEditLoading,
		serverSideJSONErrors,
		setServerSideValidationErrors,
		success,
		setSuccess,
		...mutation,
	}
}

export default useEditEndpoint
