import { Center, rem } from "@mantine/core"
import { Github, Gitlab, Google } from "../../../assets/icons"
interface IProps {
	onClick: () => void
	type: "github" | "gitlab" | "google"
}

export default function SocialIcon({ onClick, type }: IProps) {
	return (
		<Center
			onClick={() => {
				onClick()
			}}
			p={16}
			sx={theme => ({
				borderRadius: rem(8),
				border: `1px solid ${theme.colors.refactor[3]}`,
				background: theme.white,
				cursor: "pointer",
				flexGrow: 1,
			})}
		>
			{type === "github" ? <Github /> : null}
			{type === "gitlab" ? <Gitlab /> : null}
			{type === "google" ? <Google /> : null}
		</Center>
	)
}
