import AuthInfographic from "../../../assets/images/AuthInfographic.png"
import {
	createStyles,
	Flex,
	Image,
	Text,
	MediaQuery,
	rem,
	Container,
	Group,
} from "@mantine/core"
import { GreenCircle, RedCircle, YellowCircle } from "../../../assets/icons"
import { Outlet } from "react-router-dom"

const useStyles = createStyles(theme => ({
	highlight: {
		color: theme.colors[theme.primaryColor][2],
	},
	img: {
		borderRadius: `0px 0px ${rem(10.395)} ${rem(10.395)}`,
		minWidth: rem(860),
		maxHeight: "100%",
		objectPosition: "top left",
	},
	imgRoot: {
		minWidth: rem(860),
		overflow: "hidden",
		maxHeight: "100%",
	},
	imgWrapper: {
		maxHeight: "100%",
		height: "100%",
	},
}))
export default function AuthLayout() {
	const { classes, cx } = useStyles()

	return (
		<Group
			// 	// maw={1440}.
			display='flex'
			p={24}
			mih='100vh'
			h='100%'
			sx={{
				justifyContent: "space-between",
			}}
			fz='16px'
			noWrap
		>
			<MediaQuery
				query='(min-width: 90em)'
				styles={{
					width: "40%",
				}}
			>
				<Flex
					w='100%'
					justify='center'
					gap={0}
				>
					<Outlet />
				</Flex>
			</MediaQuery>
			<MediaQuery
				query='(min-width: 90em)'
				styles={{
					width: "60%",
					paddingRight: rem(72),
					minHeight: `calc(100vh - ${rem(72 + 72)})`,
					height: "fit-content",
				}}
			>
				<MediaQuery
					query='(max-width: 75em)'
					styles={{
						display: "none",
					}}
				>
					<Flex
						pt={80}
						pb={80}
						pl={72}
						sx={theme => ({
							borderRadius: rem(16),
							background: theme.colors["refactor"][1],
							display: "flex",
							flex: 1,
						})}
						w='50%'
					>
						<Flex
							sx={{
								maxWidth: "100%",
								overflow: "hidden",
							}}
							gap={56}
							direction='column'
						>
							<Flex
								gap={16}
								direction='column'
								sx={{
									overflowX: "hidden",
								}}
								pr={60}
							>
								<Text
									component='h3'
									sx={theme => {
										return {
											lineHeight: rem(48),
											fontSize: rem(40),
											fontWeight: 600,
											letterSpacing: "-1.4px",
											textTransform: "capitalize",
											color: theme.colors[theme.primaryColor][7],
											margin: 0,
										}
									}}
								>
									Optimize Your API{" "}
									<span className={classes.highlight}>
										Performance
									</span>{" "}
									Like Never Before
								</Text>
								<Text
									component='h4'
									sx={theme => ({
										color: theme.colors["refactor"][0],
										fontSize: rem(16),
										lineHeight: rem(24),
										margin: 0,
									})}
								>
									
								</Text>
							</Flex>

							<Flex
								direction='column'
								sx={{ flexGrow: 1 }}
							>
								<MediaQuery
									query='(min-width: 90em)'
									styles={{
										width: "100%",
									}}
								>
									<Flex
										gap={rem(6)}
										align='center'
										sx={theme => ({
											padding: `${rem(9)} ${rem(10)}`,
											background: theme.white,
											borderRadius: `${rem(15.543)} ${rem(
												15.543
											)} 0 0`,
											borderBottom: `0.919px solid ${theme.colors["refactor"][2]}`,
											width: rem(860),
										})}
									>
										<RedCircle />
										<YellowCircle />
										<GreenCircle />
									</Flex>
								</MediaQuery>

								<MediaQuery
									query='(min-width: 90em)'
									styles={{
										width: "100%",
									}}
								>
									<Flex
										sx={theme => ({
											background: theme.white,
											borderRadius: `0px 0px ${rem(10.395)}  ${rem(
												10.395
											)}`,
											padding: `${rem(10)} ${rem(8)}`,
											width: rem(860),
											height: rem(284),
											flexGrow: 1,
										})}
									>
										<Image
											src={AuthInfographic}
											classNames={{
												image: classes.img,
												root: classes.imgRoot,
												imageWrapper: classes.imgWrapper,
												figure: classes.imgWrapper,
											}}
										/>
									</Flex>
								</MediaQuery>
							</Flex>
						</Flex>
					</Flex>
				</MediaQuery>
			</MediaQuery>
		</Group>
	)
}
