import { Slider, SliderProps, rem, createStyles } from "@mantine/core"

const useStyles = createStyles(theme => ({
	track: { background: "#EAECF0" },
	bar: { background: "#5E5E5E" },
	barBlue: { background: theme.colors[theme.primaryColor][2] },
	barZero: {
		width: "0!important",
	},
}))
interface IProps extends SliderProps {
	value: number
	max: number
	color?: string
}

const ProgressBar = ({ value, max, color, ...props }: IProps) => {
	const { classes, cx } = useStyles()

	return (
		<Slider
			value={value}
			max={max}
			w={rem(120)}
			label={null}
			disabled
			{...props}
			h={rem(4)}
			classNames={{
				track: classes.track,
				bar: cx(classes.bar, {
					[classes.barZero]: value === 0,
					[classes.barBlue]: color === "primary",
				}),
			}}
		/>
	)
}

export default ProgressBar
