import { RingProgress as MantineRingProgress, Text, rem } from "@mantine/core"

interface IProps {
	label: string
	value: number
	size?: number
	thickness?: number
	textSize: "big" | "small"
	color?: "error" | "success"
}
const RingProgress = ({
	label,
	value,
	size = 48,
	thickness = 5,
	textSize,
	color = "success",
}: IProps) => {
	return (
		<MantineRingProgress
			sections={[
				{
					value: value,
					color: color === "success" ? "green.7" : "danger.4",
				},
			]}
			thickness={thickness}
			size={size}
			rootColor='refactor.3'
			label={
				<Text
					color='brand.7'
					weight={500}
					ta='center'
					fz={textSize === "small" ? rem(10) : rem(24)}
					lh={textSize === "small" ? rem(12) : rem(40)}
				>
					{label}
				</Text>
			}
			roundCaps
		/>
	)
}

export default RingProgress
