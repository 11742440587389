import { axiosConfig } from "../../../config/axios.config"
import { Authorization, Protocols } from "../../../lib/parser"

export const uploadSwaggerURL = async ({
	url,
	token,
	protocol: p,
	origin,
	basePath,
	authorization,
}: {
	authorization: Authorization | null
	url: string
	token: string
	protocol: Protocols
	origin: string
	basePath: string
}) => {
	const response = await axiosConfig.post(
		"/service",
		{
			url,
			protocol: p,
			authorization,
			origin,
			basePath,
		},
		{
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		}
	)
	const { data } = response

	return data
}
