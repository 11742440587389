import { axiosConfig } from "../../../config/axios.config"

export const updateDomain = async (body: {
	id: string
	hostname: string
	token: string
	method: "http" | "txt"
}) => {
	const response = await axiosConfig.patch(
		`/hostname/${body.id}`,
		{
			hostname: body.hostname,
			method: body.method,
		},
		{
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${body.token}`,
			},
		}
	)
	const { data } = response

	return data
}
