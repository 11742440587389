import {
	Header,
	rem,
	Center,
	Group,
	ActionIcon,
	Menu,
	Button,
	createStyles,
	Text,
	Avatar,
	Flex,
	MediaQuery,
	ScrollArea,
	Anchor,
	Box,
	LoadingOverlay,
	Image,
} from "@mantine/core"
import { Logo } from "../../assets/icons"
import { Notification, NotificationOff } from "../../assets/icons"
import useAppStore from "../../zustand/new-store"
import {
	IconFileText,
	IconLogout,
	IconUserCircle,
	IconChevronDown,
	IconLayoutDashboard,
	IconSettings,
	IconCircleCheck,
	IconPlus,
	IconX,
	IconRoute2,
	IconRestore,
} from "@tabler/icons-react"
import { useAuth, useUser } from "@clerk/clerk-react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import WorkspaceDrawer from "./WorkspaceDrawer"
import { getEndpoints } from "../../api/supabase/get-config"
import { useEffect } from "react"
import { Service } from "../../types/supabase.types"
import { notifications } from "@mantine/notifications"
import NotificationDrawer from "./NotificationDrawer"
import { useNotification } from "../../context/NotificationsProvider"
import { getWorkspaceIntegrations } from "../../api/supabase/get-integrations"
import { getScrollbarWidth } from "../../lib/get-scrollbar-width"
import { SCROLLBAR_WIDTH_OFFSET } from "../../constants/scrollbar-offset"
import { isProduction } from "../../constants/is-production"
import useHeaderHintStore from "../../zustand/header-hint-tour-store"
import useTourStore from "../../zustand/tour-store"
import useEndpointHintStore from "../../zustand/endpoints-hint-tour-store"
const useStyles = createStyles(theme => ({
	dropdown: {
		padding: rem(16),
		borderRadius: rem(6),
		border: "none",
		background: theme.white,
		boxShadow: "0px 10px 40px 0px rgba(0, 0, 0, 0.10)",
		">div": {
			display: "flex",
			flexDirection: "column",
			gap: rem(12),
		},
	},
	dropdownWorkspace: {
		padding: rem(20),
		">div": {
			gap: rem(0),
		},
	},
	label: {
		fontSize: rem(14),
		lineHeight: rem(20),
		color: theme.colors[theme.primaryColor][7],
		padding: 0,
		fontWeight: 400,
	},
	menuItem: {
		"&[data-hovered]": {
			background: theme.white,
		},
		background: theme.white,
		padding: 0,
		transition: "none",
	},
	itemLabel: {
		lineHeight: rem(20),
		fontSize: rem(14),
		color: theme.colors["refactor"][0],
		borderRadius: rem(8),
		maxWidth: "100%",
	},

	itemLabelWorkspace: {
		padding: rem(8),
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	},

	itemLabelLogout: {
		color: theme.colors["danger"][0],
	},
	itemIcon: {
		marginRight: rem(12),
	},
	icon: {
		stroke: "#A3A3A3",
	},
	chevronIcon: {
		stroke: theme.colors["refactor"][0],
	},
	dangerIcon: {
		stroke: theme.colors["danger"][0],
	},
	navIcon: {
		stroke: theme.colors["refactor"][4],
	},
	navIconActive: {
		stroke: theme.colors[theme.primaryColor][2],
	},
	divider: {
		borderTop: `1px solid ${theme.colors["refactor"][2]}`,
		marginTop: 0,
		marginBottom: 0,
	},
	avatarRoot: {
		background: "#404040",
		padding: rem(4),
		minWidth: "fit-content",
		width: "fit-content",
		height: "fit-content",
	},
	avatarPlaceHolder: {
		fontSize: rem(16),
		lineHeight: rem(24),
		fontWeight: 500,
		color: theme.white,
		background: "transparent",
		height: rem(20),
		width: rem(20),
		letterSpacing: 0.064,
	},
	navLink: {
		lineHeight: rem(20),
		textDecoration: "none",
		fontSize: rem(16),
		color: "#404040",
		display: "inline-flex",
		alignItems: "center",
		gap: rem(8),
		":hover": {
			textDecoration: "none",
		},
	},

	navLinkActive: {
		fontWeight: 500,
		color: theme.colors[theme.primaryColor][7],
	},

	imageRoot: {
		borderRadius: "50%",
		height: rem(32),
		width: `${rem(32)}!important`,
		cursor: "pointer",
	},
	imageWrapper: {
		borderRadius: "50%",
		height: rem(32),
		width: rem(32),
	},
	imageFigure: {
		borderRadius: "50%",
		height: rem(32),
		width: rem(32),
	},
	image: {
		borderRadius: "50%",
		height: rem(32),
		width: rem(32),
	},
}))

const links = [
	{ href: "/", title: "Dashboard", icon: IconLayoutDashboard },
	{ href: "/endpoints", title: "Endpoints", icon: IconRoute2 },
	{ href: "/settings", title: "Settings", icon: IconSettings },
]

function isActive(href: string, pathname: string): boolean {
	if (href === pathname) {
		return true
	} else if (pathname.includes(href) && href !== "/") {
		return true
	} else {
		return false
	}
}

const AppHeader = () => {
	const { pathname } = useLocation()
	const notDashboard =
		pathname === "/get-started" || pathname === "/pricing-auth"
	const { user } = useUser()

	const hideNav =
		pathname.includes("confirmation") ||
		pathname === "/pricing" ||
		pathname.includes("paid") ||
		pathname === "/get-started" ||
		pathname === "/pricing-auth"
	const { unread, fetchUnread } = useNotification()

	useEffect(() => {
		fetchUnread()
	}, [fetchUnread])
	const navigate = useNavigate()
	const {
		activeWorkspace,
		cacheConfig,
		resetState,
		organizationsMetadata,
		openNewWorkspaceModal,
		setCacheConfig,
		setCacheConfigSrc,
		setActiveWorkSpace,
		setNotificationDrawerOpen,
		isSwitchingWorkspace,
		setIntegrations,
		setISSwitchingWorkspace,
	} = useAppStore()
	const { signOut, getToken, orgId } = useAuth()
	const { classes, cx } = useStyles()
	const { updateTourState } = useTourStore()
	const { updateHintState: updateEndpointHintState } = useEndpointHintStore()
	const { updateHintState: updateHeaderHintState } = useHeaderHintStore()

	useEffect(() => {
		if (isSwitchingWorkspace) {
			document.body.style.overflow = "hidden"
		} else {
			document.body.style.overflow = "unset"
		}
	}, [isSwitchingWorkspace])

	async function switchWorkspace(workspace: Service) {
		setISSwitchingWorkspace(true)
		try {
			const result = await getEndpoints(
				orgId || "",
				workspace.workspace_id,
				getToken
			)
			const integrations = await getWorkspaceIntegrations(
				getToken,
				cacheConfig?.host || ""
			)

			if (integrations.data) {
				setIntegrations(integrations.data)
			} else {
				setIntegrations(null)
			}

			if (result.data) {
				setCacheConfig(result.data)
				setCacheConfigSrc(result.data?.source || "local")
				setActiveWorkSpace(workspace)
				if (location.pathname !== "/") {
					navigate("/")
				}
			} else {
				notifications.show({
					withCloseButton: true,
					color: "danger.0",
					title: "Something went wrong",
					icon: <IconX />,
					autoClose: 5000,
					message: "Could not switch workspace",
				})
			}
		} catch (error) {
			console.log(error)

			notifications.show({
				withCloseButton: true,
				color: "danger.0",
				title: "Something went wrong",
				icon: <IconX />,
				autoClose: 5000,
				message: "Could not switch workspace",
			})
		} finally {
			setISSwitchingWorkspace(false)
		}
	}
	return (
		<Header
			height={rem(80)}
			sx={theme => ({
				scrollbarGutter: "stable",
				borderBottom: "none",
			})}
			w={`calc(100vw - ${getScrollbarWidth() + SCROLLBAR_WIDTH_OFFSET}px)`}
		>
			<LoadingOverlay
				h='100vh'
				w='100vw'
				visible={isSwitchingWorkspace}
				zIndex={1000}
				overlayBlur={4}
				loaderProps={{
					size: 96,
					color: "brand.3",
				}}
			/>
			<MediaQuery
				query='(max-width: 68.5em)'
				styles={{
					paddingLeft: rem(40),
					paddingRight: rem(40),
				}}
			>
				<Flex
					h='100%'
					w='100%'
					px={rem(120)}
					sx={theme => ({
						justifyContent: "space-between",
						borderBottom: `1px solid ${theme.colors["refactor"][2]}`,
						margin: "0 auto",
						scrollbarGutter: "stable",
					})}
					maw={1440}
				>
					<WorkspaceDrawer />
					<NotificationDrawer />
					<Group
						sx={{ width: "fit-content", height: "100%", gap: rem(64) }}
						noWrap
					>
						<Center sx={{ width: "fit-content", height: "100%" }}>
							<Logo />
						</Center>
						{!hideNav ? (
							<MediaQuery
								query='(max-width: 990px)'
								styles={{
									display: "none",
								}}
							>
								<Flex
									gap={rem(24)}
									h={"100%"}
									align='flex-end'
								>
									{links.map(link => (
										<Group
											align='center'
											key={link.href}
											id={
												link.href === "/"
													? "home"
													: link.href.slice(1)
											}
											sx={theme => ({
												gap: rem(8),
												borderBottom: isActive(link.href, pathname)
													? `2px solid ${
															theme.colors[theme.primaryColor][7]
													  }`
													: "none",
												position: "relative",
												bottom: -1,
											})}
										>
											<Anchor
												pb={rem(28)}
												component={Link}
												to={link.href}
												className={cx(classes.navLink, {
													[classes.navLinkActive]: isActive(
														link.href,
														pathname
													),
												})}
											>
												{
													<link.icon
														className={
															isActive(link.href, pathname)
																? classes.navIconActive
																: classes.navIcon
														}
													/>
												}
												{link.title}
											</Anchor>
										</Group>
									))}
								</Flex>
							</MediaQuery>
						) : null}
					</Group>

					<Center
						sx={{ width: "fit-content", height: "100%", gap: rem(16) }}
					>
						{!notDashboard ? (
							<Menu
								classNames={{
									dropdown: cx(
										classes.dropdown,
										classes.dropdownWorkspace
									),
									label: classes.label,
									item: classes.menuItem,
									itemLabel: cx(
										classes.itemLabel,
										classes.itemLabelWorkspace
									),
								}}
								width={rem(300)}
								withinPortal
							>
								<Menu.Target>
									<Group
										sx={theme => ({
											gap: rem(8),
											padding: `${rem(8)} ${rem(10)} ${rem(8)} ${rem(
												8
											)}`,
											borderRadius: rem(6),
											background: theme.white,
											border: `1px solid ${theme.colors["refactor"][2]} `,
											cursor: "pointer",
										})}
										align='center'
										noWrap
									>
										<Avatar
											color='#404040'
											classNames={{
												root: classes.avatarRoot,
												placeholder: classes.avatarPlaceHolder,
											}}
										>
											{activeWorkspace?.workspace_name
												.slice(0, 1)
												.toUpperCase()}
										</Avatar>

										<MediaQuery
											query='(max-width: 600px)'
											styles={{
												display: "none",
											}}
										>
											<Text
												sx={theme => ({
													lineHeight: rem(20),
													letterSpacing: 0.056,
													fontWeight: 500,
													fontSize: rem(14),
													color: theme.colors[
														theme.primaryColor
													][7],
													whiteSpace: "nowrap",
													overflow: "hidden",
													textOverflow: "ellipsis",
												})}
												maw={rem(200)}
											>
												{activeWorkspace?.workspace_name}
											</Text>
										</MediaQuery>
										<ActionIcon
											sx={{
												height: rem(32),
												width: rem(32),
												background: "transparent",
												":hover": {
													border: "none",
													scale: "none",
													background: "transparent",
													borderRadius: 0,
												},
											}}
											id='service-manager'
										>
											<IconChevronDown
												className={classes.chevronIcon}
											/>
										</ActionIcon>
									</Group>
								</Menu.Target>

								<Menu.Dropdown
									// @ts-ignore
									sx={{
										overflowY: "auto",
										maxHeight: `calc(100vh - ${rem(128)})`,
										"::-webkit-scrollbar": {
											display: "none",
										},
										scrollbarWidth: "none",
										msOverflowStyle: "none",
										zIndex: "600!important",
									}}
								>
									<ScrollArea>
										<Menu.Label mb={rem(16)}>Services</Menu.Label>

										{organizationsMetadata?.services.map(service => (
											<Menu.Item
												key={service.workspace_id}
												sx={theme => ({
													"&[data-hovered]": {
														background:
															activeWorkspace?.workspace_id ===
															service.workspace_id
																? theme.colors["refactor"][3]
																: theme.white,
													},
													padding: 0,
													transition: "none",
													background:
														activeWorkspace?.workspace_id ===
														service.workspace_id
															? theme.colors["refactor"][3]
															: theme.white,
												})}
												mb={rem(6)}
												onClick={() => {
													if (
														activeWorkspace?.workspace_id ===
														service.workspace_id
													) {
														return
													}
													switchWorkspace(service)
												}}
											>
												<Group
													sx={{
														display: "flex",
														alignItems: "center",
														gap: rem(10),

														width: `calc(100% - ${rem(24)})`,
													}}
													noWrap
												>
													<Avatar
														color='#404040'
														classNames={{
															root: classes.avatarRoot,
															placeholder:
																classes.avatarPlaceHolder,
														}}
													>
														{service?.workspace_name
															.slice(0, 1)
															.toUpperCase()}
													</Avatar>

													<Text
														fz={rem(14)}
														fw={
															activeWorkspace?.workspace_id ===
															service.workspace_id
																? 500
																: 400
														}
														lh={rem(20)}
														lts={0.056}
														maw={rem(180)}
														sx={theme => ({
															color: theme.colors["refactor"][0],
															overflow: "hidden",
															whiteSpace: "nowrap",
															textOverflow: "ellipsis",
															width: "calc(100%)",
														})}
													>
														{service.workspace_name}
													</Text>
												</Group>

												{activeWorkspace?.workspace_id ===
												service.workspace_id ? (
													<Center
														h={rem(16)}
														w={rem(16)}
													>
														<IconCircleCheck
															size={16}
															color='#A3A3A3'
														/>
													</Center>
												) : null}
											</Menu.Item>
										))}

										<Menu.Item
											onClick={() => {
												openNewWorkspaceModal(true)
											}}
										>
											<Box>
												<Group
													align='center'
													sx={{ gap: rem(12) }}
												>
													<IconPlus
														color='#A3A3A3'
														size={16}
													/>
													<Text
														lh={rem(20)}
														sx={theme => ({
															color: theme.colors["refactor"][0],
														})}
														fw={400}
														fz={rem(14)}
													>
														Create new service
													</Text>
												</Group>
											</Box>
										</Menu.Item>
									</ScrollArea>
								</Menu.Dropdown>
							</Menu>
						) : null}

						<ActionIcon
							sx={{
								height: rem(32),
								width: rem(32),
								":hover": {
									border: "none",
									scale: "none",
									borderRadius: 0,
									background: "none",
								},
							}}
							onClick={() => {
								setNotificationDrawerOpen(true)
							}}
							id='notifications-bell'
						>
							{unread > 0 ? <Notification /> : <NotificationOff />}
						</ActionIcon>

						<Menu
							classNames={{
								dropdown: classes.dropdown,
								label: classes.label,
								item: classes.menuItem,
								itemLabel: classes.itemLabel,
								itemIcon: classes.itemIcon,
								divider: classes.divider,
							}}
						>
							<Menu.Target>
								<Image
									src={user?.imageUrl || ""}
									classNames={{
										root: classes.imageRoot,
										imageWrapper: classes.imageWrapper,
										figure: classes.imageFigure,
										image: classes.image,
									}}
								/>
							</Menu.Target>

							<Menu.Dropdown>
								<Menu.Label>Account</Menu.Label>
								<Menu.Item
									icon={
										<IconUserCircle
											size={16}
											className={classes.icon}
										/>
									}
									onClick={() => {
										navigate("/settings")
									}}
								>
									My Account
								</Menu.Item>
								<Menu.Item
									icon={
										<IconFileText
											size={16}
											className={classes.icon}
										/>
									}
									onClick={() => {
										window.open(
											"https://resilis.io/docs/overview",
											"_blank",
											"noopener,noreferrer"
										)
									}}
								>
									Help & Documentation
								</Menu.Item>

								<Menu.Item
									icon={
										<IconRestore
											size={16}
											className={classes.icon}
										/>
									}
									onClick={() => {
										navigate("/")
										updateEndpointHintState({ completedHints: false })
										updateHeaderHintState({ completedHints: false })
										updateTourState({ completedTour: false })
									}}
								>
									Restart Tour
								</Menu.Item>
								<Menu.Item
									icon={
										<IconLogout
											size={16}
											className={classes.dangerIcon}
										/>
									}
									onClick={() => {
										resetState()
										signOut()
									}}
								>
									<Text
										sx={theme => ({
											color: theme.colors["danger"][0],
											lineHeight: rem(20),
											fontSize: rem(14),
										})}
									>
										Sign Out
									</Text>
								</Menu.Item>

								{isProduction ? null : <Menu.Divider />}
								{isProduction ? null : (
									<Menu.Item>
										<Button
											fullWidth
											radius='md'
											h={rem(32)}
											sx={{
												letterSpacing: -0.2,
												lineHeight: rem(16),
												fontWeight: 500,
												fontSize: rem(14),
												borderRadius: rem(8),
											}}
											onClick={() => {
												navigate("/pricing", {
													state: { from: location.pathname },
												})
											}}
										>
											Upgrade
										</Button>
									</Menu.Item>
								)}
							</Menu.Dropdown>
						</Menu>
					</Center>
				</Flex>
			</MediaQuery>
		</Header>
	)
}

export default AppHeader
