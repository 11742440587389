import {
	IconChevronDown,
	IconChevronUp,
	IconSelector,
} from "@tabler/icons-react"
import { ThProps } from "../../../../../types/endpoint-table.types"
import { useStyles } from "./styles"
import { UnstyledButton, rem, Group, Text, Center } from "@mantine/core"
function Th({ children, reversed, sorted, onSort }: ThProps) {
	const { classes } = useStyles()
	const Icon = sorted
		? reversed
			? IconChevronUp
			: IconChevronDown
		: IconSelector
	return (
		<th className={classes.thHead}>
			<UnstyledButton
				onClick={onSort}
				p={0}
				w='100%'
				pb={rem(16)}
				lh={rem(12)}
			>
				<Group>
					<Text
						fw={500}
						lts={0.8}
						tt='uppercase'
						lh={rem(12)}
						fz={rem(12)}
					>
						{children}
					</Text>
					{onSort ? (
						<Center>
							<Icon
								size={rem(12)}
								stroke={1.5}
							/>
						</Center>
					) : null}
				</Group>
			</UnstyledButton>
		</th>
	)
}

export default Th
