import { Link } from "react-router-dom"
import { Laptop } from "../../../assets/icons"
import { Stack, Text, Button, Group, rem } from "@mantine/core"

const RedirectModal = () => {
	return (
		<Stack sx={{ gap: 0, alignItems: "center" }}>
			<Group sx={{ gap: rem(12), justifyContent: "center" }}>
				<Laptop />
				<Text
					sx={theme => ({
						fontWeight: 600,
						fontSize: rem(21),
						lineHeight: rem(24),
						color: theme.colors[theme.primaryColor][7],
					})}
				>
					Password Updated
				</Text>
			</Group>
			<Text
				mt={rem(24)}
				sx={theme => ({
					lineHeight: rem(24),
					fontSize: rem(16),
					color: theme.colors[theme.primaryColor][8],
				})}
			>
				Your password has been successfully updated. You can now sign in
				with your new password
			</Text>
			<Button
				sx={theme => ({
					borderRadius: rem(8),
					height: rem(48),
					marginTop: rem(40),
				})}
				fullWidth
				component={Link}
				to='/login'
			>
				Log into Resilis
			</Button>
		</Stack>
	)
}

export default RedirectModal
