export const infoMap: Record<string, string> = {
	"": "",
	"starter-monthly":
		"Enterprise-level features with top-tier support; for large teams and complexities.",
	"pro-monthly":
		"Advanced optimization with priority support; tailored for ambitious teams",
	"business-monthly":
		"Enterprise-level features with top-tier support; for large teams and complexities.",
	"starter-annual":
		"Advanced optimization with priority support; tailored for ambitious teams",
	"pro-annual":
		"Enterprise-level features with top-tier support; for large teams and complexities.",
	"business-annual":
		"Advanced optimization with priority support; tailored for ambitious teams",
}

export const currencyMap = {
	usd: "$",
}
export const perksMap: Record<string, string[]> = {
	"": [],
	"starter-monthly": [
		"Edge Caching",
		"Expiry Based Invalidation",
		"Mutation Based Invalidation",
		"Asynchronous Request",
		"Analytics",
		"Email Alerts",
		"Slack Support",
		"Strict Routing",
		"Geo Restrictions",
		"DDoS Protection",
		"Bot Traffic Protection",
		"Custom Domains",
		"Email Support",
		"Community Support (Discord)",
	],
	"pro-monthly": [
		"Includes Everything in Starter, Plus:",
		"JWT Validation",
		"Rate Limiting",
		"Enhanced Asynchronous Processing",
	],
	"business-monthly": [
		"Includes Everything in Pro, Plus:",
		"SAML Single Sign-On (SSO)",
		"Priority Support, 99.99% Uptime SLA",
	],
	"starter-annual": [
		"Edge Caching",
		"Expiry Based Invalidation",
		"Mutation Based Invalidation",
		"Asynchronous Request",
		"Analytics",
		"Email Alerts",
		"Slack Support",
		"Strict Routing",
		"Geo Restrictions",
		"DDoS Protection",
		"Bot Traffic Protection",
		"Custom Domains",
		"Email Support",
		"Community Support (Discord)",
	],
	"pro-annual": [
		"Includes Everything in Starter, Plus:",
		"JWT Validation",
		"Rate Limiting",
		"Enhanced Asynchronous Processing",
	],
	"business-annual": [
		"Includes Everything in Pro, Plus:",
		"SAML Single Sign-On (SSO)",
		"Priority Support, 99.99% Uptime SLA",
	],
}
export const parseTitle = (lookupKey: string) => {
	return lookupKey
		.split("-")
		.map(k => k[0].toUpperCase() + k.slice(1))
		.join("-")
}
