import CustomDrawer from "../Drawer/Drawer"
import useAppState from "../../zustand/new-store"
import { useState } from "react"
import { Box, Button, Group, Stack, Text, rem } from "@mantine/core"
import DrawerSuccess from "../DrawerSuccess/DrawerSuccess"
import { SubmitHandler, useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import * as z from "zod"
import CustomTextInput from "../TextInput/TextInput"
import { IconAlertTriangle } from "@tabler/icons-react"
import { Service } from "../../types/supabase.types"
import { nanoid } from "nanoid"
import { updateOrganizationMetadata } from "../../api/supabase/update-org-metadata"
import { useAuth } from "@clerk/clerk-react"

const workspaceSchema = z.object({
	workspaceName: z.string().min(1, { message: "Service name is required" }),
})

type WorkspaceFormType = z.infer<typeof workspaceSchema>

const WorkspaceDrawer = () => {
	const {
		openNewWorkspaceModal,
		workspaceModalOpen,
		organizationsMetadata,
		setActiveWorkSpace,
		setOrganizationMetadata,
		setCacheConfig,
	} = useAppState()
	const [success, setSuccess] = useState(false)
	const [isUploading, setIsUploading] = useState(false)
	const [error, setError] = useState<string>("")
	const [localWorkspace, setLocalWorkspace] = useState<Service | null>(null)
	const { getToken, orgId, isLoaded } = useAuth()
	const { handleSubmit, control, formState, getValues, reset } =
		useForm<WorkspaceFormType>({
			resolver: zodResolver(workspaceSchema),
			defaultValues: {
				workspaceName: "",
			},
		})

	const submitHandler: SubmitHandler<WorkspaceFormType> = async values => {
		setError("")
		setIsUploading(true)

		if (!isLoaded || !organizationsMetadata || !orgId) {
			setIsUploading(false)
			return
		}
		const services =
			organizationsMetadata?.services.map(
				service => service.workspace_name
			) || []

		if (services?.includes(values.workspaceName)) {
			setError(
				`A service with the name ${values.workspaceName} already exists. Try a different name.`
			)
		} else {
			const newService: Service = {
				workspace_id: nanoid().toLowerCase(),
				workspace_name: values.workspaceName,
			}
			const services: Service[] = [
				...organizationsMetadata.services,
				newService,
			]

			const { data, error } = await updateOrganizationMetadata(getToken, {
				org_id: orgId,
				services,
			})

			if (error) {
				if (typeof error === "string") {
					setError(error)
				} else {
					setError("Something went wrong please try again")
				}
				reset()
			}

			if (data) {
				setOrganizationMetadata(data)
				setSuccess(true)
				setLocalWorkspace(newService)
				reset()
			}
		}

		setIsUploading(false)
	}

	return (
		<CustomDrawer
			opened={workspaceModalOpen}
			title={success ? undefined : "Create a New Service"}
			onClose={() => {
				openNewWorkspaceModal(false)
				if (success) {
					setActiveWorkSpace(localWorkspace)
					setCacheConfig(null)
					reset()
					setSuccess(false)
				}
			}}
		>
			{success ? (
				<DrawerSuccess
					title='Service Created Successfully!'
					subtext={`${getValues(
						"workspaceName"
					)} is now ready. Let's get it set up for optimal API performance!`}
					btnText=' Start Setting Up'
					btnAction={() => {
						openNewWorkspaceModal(false)
						setActiveWorkSpace(localWorkspace)
						setSuccess(false)
						reset()
						setCacheConfig(null)
					}}
				/>
			) : (
				<Stack
					sx={{ gap: rem(24) }}
					mt={rem(24)}
					w='100%'
				>
					<Text
						fw={400}
						lh={rem(24)}
						fz={rem(16)}
						sx={theme => ({ color: theme.colors["refactor"][4] })}
					>
						Organize your APIs and metrics by projects, teams, or criteria
						with workspaces.
					</Text>
					<Box
						component='form'
						display='flex'
						sx={{
							gap: rem(24),
							flexDirection: "column",
						}}
						onSubmit={handleSubmit(submitHandler)}
					>
						<CustomTextInput
							name='workspaceName'
							placeholder='Enter Service Name'
							label='Service Name'
							control={control}
							errorMessage={formState.errors.workspaceName?.message}
							disabled={isUploading}
						/>

						<Button
							variant='filled'
							fullWidth
							h={rem(48)}
							type='submit'
							mt={
								Boolean(formState.errors.workspaceName?.message)
									? rem(24)
									: 0
							}
							disabled={isUploading}
							loading={isUploading}
							sx={{
								letterSpacing: -0.2,
								lineHeight: rem(16),
								fontWeight: 500,
								borderRadius: rem(8),
								fontSize: rem(16),
							}}
						>
							Continue
						</Button>

						{error ? (
							<Group
								p={rem(12)}
								sx={theme => ({
									borderRadius: rem(4),
									background: theme.colors["danger"][1],
									gap: rem(8),
								})}
								noWrap
								align='flex-start'
							>
								<IconAlertTriangle
									size={24}
									color='#DB1B24'
								/>

								<Text
									lh={rem(20)}
									fw={400}
									fz={rem(14)}
									sx={theme => ({ color: theme.colors["danger"][0] })}
								>
									{error}
								</Text>
							</Group>
						) : null}
					</Box>
				</Stack>
			)}
		</CustomDrawer>
	)
}

export default WorkspaceDrawer
