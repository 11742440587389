import { getDashboardLatencyChartData } from "../../../../api/server/analytics/latency-performance-metrics"
import { useQuery } from "@tanstack/react-query"
import useAppStore from "../../../../zustand/new-store"
import { AxiosError } from "axios"
import { useAuth } from "@clerk/clerk-react"
import { Intervals } from "../../../../types/dashboard-chart-types"
import { ANALYTICS_REFETCH_INTERVAL } from "../../../../constants/analytics-refetch"

const useLatencyPercentile = (
	interval: Intervals,
	percentile: string,
	country: string
) => {
	const { cacheConfig } = useAppStore()
	const { getToken } = useAuth()

	return useQuery<{
		meta: {
			name: string
			type: string
		}[]
		data: {
			time_group: string
			total_requests: string
			percentile_latency: number
			avg_latency: number
			error_rate: number
		}[]
		rows: 15
		aggregateValues: {
			totalRequests: number
			avgRequestsPerSecond: number
			avgLatency: number
			totalErrorRate: number
			percentileLantency: number
		}
	}>({
		queryFn: async () => {
			const token = await getToken()

			if (!token) {
				throw new AxiosError("Unauthenticated")
			}
			return getDashboardLatencyChartData({
				host: cacheConfig?.host || "",
				token,
				interval,
				percentile: percentile.replace("th", ""),
				country: country !== "global" ? country : "",
			})
		},
		queryKey: [
			cacheConfig?.host,
			interval,
			percentile,
			"latency percentile",
			country,
		],
		refetchInterval: data => {
			if (!data) {
				return false
			} else {
				return ANALYTICS_REFETCH_INTERVAL
			}
		},
		refetchOnWindowFocus: false,
		retry: 1,
	})
}

export default useLatencyPercentile
