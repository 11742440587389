import { isClerkAPIResponseError, useSignIn } from "@clerk/clerk-react"
import { Logo } from "../../../assets/icons"
import {
	Center,
	Stack,
	Text,
	Group,
	Divider,
	rem,
	createStyles,
	Button,
	Box,
	MediaQuery,
	Flex,
} from "@mantine/core"
import CustomTextInput from "../../../Components/TextInput/TextInput"
import SocialIcon from "../Components/SocialIcon"
import * as z from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm, SubmitHandler } from "react-hook-form"
import CustomPasswordInput from "../../../Components/PasswordInput/PasswordInput"
import { IconMailFilled, IconX } from "@tabler/icons-react"
import { Link, Navigate } from "react-router-dom"
import useOauth from "../../../hooks/useOauth"
import { useState } from "react"
import { notifications } from "@mantine/notifications"
import { useNavigate } from "react-router-dom"
import { useDocumentTitle } from "@mantine/hooks"

const loginFormSchema = z.object({
	email: z
		.string({
			required_error: "email is required",
			invalid_type_error: "Invalid email format",
		})
		.email({ message: "Invalid email format" }),
	password: z.string(),
})
type loginFormType = z.infer<typeof loginFormSchema>
const useStyles = createStyles(theme => ({
	label: {
		color: theme.colors["refactor"][5],
		margin: "0 auto !important",
		"::before": {
			borderTop: `1px solid ${theme.colors["refactor"][5]}`,
			height: rem(1),
		},
		"::after": {
			borderTop: `1px solid ${theme.colors["refactor"][5]}`,
			height: rem(1),
		},
	},
	signInLink: {
		textDecoration: "none",
		display: "inline",
		fontWeight: 600,
		fontSize: "inherit",
		linHeight: "inherit",
		letterSpacing: "inherit",
		fontVariantNumeric: "inherit",
		":visited": {
			color: theme.colors["brand"][2],
		},
	},
	serviceLink: {
		display: "inline",
		fontWeight: 400,
		fontSize: rem(10),
		linHeight: rem(14),
		letterSpacing: "inherit",
		fontVariantNumeric: "inherit",
		color: theme.colors["refactor"][0],
		alignSelf: "flex-start",
		marginTop: rem(-10),
	},
	password: {
		marginTop: rem(16),
	},
}))
const LogIn = () => {
	const { classes, cx } = useStyles()
	const { isLoaded, signIn, setActive } = useSignIn()
	const [loading, setLoading] = useState(false)
	const [emailError, setEmailError] = useState<string | undefined>(undefined)
	const [passwordError, setPasswordError] = useState<string | undefined>(
		undefined
	)
	useDocumentTitle("Login")

	const ticketParam = "__clerk_ticket"
	const statusParam = "__clerk_status"
	const emailParam = "email"
	const ticket = new URL(window.location.href).searchParams.get(ticketParam)
	const status = new URL(window.location.href).searchParams.get(statusParam)
	const emailP = new URL(window.location.href).searchParams.get(emailParam)

	const { signInWithGoogle, signInWithGithub, signInWithGitlab } = useOauth()
	const navigate = useNavigate()
	const { control, handleSubmit, reset, watch, formState } =
		useForm<loginFormType>({
			resolver: zodResolver(loginFormSchema),
			defaultValues: {
				email: emailP ? emailP : "",
				password: "",
			},
		})

	const submit: SubmitHandler<loginFormType> = async values => {
		setLoading(true)
		setPasswordError("")
		setEmailError("")
		if (!isLoaded) {
			setLoading(false)
			return
		}

		try {
			if (!ticket) {
				const result = await signIn.create({
					identifier: values.email,
					password: values.password,
				})

				if (result.status === "complete") {
					await setActive({ session: result.createdSessionId })
					navigate("/")
					reset()
				} else {
					console.log(JSON.stringify(result, null, 2))
				}
			} else {
				const result = await signIn.create({
					identifier: values.email,
					password: values.password,
					ticket,
				})

				if (result.status === "complete") {
					await setActive({ session: result.createdSessionId })
					navigate("/")
					reset()
				} else {
					console.log(JSON.stringify(result, null, 2))
				}
			}
		} catch (error) {
			if (isClerkAPIResponseError(error)) {
				if (!error.errors[0].longMessage) {
					notifications.show({
						withCloseButton: true,
						color: "danger.0",
						title: "Error",
						icon: <IconX />,
						autoClose: 5000,
						message: error.errors[0].longMessage,
					})
				} else {
					if (
						error.errors[0].longMessage === "Couldn't find your account."
					) {
						setEmailError(error.errors[0].longMessage)
					} else if (
						error.errors[0].longMessage ===
						"Password is incorrect. Try again, or use another method."
					) {
						setPasswordError(
							"Password is incorrect. Try again, or use another method."
						)
					} else {
						notifications.show({
							withCloseButton: true,
							color: "danger.0",
							title: "Error",
							icon: <IconX />,
							autoClose: 5000,
							message: error.errors[0].longMessage,
						})
					}
				}
			} else {
				notifications.show({
					withCloseButton: true,
					color: "danger.0",
					title: "Error",
					icon: <IconX />,
					autoClose: 5000,
					message: "Something went wrong",
				})
			}
			console.log(error)
			console.log(JSON.stringify(error, null, 2))
		}

		setLoading(false)
	}

	if (status && status === "sign_up" && ticket && emailP) {
		return (
			<Navigate
				to={`/sign-up?email=${encodeURIComponent(
					emailP
				)}&${statusParam}=${status}&${ticketParam}=${ticket}`}
			/>
		)
	}
	return (
		<MediaQuery
			query='(min-width: 75em)'
			styles={{
				width: rem(360),
			}}
		>
			<Flex
				sx={{
					width: `max(${rem(360)}, 45vw)`,
				}}
			>
				<Stack
					justify='center'
					sx={{
						gap: rem(0),
					}}
				>
					<Box mb={!!ticket ? rem(48) : rem(75)}>
						<Logo />
					</Box>
					<Stack sx={{ gap: rem(8) }}>
						<Text
							component='h1'
							sx={theme => ({
								fontSize: rem(36),
								fontWeight: 600,
								lineHeight: rem(40),
								letterSpacing: rem(-0.2),
								color: theme.colors[theme.primaryColor][7],
							})}
							mt={0}
							mb={0}
						>
							Welcome Back
						</Text>
						<Text
							component='h2'
							mt={0}
							mb={0}
							sx={theme => ({
								lineHeight: rem(24),
								fontSize: rem(16),
								letterSpacing: rem(-0.2),
								color: theme.colors["refactor"][4],
								fontWeight: 400,
							})}
						>
							Log in to optimize and manage your API infrastructure.
						</Text>
					</Stack>

					{!!ticket ? null : (
						<>
							<Group
								spacing={rem(16)}
								sx={{
									marginTop: rem(40),
								}}
							>
								<SocialIcon
									type='github'
									onClick={() => {
										if (signInWithGithub) {
											if (loading) {
												return
											}
											setLoading(true)
											signInWithGithub().then(r => setLoading(false))
										}
									}}
								/>
								<SocialIcon
									type='gitlab'
									onClick={() => {
										if (signInWithGitlab) {
											if (loading) {
												return
											}
											setLoading(true)
											signInWithGitlab().then(r => setLoading(false))
										}
									}}
								/>
								<SocialIcon
									type='google'
									onClick={() => {
										if (signInWithGoogle) {
											if (loading) {
												return
											}
											setLoading(true)
											signInWithGoogle().then(r => setLoading(false))
										}
									}}
								/>
							</Group>
							<Center w='100%'>
								<Divider
									my='xs'
									label='or'
									classNames={{ label: classes.label }}
									labelPosition='center'
									w={rem("75%")}
								/>
							</Center>
						</>
					)}
					<Box
						component='form'
						sx={{
							alignItems: "center",
							justifyContent: "center",
							display: "flex",
							flexDirection: "column",
							gap: rem(16),
						}}
						w={"100%"}
						onSubmit={handleSubmit(submit)}
						mt={!!ticket ? rem(24) : 0}
					>
						<CustomTextInput
							name='email'
							placeholder='Email address'
							label='Email address'
							control={control}
							value={watch("email")}
							disabled={loading}
							errorMessage={
								formState.errors.email?.message || emailError
							}
						/>
						<CustomPasswordInput
							name='password'
							control={control}
							placeholder='Password'
							label='Password'
							value={watch("password")}
							disabled={loading}
							className={cx({
								[classes.password]:
									!!formState.errors.email || emailError,
							})}
							errorMessage={
								formState.errors.password?.message || passwordError
							}
							hideStrengthBar={true}
						/>
						<Flex
							w={"100%"}
							justify='flex-end'
						>
							<Link
								to='/forgot-password'
								className={classes.serviceLink}
							>
								Forgot Password?
							</Link>
						</Flex>

						<Button
							variant='filled'
							leftIcon={<IconMailFilled />}
							type='submit'
							radius='md'
							fullWidth
							sx={theme => ({
								padding: rem(16),
								height: rem(52),
								":disabled": {
									background: theme.colors["refactor"][5],
									color: theme.colors["refactor"][4],
								},
							})}
							disabled={loading}
							loading={loading}
						>
							Login with Email
						</Button>
					</Box>
					<Flex
						mt={rem(16)}
						direction='column'
						gap={rem(24)}
						align='center'
					>
						{!!ticket ? null : (
							<Text
								align='center'
								sx={theme => ({
									color: theme.colors["refactor"][0],
									fontVariantNumeric: "lining-nums  proportional-nums",
									fontSize: rem(14),
									lineHeight: rem(16),
									letterSpacing: -0.2,
									display: "inline-block",

									borderRadius: rem(6),
									background: theme.white,
									border: ` 1px solid ${theme.colors["refactor"][7]}`,
									padding: `${rem(10)} ${rem(16)}`,
									width: "fit-content",
								})}
							>
								Don’t have an account?{" "}
								<Link
									to='/sign-up'
									className={classes.signInLink}
								>
									Get Started
								</Link>
							</Text>
						)}
					</Flex>
				</Stack>
			</Flex>
		</MediaQuery>
	)
}

export default LogIn
