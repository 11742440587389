import { Pricing } from "../../../../types/pricing.types"
import {
	Box,
	Group,
	Stack,
	Text,
	rem,
	Skeleton,
	Center,
	Button,
} from "@mantine/core"
import { IconCircleCheck } from "@tabler/icons-react"
import { useMantineTheme } from "@mantine/core"
import { currencyMap, infoMap, parseTitle, perksMap } from "./utils"
import useCheckout from "../api/useCheckout"
import { useAuth } from "@clerk/clerk-react"
import { isProduction } from "../../../../constants/is-production"

interface IProps {
	pricing: Pricing | undefined
	loading: boolean
	popular?: boolean
	active: boolean
}

const PricingCard = ({ pricing, loading, popular, active }: IProps) => {
	const theme = useMantineTheme()
	const { mutate, isLoading: isCheckoutLoading } = useCheckout()
	const { getToken, orgId } = useAuth()
	return (
		<Box
			component='article'
			sx={theme => ({
				borderRadius: rem(12),
				borderStyle: "solid",
				borderColor: active
					? theme.colors[theme.primaryColor][2]
					: theme.colors["refactor"][2],
				borderWidth: "1px",
				position: "relative",
			})}
			bg='white'
			p={rem(24)}
			h='100%'
		>
			<Stack
				justify='space-between'
				h='100%'
				spacing={rem(16)}
			>
				<Stack spacing={rem(12)}>
					<Group
						sx={{
							justifyContent: "space-between",
						}}
					>
						{loading ? (
							<Skeleton
								h={rem(20)}
								w={rem(40)}
							/>
						) : (
							<Text
								component='h3'
								m={0}
								c='brand.7'
								fz={rem(18)}
								fw={600}
								lh={rem(20)}
							>
								{currencyMap[
									pricing?.currency as keyof typeof currencyMap
								] || "$"}{" "}
								{Number(pricing?.unit_amount) / 100 || 0}
							</Text>
						)}
						{popular && !active ? (
							<Center
								c='brand.2'
								px={rem(6)}
								py={rem(8)}
								bg='#F6F3FF'
								lh={rem(18)}
								fw={400}
								fz={rem(14)}
								pos='absolute'
								top={rem(16)}
								right={rem(16)}
								sx={{
									borderRadius: rem(6),
								}}
							>
								Most Popular
							</Center>
						) : null}

						{active ? (
							<IconCircleCheck
								size={24}
								color={theme.colors[theme.primaryColor][2]}
							/>
						) : null}
					</Group>

					{loading ? (
						<Skeleton
							h={rem(20)}
							w={rem(160)}
						/>
					) : (
						<Text
							fw={400}
							fz={rem(18)}
							lh={rem(20)}
							c=' #404040'
							w='fit-content'
						>
							{pricing?.lookup_key
								? parseTitle(pricing?.lookup_key)
								: ""}
						</Text>
					)}

					{loading ? (
						<Stack
							w='100%'
							sx={{ gap: rem(8) }}
						>
							<Skeleton
								h={rem(24)}
								w='100%'
							/>
							<Skeleton
								h={rem(24)}
								w='100%'
							/>
						</Stack>
					) : (
						<Text
							fw={400}
							fz={rem(16)}
							lh={rem(24)}
							c='refactor.4'
							w='fit-content'
						>
							{infoMap[pricing?.lookup_key || ""] || ""}
						</Text>
					)}
					<Stack
						spacing={rem(8)}
						w='100%'
					>
						{loading ? (
							<>
								<Group
									spacing={rem(8)}
									align='center'
									w='100%'
									noWrap
								>
									<Skeleton
										h={rem(16)}
										w={rem(16)}
										circle
									/>
									<Skeleton
										h={rem(24)}
										sx={{
											flexGrow: 1,
										}}
									/>
								</Group>
								<Group
									spacing={rem(8)}
									align='center'
									w='100%'
									noWrap
								>
									<Skeleton
										h={rem(16)}
										w={rem(16)}
										circle
									/>
									<Skeleton
										h={rem(24)}
										sx={{
											flexGrow: 1,
										}}
									/>
								</Group>
								<Group
									spacing={rem(8)}
									align='center'
									w='100%'
									noWrap
								>
									<Skeleton
										h={rem(16)}
										w={rem(16)}
										circle
									/>
									<Skeleton
										h={rem(24)}
										sx={{
											flexGrow: 1,
										}}
									/>
								</Group>
							</>
						) : (
							perksMap[pricing?.lookup_key || ""]?.map((perk, idx) => (
								<Group
									key={idx}
									spacing={rem(8)}
									align='center'
									w='100%'
									noWrap
								>
									<IconCircleCheck
										size={16}
										color={theme.colors["green"][0]}
									/>
									<Text
										fw={400}
										fz={rem(16)}
										lh={rem(24)}
										c='refactor.4'
										w='fit-content'
										display='inline'
										sx={{
											whiteSpace: "break-spaces",
										}}
									>
										{perk}
									</Text>
								</Group>
							))
						)}
					</Stack>
				</Stack>
				{active ? null : (
					<Button
						h={rem(40)}
						sx={{
							letterSpacing: -0.2,
							lineHeight: rem(16),
							fontWeight: 500,
							borderRadius: rem(8),
							fontSize: rem(12),
						}}
						disabled={loading || isCheckoutLoading}
						loading={loading || isCheckoutLoading}
						onClick={async () => {
							const token = await getToken()
							if (!orgId || !token || !pricing || isProduction) {
								return
							}
							mutate({
								org_id: orgId,
								token,
								lookup_key: pricing.lookup_key,
							})
						}}
					>
						Upgrade Plan
					</Button>
				)}
			</Stack>
		</Box>
	)
}

export default PricingCard
