import { Button, Group, Stack, Text, rem } from "@mantine/core"
import { useDocumentTitle } from "@mantine/hooks"
import { Slack } from "../../../../assets/icons"
import { useEffect, useMemo, useState } from "react"
import useAppStore from "../../../../zustand/new-store"
import { useAuth } from "@clerk/clerk-react"
import { getWorkspaceIntegrations } from "../../../../api/supabase/get-integrations"

const Integrations = () => {
	useDocumentTitle("Integrations")

	const { cacheConfig, setIntegrations, integrations } = useAppStore()
	const { getToken } = useAuth()
	const [loading, setLoading] = useState(false)

	const url = useMemo(() => {
		return `https://api.novu.co/v1/subscribers/${cacheConfig?.host}/credentials/slack/oauth?environmentId=${process.env.REACT_APP_NOVU_ENVIRONMENT_ID}`
	}, [cacheConfig?.host])

	const getIntegrations = async (
		getToken: (
			options?: { template: string } | undefined
		) => Promise<string | null>,
		hostname: string
	) => {
		setLoading(true)
		try {
			const integrated = await getWorkspaceIntegrations(
				getToken,
				hostname || ""
			)
			if (integrated.data) {
				setIntegrations(integrated.data)
				return
			}
			setIntegrations(null)
			console.log(integrated.error)
		} catch (error) {
			console.log(error)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		let ignore = false

		if (!ignore) {
			getIntegrations(getToken, cacheConfig?.host || "")
		}

		return () => {
			ignore = true
		}
	}, [getToken, cacheConfig?.host])
	return (
		<Stack
			w='100%'
			p={rem(32)}
			sx={theme => ({
				borderRadius: rem(8),
				border: `1px solid ${theme.colors["refactor"][2]}`,
				gap: rem(32),
			})}
		>
			<Stack sx={{ gap: rem(8) }}>
				<Text
					lh={rem(20)}
					fw={500}
					fz={rem(16)}
					component='h1'
					color='brand.7'
					m={0}
				>
					Connected Apps
				</Text>
				<Text
					component='h6'
					m={0}
					fw={400}
					lh={rem(20)}
					color='refactor.4'
					fz={rem(14)}
				>
					Connect Resilis with tools to enhance functionality and
					streamline your workflow.
				</Text>
			</Stack>
			<Stack sx={{ gap: rem(40) }}>
				<Group
					sx={{
						justifyContent: "space-between",
					}}
				>
					<Group
						sx={{ gap: rem(16) }}
						align='center'
					>
						<Slack />
						<Stack sx={{ gap: 0 }}>
							<Text
								lh={rem(24)}
								fw={600}
								fz={rem(16)}
								c='#171717'
							>
								Slack
							</Text>
							<Text
								fw={400}
								lh={rem(20)}
								color='refactor.4'
								fz={rem(14)}
							>
								Sync Resilis with Slack for instant notifications and
								seamless team communication.
							</Text>

							{integrations && integrations.slack ? (
								<Text
									fw={400}
									lh={rem(20)}
									color='refactor.4'
									fz={rem(12)}
								>
									Connected.
								</Text>
							) : null}
						</Stack>
					</Group>
					<Button
						component='a'
						href={url}
						target='_blank'
						h={rem(40)}
						sx={{
							borderRadius: rem(8),
							fontWeight: 500,
							lineHeight: rem(16),
							letterSpacing: -0.2,
						}}
					>
						Connect
					</Button>
				</Group>
			</Stack>
		</Stack>
	)
}

export default Integrations
