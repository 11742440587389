import { isClerkAPIResponseError, useAuth, useSignIn } from "@clerk/clerk-react"
import { Logo, Email } from "../../../assets/icons"
import {
	Stack,
	Text,
	rem,
	createStyles,
	Button,
	Box,
	MediaQuery,
	Flex,
} from "@mantine/core"
import CustomTextInput from "../../../Components/TextInput/TextInput"
import * as z from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm, SubmitHandler } from "react-hook-form"
import { Link, useNavigate } from "react-router-dom"
import { useState } from "react"
import CustomPasswordInput from "../../../Components/PasswordInput/PasswordInput"
import CustomPinInput from "../../../Components/PinInput/PinInput"
import { useDisclosure, useDocumentTitle } from "@mantine/hooks"
import RedirectModal from "./RedirectModal"
import { notifications } from "@mantine/notifications"
import { IconX } from "@tabler/icons-react"
import CustomModal from "../../../Components/Modal/Modal"

const forgotPasswordFormSchema = z.object({
	email: z
		.string({
			required_error: "email is required",
			invalid_type_error: "Invalid email format",
		})
		.email({ message: "Invalid email format" }),
})
type forgotPasswordFormType = z.infer<typeof forgotPasswordFormSchema>

const resetPasswordFormSchema = z
	.object({
		password: z
			.string()
			.min(8, { message: "Password must have at least 8 characters" })
			.regex(/[$&+,:;=?@#|'<>.^*()%!-]/, {
				message: "Password must contain one special character",
			})
			.regex(/[A-Z]/, {
				message: "Password must contain one uppercase character",
			})
			.regex(/[0-9]/, {
				message: "Password must contain one number",
			})
			.regex(/[a-z]/, {
				message: "Password must contain one lowercase character",
			}),
		confirmPassword: z
			.string()
			.min(8, { message: "Password must have at least 8 characters" })
			.regex(/[$&+,:;=?@#|'<>.^*()%!-]/, {
				message: "Password must contain one special character",
			})
			.regex(/[A-Z]/, {
				message: "Password must contain one uppercase character",
			})
			.regex(/[0-9]/, {
				message: "Password must contain one number",
			})
			.regex(/[a-z]/, {
				message: "Password must contain one lowercase character",
			}),
		otp: z.string(),
	})
	.superRefine(({ confirmPassword, password }, ctx) => {
		if (confirmPassword !== password) {
			ctx.addIssue({
				code: "custom",
				message: "The passwords did not match",
				path: ["confirmPassword"],
			})
		}
	})
type resetPasswordType = z.infer<typeof resetPasswordFormSchema>
const useStyles = createStyles(theme => ({
	label: {
		color: theme.colors["refactor"][5],
		margin: "0 auto !important",
		"::before": {
			borderTop: `1px solid ${theme.colors["refactor"][5]}`,
			height: rem(1),
		},
		"::after": {
			borderTop: `1px solid ${theme.colors["refactor"][5]}`,
			height: rem(1),
		},
	},
	logo: {
		marginBottom: rem(75),
		marginTop: rem(66),
	},
	signInLink: {
		textDecoration: "none",
		display: "inline",
		fontWeight: 600,
		fontSize: "inherit",
		linHeight: "inherit",
		letterSpacing: "inherit",
		fontVariantNumeric: "inherit",
		":visited": {
			color: theme.colors["brand"][2],
		},
	},
	serviceLink: {
		display: "inline",
		fontWeight: 400,
		fontSize: rem(14),
		linHeight: rem(16),
		letterSpacing: "inherit",
		fontVariantNumeric: "inherit",
		color: theme.colors["refactor"][0],
		alignSelf: "flex-start",
	},
	highlight: {
		fontSize: rem(16),
		lineHeight: rem(24),
		fontWeight: 500,
		letterSpacing: -0.2,
		color: theme.colors[theme.primaryColor][7],
	},
	modalContent: {
		borderRadius: rem(16),
		background: theme.white,
		boxShadow: "none",
		padding: rem(40),
		width: rem(480),
		maxWidth: "fit-content",
	},
	modalBody: {
		padding: 0,
	},
}))

const SuccessfulRequest = ({ email }: { email: string }) => {
	useDocumentTitle("Password Reset Successful")

	const { classes } = useStyles()
	const { isLoaded, signIn } = useSignIn()
	const [requestSuccess, { open, close }] = useDisclosure(false)
	const navigate = useNavigate()
	const [loading, setLoading] = useState(false)

	const [otpError, setOtpError] = useState<string | undefined>(undefined)
	const [passwordError, setPasswordError] = useState<string | undefined>(
		undefined
	)
	const [confirmPassError, setConfirmPassError] = useState<string | undefined>(
		undefined
	)
	const { signOut } = useAuth()

	const { control, handleSubmit, watch, formState } =
		useForm<resetPasswordType>({
			resolver: zodResolver(resetPasswordFormSchema),
			defaultValues: {
				password: "",
				confirmPassword: "",
				otp: "",
			},
		})

	const submit: SubmitHandler<resetPasswordType> = async values => {
		if (!isLoaded) {
			return null
		}
		setLoading(true)
		setConfirmPassError(undefined)
		setPasswordError(undefined)
		setOtpError(undefined)
		try {
			const result = await signIn?.attemptFirstFactor({
				strategy: "reset_password_email_code",
				code: values.otp,
				password: values.password,
			})

			if (result.status === "complete") {
				signOut()
				open()
			} else {
				console.log(result)
				return
			}
		} catch (error) {
			console.log(error)
			console.log(JSON.stringify(error, null, 2))

			if (isClerkAPIResponseError(error)) {
				if (error.errors[0].longMessage === "Incorrect code") {
					setOtpError("Incorrect code")
				}
				notifications.show({
					withCloseButton: true,
					color: "danger.0",
					title: "Error",
					icon: <IconX />,
					autoClose: 5000,
					message: error.errors[0].longMessage,
				})
			} else {
				notifications.show({
					withCloseButton: true,
					color: "danger.0",
					title: "Error",
					icon: <IconX />,
					autoClose: 5000,
					message: "Something went wrong",
				})
			}
		}
		setLoading(false)
	}
	return (
		<>
			<CustomModal
				opened={requestSuccess}
				onClose={() => {
					close()
					navigate("/login")
				}}
			>
				<RedirectModal />
			</CustomModal>
			<Email />
			<Stack sx={{ gap: rem(8) }}>
				<Text
					component='h1'
					sx={theme => ({
						fontSize: rem(36),
						fontWeight: 600,
						lineHeight: rem(40),
						letterSpacing: rem(-0.2),
						color: theme.colors[theme.primaryColor][7],
					})}
					mt={0}
					mb={0}
				>
					Check your inbox
				</Text>
				<Text
					component='h2'
					mt={0}
					mb={0}
					sx={theme => ({
						lineHeight: rem(24),
						fontSize: rem(16),
						letterSpacing: rem(-0.2),
						fontWeight: 400,
						color: theme.colors["refactor"][4],
					})}
				>
					We've sent an email to{" "}
					<span className={classes.highlight}>{email}</span> with a link to
					reset your password. If you don't see it, please check your spam
					folder
				</Text>
			</Stack>
			<Box
				component='form'
				sx={{
					alignItems: "center",
					justifyContent: "center",
					display: "flex",
					flexDirection: "column",
					gap: rem(24),
					marginTop: rem(40),
				}}
				w={"100%"}
				onSubmit={handleSubmit(submit)}
			>
				<CustomPinInput
					name='otp'
					onComplete={() => {}}
					control={control}
					disabled={loading}
					errorMessage={formState.errors.otp?.message || otpError}
				/>
				<CustomPasswordInput
					name='password'
					placeholder='New Password'
					label='New Password'
					control={control}
					value={watch("password")}
					disabled={loading}
					errorMessage={formState.errors.password?.message}
				/>
				<CustomPasswordInput
					name='confirmPassword'
					placeholder='Confirm New Password'
					label='Confirm New Password'
					control={control}
					value={watch("confirmPassword")}
					hideStrengthBar
					disabled={loading}
					errorMessage={formState.errors.confirmPassword?.message}
				/>

				<Button
					variant='filled'
					type='submit'
					radius='md'
					fullWidth
					sx={{
						padding: rem(16),
						height: rem(52),
					}}
					disabled={loading}
					loading={loading}
				>
					Create New Password
				</Button>
			</Box>
		</>
	)
}
const ForgotPassword = () => {
	useDocumentTitle("Forgot Password")

	const { classes } = useStyles()
	const { isLoaded, signIn } = useSignIn()
	const [successfulCreation, setSuccessfulCreation] = useState(false)
	const [loading, setLoading] = useState(false)
	const [emailError, setEmailError] = useState<string | undefined>(undefined)

	const { control, handleSubmit, watch, getValues, formState } =
		useForm<forgotPasswordFormType>({
			resolver: zodResolver(forgotPasswordFormSchema),
			defaultValues: {
				email: "",
			},
		})

	const submit: SubmitHandler<forgotPasswordFormType> = async values => {
		if (!isLoaded) {
			return null
		}
		setLoading(true)
		setEmailError(undefined)

		try {
			await signIn?.create({
				strategy: "reset_password_email_code",
				identifier: values.email,
			})

			setSuccessfulCreation(true)
		} catch (error) {
			if (isClerkAPIResponseError(error)) {
				if (error.errors[0].longMessage === "Couldn't find your account.") {
					setEmailError("Couldn't find your account.")
				} else {
					notifications.show({
						withCloseButton: true,
						color: "danger.0",
						title: "Error",
						icon: <IconX />,
						autoClose: 5000,
						message: error.errors[0].longMessage,
					})
				}
			} else {
				notifications.show({
					withCloseButton: true,
					color: "danger.0",
					title: "Error",
					icon: <IconX />,
					autoClose: 5000,
					message: "Something went wrong",
				})
			}
		}
		setLoading(false)
	}
	return (
		<MediaQuery
			query='(min-width: 75em)'
			styles={{
				width: rem(360),
			}}
		>
			<Flex
				sx={{
					width: `max(${rem(360)}, 45vw)`,
					alignItems: "flex-start",
				}}
			>
				<Stack
					justify='center'
					sx={{
						gap: rem(0),
					}}
				>
					<Logo className={classes.logo} />

					{successfulCreation ? (
						<SuccessfulRequest email={getValues("email")} />
					) : (
						<>
							<Stack sx={{ gap: rem(8) }}>
								<Text
									component='h1'
									sx={theme => ({
										fontSize: rem(36),
										fontWeight: 600,
										lineHeight: rem(40),
										letterSpacing: rem(-0.2),
										color: theme.colors[theme.primaryColor][7],
									})}
									mt={0}
									mb={0}
								>
									Reset Password
								</Text>
								<Text
									component='h2'
									mt={0}
									mb={0}
									sx={theme => ({
										lineHeight: rem(24),
										fontSize: rem(16),
										letterSpacing: rem(-0.2),
										color: theme.colors["refactor"][4],
										fontWeight: 400,
									})}
								>
									Enter your registered email address, and we’ll send
									you a link to reset your password
								</Text>
							</Stack>

							<Box
								component='form'
								sx={{
									alignItems: "center",
									justifyContent: "center",
									display: "flex",
									flexDirection: "column",
									gap: rem(24),
									marginTop: rem(40),
								}}
								w={"100%"}
								onSubmit={handleSubmit(submit)}
							>
								<CustomTextInput
									name='email'
									placeholder='Email address'
									label='Email address'
									control={control}
									value={watch("email")}
									disabled={loading}
									errorMessage={
										formState.errors.email?.message || emailError
									}
								/>
								<Button
									variant='filled'
									type='submit'
									radius='md'
									fullWidth
									sx={{
										padding: rem(16),
										height: rem(52),
										marginTop:
											formState.errors.email?.message || emailError
												? rem(16)
												: 0,
									}}
									disabled={loading}
									loading={loading}
								>
									Send Reset Link
								</Button>
							</Box>
							<Flex
								mt={rem(16)}
								direction='column'
								gap={rem(24)}
								align='center'
							>
								<Text
									align='center'
									sx={theme => ({
										color: theme.colors["refactor"][0],
										fontVariantNumeric:
											"lining-nums  proportional-nums",
										fontSize: rem(14),
										lineHeight: rem(16),
										letterSpacing: -0.2,
										display: "inline-block",

										borderRadius: rem(6),
										background: theme.white,
										border: ` 1px solid ${theme.colors["refactor"][7]}`,
										padding: `${rem(10)} ${rem(16)}`,
										width: "fit-content",
									})}
								>
									Remember password?{" "}
									<Link
										to='/login'
										className={classes.signInLink}
									>
										Login
									</Link>
								</Text>
							</Flex>
						</>
					)}
				</Stack>
			</Flex>
		</MediaQuery>
	)
}

export default ForgotPassword
