import { RowData } from "../../../../../types/endpoint-table.types"
import { keys } from "@mantine/utils"

export function filterData(data: RowData[], search: string) {
	const query = search.toLowerCase().trim()
	return data.filter(item =>
		keys(data[0]).some(key =>
			item[key]?.toString().toLowerCase().includes(query)
		)
	)
}

export function sortData(
	data: RowData[],
	payload: {
		sortBy: keyof RowData | null
		reversed: boolean
		search: string
	}
) {
	const { sortBy } = payload

	if (!sortBy) {
		return filterData(data, payload.search)
	}

	return filterData(
		[...data].sort((a, b) => {
			if (payload.reversed) {
				return b[sortBy]!.toString().localeCompare(a[sortBy]!.toString())
			}

			return a[sortBy]!.toString().localeCompare(b[sortBy]!.toString())
		}),
		payload.search
	)
}
