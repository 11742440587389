import { Group, Stack, Center, Text, rem, createStyles } from "@mantine/core"
import { IconApi, IconArrowNarrowRight } from "@tabler/icons-react"

const useStyles = createStyles(theme => ({
	postmanIcon: {
		stroke: theme.colors["yellow"][1],
	},
	swaggerIcon: {
		stroke: theme.colors["green"][1],
	},
	serverIcon: {
		stroke: theme.colors[theme.primaryColor][2],
	},
}))
const Cards = ({
	openSwagger,
	openPostman,
}: {
	openPostman: () => void
	openSwagger: () => void
}) => {
	const { classes } = useStyles()

	return (
		<Stack
			mt={rem(24)}
			sx={{
				gap: rem(24),
			}}
			w='100%'
		>
			<Group
				p={rem(24)}
				sx={theme => ({
					gap: rem(16),
					borderRadius: rem(8),
					border: `1px solid ${theme.colors["refactor"][2]}`,
					cursor: "pointer",
				})}
				align='center'
				noWrap
				onClick={openSwagger}
			>
				<Stack>
					<Group
						sx={{
							gap: rem(12),
						}}
						align='center'
					>
						<IconApi className={classes.swaggerIcon} />
						<Text
							lh={rem(24)}
							fw={500}
							fz={rem(16)}
							sx={theme => ({
								color: theme.colors[theme.primaryColor][7],
							})}
						>
							OpenAPI Setup
						</Text>
					</Group>
					<Text
						lh={rem(20)}
						fz={rem(14)}
						fw={500}
						sx={theme => ({
							color: theme.colors["refactor"][4],
						})}
					>
						Import your OpenAPI specification to get started.
					</Text>
				</Stack>
				<Center
					sx={{
						flexGrow: 1,
					}}
				>
					<IconArrowNarrowRight />
				</Center>
			</Group>

			<Group
				p={rem(24)}
				sx={theme => ({
					gap: rem(16),
					borderRadius: rem(8),
					border: `1px solid ${theme.colors["refactor"][2]}`,
					cursor: "pointer",
				})}
				align='center'
				noWrap
				onClick={openPostman}
			>
				<Stack>
					<Group
						sx={{
							gap: rem(12),
						}}
						align='center'
					>
						<IconApi className={classes.postmanIcon} />
						<Text
							lh={rem(24)}
							fw={500}
							fz={rem(16)}
							sx={theme => ({
								color: theme.colors[theme.primaryColor][7],
							})}
						>
							Postman Collection Setup
						</Text>
					</Group>
					<Text
						lh={rem(20)}
						fz={rem(14)}
						fw={500}
						sx={theme => ({
							color: theme.colors["refactor"][4],
						})}
					>
						Import your existing Postman collections to get started.
					</Text>
				</Stack>
				<Center
					sx={{
						flexGrow: 1,
					}}
				>
					<IconArrowNarrowRight />
				</Center>
			</Group>

			{/* <Group
				p={rem(24)}
				sx={theme => ({
					gap: rem(16),
					borderRadius: rem(8),
					border: `1px solid ${theme.colors["refactor"][2]}`,
					cursor: "pointer",
				})}
				align='center'
				noWrap
				onClick={openCustom}
			>
				<Stack>
					<Group
						sx={{
							gap: rem(12),
						}}
						align='center'
					>
						<IconServerBolt className={classes.serverIcon} />
						<Text
							lh={rem(24)}
							fw={500}
							fz={rem(16)}
							sx={theme => ({
								color: theme.colors[theme.primaryColor][7],
							})}
						>
							Custom Configuration
						</Text>
					</Group>
					<Text
						lh={rem(20)}
						fz={rem(14)}
						fw={500}
						sx={theme => ({
							color: theme.colors["refactor"][4],
						})}
					>
						Configure your API settings from the ground up, ensuring a
						precise fit for your unique requirements.
					</Text>
				</Stack>
				<Center
					sx={{
						flexGrow: 1,
					}}
				>
					<IconArrowNarrowRight />
				</Center>
			</Group> */}
		</Stack>
	)
}

export default Cards
