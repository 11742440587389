import { useQuery } from "@tanstack/react-query"
import { getEndpointAnalytics } from "../../../../api/server/analytics/endpoint-analytics"
import { AxiosError } from "axios"
import useAppStore from "../../../../zustand/new-store"
import { useAuth } from "@clerk/clerk-react"

const useTableAnalytics = () => {
	const { cacheConfig } = useAppStore()

	const { getToken } = useAuth()
	return useQuery<{
		meta: {
			name: string
			type: string
		}[]
		data: {
			method_path: string
			total_requests: string
			avg_latency: number
			latency_percentile: number
			error_rate: number
			time_series: {
				time_group: string
				avg_latency: number
				latency_percentile: number
			}[]
			error_codes: {
				error_code: number
				error_count: number
			}[]
		}[]
		aggregateValues: never[]
	}>({
		queryFn: async () => {
			const token = await getToken()
			if (!token) {
				throw new AxiosError("Unauthenticated")
			}
			return getEndpointAnalytics({
				host: cacheConfig?.host || "",
				token,
			})
		},
		queryKey: [cacheConfig?.host],
		refetchOnWindowFocus: false,
	})
}

export default useTableAnalytics
