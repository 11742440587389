import { AxiosError } from "axios"

export const axiosErrorParser = (error: AxiosError) => {
	if (typeof error.response?.data === "string") {
		return error.response?.data
	}

	if (error.response?.data instanceof Array) {
		return error.response?.data as string[]
	}
	return error.response?.data as { error: string[] }
}
