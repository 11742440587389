import { Box, Group, rem, Skeleton, Stack, Text } from "@mantine/core"
import { IconTrendingDown, IconTrendingUp } from "@tabler/icons-react"
import { Intervals } from "../../../../types/dashboard-chart-types"
import { formatBytes, formatNumber } from "../../Endpoints/components/Table/utils"
import useCacheMetrics from "../api/useCacheMetrics"
const metricsLabel: Record<Intervals, string> = {
	"15minute": "past 15 mins",
	"1hour": "past hour",
	"6hour": "past 6 hrs",
	"1day": "yesterday",
	"7day": "past week",
	"1month": "past month"
}


const InfoCardLayout = ({ interval }: { interval: Intervals }) => {
	const { data, isLoading } = useCacheMetrics(interval)

	return (
		<Group
			align='center'
			sx={{
				gap: rem(16),
				">div": {
					flexGrow: 1,
				},
			}}
			noWrap
		>
			<Box
				p={24}
				sx={theme => ({
					borderRadius: rem(8),
					border: `1px solid ${theme.colors["refactor"][2]}`,
					gap: rem(16),
					flexBasis: "23%",
					alignItems: "center",
					display: "flex",
				})}
				h='100%'
			>
				<Stack sx={{ gap: rem(8) }}>
					{isLoading ? (
						<Skeleton h={32} />
					) : (
						<Text
							lh={rem(32)}
							fw={600}
							fz={rem(20)}
							c='brand.7'
						>
							{data?.data?.currentMetrics?.cache_hit_rate?.toFixed(1) ||
								0}
							%
						</Text>
					)}
					<Text
						fz={rem(14)}
						lh={rem(20)}
						fw={400}
						c='#404040'
					>
						Cached Responses
					</Text>

					{data?.data.percentageChange.cache_hit_rate_change &&
						typeof data?.data.percentageChange.cache_hit_rate_change ===
						"number" ? (
						<Group>
							<Text
								fz={rem(14)}
								lh={rem(16)}
								fw={400}
								c='refactor.4'
							>
								{metricsLabel[interval]}
							</Text>

							<Group
								sx={theme => ({
									gap: rem(2),
									borderRadius: rem(4),
									background:
										data?.data.percentageChange
											.cache_hit_rate_change &&
											data?.data.percentageChange
												.cache_hit_rate_change > 0
											? theme.fn.rgba(theme.colors["green"][7], 0.1)
											: theme.fn.rgba(
												theme.colors["danger"][0],
												0.1
											),
								})}
								c={
									data?.data.percentageChange.cache_hit_rate_change > 0
										? "green.7"
										: "danger.0"
								}
								pl={rem(4)}
								pr={rem(4)}
							>
								{data?.data.percentageChange.cache_hit_rate_change >
									0 ? (
									<IconTrendingUp size={12} />
								) : (
									<IconTrendingDown size={12} />
								)}
								<Text
									fw={400}
									lh={rem(16)}
									fz={rem(12)}
								>
									{Math.abs(data?.data.percentageChange.cache_hit_rate_change).toFixed(
										0
									) + "%"}
								</Text>
							</Group>
						</Group>
					) : null}
				</Stack>
			</Box>
			<Box
				p={24}
				sx={theme => ({
					borderRadius: rem(8),
					border: `1px solid ${theme.colors["refactor"][2]}`,
					gap: rem(16),
					flexBasis: "23%",
					alignItems: "center",
					display: "flex",
				})}
				h='100%'
			>
				<Stack sx={{ gap: rem(8) }}>
					{isLoading ? (
						<Skeleton h={32} />
					) : (
						<Text
							lh={rem(32)}
							fw={600}
							fz={rem(20)}
							c='brand.7'
						>
							{(data?.data.currentMetrics.total_response_size
								? formatBytes(data?.data.currentMetrics.total_response_size)
								: 0
							)}
						</Text>
					)}
					<Text
						fz={rem(14)}
						lh={rem(20)}
						fw={400}
						c='#404040'
					>
						Egress Savings
					</Text>

					{data?.data.percentageChange.total_response_size_change &&
						typeof data?.data.percentageChange.total_response_size_change ===
						"number" ? (
						<Group>
							<Text
								fz={rem(14)}
								lh={rem(16)}
								fw={400}
								c='refactor.4'
							>
								{metricsLabel[interval]}
							</Text>

							<Group
								sx={theme => ({
									gap: rem(2),
									borderRadius: rem(4),
									background:
										data?.data.percentageChange
											.total_response_size_change &&
											data?.data.percentageChange
												.total_response_size_change > 0
											? theme.fn.rgba(theme.colors["green"][7], 0.1)
											: theme.fn.rgba(
												theme.colors["danger"][0],
												0.1
											),
								})}
								c={
									data?.data.percentageChange
										.total_response_size_change > 0
										? "green.7"
										: "danger.0"
								}
								pl={rem(4)}
								pr={rem(4)}
							>
								{data?.data.percentageChange
									.total_response_size_change > 0 ? (
									<IconTrendingUp size={12} />
								) : (
									<IconTrendingDown size={12} />
								)}
								<Text
									fw={400}
									lh={rem(16)}
									fz={rem(12)}
								>
									{Math.abs(data?.data.percentageChange.total_response_size_change).toFixed() +
										"%"}
								</Text>
							</Group>
						</Group>
					) : null}
				</Stack>
			</Box>
			<Box
				p={24}
				sx={theme => ({
					borderRadius: rem(8),
					border: `1px solid ${theme.colors["refactor"][2]}`,
					gap: rem(16),
					flexBasis: "23%",
					alignItems: "center",
					display: "flex",
				})}
				h='100%'
			>
				<Stack sx={{ gap: rem(8) }}>
					{isLoading ? (
						<Skeleton h={32} />
					) : (
						<Group>

							<Text
								lh={rem(32)}
								fw={600}
								fz={rem(20)}
								c='brand.7'
							>
								{formatNumber(
									data?.data?.currentMetrics?.request_count || 0
								)}
							</Text>

						</Group>

					)}
					<Text
						fz={rem(14)}
						lh={rem(20)}
						fw={400}
						c='#404040'
					>
						Requests
					</Text>
					{data?.data.percentageChange.request_count_change &&
						typeof data?.data.percentageChange.request_count_change ===
						"number" ? (
						<Group>
							<Text
								fz={rem(14)}
								lh={rem(16)}
								fw={400}
								c='refactor.4'
							>
								{metricsLabel[interval]}
							</Text>

							<Group
								sx={theme => ({
									gap: rem(2),
									borderRadius: rem(4),
									background:
										data?.data.percentageChange
											.request_count_change &&
											data?.data.percentageChange.request_count_change >
											0
											? theme.fn.rgba(theme.colors["green"][7], 0.1)
											: theme.fn.rgba(
												theme.colors["danger"][0],
												0.1
											),
								})}
								c={
									data?.data.percentageChange.request_count_change > 0
										? "green.7"
										: "danger.0"
								}
								pl={rem(4)}
								pr={rem(4)}
								align='center'
							>
								{data?.data.percentageChange.request_count_change >
									0 ? (
									<IconTrendingUp size={12} />
								) : (
									<IconTrendingDown size={12} />
								)}
								<Text
									fw={400}
									lh={rem(16)}
									fz={rem(12)}
								>
									{Math.abs(data?.data.percentageChange.request_count_change).toFixed(
										0
									) + "%"}
								</Text>
							</Group>
						</Group>
					) : null}

				</Stack>
			</Box>
			<Box
				p={24}
				sx={theme => ({
					borderRadius: rem(8),
					border: `1px solid ${theme.colors["refactor"][2]}`,
					gap: rem(16),
					flexBasis: "23%",
					alignItems: "center",
					display: "flex",
				})}
				h='100%'
			>
				<Stack sx={{ gap: rem(8) }}>
					{isLoading ? (
						<Skeleton h={32} />
					) : (
						<Text
							lh={rem(32)}
							fw={600}
							fz={rem(20)}
							c='brand.7'
						>
							{data?.data?.currentMetrics?.requests_per_second.toFixed(
								1
							) || 0}
						</Text>
					)}
					<Text
						fz={rem(14)}
						lh={rem(20)}
						fw={400}
						c='#404040'
					>
						Request/Second
					</Text>

					{data?.data.percentageChange.requests_per_second_change &&
						typeof data?.data.percentageChange.requests_per_second_change ===
						"number" ? (
						<Group>
							<Text
								fz={rem(14)}
								lh={rem(16)}
								fw={400}
								c='refactor.4'
							>
								{metricsLabel[interval]}
							</Text>

							<Group
								sx={theme => ({
									gap: rem(2),
									borderRadius: rem(4),
									background:
										data?.data.percentageChange
											.requests_per_second_change &&
											data?.data.percentageChange
												.requests_per_second_change > 0
											? theme.fn.rgba(theme.colors["green"][7], 0.1)
											: theme.fn.rgba(
												theme.colors["danger"][0],
												0.1
											),
								})}
								c={
									data?.data.percentageChange
										.requests_per_second_change > 0
										? "green.7"
										: "danger.0"
								}
								pl={rem(4)}
								pr={rem(4)}
							>
								{data?.data.percentageChange
									.requests_per_second_change > 0 ? (
									<IconTrendingUp size={12} />
								) : (
									<IconTrendingDown size={12} />
								)}
								<Text
									fw={400}
									lh={rem(16)}
									fz={rem(12)}
								>
									{Math.abs(data?.data.percentageChange.request_count_change?.valueOf()!).toFixed(
										0
									) + "%"}
								</Text>
							</Group>
						</Group>
					) : null}
				</Stack>
			</Box>

		</Group>
	)
}

export default InfoCardLayout
