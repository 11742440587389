import { useMutation, useQueryClient } from "@tanstack/react-query"
import { axiosErrorParser } from "../../../lib/parse-axios-error"
import { AxiosError } from "axios"
import { IconCheck, IconX } from "@tabler/icons-react"
import { notifications } from "@mantine/notifications"
import useAppStore from "../../../zustand/new-store"
import { updateOrganizationMetadata } from "../../../api/supabase/update-org-metadata"
import { useState } from "react"
import { useAuth } from "@clerk/clerk-react"
import { configureNotifications } from "../../../api/server/notifications/configure"
import { Alert } from "../../../types/supabase.types"

async function update(
	orgId: string,
	alerts: Alert[],
	getToken: (
		options?: { template: string } | undefined
	) => Promise<string | null>
) {
	try {
		const result = await updateOrganizationMetadata(getToken, {
			org_id: orgId,
			alerts,
		})
		if (result.error || !result.data) {
			return { data: null, error: result.error || result.data }
		}
		return { data: result.data, error: null }
	} catch (error) {
		return { data: null, error }
	}
}

const useConfigureNotifs = () => {
	const { activeWorkspace, organizationsMetadata, setOrganizationMetadata } =
		useAppStore()
	const [supabaseLoading, setSupabaseLoading] = useState(false)
	const [serverSideJSONErrors, setServerSideValidationErrors] = useState<
		string[]
	>([])
	const [success, setSuccess] = useState(false)
	const { getToken, orgId } = useAuth()
	const queryClient = useQueryClient()

	const mutation = useMutation({
		mutationFn: configureNotifications,
		onError(error: AxiosError) {
			const errorMessage = axiosErrorParser(error)
			if (typeof errorMessage === "string") {
				notifications.show({
					withCloseButton: true,
					color: "danger.0",
					title: "Something went wrong",
					icon: <IconX />,
					autoClose: 5000,
					message: errorMessage,
				})
			} else {
				if (errorMessage instanceof Array) {
					notifications.show({
						withCloseButton: true,
						color: "danger.0",
						title: "Something went wrong",
						icon: <IconX />,
						autoClose: 5000,
						message: errorMessage[0],
					})
				} else {
					notifications.show({
						withCloseButton: true,
						color: "danger.0",
						title: "Something went wrong",
						icon: <IconX />,
						autoClose: 5000,
						message: errorMessage.error,
					})
				}
			}
			setSuccess(false)
			setSupabaseLoading(false)
		},
		async onSuccess() {
			setSupabaseLoading(true)
			const allAlertsWithoutCurrent = organizationsMetadata?.alerts?.filter(
				alert => alert.workspace_id !== activeWorkspace?.workspace_id
			)

			const current = organizationsMetadata?.alerts?.find(
				alert => alert.workspace_id === activeWorkspace?.workspace_id
			)
			const newAlert: Alert = current
				? {
						high_response_time: current.high_response_time,
						api_error_rate: current.api_error_rate,
						workspace_id: activeWorkspace?.workspace_id || "",
						configured: true,
				  }
				: {
						high_response_time: 0,
						api_error_rate: 0,
						workspace_id: activeWorkspace?.workspace_id || "",
						configured: true,
				  }
			const alerts = allAlertsWithoutCurrent
				? [...allAlertsWithoutCurrent, newAlert]
				: [newAlert]

			const { data, error } = await update(orgId || "", alerts, getToken)

			if (error || !data) {
				console.log("SupabaseError:", error)
				setSupabaseLoading(false)
				setSuccess(false)
				notifications.show({
					withCloseButton: true,
					color: "danger.0",
					title: "Something went wrong",
					icon: <IconX />,
					autoClose: 5000,
					message: "Something went wrong",
				})
				return
			}

			notifications.show({
				withCloseButton: true,
				color: "green",
				title: "Success",
				icon: <IconCheck />,
				autoClose: 5000,
				message: "Alerts config uploaded successfully",
			})
			setSupabaseLoading(false)
			setSuccess(true)
			queryClient.invalidateQueries({
				queryKey: [activeWorkspace?.workspace_id + "notifications"],
			})
			setOrganizationMetadata(data)
		},
	})

	return {
		supabaseLoading,
		setSupabaseLoading,
		serverSideJSONErrors,
		setServerSideValidationErrors,
		success,
		setSuccess,
		...mutation,
	}
}

export default useConfigureNotifs
