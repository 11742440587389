import { extent, max, bisector } from "@visx/vendor/d3-array"
import * as allCurves from "@visx/curve"
import { Group, Text, Box, Stack } from "@mantine/core"
import { Line, LinePath } from "@visx/shape"
import { scaleTime, scaleLinear, scaleOrdinal } from "@visx/scale"
import { createStyles, rem } from "@mantine/core"
import { GridColumns } from "@visx/grid"
import { AxisBottom } from "@visx/axis"
import { timeFormat } from "@visx/vendor/d3-time-format"
import { NumberValue } from "@visx/vendor/d3-scale"
import React, { useState } from "react"
import { Intervals } from "../../types/dashboard-chart-types"
import { useTooltip, useTooltipInPortal, defaultStyles } from "@visx/tooltip"
import { localPoint } from "@visx/event"
import { LegendOrdinal, LegendItem, LegendLabel } from "@visx/legend"
import { Group as MGroup } from "@visx/group"

interface Datum {
	date: Date
	value: number
}

interface DatumMeta {
	title: string
	unit: string
}

interface ToolTipData {
	data: Datum
	meta: DatumMeta
	time: Date
}

const getX = (d: Datum) => d.date
const getY = (d: Datum) => d.value
const bisectDate = bisector<Datum, Date>(d => new Date(d.date)).left

export type CurveProps = {
	dataSet: {
		data: Datum[]
		title: string
		stroke: string
		strokeWidth: number
	}[]
	width: number
	height: number
	interval: Intervals
	columnStroke?: string
	columnStrokeWidth?: number
}

const useStyles = createStyles(theme => ({
	xAxisLabels: {
		lineHeight: rem(20),
		fontWeight: 400,
		fontSize: rem(12),
		fontFamily: "Inter, sans-serif",
		color: "#404040",
		overflow: "visible",
		textAnchor: "middle",
	},
}))

const timeFormatSpecifierMap: Record<Intervals, string> = {
	"15minute": "%H:%M",
	"1hour": "%H:%M",
	"6hour": "%H:%M",
	"1day": "%a",
	"7day": "%a",
	"1month": "%b %d",
}

const datumMeta: Record<number, DatumMeta> = {
	1: {
		title: "Latency",
		unit: "ms",
	},
	0: {
		title: "Requests",
		unit: "",
	},
	2: {
		title: "Error rate",
		unit: "%",
	},
}

const ordinalColorScale = scaleOrdinal({
	domain: ["requests", "latency", "error rate"],
	range: ["#cfcdd1", "#C74FFF", "#fc819e"],
})

export default function VariableLineChart({
	width,
	height,
	dataSet = [],
	columnStroke = "#E5E5E5",
	columnStrokeWidth = 1,
	interval,
}: CurveProps) {
	const [hiddenIdxs, setHiddenIdxs] = useState<number[]>([])
	const { classes } = useStyles()
	const {
		tooltipData,
		tooltipLeft,
		tooltipTop,
		tooltipOpen,
		showTooltip,
		hideTooltip,
	} = useTooltip()

	const { containerRef, TooltipInPortal } = useTooltipInPortal({
		detectBounds: true,
		scroll: true,
	})

	const toggleHiddenIdxs = (arr: number[], num: number): void => {
		setHiddenIdxs(currentIdxs => {
			const index = currentIdxs.indexOf(num)
			let newArr = [...currentIdxs] // Create a new array for immutability
			if (index === -1) {
				// Number not in array, so add it
				newArr.push(num)
			} else {
				// Number already in array, so remove it
				newArr = newArr.filter(item => item !== num)
			}
			return newArr
		})
	}

	const handleMouseOver = (event: any, data: Datum[], idx: number) => {
		const coords: any = localPoint(event.target.ownerSVGElement, event)
		const time = xScale.invert(coords.x)
		const index = bisectDate(data, time, 1)
		const d0 = data[index - 1]
		const d1 = data[index]

		let d = d0
		if (d1 && getX(d1)) {
			d =
				time.valueOf() - getX(d0).valueOf() >
				getX(d1).valueOf() - time.valueOf()
					? d1
					: d0
		}

		showTooltip({
			tooltipLeft: coords.x,
			tooltipTop: coords.y,
			tooltipData: { data: d, meta: datumMeta[idx], time: time },
		})
	}

	const handleMouseOut = (event: any) => {
		hideTooltip()
	}

	const xScale = scaleTime<number>({
		domain: extent(dataSet[0].data, getX) as [Date, Date],
	})
	const yScale = scaleLinear<number>({
		domain: [0, max(dataSet[1].data, getY) as number],
	})

	return (
		<>
			<svg
				ref={containerRef}
				width={width}
				height={height + 90}
			>
				<rect
					x={0}
					y={0}
					width={width}
					height={height + 90}
					fill='#fff'
					rx={14}
					ry={14}
				/>
				<MGroup
					top={18}
					left={3}
				>
					<GridColumns
						scale={xScale}
						height={height + 40}
						strokeWidth={columnStrokeWidth}
						stroke={columnStroke}
						strokeDasharray='4'
					/>
					{dataSet.map((dataset, idx) => {
						const { title, data, stroke, strokeWidth } = dataset
						xScale.range([0, width])
						yScale.range([height, 0])
						return (
							<React.Fragment key={idx}>
								{!hiddenIdxs.includes(idx) ? (
									<>
										<LinePath<Datum>
											curve={allCurves["curveMonotoneX"]}
											data={data}
											x={d => xScale(getX(d)) ?? 0}
											y={d => yScale(getY(d)) ?? 0}
											stroke={stroke}
											strokeWidth={strokeWidth || 1.67}
											strokeOpacity={1}
											shapeRendering='geometricPrecision'
											width={width}
											height={height}
										/>
										<LinePath<Datum>
											curve={allCurves["curveMonotoneX"]}
											data={data}
											x={d => xScale(getX(d)) ?? 0}
											y={d => yScale(getY(d)) ?? 0}
											stroke={stroke}
											strokeWidth={15}
											strokeOpacity={0} //a wide invisible line for cursors
											shapeRendering='geometricPrecision'
											width={width}
											height={height + (idx == 2 ? 50 : 0)}
											onMouseMove={(x: any): any => {
												handleMouseOver(x, data, idx)
											}}
											onMouseOut={handleMouseOut}
										/>
										{tooltipData ? (
											<g>
												<Line
													from={{ x: tooltipLeft, y: 0 }}
													to={{
														x: tooltipLeft,
														y: innerHeight + 40,
													}}
													stroke={columnStroke}
													strokeWidth={strokeWidth || 1.67}
													pointerEvents='none'
													strokeDasharray='5,2'
												/>
											</g>
										) : null}
									</>
								) : null}
							</React.Fragment>
						)
					})}

					<AxisBottom
						top={height + 44}
						scale={xScale}
						stroke='#404040'
						tickLabelProps={{
							className: classes.xAxisLabels,
						}}
						hideTicks
						hideAxisLine
						tickFormat={(
							value: NumberValue | Date,
							index: number,
							values: {
								value: NumberValue | Date
								index: number
							}[]
						) => {
							const format = timeFormat(timeFormatSpecifierMap[interval])
							const date = new Date(value.toString())

							if (index === 1) {
								return format(date)
							} else if (index === values.length - 2) {
								return format(date)
							} else {
								return ""
							}
						}}
					/>
				</MGroup>

				{tooltipOpen ? (
					<>
						<TooltipInPortal
							key={Math.random()}
							top={tooltipTop! - 20}
							left={tooltipLeft}
							style={{
								...defaultStyles,
								minWidth: 72,
								fontSize: rem(12),
								textAlign: "center",
								border: "1px solid white",
							}}
						>
							{(tooltipData as ToolTipData).meta.title}:
							<strong>
								{(tooltipData as ToolTipData).data.value}
								{(tooltipData as ToolTipData).meta.unit}
							</strong>
						</TooltipInPortal>
						<TooltipInPortal
							key={Math.random()}
							top={height + 60}
							left={tooltipLeft}
							style={{
								...defaultStyles,
								minWidth: 72,
								fontSize: rem(11),
								textAlign: "center",
								border: "1px solid white",
							}}
						>
							{(() => {
								const format = timeFormat(
									timeFormatSpecifierMap[interval]
								)
								return <>{format((tooltipData as ToolTipData).time)}</>
							})()}
						</TooltipInPortal>
					</>
				) : null}
			</svg>
			<Stack
				w='100%'
				justify='center'
				sx={{
					gap: rem(6),
					alignItems: "center",
				}}
			>
				<LegendOrdinal
					scale={ordinalColorScale}
					labelFormat={label => `${label.toLocaleLowerCase()}`}
				>
					{labels => {
						return (
							<Group
								align='center'
								sx={{
									gap: rem(8),
								}}
							>
								{labels.map((label, i) => (
									<Box
										style={{
											opacity: hiddenIdxs.includes(i) ? 0.3 : 1,
											cursor: "pointer",
										}}
										key={i}
									>
										<LegendItem
											key={`legend-quantile-${label.datum}`}
											margin='0 5px'
											onClick={() => {
												toggleHiddenIdxs(hiddenIdxs, i)
											}}
										>
											<svg
												width={20}
												height={2}
											>
												<rect
													fill={label.value}
													width={20}
													height={3}
												/>
											</svg>
											<LegendLabel
												align='left'
												margin='0 0 0 4px'
											>
												<Text
													fw={200}
													lh={rem(14)}
													fz={rem(13)}
													c='#343A40'
												>
													{label.text}
												</Text>
											</LegendLabel>
										</LegendItem>
									</Box>
								))}
							</Group>
						)
					}}
				</LegendOrdinal>
			</Stack>
		</>
	)
}
