import { axiosConfig } from "../../../config/axios.config"
import { CacheConfig } from "../../../lib/parser"

export const updateEndpoint = async (body: {
	config: Partial<CacheConfig>
	token: string
}) => {
	const response = await axiosConfig.post(
		"/config",
		{
			...body.config,
		},
		{
			headers: {
				Authorization: `Bearer ${body.token}`,
				"Content-Type": "application/json",
			},
		}
	)
	const { data } = response

	return data
}
