import { Box, Button, Stack, Title, rem } from "@mantine/core"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { useAuth } from "@clerk/clerk-react"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import useAppStore from "../../../zustand/new-store"
import Loading from "../loading"
import { UpdateOrg } from "../../../types/supabase.types"
import { updateOrganizationMetadata } from "../../../api/supabase/update-org-metadata"
import useCompletedGetStarted from "../../../hooks/useCompletedGetStarted"
dayjs.extend(utc)
export default function PaymentConfirmation() {
	const navigate = useNavigate()
	const [loading, setLoading] = useState(false)
	const [searchParams] = useSearchParams()
	const { getToken } = useAuth()
	const { setOrganizationMetadata, organizationsMetadata, getStartedLoading } =
		useAppStore()
	const sid = searchParams.get("session_id")
	const success = searchParams.get("success")
	const { data, error } = useCompletedGetStarted()

	const update = async (
		getToken: (
			options?: { template: string } | undefined
		) => Promise<string | null>,
		sessionId: string | null,
		success: string | null
	) => {
		if (!organizationsMetadata || success !== "true" || !sessionId) {
			return
		}
		// if (organizationsMetadata.billing?.plan.stripe_session_id) {
		// 	console.log("saved")
		// 	return
		// }
		setLoading(true)

		try {
			const addons = organizationsMetadata.billing?.addons
				? organizationsMetadata.billing?.addons
				: {}
			const newOrganization: UpdateOrg = {
				org_id: organizationsMetadata.org_id,
				billing: {
					plan: {
						created_at: dayjs().utc().format(),
						stripe_session_id: sessionId,
					},
					addons,
				},
			}

			const { data, error } = await updateOrganizationMetadata(
				getToken,
				newOrganization
			)

			if (!data || error) {
				console.log(error || data)
				return
			} else {
				setOrganizationMetadata(data)
			}
		} catch (error) {
			console.log(error)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		let ignore = false
		if (!ignore) {
			update(getToken, sid, success)
		}
		return () => {
			ignore = true
		}
	}, [sid, success, organizationsMetadata])

	return (
		<Box w='100%'>
			{loading || getStartedLoading ? (
				<Loading />
			) : (
				<Stack
					align='center'
					sx={{
						gap: rem(24),
						justifyContent: "center",
					}}
					h={`max(calc(100vh - ${rem(212)}), 300px)`}
				>
					<Title
						c='brand.7'
						order={3}
					>
						Payment Successful!
					</Title>
					<Button
						variant='outline'
						sx={{
							borderRadius: rem(8),
							fontSize: rem(12),
						}}
						onClick={() => {
							if (!data || error) {
								navigate("/get-started", { replace: true })
							} else {
								navigate("/", { replace: true })
							}
						}}
					>
						Continue
					</Button>
				</Stack>
			)}
		</Box>
	)
}
