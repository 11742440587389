//import fetch from 'node-fetch';
import { OpenAPIV2, OpenAPIV3, OpenAPIV3_1 } from "openapi-types"
import * as z from "zod"

type OpenApiDoc = any

export interface ResilisConfig {
	enabled?: boolean
	async?: boolean
	ttl?: number
	purge?: string[] | null
	type?: "private" | "public" | "protected" | "login" | "logout" | "refresh"
	tags?: string[]
	summary?: string
	description?: string
	unmanaged?: boolean
	cache?: boolean
}

export interface CacheDoc {
	apis: string[]
	paths: PathConfig
}
export interface PathConfig {
	[path: string]: {
		[method: string]: ResilisConfig
	}
}

export interface SecurityConfig {
	geoRestrictions?: {
		allowed: string[] // Default: []
		blocked: string[] // Default: []
	}
	rateLimit?: {
		enabled: boolean
		limit: number
		window: number // in milliseconds
	}
	jwtVerify: boolean // Enable or disable JWT authentication
	strictRouting: boolean // Enable or disable strict routing
}

export type Protocols = "JSON-RPC" | "REST"

const errorMap: z.ZodErrorMap = (error, ctx) => {
	switch (error.code) {
		case z.ZodIssueCode.invalid_union_discriminator:
			return { message: "Type must be either header or query" }
		default:
			return { message: ctx.defaultError }
	}
}

export const serviceAuthSchema = z
	.discriminatedUnion(
		"type",
		[
			z.object({
				type: z.literal("header", {
					invalid_type_error: "Type must be either header or query",
				}),
				key: z
					.string({ invalid_type_error: "Key must be a string" })
					.max(20, { message: "Key must have a max of 20 characters" }),
				variant: z.enum(["", "Authorization", "custom"]).optional(),
			}),
			z.object({
				type: z.literal("query", {
					invalid_type_error: "Type must be either header or query",
				}),
				key: z
					.string({ invalid_type_error: "Key must be a string" })
					.max(20, { message: "Key must have a max of 20 characters" }),
			}),
		],
		{
			errorMap,
		}
	)
	.superRefine((values, refinementContext) => {
		if (!values.type) {
			refinementContext.addIssue({
				code: z.ZodIssueCode.custom,
				message: `type is required when authorization is enabled`,
				path: ["type"],
			})
		}
		if (!values.key) {
			refinementContext.addIssue({
				code: z.ZodIssueCode.custom,
				message: `key is required when authorization is enabled`,
				path: ["key"],
			})
		}

		if (values.type === "header" && "variant" in values && !values.variant) {
			refinementContext.addIssue({
				code: z.ZodIssueCode.custom,
				message: `variant is required when authorization is enabled with header auth type `,
				path: ["variant"],
			})
		}
	})

export type serviceAuthSchemaType = z.infer<typeof serviceAuthSchema>

export type Authorization = {
	value: string
	type: serviceAuthSchemaType["type"]
}

export interface CacheConfig {
	host: string
	origins: string[]
	basePath: string
	security: SecurityConfig | null
	cache: CacheDoc
	orgId?: string
	protocol?: Protocols
	authorization?: Authorization | null
}
export interface ConfigSupabaseMetadata {
	workspace_id: string
	source: "local" | "Postman" | "Swagger"
	source_url: string | null
	user_id: string
	org_id: string
	uuid: string
	created_at: Date
	updated_at: Date
}
async function parseOpenApiDoc(
	url: string,
	hostname: string,
	origins: string[]
): Promise<CacheConfig> {
	const response = await fetch(url, {
		method: "GET",
		mode: "no-cors",
		headers: {
			"Content-Type": "application/json",
		},
	})
	const openApiDoc: OpenAPIV2.Document | any = await response.json()

	const paths: PathConfig = {}

	for (const path in openApiDoc.paths) {
		const convertedPath = path.replace(/{(\w+)}/g, ":$1")
		paths[convertedPath] = {}

		for (const method in openApiDoc.paths[path]) {
			const operation = openApiDoc.paths[path][method]
			if (operation["x-resilis-config"]?.invalidateParams) {
				operation["x-resilis-config"].invalidateParams.forEach(
					(param: { current: string; target: string }) => {
						param.target = param.target.replace(/{(\w+)}/g, ":$1")
					}
				)
			}
			paths[convertedPath][method] = operation["x-resilis-config"]
		}
	}

	const cacheConfig: CacheConfig = {
		host: hostname,
		basePath: openApiDoc.basePath,
		origins: origins,
		cache: {
			apis: [...origins, openApiDoc.basePath!],
			paths: paths,
		},
		security: null,
	}

	return cacheConfig
}
export const addSinglePathSchema = z.object({
	path: z.string().min(1, { message: "Path is required" }),
	// .startsWith("/", { message: "Endpoint path must begin with /" }),
	method: z.enum([
		"post",
		"put",
		"patch",
		"delete",
		"get",
		"head",
		"option",
		"",
	]),
	type: z.enum([
		"private",
		"public",
		"protected",
		"login",
		"logout",
		"refresh",
	]),
	summary: z.string().optional(),
	description: z.string().optional(),
	ttl: z.coerce
		.number({ invalid_type_error: "TTL must be a number" })
		.min(0, { message: "TTL must have a minimum value of 0" }),
	purge: z.string().array().min(0),
	enabled: z.boolean(),
	async: z.boolean(),
	tags: z.string().array().min(0),
	unmanaged: z.boolean().optional(),
	cache: z.boolean().optional(),
})

export type CacheConfigSchemaType = z.infer<typeof addSinglePathSchema>
export default parseOpenApiDoc

export interface SSLSettings {
	tls_1_3: string
	min_tls_version: string
	ciphers: Array<string>
	early_hints: string
}

export type SSLType = {
	id: string
	type: string
	method: "http" | "txt"
	status: string
	settings: SSLSettings
	wildcard: boolean
	certificate_authority: string
}

export type DomainVerificationTextType = {
	type: "txt"
	name: string
	value: string
}
export type DomainVerificationHTTPType = {
	http_url: string
	http_body: string
}
export interface AddDomainResult {
	id: string
	hostname: string
	ssl: SSLType
	status: "pending" | "active"
	ownership_verification: DomainVerificationTextType
	ownership_verification_http: DomainVerificationHTTPType
	created_at: string
}

export interface AddDomainResponse {
	result: AddDomainResult
	success: boolean
	errors: Array<string>
	messages: Array<string>
}
