import {
	Box,
	Group,
	Stack,
	Text,
	rem,
	createStyles,
	Skeleton,
	Center,
} from "@mantine/core"
import GeoChart from "../../../../Components/GeoChart/GeoChart"
import "/node_modules/flag-icons/css/flag-icons.min.css"
import { getCountryData, type TCountryCode } from "countries-list"
import ProgressBar from "../../../../Components/ProgressBar/ProgressBar"
import ParentSize from "@visx/responsive/lib/components/ParentSize"
import { useMemo } from "react"
import countryCoordinates from "./coordinates.json"
import { Intervals } from "../../../../types/dashboard-chart-types"
import useGeoTraffic from "../api/useGeoTraffic"
import { IconWorldUp } from "@tabler/icons-react"
const useStyles = createStyles(theme => ({
	flag: {
		borderRadius: "50%",
		height: `${rem(24)}!important`,
		width: `${rem(24)}!important`,
	},
}))
const GeoTraffic = ({ interval }: { interval: Intervals }) => {
	const { classes } = useStyles()
	const { data: response, isLoading } = useGeoTraffic(interval)

	const totalRequests = useMemo(() => {
		if (!response) {
			return 0
		} else {
			return response.data.reduce((a, b) => {
				return a + Number(b.number_of_requests)
			}, 0)
		}
	}, [response])
	const data = useMemo(() => {
		if (!response) {
			return []
		} else {
			return response.data
				.map(item => {
					const cData = getCountryData(item.country as TCountryCode)
					const coordinates = countryCoordinates.ref_country_codes.find(
						c => c.alpha3 === cData.iso3
					)
					return {
						name: cData.name,
						code: cData.iso3,
						value:
							(Number(item.number_of_requests) / totalRequests) * 100,
						flag: (
							<span
								className={`fi fi-${item.country.toLowerCase()} fis ${
									classes.flag
								}`}
							/>
						),
						coordinates: {
							lat: coordinates?.latitude || 0,
							lng: coordinates?.longitude || 0,
						},
					}
				})
				.slice(0, 5)
		}
	}, [response])
	return (
		<Stack
			sx={theme => ({
				borderRadius: rem(8),
				border: `1px solid ${theme.colors["refactor"][2]} `,
			})}
			p={rem(32)}
		>
			<Text
				c='brand.7'
				fz={rem(16)}
				fw={500}
				lh={rem(24)}
				pb={rem(30)}
			>
				Traffic
			</Text>

			{isLoading ? (
				<Skeleton
					h={rem(348)}
					w='100%'
				/>
			) : response && data && data.length > 0 ? (
				<Group
					sx={{
						gap: 0,
					}}
					align='flex-start'
					noWrap
				>
					<Box
						h={rem(338)}
						w='78%'
					>
						<Box
							h={`calc(100% - ${rem(114)})`}
							w='100%'
						>
							<ParentSize>
								{({ width, height }) => (
									<GeoChart
										height={height}
										width={width}
										data={data}
									/>
								)}
							</ParentSize>
						</Box>
					</Box>
					<Stack
						sx={{
							gap: rem(12),
						}}
						ml={rem(-20)}
					>
						{response.data.slice(0, 5).map(datum => {
							const cData = getCountryData(datum.country as TCountryCode)
							const percentage =
								(Number(datum.number_of_requests) / totalRequests) * 100
							return (
								<Group
									sx={{
										gap: rem(16),
									}}
									align='flex-start'
									key={datum.country}
									noWrap
								>
									<Box
										sx={{
											borderRadius: "50%",
										}}
									>
										<span
											className={`fi fi-${datum.country.toLowerCase()} fis ${
												classes.flag
											}`}
										/>
									</Box>

									<Stack
										sx={{
											gap: "2px",
										}}
									>
										<Text
											lh={rem(20)}
											fw={500}
											fz={rem(14)}
											c='#404040'
										>
											{cData.name}
										</Text>
										<Group
											align='center'
											sx={{
												gap: rem(16),
											}}
											noWrap
										>
											<ProgressBar
												value={percentage}
												max={100}
												color='primary'
											/>
											<Text
												lh={rem(20)}
												fz={rem(14)}
												fw={500}
												c='#344054'
											>
												{percentage.toFixed(2)}%
											</Text>
										</Group>
									</Stack>
								</Group>
							)
						})}
					</Stack>
				</Group>
			) : (
				<Center
					w='100%'
					sx={{
						gap: rem(16),
						flexDirection: "column",
					}}
					h={rem(256)}
				>
					<Center c='#000'>
						<IconWorldUp size={rem(24)} />
					</Center>
					<Stack
						sx={{
							gap: rem(8),
						}}
						w={rem(280)}
					>
						<Text
							c='brand.7'
							fz={rem(16)}
							fw={400}
							lh={rem(24)}
							ta='center'
						>
							No Geographic Traffic Data
						</Text>
						<Text
							lh={rem(20)}
							fw={400}
							fz={rem(14)}
							c='refactor.4'
							ta='center'
						>
							See where your users are located! Start routing API traffic
							through Resilis.
						</Text>
					</Stack>
				</Center>
			)}
		</Stack>
	)
}

export default GeoTraffic
