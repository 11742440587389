import {
	Drawer,
	rem,
	createStyles,
	ScrollArea,
	Button,
	Group,
	Center,
} from "@mantine/core"
import { IconSettings } from "@tabler/icons-react"
import { useRef } from "react"
import { useNavigate } from "react-router-dom"
const useStyles = createStyles(theme => ({
	overlay: {
		background: "rgba(3, 2, 23, 0.20)",
	},
	content: {
		margin: rem(16),
		borderRadius: rem(8),
		padding: rem(24),
		background: theme.white,
		width: `min(${rem(450)}, 100%)`,
		minWidth: rem(360),
		boxShadow: "none",
		height: `calc(100vh - ${rem(32)})`,
		">div": {
			height: "100%!important",
			maxHeight: "fit-content",
		},
	},
	header: {
		display: "inline-block",
		borderBottom: "1px solid #eee",
		width: "100%",
		padding: `0 0 ${rem(16)}`,
	},
	title: {
		textTransform: "capitalize",
		fontWeight: 500,
		lineHeight: rem(24),
		fontSize: rem(18),
		color: theme.colors[theme.primaryColor][7],
		fontFeatureSettings: "'clig' off, 'liga' off",
	},
	body: {
		padding: 0,
	},
	btn: {
		background: "transparent",
		display: "inline-flex",
		alignItems: "center",
		justifyContent: "center",
		padding: `${rem(8)} ${rem(12)}`,
		borderRadius: rem(8),
		borderWidth: "1px",
		borderStyle: "solid",
		borderColor: theme.colors["refactor"][3],
		marginBottom: rem(24),
		":hover": {
			background: "transparent",
		},
	},
	inner: {
		" .mantine-ScrollArea-root, .mantine-ScrollArea-viewport > div": {
			minHeight: `calc(100vh - ${rem(80)})`,
			height: "100%",
		},
	},
}))

const CustomDrawer = ({
	opened,
	onClose,
	children,
	title,
	backButtonAction,
	backButtonContent,
}: {
	opened: boolean
	onClose: () => void
	children: React.ReactNode
	title?: string
	backButtonContent?: React.ReactNode
	backButtonAction?: () => void
}) => {
	const { classes } = useStyles()
	const navigate = useNavigate()
	const ref = useRef<HTMLDivElement | null>(null)

	return (
		<Drawer.Root
			opened={opened}
			onClose={() => {
				onClose()
			}}
			trapFocus={false}
			position='right'
			classNames={{
				overlay: classes.overlay,
				content: classes.content,
				header: classes.header,
				title: classes.title,
				body: classes.body,
				inner: classes.inner,
			}}
			title={title}
			size={500}
			withinPortal
			scrollAreaComponent={ScrollArea.Autosize}
		>
			<Drawer.Overlay />
			<Drawer.Content>
				{backButtonContent || title ? (
					<Drawer.Header ref={ref}>
						{backButtonContent ? (
							<Button
								onClick={() => {
									if (backButtonAction) backButtonAction()
								}}
								className={classes.btn}
							>
								{backButtonContent}
							</Button>
						) : null}

						{title ? (
							<Group
								align='center'
								sx={{
									justifyContent: "space-between",
								}}
							>
								<Drawer.Title>{title}</Drawer.Title>
								{title === "Notifications" ? (
									<Center
										sx={{
											fill: "#fff",
											cursor: "pointer",
										}}
										role='button'
										onClick={() => {
											if (title === "Notifications") {
												onClose()
												navigate("/settings/notifications")
											}
										}}
									>
										<IconSettings size={24} />
									</Center>
								) : null}
							</Group>
						) : null}
					</Drawer.Header>
				) : null}
				<Drawer.Body
					display='flex'
					mih={`calc(100vh - ${rem(
						104 +
							Number(ref?.current?.getBoundingClientRect()?.height || 40)
					)})`}
					sx={{ gap: 0 }}
				>
					{children}
				</Drawer.Body>
			</Drawer.Content>
		</Drawer.Root>
	)
}

export default CustomDrawer
