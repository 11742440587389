import { supabaseClient } from "../../config/supabase.config"
import { SUPABASE_ORGANIZATION } from "../../constants/supabase-tables"
import { UpdateOrg } from "../../types/supabase.types"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
dayjs.extend(utc)

export const updateOrganizationMetadata = async (
	getToken: (
		options?: { template: string } | undefined
	) => Promise<string | null>,
	organization: UpdateOrg
) => {
	try {
		const supabaseAccessToken = (await getToken({
			template: "supabase",
		})) as string

		const supabase = supabaseClient(supabaseAccessToken)
		const org_id = organization.org_id
		const data = await supabase
			.from(SUPABASE_ORGANIZATION)
			.select()
			.eq("org_id", org_id)
			.single()
		if (!data.data) {
			return { data: null, error: "Organization not found" }
		}
		if (
			data.data.billing &&
			data.data.billing.plan.stripe_session_id ===
				organization.billing?.plan.stripe_session_id
		) {
			return {
				data: null,
				error: "A plan with this session has been created",
			}
		}
		const updateData = await supabase
			.from(SUPABASE_ORGANIZATION)
			.update({ ...organization, updated_at: dayjs().utc().format() })
			.eq("org_id", org_id)
			.select()
			.single()
		return { data: updateData.data, error: null }
	} catch (error) {
		console.log(error)
		return { data: null, error }
	}
}
