import { getCacheMetrics } from "../../../../api/server/analytics/cache-metrics"
import { useQuery } from "@tanstack/react-query"
import useAppStore from "../../../../zustand/new-store"
import { AxiosError } from "axios"
import { useAuth } from "@clerk/clerk-react"
import { Intervals } from "../../../../types/dashboard-chart-types"
import { ANALYTICS_REFETCH_INTERVAL } from "../../../../constants/analytics-refetch"
const useCacheMetrics = (interval: Intervals) => {
	const { cacheConfig } = useAppStore()
	const { getToken } = useAuth()

	return useQuery<{
		meta: {
			name: string
			type: string
		}[]
		data: {
			currentMetrics: {
				cache_hit_rate: number
				request_count: number
				total_response_size: number
				requests_per_second: number
			}
			percentageChange: {
				cache_hit_rate_change: number | null
				request_count_change: number | null
				total_response_size_change: number | null
				requests_per_second_change: number | null
			}
		}
		aggregateValues: never[]
	}>({
		queryFn: async () => {
			const token = await getToken()
			if (!token) {
				throw new AxiosError("Unauthenticated")
			}
			return getCacheMetrics({
				host: cacheConfig?.host || "",
				token,
				interval,
			})
		},
		queryKey: [cacheConfig?.host, interval, "cache metrics"],
		refetchOnWindowFocus: false,
		refetchInterval: data => {
			if (!data) {
				return false
			} else {
				return ANALYTICS_REFETCH_INTERVAL
			}
		},
		retry: 1,
	})
}

export default useCacheMetrics
