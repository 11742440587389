import { analyticsAxiosConfig } from "../../../config/axios.config"
import { Intervals } from "../../../types/dashboard-chart-types"

export async function getCacheMetrics(body: {
	token: string
	interval: Intervals
	host: string
}) {
	const response = await analyticsAxiosConfig.get(
		`/analytics/cache/cache-metrics?interval=${body.interval}&host=${body.host}`,
		{
			headers: {
				Authorization: `Bearer ${body.token}`,
			},
		}
	)
	const { data } = response
	return data
}
